import React from "react";
import { Row, Col, Card, Container } from "react-bootstrap";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import PureBreadcrumbs from "../../breadcrums";
import ApexCharts from "react-apexcharts";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { fetchStationComparisionAction } from "../../../../store/actions/insightAction";
import { allEvseAction } from "../../../../store/actions/evseAction";
import { useTranslation } from "react-i18next";

function StationComparisionGraph(props) {
  const currency = process.env.REACT_APP_CURRENCY;
  const { t } = useTranslation();
  //   Chart.register(...registerables);

  const [startDate, setStartDate] = useState(
    new Date(Date.now() - 3600 * 1000 * 720)
  );
  const [endDate, setEndDate] = useState(
    new Date()
  );

  useEffect(() => {
    props.all();
  }, []);

  const [station1, setStation1] = useState(null);
  const [station2, setStation2] = useState(null);

  const handleStation1Change = (e) => {
    const data = e.target.value;
    setStation1(data);
  };
  const handleStation2Change = (e) => {
    const data = e.target.value;
    setStation2(data);
  };
  const onSubmit = () => {
    if (station1 == null) {
      alert("Select Station 1")
    } if (station2 == null) {
      alert("Select Station 2")
    }
    props.stationcompare(station1, station2, startDate.toISOString().slice(0, 10), endDate.toISOString().slice(0, 10));
  };

  // console.log("station 1", props.station.data1)
  // console.log("station 2", props.station.data2)

  const series1 = [
    {
      name: "Total Energy",
      data:
        props.station?.data1 && props.station.data1.map((en) => en.consumption),
    },
    {
      name: "Total Revenue",
      data: props.station?.data1 && props.station.data1.map((en) => en.amount),
    },
  ];

  const series2 = [
    {
      name: "Total Energy",
      data:
        props.station?.data2 && props.station.data2.map((en) => en.consumption),
    },
    {
      name: "Total Revenue",
      data: props.station?.data2 && props.station.data2.map((en) => en.amount),
    },
  ];

  const options = {
    chart: {
      toolbar: {
        show: false,
      },
    },
    grid: {
      show: true,      // you can either change hear to disable all grids
      xaxis: {
        lines: {
          show: false  //or just here to disable only x axis grids
         }
       },  
      yaxis: {
        lines: { 
          show: false  //or just here to disable only y axis
         }
       },   
    },
    tooltip: {
      enabled: true,
      enabledOnSeries: undefined,
      followCursor: false,
      fillSeriesColor: false,
      theme: false,
      style: {
        fontSize: '12px',
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
        endingShape: "rounded",
        borderRadius: 5,
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories:
        props.station?.data1 && props.station.data1?.map((en) => en.stationid),
      labels: {
        show: false,
        style: {
          colors: "#FFFFFF", // Replace with the color you want for Y-axis content
        },
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: [
      {
        title: {
          text: "Total Energy",
          style: {
            color: "#FFFFFF",
            fontSize:"13px",
            fontWeight: 600,
        },
        },
        labels: {
          formatter: function (value) {
            return value.toFixed(2);
          },
          style: {
            colors: "#FFFFFF", // Replace with the color you want for Y-axis content
          },
        },
        min: 0,
      },

      {
        opposite: true,
        title: {
          text: "Total Revenue",
          style: {
            color: "#FFFFFF",
            fontSize:"13px",
            fontWeight: 600,
        },
        },
        labels: {
          formatter: function (value) {
            return `${currency} ${value.toFixed(2)}`;
          },
          style: {
            colors: "#FFFFFF", // Replace with the color you want for Y-axis content
          },
        },

      },
    ],
    fill: {
      type: "gradient",
      gradient: {
        shade: "dark",
        type: "vertical",
        shadeIntensity: 0.5,
        gradientToColors: ["#5559B2", "#0BCF6B"],
        inverseColors: true,
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 100],
      },
    },
    legend: {
      position: "top",
      labels: {
        colors: "#FFFFFF", // Replace with the desired color for legend labels
      },
    },

    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: "100%",
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };
  const options2 = {
    chart: {
      toolbar: {
        show: false,
      },
    },
    grid: {
      show: true,      // you can either change hear to disable all grids
      xaxis: {
        lines: {
          show: false  //or just here to disable only x axis grids
         }
       },  
      yaxis: {
        lines: { 
          show: false  //or just here to disable only y axis
         }
       },   
    },
    tooltip: {
      enabled: true,
      enabledOnSeries: undefined,
      followCursor: false,
      fillSeriesColor: false,
      theme: false,
      style: {
        fontSize: '12px',
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
        endingShape: "rounded",
        borderRadius: 5,
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      
      categories:
        props.station.data2 && props.station.data2.map((en) => en.stationid),
      labels: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: [
      {
        title: {
          text: "Total Energy",
          style: {
            color: "#FFFFFF",
            fontSize:"13px",
            fontWeight: 600,
        },
        },
        labels: {
          formatter: function (value) {
            return value.toFixed(2);
          },
          style: {
            colors: "#FFFFFF", // Replace with the color you want for Y-axis content
          },
        },
        min: 0,
      },

      {
        opposite: true,
        title: {
          text: "Total Revenue",
          style: {
            color: "#FFFFFF",
            fontSize:"13px",
            fontWeight: 600,
        },
        },
        labels: {
          formatter: function (value) {
            return `${currency} ${value.toFixed(2)}`;
          },
          style: {
            colors: "#FFFFFF", // Replace with the color you want for Y-axis content
          },
        },
      },
    ],
    fill: {
      type: "gradient",
      gradient: {
        shade: "dark",
        type: "vertical",
        shadeIntensity: 0.5,
        gradientToColors: ["#5559B2", "#0BCF6B"],
        inverseColors: true,
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 100],
      },
    },
    legend: {
      position: "top",
      labels: {
        colors: "#FFFFFF", // Replace with the desired color for legend labels
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: "100%",
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };

  // console.log("EFWE", props.station)

  return (
    <Container fluid>
      <PureBreadcrumbs />
      <br />
      <Row className="mt-4">
        <Col>
          <Card className="customercard">
            <Card.Header>
              <Row>
                <Col xs={12} md={4} className="pb-2">
                  {t("Station Comparision Graph")}
                </Col>
                <Col xs={12} md={12} >
                  <div className="row float-right">
                    <div className="mb-2 mr-2" >
                      <select style={{ width: "15rem", borderRadius: "3px", borderColor: "#a7a6a6" }} onChange={(e) => handleStation1Change(e)}>
                        <option value={""}>Select Station 1</option>
                        {props.list &&
                          props.list.data?.filter((item) => item.status !== 'Discovery' && item.publish !== false)?.map((c, i) => (
                            <option key={c.name} value={c.name}>
                              {c.name}
                            </option>
                          ))}
                      </select>
                    </div>

                    <div >
                      <select style={{ width: "15rem", borderRadius: "3px", borderColor: "#a7a6a6" }} onChange={(e) => handleStation2Change(e)}>
                        <option value={""}>Select Station 2</option>
                        {props.list &&
                          props.list.data?.filter((item) => item.status !== 'Discovery' && item.publish !== false)?.map((c, i) => (
                            <option key={c.name} value={c.name}>
                              {c.name}
                            </option>
                          ))}
                      </select>
                    </div>
                    <div className="row justify-content-center ml-2">
                      <div className="col-auto claender">
                        <DatePicker
                          className="gray-border"
                          selected={startDate}
                          onChange={(date) => setStartDate(date)}
                          selectsStart
                          startDate={startDate}
                          maxDate={endDate}
                          dateFormat="dd/MM/yyyy"
                        />
                      </div>
                      <span style={{ paddingTop: "7px" }}> To </span>
                      <div className="col-auto mx-2">
                        <DatePicker
                          className="gray-border"
                          selected={endDate}
                          onChange={(date) => setEndDate(date)}
                          selectsEnd
                          startDate={startDate}
                          endDate={endDate}
                          minDate={startDate}
                          maxDate={new Date()}
                          todayButton="Today"
                          showDisabledMonthNavigation
                          dateFormat="dd/MM/yyyy"
                        />
                      </div>
                    </div>
                    <div className="csvbutton go-btn ml-5">
                      <button className="btn btn-sm" onClick={onSubmit}>
                        <span
                          style={{
                            fontSize: "16px",
                            color: "#676767",
                            fontWeight: "600",
                          }}
                        >
                          GO
                        </span>
                      </button>
                    </div>
                  </div>
                </Col>
              </Row>
            </Card.Header>
            <Card.Body>
              <Row>
                <Col sm={12} md={6}>
                  <Card className="customercard">
                    <Card.Title>
                      <center> {t("Station 1")} : {station1} </center>
                      <hr />
                    </Card.Title>
                    {props.isStationComparisionGraphLoading ? <h3 style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      height: "300px",
                    }}> Loading data... </h3> :
                      props.station.data1 && props.station.data1.length > 0 ? (

                        <ApexCharts
                          options={options}
                          series={series1}
                          type="bar"
                          height={300}
                        />) : (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            height: "300px",
                          }}
                        >
                          <h3>{t("No data found")} </h3>
                        </div>
                      )}
                  </Card>
                </Col>
                <Col sm={12} md={6}>
                  <Card className="customercard">
                    <Card.Title>
                      <center> {t("Station 2")} : {station2} </center>
                      <hr />
                    </Card.Title>
                    {props.isStationComparisionGraphLoading ? <h3 style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      height: "300px",
                    }}> Loading data... </h3> :
                      props.station.data2 && props.station.data2.length > 0 ? (

                        <ApexCharts
                          options={options2}
                          series={series2}
                          type="bar"
                          height={300}
                        />) : (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            height: "300px",
                          }}
                        >
                          <h3>{t("No data found")}</h3>
                        </div>
                      )}
                  </Card>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

const mapStateToProps = (state) => {
  return {
    list: state.evse.allevse,
    station: state.insight.station_comparision,
    isStationComparisionGraphLoading: state.insight.isStationGraphLoading,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    all: () => dispatch(allEvseAction()),
    stationcompare: (station1, station2, start_date, end_date) => dispatch(fetchStationComparisionAction(station1, station2, start_date, end_date)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StationComparisionGraph);
