import { Container, Row, Col, Card } from "react-bootstrap";
import React, { useEffect } from "react";
import { useParams, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { singleUserAction } from "../../../../store/actions/accessAction";
import PureBreadcrumbs from "../../breadcrums";
import { useTranslation } from "react-i18next";

function UserDetails(props) {
    const { t } = useTranslation();
    const { userid } = useParams();
    const { role } = JSON.parse(localStorage.getItem('user'));
    // console.log(role);
    useEffect(() => {
        props.getSingleUserData(userid);
    }, []);
    const data = props.fetchSingleUserDetails && props.fetchSingleUserDetails.users
    // console.log("data", data);

    return (
        <Container fluid className="pb-4">
            <PureBreadcrumbs />
            <Row>
                <Col className="mt-4">
                    <span style={{ color: "var(--text-primary)", fontWeight: "bold" }}> {t("User Details")} </span>
                </Col>
            </Row>

            <Row className="mt-3">
                <Col>
                    <Card className="tariffcard">
                        <Card.Body>
                            <Row>
                                <Col xs={12} md={4}>
                                    <div>
                                        <label htmlFor="floatingInputGridName">{t("First Name")}</label>
                                        <input className="form-control gray-border " type="text" name="first_name" id="floatingInputGridName" placeholder="Firstname" defaultValue={data.first_name} disabled />
                                    </div>
                                </Col>
                                <Col xs={12} md={4}>
                                    <div>
                                        <label htmlFor="floatingInputGridName">{t("Last Name")}</label>
                                        <input className="form-control gray-border " type="text" name="lastname" id="floatingInputGridName" placeholder="Lasttname" defaultValue={data.last_name} disabled />
                                    </div>
                                </Col>
                                <Col xs={12} md={4}>
                                    <div>
                                        <label htmlFor="floatingInputGridName">{t("Username")}</label>
                                        <input className="form-control gray-border " type="text" name="userid" id="floatingInputGridName" placeholder="UserID" defaultValue={data.userid} disabled />
                                    </div>
                                </Col>
                            </Row>

                            <Row className="mt-5">
                                <Col xs={12} md={4}>
                                    <div>
                                        <label htmlFor="floatingInputGridName">{t("Email")}</label>
                                        <input className="form-control gray-border " type="email" name="email" id="floatingInputGridemail" placeholder="Email" defaultValue={data.email} disabled />
                                    </div>
                                </Col>
                                <Col xs={12} md={4}>
                                    <div>
                                        <label htmlFor="floatingInputGridName">{t("Phone No.")}</label>
                                        <input className="form-control gray-border " type="text" name="phone" id="floatingInputGridphone" placeholder="phone" defaultValue={data.phone} disabled />
                                    </div>
                                </Col>
                                <Col xs={12} md={4}>
                                    <div>
                                        <label htmlFor="floatingInputGridName">{t("Role")}</label>
                                        <input className="form-control gray-border " type="text" name="role" id="floatingInputGridrole" placeholder="Account Type" defaultValue={data.role} disabled />
                                    </div>
                                </Col>
                            </Row>

                            <Row className="mt-5">
                                <Col xs={12} md={4}>
                                    <div>
                                        <label htmlFor="floatingInputGridName">{t("City")} </label>
                                        <input className="form-control gray-border" type="text" name="city" id="floatingInputGridcity" placeholder="City" defaultValue={data.city} disabled />
                                    </div>
                                </Col>
                                <Col xs={12} md={4}>
                                    <div>
                                        <label htmlFor="floatingInputGridName">{t("State")}</label>
                                        <input className="form-control gray-border" type="text" name="state" id="floatingInputGridstate" placeholder="State" defaultValue={data.state} disabled />
                                    </div>
                                </Col>
                                <Col xs={12} md={4}>
                                    <div>
                                        <label htmlFor="floatingInputGridName">{t("Country")}</label>
                                        <input className="form-control gray-border" type="text" name="country" id="floatingInputGridcountry" placeholder="Country" defaultValue={data.country} disabled />
                                    </div>
                                </Col>
                            </Row>

                            <Row className="mt-5">
                                <Col xs={12} md={4}>
                                    <div>
                                        <label htmlFor="floatingInputGridName">{t("Organisation")}</label>
                                        <input className="form-control gray-border" type="text" name="organisation" id="floatingInputGridorganisation" placeholder="Organisation" defaultValue={data.organisation} disabled />
                                    </div>
                                </Col>
                                <Col xs={12} md={4}>
                                    <div>
                                        <label htmlFor="floatingInputGridName">{t("Organisation Type")}</label>
                                        <input className="form-control gray-border" type="text" name="organisation_type" id="floatingInputGridorganisation_type" placeholder="organisation_type" defaultValue={data.organisation_type} disabled />
                                    </div>
                                </Col>
                                <Col xs={12} md={4}>
                                    <div>
                                        <label htmlFor="floatingInputGridName">{t("Zipcode")}</label>
                                        <input className="form-control gray-border" type="text" name="postal_code" id="floatingInputGridpostal_code" placeholder="Postal Code" defaultValue={data.postal_code} disabled />
                                    </div>
                                </Col>
                            </Row>

                            <Row className="mt-5">

                                <Col lg={6} xs={12} md={4}>
                                    <div>
                                        <label htmlFor="floatingInputGridName">{t("Status")}</label>
                                        <input className="form-control gray-border" type="text" name="status" id="floatingInputGridstatus" placeholder="Status" defaultValue={data.status} disabled />
                                    </div>
                                </Col>
                                {<Col lg={6} xs={12} md={4}>
                                    <div>
                                        <label htmlFor="floatingInputGridName">{t("Regions")}</label>
                                        <input className="input gray-border" type="text" name="status" id="floatingInputGridstatus" placeholder="Regions" defaultValue={data?.region?.map((region) => region)} disabled />
                                    </div>
                                </Col>}
                            </Row>
                            <Row className='d-flex justify-content-around mt-5' >

                                <div className='col-2' >
                                    <h5>{t("Role")}</h5>
                                </div>
                                <div className='col-3 '>
                                    <h5>{t("Privileges")}</h5>
                                </div>
                                <div className='col-7'>
                                    <h5>{t("Sub-Privileges")}</h5>
                                </div>

                            </Row>
                            <>
                                {data && data.access.map((item, index) =>
                                    <Row key={index}>
                                        {index === 0 ?
                                            <Col lg={2} className="">
                                                <div>
                                                    <div className="custom-control custom-radio mt-3">
                                                        <input type="radio" name="customRadio" className="custom-control-input" readOnly value={data.role} checked />
                                                        <label className="custom-control-label">{data.role}</label>
                                                    </div>
                                                </div>
                                            </Col> : <Col lg={2}></Col>}
                                        <Col lg={3}>
                                            <div className="my_Privilagecontainer">
                                                <div className="custom-control mt-3">
                                                    <input type="checkbox" id={item.parent_key} name="customCheck" className="custom-control-input" readOnly value={item.value} checked />
                                                    <label className="custom-control-label" htmlFor={item.parent_key}>{item.value}</label>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col lg={7} className="subprivilage_column">
                                            <div className="my_SubPrivilagecontainer" id='subcontainer'>
                                                {item.menu && item.menu.map((sub_item, index) => (
                                                    <div key={index} className="custom-control custom-checkbox pl-5">
                                                        <input type="checkbox" id={sub_item.key} name="customCheck" className="custom-control-input" readOnly value={sub_item.value} checked />
                                                        <label className="custom-control-label pt-1" htmlFor={sub_item.key} style={{ fontSize: "0.8rem" }}>{sub_item.value}</label>
                                                    </div>
                                                )
                                                )}
                                            </div>
                                        </Col>
                                    </Row>
                                )}
                            </>
                            <br />
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    )

}

const mapStateToProps = function (state) {
    return {
        fetchSingleUserDetails: state.access.singleuser,
    }
}
const mapDispatchToProps = dispatch => {
    return {
        getSingleUserData: (userid) => dispatch(singleUserAction(userid)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(UserDetails));