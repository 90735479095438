import React, { useMemo } from "react";
import Table from "../../reacttable/table";
import "../../reacttable/reactTable.css";
import { FaDownload, FaEdit, FaEye } from "react-icons/fa";
import { CSVLink } from "react-csv";
import { TiTick } from "react-icons/ti";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { useTranslation } from "react-i18next";

const TimeTariffListComponent = (props) => {
  const { t } = useTranslation();

  // console.log(props.fetchList );

  const data = props.fetchList && props.fetchList.data;
  // console.log(data)

  const activateStation = (id) => {
    // console.log(id);
    props.activateTariffAPI(id);
  };

  if (props.activate_tariff_api.statuscode === 200) {
    window.location.reload();
  }

  const deactivateStation = (id) => {
    // console.log(id);
    props.deActivateTariff(id);
  };

  if (props.deactivate_tariff_api.statuscode === 200) {
    window.location.reload();
  }
  // console.log(props.deactivate_tariff_api)
  if (props.deactivate_tariff_api.statuscode === 405) {
    alert(props.deactivate_tariff_api.error);
    window.location.reload();
  }

  const columns = useMemo(
    () => [
      {
        Header: "Tariff",
        accessor: "name",
      },
      {
        Header: "Organisation",
        accessor: "operator",
      },
      {
        Header: "Description",
        accessor: "description",
      },
      {
        Header: "Currency",
        accessor: "currency",
      },
      {
        Header: "Is Peak Hours ?",
        accessor: "ispeak",
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: (status) => {
          // console.log(publish.value)
          return (
            <div>
              <span>
                {status.value === "active" ? (
                  <span className="text-success">{t("Active")}</span>
                ) : status.value === "inactive" ? (
                  <span className="text-danger">{t("Inactive")}</span>
                ) : (
                  <span className="text-warning">{t("Draft")}</span>
                )}
              </span>
            </div>
          );
        },
      },
      {
        Header: "Actions",
        accessor: "id",
        Cell: (id) => {
          return (
            <div>
              <span>
                <a
                  href={`/dashboard/view_tariff/singletariff/${id.value}`}
                  rel="tooltip"
                  title="View"
                >
                  <FaEye />
                </a>
              </span>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <span>
                <a
                  href={`/dashboard/updatetariff/${id.value}`}
                  rel="tooltip"
                  title="Edit"
                >
                  <FaEdit />
                </a>
              </span>
              &nbsp;&nbsp;  &nbsp;&nbsp;
              <span style={{ cursor: "pointer" }}>
                <TiTick
                  onClick={() => activateStation(id.value)}
                  title="Activate"
                />
              </span>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <span style={{ cursor: "pointer" }}>
                <AiOutlineCloseCircle
                  onClick={() => deactivateStation(id.value)}
                  title="De-Activate"
                />
              </span>
            </div>
          );
        },
      },
    ],
    []
  );

  const headers = [
    { label: 'ID', key: 'id' },
    { label: 'Peak Hour', key: 'ispeak' },
    // { label: 'Time From', key: 'timeslots[0].from' },
    // { label: 'Time To', key: 'timeslots[0].to' },
    { label: 'Amount', key: 'components[0].amount' },
    { label: 'Tax', key: 'components[0].tax' },
    { label: 'Unit', key: 'components[0].unit' },
    { label: 'Currency', key: 'currency' },
    { label: 'Description', key: 'description' },
    { label: 'Name', key: 'name' },
    // { label: 'Day', key: 'day' },
    { label: 'Role', key: 'role' },
    { label: 'Operator', key: 'operator' },
    { label: 'Created At', key: 'createdat' },
    { label: 'Updated At', key: 'updatedat' },
    { label: 'Status', key: 'status' },
    { label: 'Created By', key: 'created_by' },
  ];

  return (
    <div>
      {props.loading ? <h3 style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "250px",
      }}>Loading data...</h3> :
        data && data.length > 0 ? (
          <div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div className="table-header"> {t("Tariff List")}</div>
              <button className="csv-file-btn">
                <CSVLink
                  className="fas"
                  data={data}
                  headers={headers}
                  filename="Tariff-List.csv"
                  style={{ textDecoration: 'none' }}
                >
                  <FaDownload className="download-icon" />
                </CSVLink>
              </button>
            </div>
            <Table data={data} columns={columns}></Table>
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          > <h3>{t("No Data Found")}</h3>
          </div>
        )}
    </div>
  );

}

export default TimeTariffListComponent;