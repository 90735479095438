import React from "react";
import { useForm, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useState, useEffect } from "react";
import { Card, Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import PureBreadcrumbs from "../../breadcrums";
import "../loyalty.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { fetchRedeemRuleTypeDropdownApi, fetchSingleRedeemApi, updateRedeemApi } from "../../../../store/actions/loyaltyAction";
import { Redirect, useParams } from "react-router-dom/cjs/react-router-dom.min";
import { ToastContainer, toast, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { HandleKeyPress } from "../../resuableComponent/handleKeyPress";


const UpdateRedeem = (props) => {
    const { t } = useTranslation();
    const { redeem_name } = useParams();
    const [redirect, setRedirect] = useState(false);
    const [isToastVisible, setIsToastVisible] = useState(false);

    useEffect(() => {
        props.fetchSingle(redeem_name);
        props.fetchDropdown();
    }, [])

    const data = props.fetchSingleRes && props.fetchSingleRes.data
    // console.log(data)
    const prevStatus = data && data[0] && data[0].status

    const redeemSchema = Yup.object().shape({
        redeem_name: Yup.string(),
        redeemGroup: Yup.array().of(
            Yup.object().shape({
                type: Yup.string().required("Rule type is required"),
                value: Yup.number().required("Rule value is required").typeError("Rule value is required"),
                points: Yup.number().required("Input value is required").typeError("Input value is required"),
            })
        ),
    });

    const {
        register,
        control,
        getValues,
        setValue,
        watch,
        handleSubmit,
        reset,
        formState: { errors, isValid },
    } = useForm({
        resolver: yupResolver(redeemSchema),
        mode: "all",
        defaultValues: {
            redeem_name: "",
            redeemGroup: [{ type: "", value: "", points: "" }],
        },
    });
    const {
        fields: fieldsList1,
        remove: removeList1,
        append: appendList1,
    } = useFieldArray({ control, name: "redeemGroup" });
    const elementsTime = watch("redeemGroup");
    // console.log(elementsTime);

    useEffect(() => {
        const { components = null, redeem_name = null } = data[0] || '';
        if (data.length > 0 && data[0]) {
            // console.log("Setting values with data: ", components, redeem_name);
            reset({
                redeem_name: redeem_name,
                redeemGroup: components,
            });
        }
    }, [data, reset]);

    useEffect(() => {
        // Update the watch function whenever the form state changes.
        // This ensures that the watch function reflects the latest state of the form.
        // This is for dynamic rule value lables.
        watch();
    }, [fieldsList1]);

    const previousStartDate = data && data[0]?.startdate;
    const previousEndDate = data && data[0]?.enddate;
    // console.log("previousEndDate",previousEndDate)
    const PrevStartDate = previousStartDate ? new Date(previousStartDate) : null;
    const PrevEndDate = previousEndDate ? new Date(previousEndDate) : null;
    const [startdate, setstartdate] = useState(null);
    const [enddate, setenddate] = useState(null);

    const onSubmit = async (data) => {
        if (startdate === null && enddate === null) {
            // console.log("data saved with both previous date successfully",data,prevStatus,PrevStartDate, PrevEndDate);
            props.updateRedeem(data, prevStatus, PrevStartDate, PrevEndDate)
        } else if (startdate !== null && enddate === null) {
            // console.log("data saved with  startdate, PrevEndDate successfully",data,prevStatus,startdate, PrevEndDate);
            props.updateRedeem(data, prevStatus, startdate, PrevEndDate)
        } else if (startdate === null && enddate !== null) {
            // console.log("data saved with  PrevStartDate, enddate successfully",data,prevStatus,PrevStartDate, enddate);
            props.updateRedeem(data, prevStatus, PrevStartDate, enddate)
        }
        else if (PrevStartDate !== null && PrevEndDate !== null) {
            // console.log("data saved with both selected date successfully",data,prevStatus,startdate, enddate);     
            props.updateRedeem(data, prevStatus, startdate, enddate)
        }
    };

    useEffect(() => {
        if (props.updateRedeemRes.statuscode === 200) {
            setIsToastVisible(true);
            toast.success('Redeem rule has been updated successfully!', {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
                onClose: () => {
                    setIsToastVisible(false);
                    setRedirect(true)
                },
            });
        } else if (props.updateRedeemRes.statuscode === 400) {
            setIsToastVisible(true);
            toast.error('❌ There was an error updating the redeem rule', {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
                onClose: () => {
                    setIsToastVisible(false);
                    setRedirect(true)
                },
            });
        }
    }, [props.updateRedeemRes]);

    if (redirect) {
        return <Redirect to='/dashboard/view_redeem' />;
    }

    return (
        <>
            {isToastVisible && <div className="overlay" />}
            <ToastContainer
                position="top-center"
                autoClose={20}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
                style={{ top: "10%", width: "auto", zIndex: "9999" }}
            />
            <div className="container-fluid">
                <PureBreadcrumbs />
                <Row className="d-flex justify-content-center align-items-center pt-5">
                    <Col xs={12} md={12} lg={12}>
                        <form autoComplete="off" onSubmit={(e) => e.preventDefault()}>
                            <div>
                                <Card className="d-flex justify-content-center align-items-center tariffcard">
                                    <Card.Body>
                                        <h6 className="text-center"> {t("Update Redeem Rules")}</h6>
                                    </Card.Body>
                                </Card>
                                <Card className="tariffcard ml-0 mr-0">
                                    <Row >
                                        <Col lg={4}>
                                            <div className="col-lg">
                                                <div className="form-floating">
                                                    <label>
                                                        {t("Redeem Name")}
                                                        <span className="mandatory-field">*</span>
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className={`form-control gray-border`}
                                                        name={`redeem_name`}
                                                        id="floatingInputplan_name"
                                                        placeholder={t("Redeem Name")}
                                                        {...register(`redeem_name`)}
                                                        disabled
                                                    />

                                                </div>
                                            </div>
                                        </Col>
                                        <Col lg={4} md={12} sm={12} className=" d-flex justify-content-start align-items-center">
                                            <div className="d-block text-center mt-4 ml-3 mr-4">
                                                {t("Select Start Date")} :
                                            </div>

                                            <DatePicker
                                                className='gray-border mt-4'
                                                selected={startdate || PrevStartDate}
                                                name="start"
                                                onChange={(date) => setstartdate(date)}
                                                selectsStart
                                                startdate={startdate || PrevStartDate}
                                                dateFormat="dd/MM/yyyy"
                                            />
                                        </Col>
                                        <Col lg={4} md={12} sm={12} className="d-flex justify-content-end justify-content-sm-start justify-content-md-start align-items-center">
                                            <label className="text-center mt-4 ml-3 mr-4">
                                                {t("Select End Date")} :
                                            </label>
                                            <DatePicker
                                                className='gray-border mt-4'
                                                selected={enddate || PrevEndDate}
                                                onChange={(date) => setenddate(date)}
                                                selectsEnd
                                                startdate={startdate || PrevStartDate}
                                                enddate={enddate || PrevEndDate}
                                                minDate={startdate || PrevStartDate}
                                                showDisabledMonthNavigation
                                                dateFormat="dd/MM/yyyy"
                                            />
                                        </Col>
                                    </Row>
                                </Card>

                                {fieldsList1.map((item, i) => (
                                    <Card className="tariffcard" key={i + 1}>
                                        {i === 0 ? (
                                            <h6 className="text-start p-3">
                                                <b>{t("Update Redeem Rule")}</b>
                                            </h6>
                                        ) : (
                                            ""
                                        )}
                                        <Card.Body>
                                            <div className="row g-2 mt-3 ">
                                                <div className="col-md">
                                                    <div className="form-floating">
                                                        <label>
                                                            {t("Rule Type")}
                                                            <span className="mandatory-field">*</span>
                                                        </label>
                                                        <select
                                                            className={`form-select form-control ${errors.redeemGroup?.[i]?.type ? "is-invalid" : ""
                                                                }`}
                                                            name={`redeemGroup[${i}].type`}
                                                            id="floatingSelectGridtype"
                                                            {...register(`redeemGroup[${i}].type`)}
                                                        >
                                                            <option value={getValues(`redeemGroup[${i}].type`)} hidden>{getValues(`redeemGroup[${i}].type`)}</option>
                                                            {props.fetchRuleTypeDropdownRes && props.fetchRuleTypeDropdownRes.redeemtype?.map((v, k) => {
                                                                const unitSelected = fieldsList1.slice(0, i).some(field => field.type === v.value);
                                                                return !unitSelected && <option key={v.key} value={v.value}>{t(v.key)}</option>;
                                                            })}

                                                        </select>
                                                    </div>
                                                    <div className="invalid-feedback">
                                                        {errors.redeemGroup?.[i]?.type?.message}
                                                    </div>
                                                </div>


                                                <div className="col-md">
                                                    <div className="form-floating">
                                                        {watch(`redeemGroup.${i}.type`) === "" && <label>{t("Rule Value")}<span className='mandatory-field'>*</span></label>}
                                                        {watch(`redeemGroup.${i}.type`) === "kwh" && <label>{t("Rule Value ( kWh )")}<span className='mandatory-field'>*</span></label>}
                                                        {watch(`redeemGroup.${i}.type`) === "flat" && <label>{t("Rule Value ( Flat )")}<span className='mandatory-field'>*</span></label>}

                                                        <input
                                                            type="number"
                                                            className={`form-control gray-border ${errors.value ? "is-invalid" : ""
                                                                }`}
                                                            placeholder={t("Rule Value")}
                                                            name={`redeemGroup[${i}].value`}
                                                            id="floatingSelectGridperiod_end"
                                                            {...register(`redeemGroup[${i}].value`)}
                                                            onKeyPress={HandleKeyPress}

                                                        />
                                                    </div>
                                                    <div className="invalid-feedback">
                                                        {errors.redeemGroup?.[i]?.value?.message}
                                                    </div>
                                                </div>
                                                <div className="col-sm mt-4" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                    <div className="form-floating" >
                                                        {t("Contains")}:
                                                    </div>

                                                </div>
                                                <div className="col-md">
                                                    <div className="form-floating">
                                                        <label>
                                                            {t("Input value points")}
                                                            <span className="mandatory-field">*</span>
                                                        </label>
                                                        <input
                                                            type="number"
                                                            className={`form-control gray-border ${errors.redeemGroup?.[i]?.points ? "is-invalid" : ""
                                                                }`}
                                                            placeholder={t("Input Value")}
                                                            name={`redeemGroup[${i}].points`}
                                                            id="floatingSelectGridperiod_end"
                                                            {...register(`redeemGroup[${i}].points`)}
                                                            min="0"
                                                            onKeyPress={HandleKeyPress}

                                                        />
                                                    </div>
                                                    <div className="invalid-feedback">
                                                        {errors.redeemGroup?.[i]?.points?.message}
                                                    </div>
                                                </div>
                                            </div>
                                        </Card.Body>
                                        <div className="d-grid gap-2 d-flex justify-content-end p-1">
                                            <button
                                                className="btn  btn-lg"
                                                type="button"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    removeList1(i);
                                                }}
                                            >
                                                {" "}
                                                <i
                                                    className="far fa-trash-alt"
                                                    style={{ color: "red" }}
                                                ></i>
                                            </button>
                                        </div>
                                    </Card>
                                ))}

                                <Card.Footer className={`row ml-1 mr-1 d-flex ${elementsTime && elementsTime.length >= 2 ? "justify-content-end" : "justify-content-between"}`}>
                                    {/* Add Another Redemption Rule Button */}
                                    {elementsTime && elementsTime.length <= 1 && (
                                        <Col lg={6} md={6} sm={12} xs={12} className="d-flex justify-content-center justify-content-md-start mt-2">
                                            <button
                                                className="btn btn-dark"
                                                type="button"
                                                onClick={() => appendList1({ type: "", value: "", points: "" })}
                                            >
                                                (+) {t("Add Another Redemption Rule")}
                                            </button>
                                        </Col>
                                    )}

                                    {/* Update Redeem Button */}
                                    {props.loading ?
                                        (
                                            <Col lg={6} md={6} sm={12} xs={12} className="d-flex justify-content-center justify-content-md-end mt-2">
                                                <button className="lgn-btn btn" type="button" disabled>
                                                    <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                                                    {t("Saving...")}
                                                </button>
                                            </Col>
                                        )
                                        :

                                        (
                                            <Col lg={6} md={6} sm={12} xs={12} className="d-flex justify-content-center justify-content-md-end mt-2">
                                                <button
                                                    className="lgn-btn btn"
                                                    type="submit"
                                                    disabled={elementsTime && elementsTime.length === 0}
                                                    onClick={handleSubmit(onSubmit)}
                                                >
                                                    {t("UPDATE REDEEM")}
                                                </button>
                                            </Col>
                                        )}
                                </Card.Footer>

                            </div>
                        </form>
                    </Col>
                </Row>
            </div>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        loading: state.loyalty.isLoading,
        updateRedeemRes: state.loyalty.updateRedeem,
        fetchSingleRes: state.loyalty.fetchSingleRedeem,
        fetchRuleTypeDropdownRes: state.loyalty.fetchRedeemRuleTypeDropdown,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        fetchDropdown: () => dispatch(fetchRedeemRuleTypeDropdownApi()),
        fetchSingle: (redeem_name) => dispatch(fetchSingleRedeemApi(redeem_name)),
        updateRedeem: (records, prevStatus, startdate, enddate) => dispatch(updateRedeemApi(records, prevStatus, startdate, enddate)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(UpdateRedeem);
