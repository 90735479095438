import React from "react";
import { useForm, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useState, useEffect } from "react";
import { Card, Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import {
  fetchActiveTariffList,
  getStationName,
  saveTimeTariffSlot,
} from "../../../../store/actions/tariffAction";
import {
  allEvseAction,
  fetchTimeMasterListAction,
  fetchaAllDaysListAction,
} from "../../../../store/actions/evseAction";
import { useTranslation } from "react-i18next";
import PureBreadcrumbs from "../../breadcrums";
import { Bounce, ToastContainer, toast } from "react-toastify";

const DynamicTariff = (props) => {
  const [selectedStartTimeIndex, setSelectedStartTimeIndex] = useState([]);
  const [selectedStartTime, setSelectedStartTime] = useState([]);
  const [isMatching, setIsMatching] = useState(false);
  const [selectedDays, setSelectedDays] = useState('');
  const [isToastVisible, setIsToastVisible] = useState(false);

  const { t } = useTranslation();
  useEffect(() => {
    props.getTimeMaster();
    props.getActiveTariffList();
    props.all();
    props.getAllDayMaster();
  }, []);

  const priceSchema = Yup.object().shape({
    station_name: Yup.string().required("Station Name is required"),
    plan_name: Yup.string().required("Plan Name is required"),
    timeslots: Yup.array().of(
      Yup.object().shape({
        day: Yup.string().required("Day is required"),
        start_time: Yup.string().required("Start Time is required"),
        end_time: Yup.string().required("End Time is required"),
        tariff_name: Yup.string().required("Tariff name is required"),
      })
    ),
  });

  const {
    register,
    control,
    getValues,
    setValue,
    watch,
    handleSubmit,
    reset,
    formState: { errors, isValid },
  } = useForm({ resolver: yupResolver(priceSchema), mode: "all" });
  const {
    fields: fieldsList1,
    remove: removeList1,
    append: appendList1,
  } = useFieldArray({ control, name: "timeslots" });
  const elementsTime = watch("timeslots");
  // console.log(elementsTime);

  const handleChange = (e, i) => {
    const value = e.target.value;
    // console.log(value);
    if (i > 0) {
      setSelectedStartTime(value);
    }
    const selectedIndex = e.target.selectedIndex;
    setSelectedStartTimeIndex((prev) => {
      const newStartTimes = [...prev];
      newStartTimes[i] = selectedIndex;
      return newStartTimes;
    });
    setIsMatching(false)
  };

  const handleDayChange = (e, i) => {
    const value = e.target.value;
    // console.log("day change", value)
    setSelectedDays(value)
    setValue(`timeslots[${i}].day`, value);
    setIsMatching(false)
  }
  const extractedDays = elementsTime && elementsTime.map(item => item.day);
  // console.log(extractedDays)

  useEffect(() => {

    //  Logic for Monday day
    if (((selectedDays === 'Monday') && (extractedDays.includes('All') || extractedDays.includes('WD')))) {
      // console.log("Monday if condition")
      const timeMatching = elementsTime.some((time) => {
        return selectedStartTime >= time.start_time &&
          selectedStartTime < time.end_time;
      });
      // console.log("Monday",timeMatching)
      setIsMatching(timeMatching);
    }

    //  Logic for Tuesday day
    if (((selectedDays === 'Tuesday') && (extractedDays.includes('All') || extractedDays.includes('WD')))) {
      // console.log("Tuesday if condition")
      const timeMatching = elementsTime.some((time) => {
        return selectedStartTime >= time.start_time &&
          selectedStartTime < time.end_time;
      });
      // console.log("Tuesday",timeMatching)
      setIsMatching(timeMatching);
    }

    //  Logic for Wednesday day
    if (((selectedDays === 'Wednesday') && (extractedDays.includes('All') || extractedDays.includes('WD')))) {
      // console.log("Wednesday if condition")
      const timeMatching = elementsTime.some((time) => {
        return selectedStartTime >= time.start_time &&
          selectedStartTime < time.end_time;
      });
      // console.log("Wednesday",timeMatching)
      setIsMatching(timeMatching);
    }

    //  Logic for Thursday day
    if (((selectedDays === 'Thursday') && (extractedDays.includes('All') || extractedDays.includes('WD')))) {
      // console.log("Thursday if condition")
      const timeMatching = elementsTime.some((time) => {
        return selectedStartTime >= time.start_time &&
          selectedStartTime < time.end_time;
      });
      // console.log("Thursday",timeMatching)
      setIsMatching(timeMatching);
    }

    //  Logic for Friday day
    if (((selectedDays === 'Friday') && (extractedDays.includes('All') || extractedDays.includes('WD')))) {
      // console.log("Friday if condition")
      const timeMatching = elementsTime.some((time) => {
        return selectedStartTime >= time.start_time &&
          selectedStartTime < time.end_time;
      });
      // console.log("Friday",timeMatching)
      setIsMatching(timeMatching);
    }

    // Logic for Weekend
    if (((selectedDays === 'Saturday' || selectedDays === 'Sunday') && (extractedDays.includes('All') || extractedDays.includes('WE')))) {
      // console.log("2nd if")
      const timeMatching = elementsTime.some((time) => {
        return selectedStartTime >= time.start_time &&
          selectedStartTime < time.end_time;
      });
      setIsMatching(timeMatching);
    }
    // Logic for All
    if (((selectedDays === 'All') && (extractedDays.includes('All') || extractedDays.includes('WE') || extractedDays.includes('Monday') || extractedDays.includes('Tuesday') || extractedDays.includes('Wednesday') || extractedDays.includes('Thursday') || extractedDays.includes('Friday')))) {
      // console.log("3rd if")
      const timeMatching = elementsTime.some((time) => {
        return selectedStartTime >= time.start_time &&
          selectedStartTime < time.end_time;
      });
      setIsMatching(timeMatching);
    }
    if (((selectedDays === 'WE') && ((extractedDays.includes('All') || (extractedDays.includes('Saturday') || (extractedDays.includes('Sunday'))))))) {
      // console.log("4th if")
      // console.log((selectedDays === 'WE'))
      // console.log((extractedDays.includes('WE') || (extractedDays.includes('All') || (extractedDays.includes('Saturday') || (extractedDays.includes('Sunday'))))))
      const timeMatching = elementsTime.some((time) => {
        return selectedStartTime >= time.start_time &&
          selectedStartTime < time.end_time;
      });
      setIsMatching(timeMatching);
    }
    if (((selectedDays === 'WD') && (extractedDays.includes('WE') || extractedDays.includes('Monday') || (extractedDays.includes('All'))))) {
      // console.log("5th if")
      const timeMatching = elementsTime.some((time) => {
        return selectedStartTime >= time.start_time &&
          selectedStartTime < time.end_time;
      });
      setIsMatching(timeMatching);
    }

  }, [elementsTime, selectedStartTime, selectedDays, extractedDays]);



  useEffect(() => {
    if (elementsTime && elementsTime.length) {
      return setSelectedStartTime([])
    }
  }, [elementsTime])


  const handleBlur = () => {
    const enteredName = getValues("station_name");
    // console.log(enteredName)
    props.getStationName(enteredName);
  };

  const onSubmit = async (data) => {
    props.saveTimeSlot(data);
    // console.log(data)
  };

  useEffect(() => {
    if (props.save.statuscode === 200) {
      setIsToastVisible(true);
      toast.success('New time slot saved successfully', {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
        onClose: () => {
          setIsToastVisible(false);
          window.location.reload();
        },
      });
    }
  }, [props.save]);

  // console.log("station name", props.list &&
  //   props.list.data?.filter((status) => status.status !== 'Discovery'))

  return (
    <>
      {isToastVisible && <div className="overlay" />}
      <ToastContainer
        position="top-center"
        autoClose={20}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        style={{ top: "10%", width: "auto", zIndex: "9999" }}
      />
      <div className="container-fluid">
        <PureBreadcrumbs />
        <Row className="d-flex justify-content-center align-items-center pt-5">
          <Col xs={12} md={12} lg={12}>
            <form autoComplete="off" onSubmit={(e) => e.preventDefault()}>
              <div>
                <Card className="d-flex justify-content-center align-items-center tariffcard">
                  <Card.Body>
                    <h6 className="text-center"> {t("Add Tariff Time Slots")}</h6>
                    {isMatching && (
                      <p className="text-danger">{t("Time is already exist in the given days.Please select the days and time wisely.")}</p>
                    )}
                  </Card.Body>
                </Card>
                <Row className="tariffcard ml-0 mr-0">
                  <Col>
                    <div className="form-floating">
                      <label>
                        {t("Select Station Name")}
                        <span className="mandatory-field">*</span>
                      </label>
                      <select
                        className={`form-select form-control ${errors.station_name ? "is-invalid" : ""
                          }`}
                        name={`station_name`}
                        id="floatingSelectGridstation_name"
                        {...register(`station_name`)}
                        onBlur={handleBlur}
                      >
                        <option value={""}>{t("Select Station Name")}</option>
                        {props.list &&
                          props.list.data?.filter((status) => status.status !== 'Discovery')?.map((v, k) => (
                            <option key={v.stationid} value={v.name}>
                              {v.name}
                            </option>
                          ))}
                      </select>
                    </div>
                    {props.fetchName.statuscode === 200 ? (
                      <p className="text-danger pl-1">
                        {t("Station name already exists in Timeslot")}
                      </p>
                    ) : (
                      ""
                    )}
                    <div className="invalid-feedback">
                      {errors.station_name?.message}
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div className="">
                      <div className="form-floating">
                        <label>
                          {t("Plan Name")}
                          <span className="mandatory-field">*</span>
                        </label>
                        <input
                          type="text"
                          className={`form-control gray-border ${errors.plan_name ? "is-invalid" : ""
                            }`}
                          name={`plan_name`}
                          id="floatingInputplan_name"
                          placeholder={t("Plan Name")}
                          {...register(`plan_name`)}
                        />
                        <div className="invalid-feedback">
                          {errors.plan_name?.message}
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>

                {fieldsList1.slice(0).map((item, i) => (
                  <Card className="tariffcard" key={i + 1}>
                    {i === 0 ? (
                      <h6 className="text-start p-3">
                        <b>{t("Add Time Slots")}</b>
                      </h6>
                    ) : (
                      ""
                    )}
                    <Card.Body>
                      <div className="row g-2 mt-3 ">
                        <div className="col-md">
                          <div className="form-floating">
                            <label>
                              {t("Select days")}
                              <span className="mandatory-field">*</span>
                            </label>
                            <select
                              className={`form-select form-control ${errors.timeslots?.[i]?.day ? "is-invalid" : ""
                                }`}
                              name={`timeslots[${i}].day`}
                              id="floatingSelectGridday"
                              {...register(`timeslots[${i}].day`)}
                              onChange={(e) => handleDayChange(e, i)}
                            >
                              {/* {console.log(props.fetchDayMaster &&
                                props.fetchDayMaster.days)} */}
                              <option value={""}>{t("Select days")}</option>
                              {props.fetchDayMaster &&
                                props.fetchDayMaster.days?.map((day, idx) => {
                                  return (
                                    <option key={idx} value={day.value}>
                                      {t(day.key)}
                                    </option>
                                  );
                                })}
                            </select>
                          </div>
                          <div className="invalid-feedback">
                            {errors.timeslots?.[i]?.day?.message}
                          </div>
                        </div>

                        <div className="col-md">
                          <div className="form-floating">
                            <label>
                              {t("From")}
                              <span className="mandatory-field">*</span>
                            </label>
                            <select
                              className={`form-select form-control ${errors.timeslots?.[i]?.start_time
                                ? "is-invalid"
                                : ""
                                }`}
                              name={`timeslots[${i}].start_time`}
                              id="floatingSelectGridperiod_end"
                              {...register(`timeslots[${i}].start_time`)}
                              onChange={(e) => handleChange(e, i)}
                            >
                              <option value={""}>
                                {t("Select Start Time")}
                              </option>
                              {props.mastertimelist &&
                                props.mastertimelist.hours &&
                                props.mastertimelist.hours.map((v, k) => (
                                  <option key={v.key} value={v.value}>
                                    {v.value}
                                  </option>
                                ))}
                            </select>
                          </div>
                          <div className="invalid-feedback">
                            {errors.timeslots?.[i]?.start_time?.message}
                          </div>
                        </div>
                        <div className="col-md">
                          <div className="form-floating">
                            <label>
                              {t("To")}
                              <span className="mandatory-field">*</span>
                            </label>
                            <select
                              className={`form-select form-control ${errors.timeslots?.[i]?.end_time
                                ? "is-invalid"
                                : ""
                                }`}
                              name={`timeslots[${i}].end_time`}
                              id="floatingSelectGridperiod_end"
                              {...register(`timeslots[${i}].end_time`)}
                            >
                              <option value={""}>{t("Select End Time")}</option>
                              {props.mastertimelist &&
                                props.mastertimelist?.hours &&
                                props.mastertimelist?.hours
                                  ?.slice(selectedStartTimeIndex[i])
                                  .map((v, k) => (
                                    <option key={v.key} value={v.value}>
                                      {v.value}
                                    </option>
                                  ))}
                            </select>
                          </div>
                          <div className="invalid-feedback">
                            {errors.timeslots?.[i]?.end_time?.message}
                          </div>
                        </div>

                        <div className="col-md">
                          <div className="form-floating">
                            <label>
                              {t("Select Tariff")}
                              <span className="mandatory-field">*</span>
                            </label>
                            <select
                              className={`form-select form-control ${errors.timeslots?.[i]?.tariff_name
                                ? "is-invalid"
                                : ""
                                }`}
                              name={`timeslots[${i}].tariff_name`}
                              id="floatingSelectGridtariff_name"
                              {...register(`timeslots[${i}].tariff_name`)}
                            >
                              <option value={""}>{t("Select Tariff")}</option>
                              {props.fetchActiveTariffList &&
                                props.fetchActiveTariffList.data &&
                                props.fetchActiveTariffList.data.map((v, k) => (
                                  <option key={v.id} value={v.name}>
                                    {v.name}
                                  </option>
                                ))}
                            </select>
                          </div>
                          <div className="invalid-feedback">
                            {errors.timeslots?.[i]?.tariff_name?.message}
                          </div>
                        </div>
                      </div>
                    </Card.Body>
                    <div className="d-grid gap-2 d-flex justify-content-end p-1">
                      <button
                        className="btn  btn-lg"
                        type="button"
                        onClick={(e) => {
                          e.preventDefault();
                          removeList1(i);
                          setIsMatching(false);
                        }}
                      >
                        {" "}
                        <i
                          className="far fa-trash-alt"
                          style={{ color: "red" }}
                        ></i>
                      </button>
                    </div>
                  </Card>
                ))}

                <Card.Footer className="d-grid gap-2 ">
                  <Row className="d-flex justify-content-between">
                    <Col lg={3} md={3} sm={4} xs={12} className="mt-2">
                      <button
                        className="btn btn-dark"
                        type="button"
                        disabled={isMatching}
                        onClick={() =>
                          appendList1({
                            day: "",
                            start_time: "",
                            end_time: "",
                            tariff_name: "",
                          })
                        }
                      >
                        {" "}
                        (+) {t("Add Time Slots")}
                      </button>
                    </Col>
                    {props.fetchName.statuscode !== 200 &&
                      elementsTime &&
                      elementsTime.length > 0 && (
                        <Col lg={3} md={3} sm={4} xs={12} className="d-md-flex justify-content-end mt-2">
                          {props.loading ?
                            <button className="btn lgn-btn" type="button" disabled>
                              <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                              &nbsp;
                              {t("Saving...")}
                            </button>
                            :
                            <button
                              className="lgn-btn btn"
                              type="submit"
                              disabled={isMatching}
                              onClick={handleSubmit(onSubmit)}
                            >
                              {t("Submit")}
                            </button>
                          }
                        </Col>
                      )}
                  </Row>
                </Card.Footer>
              </div>
            </form>
          </Col>
        </Row>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    res: state.tariff.createtariff,
    loading: state.tariff.isLoading,
    mastertimelist: state.evse.timemaster,
    fetchActiveTariffList: state.tariff.fetchActiveTariffName,
    save: state.tariff.saveTimeSlot,
    list: state.evse.allevse,
    fetchName: state.tariff.fetchStationName,
    fetchDayMaster: state.evse.fetchAllDaysMaster,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    all: () => dispatch(allEvseAction()),
    getActiveTariffList: () => dispatch(fetchActiveTariffList()),
    getTimeMaster: () => dispatch(fetchTimeMasterListAction()),
    getAllDayMaster: () => dispatch(fetchaAllDaysListAction()),
    saveTimeSlot: (data) => dispatch(saveTimeTariffSlot(data)),
    getStationName: (name) => dispatch(getStationName(name)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(DynamicTariff);
