import React from "react";
import { GoogleMap, Marker, MarkerClusterer } from "@react-google-maps/api";
import discovery_icon from '../../../assets/images/dicovery_icon.svg'

function CustomMarker({ location, key, clusterer }) {
    const { status, stationid, name } = location;
    const { latitude, longitude } = location.coordinates;

    // Define icon URLs based on the status of the station
    // The `icons` object should contain the URLs for the different icons
    // for each station status.

    const iconUrls = {
        "Discovery": discovery_icon
    };

    // Select the appropriate icon URL based on the status of the station
    // Use the `status` to determine which icon URL to use from `iconUrls`
    const iconUrl = iconUrls[status];
    if (status === "Discovery") {
        return (
            <Marker
                // key={stationid || 1}
                position={{ lat: latitude, lng: longitude }}
                icon={{
                    url: iconUrl,
                    scaledSize: new window.google.maps.Size(30, 35),
                }}
                title={name} // Show station name as a tooltip
                onClick={() => {
                    // Redirect to the view single EVSE page when the marker is clicked
                    window.location.href = `/dashboard/viewsingleevse/${stationid}`;
                }}
                clusterer={clusterer}
            />
        );
    }

    return null;

}

function DiscoveryMap({ stations, center, zoom }) {
    const mapContainerStyle = {
        height: "81vh",
        width: "100%",
    };

    return (
        <GoogleMap
            mapContainerStyle={mapContainerStyle}
            center={center}
            zoom={zoom}
        >
            <MarkerClusterer
                options={{ imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m' }}
            >
                {(clusterer) => (
                    <>
                        {stations && stations.map((location) => (
                            <div key={location.stationid}>
                                <CustomMarker
                                    // key={location.stationid}
                                    location={location}
                                    clusterer={clusterer} // Pass the clusterer prop to CustomMarker
                                />
                            </div>
                        ))}
                    </>
                )}
            </MarkerClusterer>
        </GoogleMap>
    );
}

export default DiscoveryMap;

