import React, { useEffect, useMemo } from "react";
import { connect } from "react-redux";
import Table from "../../reacttable/table";
import moment from "moment";
import { Row, Col, Card, Container } from "react-bootstrap";
import { FaEye } from "react-icons/fa";
import PureBreadcrumbs from "../../breadcrums";
import CustomerRedemptionGraph from "./customerRedemptionGraph";
import { fetchAllCardData, fetchAllCustomerData } from "../../../../store/actions/loyaltyAction";
import { useTranslation } from "react-i18next";
// import { t } from "i18next";

function CustomerRedeemption(props) {
    // const { userid } = JSON.parse(localStorage.getItem('user'));
    const { t } = useTranslation();

    useEffect(() => {
        props.allCardData();
        props.customerTable();
    }, []);

    const data = props.all && props.all.data;
    //   console.log("data", data);
    const customerData = props.allCustomerListRes && props.allCustomerListRes.data;
    // console.log("customerData", customerData);

    const columns = useMemo(
        () => [
            { Header: "Customer Name", accessor: "customer_name" },
            { Header: "Organisation", accessor: "operator" },
            { Header: "Total Time", accessor: "TotalTime" },
            {
                Header: "Total kWh",
                accessor: "total_kwh",
                Cell: ({ value }) => (
                    <span>{value ? value.toFixed(2) : ''}</span>
                )
            },
            { Header: "Total Amount", accessor: "total_amount" },
            { Header: "Total Earned Points", accessor: "total_earned_points" },
            { Header: "Total Redeem Points", accessor: "total_redeemed_points" },
            { Header: "Total Available Points", accessor: "points" },
            {
                Header: "Redeem & Earned Date",
                id: "createdat",
                accessor: (d) => {
                    return moment(d.createdat, "YYYY-MM-DD").format("DD-MM-YYYY");
                },
            },
            {
                Header: "Actions",
                accessor: "userid",
                Cell: ({ row }) => {
                    const { original } = row;
                    const userid = original.userid;
                    // console.log("userid",userid)

                    return (
                        <div>
                            <span>
                                <a
                                    href={`/dashboard/viewSingleCustomerGraph/${userid}`}
                                    rel="tooltip"
                                    title="View"
                                >
                                    <FaEye />
                                </a>
                            </span>
                        </div>
                    );
                },
            },
        ],
        []
    );

    return (
        <Container fluid className="pb-2">
            <PureBreadcrumbs />
            <br />
            <Row>
                <Col lg={4} xs={12} md={4}>
                    <Card className="customercard total_customer_card">
                        <Row>
                            <Col lg={12} md={12}>
                                <span>
                                    <img
                                        src={process.env.PUBLIC_URL + "/images/loyalty/total_customer.png"}
                                        alt="evse"
                                        className="customer_image"
                                    />
                                </span>
                                <span className="customercard_details">
                                    <Card.Text style={{ fontSize: "16px", fontWeight: "600px" }}>
                                        {t("TOTAL CUSTOMERS")}
                                    </Card.Text>
                                    <Card.Title>
                                        <b>{data?.total_customer}</b>
                                    </Card.Title>
                                </span>
                            </Col>
                        </Row>

                    </Card>
                    <br />
                </Col>
                <Col lg={4} xs={12} md={4}>
                    <Card className="customercard total_points_card">
                        <Row>
                            <Col lg={12} md={12}>
                                <span>
                                    <img
                                        src={process.env.PUBLIC_URL + "/images/loyalty/total_points.png"}
                                        alt="evse"
                                        className="points_image"
                                    />
                                </span>
                                <span className="customercard_details">
                                    <Card.Text style={{ fontSize: "16px", fontWeight: "600px" }}>
                                        {t("TOTAL EARNED POINTS")}
                                    </Card.Text>
                                    <Card.Title>
                                        <b>{data?.total_earned_points}</b>                                    </Card.Title>
                                </span>
                            </Col>
                        </Row>

                    </Card>
                    <br />
                </Col>
                <Col lg={4} xs={12} md={4}>
                    <Card className="customercard total_redemption_card">
                        <Row>
                            <Col lg={12} md={12}>
                                <span>
                                    <img
                                        src={process.env.PUBLIC_URL + "/images/loyalty/redemption_points.png"}
                                        alt="evse"
                                        className="redeem_points_image"
                                    />
                                </span>
                                <span className="customercard_details">
                                    <Card.Text style={{ fontSize: "16px", fontWeight: "600px" }}>
                                        {t("TOTAL REDEEMED POINTS")}
                                    </Card.Text>
                                    <Card.Title style={{ position: "absolute" }}>
                                        <b>{data?.total_redeemed_points}</b>                                    </Card.Title>
                                </span>
                            </Col>
                        </Row>

                    </Card>
                    <br />
                </Col>
            </Row>
            <div className="customercard mb-4 ">
                <Card.Header>
                    <b>{t("Total Statistics")}</b>
                </Card.Header>
                <CustomerRedemptionGraph />
            </div>

            <Card className="mb-4">
                <Row>
                    <Col>
                        <Card className="customercard">
                            <div className="table-header">{t("Customer List")}</div>
                            <Card.Body>
                                {props.loading ? <h3 style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    height: "450px",
                                }}>Loading data...</h3> :
                                    customerData && customerData.length > 0 ? (
                                        <div>
                                            {" "}
                                            <Table data={customerData} columns={columns}></Table>
                                        </div>
                                    ) : (
                                        <div
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                            }}
                                        >
                                            <h3 className="loader">{t("No data found")}</h3>
                                        </div>
                                    )}
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Card>
        </Container>
    );
}

const mapStateToProps = (state) => {
    return {
        loading: state.loyalty.isLoading,
        all: state.loyalty.fetchAllCard,
        allCustomerListRes: state.loyalty.allCustomerList,

    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        allCardData: () => dispatch(fetchAllCardData()),
        customerTable: () => dispatch(fetchAllCustomerData()),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(CustomerRedeemption);
