import React, { useEffect } from "react";
import { Row, Col, Card, Container } from "react-bootstrap";
import "react-dates/initialize";
import PureBreadcrumbs from "../breadcrums";
import { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { connect } from "react-redux";
import { CSVLink } from "react-csv";
import { allOrgTariffAction } from "../../../store/actions/organisationAction";
import { useTranslation } from "react-i18next";



function RoamingReport(props) {
    const { t } = useTranslation();
    const [startDate, setStartDate] = useState(new Date(Date.now() - 3600 * 1000 * 720));
    const [endDate, setEndDate] = useState(new Date(Date.now() + 3600 * 1000 * 24));
    const [org, setOrg] = useState("All");
    const currency = process.env.REACT_APP_CURRENCY;


    useEffect(() => {
        props.getOrg();
    }, [])

    const handleChange = (e) => {
        const data = e.target.value;
        setOrg(data);
    };

    const loadNewData = () => {
        // props.list(startDate.toISOString().substr(0, 10), endDate.toISOString().substr(0, 10), org);
    };

    return (
        <Container fluid>
            <PureBreadcrumbs />
            {/* Ev Report Cards Start here */}
            <Row className="mt-2">
                <Col className="mt-2" xs={12} md={3}>
                    <Card className="customercard">
                        <div style={{ textAlign: "left" }}>
                            <Card.Text style={{ fontSize: "14px" }}>Total Session</Card.Text>
                            <Card.Title>
                                <b>
                                    {/* {props.all.total_session != 0 ? props.all.total_session : 0} */}
                                    0
                                </b>
                            </Card.Title>
                            <span style={{ fontSize: "12px" }}>
                                {/* {Total_session_change_symbol === "+" ? <img src={process.env.PUBLIC_URL + "/images/admin/profit.svg"} className="profitloss" alt="" /> : <img src={process.env.PUBLIC_URL + "/images/admin/loss.svg"} className="profitloss" alt="" />} */}
                                {/* &nbsp;&nbsp;{Total_session_percentage}% &nbsp;change in last 30 days */}
                                &nbsp;&nbsp;{0}% &nbsp;change in last 30 days
                            </span>
                            <span
                                style={{
                                    float: "right",
                                    marginTop: "-60px",
                                    marginRight: "-8px",
                                }}
                            >
                                <div style={{ width: "15%" }}>
                                    <img
                                        src={
                                            process.env.PUBLIC_URL + "/images/reports/sessions.svg"
                                        }
                                        style={{ "width": "60px" }}
                                        alt=""
                                    />
                                </div>
                            </span>
                        </div>
                    </Card>
                    <br />
                </Col>
                <Col className="mt-2" xs={12} md={3}>
                    <Card className="customercard">
                        <div style={{ textAlign: "left" }}>
                            <Card.Text style={{ fontSize: "14px" }}>Total Energy</Card.Text>
                            <Card.Title>
                                {/* <b>{props.all && props.all.total_energy}</b> */}
                                0
                            </Card.Title>
                            <span style={{ fontSize: "12px" }}>
                                {/* {Total_energy_change_symbol === "+" ? <img src={process.env.PUBLIC_URL + "/images/admin/profit.svg"} className="profitloss" alt="" /> : <img src={process.env.PUBLIC_URL + "/images/admin/loss.svg"} className="profitloss" alt="" />}
                &nbsp;&nbsp;{Total_energy_percentage}% &nbsp;change in last 30 days */}
                                &nbsp;&nbsp;{0}% &nbsp;change in last 30 days
                            </span>
                            <span
                                style={{
                                    float: "right",
                                    marginTop: "-60px",
                                    marginRight: "-8px",
                                }}
                            >
                                <div style={{ width: "10%" }}>
                                    <img
                                        src={process.env.PUBLIC_URL + "/images/reports/energy.svg"}
                                        alt=""
                                        style={{ "width": "40px" }}
                                    />
                                </div>
                            </span>
                        </div>
                    </Card>
                    <br />
                </Col>
                <Col className="mt-2" xs={12} md={3}>
                    <Card className="customercard">
                        <div style={{ textAlign: "left" }}>
                            <Card.Text style={{ fontSize: "14px" }}>Total Revenue</Card.Text>
                            <Card.Title>
                                {/* <b>{props.all && props.all.total_revenue}</b> */}
                                0
                            </Card.Title>
                            <span style={{ fontSize: "12px" }}>
                                {/* {Total_revenue_change_symbol === "+" ? <img src={process.env.PUBLIC_URL + "/images/admin/profit.svg"} className="profitloss" alt="" /> : <img src={process.env.PUBLIC_URL + "/images/admin/loss.svg"} className="profitloss" alt="" />}
                                &nbsp;&nbsp;{Total_revenue_percentage}% &nbsp;change in last 30 days */}
                                &nbsp;&nbsp;{0}% &nbsp;change in last 30 days
                            </span>
                            <span
                                style={{
                                    float: "right",
                                    marginTop: "-65px",
                                    marginRight: "-8px",
                                }}
                            >
                                {<div style={{ fontSize: "3.5rem", paddingRight: "11px", color: "#2ea8cc80", marginTop: "-7px" }}>
                                    {currency}
                                </div>}
                            </span>
                        </div>
                    </Card>
                </Col>
                <Col className="mt-2" xs={12} md={3}>
                    <Card className="customercard ">
                        <div style={{ textAlign: "left" }}>
                            <Card.Text style={{ fontSize: "14px" }}>
                                Total Sessions Completed
                            </Card.Text>
                            <Card.Title>
                                {/* <b>{props.all && props.all.total_completed}</b> */}
                                0
                            </Card.Title>
                            <span style={{ fontSize: "12px" }}>
                                {/* {Total_completed_session_symbol === "+" ? <img src={process.env.PUBLIC_URL + "/images/admin/profit.svg"} className="profitloss" alt="" /> : <img src={process.env.PUBLIC_URL + "/images/admin/loss.svg"} className="profitloss" alt="" />}
                &nbsp;&nbsp;{Total_completed_session_percentage}% &nbsp;change in last 30 days */}
                                &nbsp;&nbsp;{0}% &nbsp;change in last 30 days
                            </span>
                            <span
                                style={{
                                    float: "right",
                                    marginTop: "-50px",
                                    marginRight: "-8px",
                                }}
                            >
                                <div style={{ width: "10%" }}>
                                    <img
                                        src={
                                            process.env.PUBLIC_URL +
                                            "/images/reports/sessionsstats.svg"
                                        }
                                        alt=""
                                        style={{ "width": "70px" }}
                                    />
                                </div>
                            </span>
                        </div>
                    </Card>
                    <br />
                </Col>
            </Row>
            {/* Ev Report Card Ends here */}
            <Row className="mt-2">
                <Col>
                    <Card className="customercard">
                        <Card.Header>
                            <Row>
                                <Col md={2} sm={12}>
                                    EV Roaming Report
                                </Col>
                                <Col md={10} sm={12}>
                                    <div className="row justify-content-end">
                                        <div style={{ paddingRight: "10px" }} >
                                            <select style={{ borderRadius: "3px", borderColor: "#a7a6a6", width: "auto" }} className={``} onChange={(e) => handleChange(e)}>
                                                <option value={"All"} >
                                                    {t("All")}
                                                </option>
                                                {props.orgdata && props.orgdata.organisations && props.orgdata.organisations.map((v, k) => (
                                                    <option key={k} value={v.name}>
                                                        {v.name}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="row justify-content-center">
                                            <div className="col-auto claender">
                                                <DatePicker
                                                    className="gray-border"
                                                    selected={startDate}
                                                    onChange={(date) => setStartDate(date)}
                                                    selectsStart
                                                    startDate={startDate}
                                                    dateFormat="dd/MM/yyyy"
                                                    maxDate={new Date()}
                                                />
                                            </div>
                                            <span style={{ paddingTop: "7px" }}> To </span>
                                            <div className="col-auto">
                                                <DatePicker
                                                    className="gray-border"
                                                    selected={endDate}
                                                    onChange={(date) => setEndDate(date)}
                                                    selectsEnd
                                                    startDate={startDate}
                                                    endDate={endDate}
                                                    minDate={startDate}
                                                    showDisabledMonthNavigation
                                                    dateFormat="dd/MM/yyyy"
                                                    maxDate={new Date()}
                                                />
                                            </div>
                                        </div>
                                        <div className="csvbutton ml-3 ">
                                            <button className="btn btn-sm" onClick={loadNewData}>
                                                <span
                                                    style={{
                                                        fontSize: "16px",
                                                        color: "#676767",
                                                        fontWeight: "600",
                                                    }}
                                                >
                                                    GO
                                                </span>
                                            </button>
                                        </div>
                                        <div className=" ">
                                            {props.all && props.all.data != null ? (
                                                <CSVLink
                                                    // data={data}
                                                    // headers={headers}
                                                    // filename={
                                                    //     org +
                                                    //     "_" +
                                                    //     startDate.toISOString().substr(0, 10) +
                                                    //     "_" +
                                                    //     endDate.toISOString().substr(0, 10) +
                                                    //     `.csv`
                                                    // }
                                                    target="_blank"
                                                >
                                                    <i className="fas fa-2x fa-file-csv"></i>
                                                </CSVLink>
                                            ) : (
                                                <i className="fas fa-2x fa-file-csv"></i>
                                            )}
                                        </div>
                                        {/* <Col className="col-md-10 col-sm-12 d-flex justify-content-end mt-3">

                                            <div style={{ paddingRight: "15px" }}>
                                                {props.all && props.all.data != null ? (
                                                    <MyDocument
                                                        {...props}
                                                        // org={org}
                                                        start={startDate}
                                                        end={endDate}
                                                    ></MyDocument>
                                                ) : (
                                                    <i className="fas fa-file-pdf"></i>
                                                )}
                                            </div>
                                            <div>
                                                {props.all && props.all.data != null ? (
                                                    <VendorInvoiceDocument
                                                        {...props}
                                                        // org={org}
                                                        start={startDate}
                                                        end={endDate}
                                                    ></VendorInvoiceDocument>
                                                ) : (
                                                    <i className="fas fa-file-pdf"></i>
                                                )}
                                            </div>
                                        </Col> */}
                                    </div>
                                </Col>
                            </Row>
                        </Card.Header>
                        <div className="evse">
                            {props.loading ? <h3 style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                height: "450px",
                            }}>Loading data...</h3>
                                :
                                // props.all && props.all.data != null ? (
                                //     <div>
                                //         <AdminTable data={data} columns={columns}></AdminTable>
                                //     </div>
                                // )
                                (
                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            height: "450px",
                                        }}
                                    >
                                        <h3>No Data Found</h3>
                                    </div>
                                )}
                        </div>
                    </Card>
                </Col>
            </Row>
        </Container >
    )
}

const mapStateToProps = (state) => {
    return {
        loading: state.evse.isEvseLoading,
        orgdata: state.operator.organisationsList,
    }

}

const mapDispatchToProps = (dispatch) => {
    return {
        getOrg: () => dispatch(allOrgTariffAction()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RoamingReport);
