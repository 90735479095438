import React from "react";
import { useEffect, useMemo } from "react";
import { connect } from "react-redux";
import { Container, Row, Col, Card } from "react-bootstrap";
import PureBreadcrumbs from "../../breadcrums";
import Table from "../../reacttable/table";
import { FaEdit, FaEye } from "react-icons/fa";
import { activateRoamingPartner, allRoamingPartner, deactivateRoamingPartner } from "../../../../store/actions/ocpiAction";
import { TiTick } from "react-icons/ti";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { useTranslation } from "react-i18next";

function ViewOCPIPartner(props) {
  const { t } = useTranslation();
  useEffect(() => {
    props.allPartner();
  }, []);

  const data = props.list && props.list.data;
  console.log("data", data);
  const activateRoaming = (id) => {
    console.log(id);
    // props.activateRoaming(id);
    // window.location.reload();
  };

  const deactivateRoaming = (id) => {
    console.log(id);
    // props.deactivateRoaming(id);
    // window.location.reload();
  };

  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "partner_name",
      },
      {
        Header: "Address",
        accessor: "address",
      },
      {
        Header: "Country",
        accessor: "country",
      },
      {
        Header: "City",
        accessor: "city",
      },
      {
        Header: "State",
        accessor: "state",
      },
      {
        Header: "Party ID Amount",
        accessor: "party_id",
      },
      {
        Header: "Active/Inactive",
        accessor: "status",
        Cell: (status) => {
          // console.log(status.value)
          return (
            <div>
              <span>
                {status.value === "Active" ? (
                  <span className="text-success">{t("Active")}</span>
                ) : (
                  <span className="text-danger">{t("Inactive")}</span>
                )}
              </span>
            </div>
          );
        },
      },
      {
        Header: "Actions",
        accessor: "id",
        Cell: (row) => {
          const original = row?.row?.original;
          const { id } = original
          // console.log("id,party_id", id, party_id);
          return (
            <div>

              <span>
                <a
                  href={`/dashboard/view_partner/singlepartner/${id}`}
                  rel="tooltip"
                  title="View"
                >
                  <FaEye />
                </a>
              </span>
              &nbsp;&nbsp;
              <span>
                <a
                  href={`/dashboard/view_partner/edit_partner/${id}`}
                  rel="tooltip"
                  title="Edit"
                >
                  <FaEdit />
                </a>
              </span>
              &nbsp;&nbsp;
              <span style={{ cursor: "pointer" }}>
                <TiTick
                  onClick={() => activateRoaming(id)}
                  title="Activate"
                />
              </span>
              &nbsp;&nbsp;
              <span style={{ cursor: "pointer" }}>
                <AiOutlineCloseCircle
                  onClick={() => deactivateRoaming(id)}
                  title="De-Activate"
                />
              </span>
            </div>
          );
        },
      },
    ],
    []
  );

  return (
    <Container fluid>
      <PureBreadcrumbs />
      <br />
      <Row className="mt-4">
        <Col>
          <Card className="customercard">
            <div className="table-header">View Roaming Partner</div>

            <Card.Body>
              {props.loading ? <h3 style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "450px",
              }}>Loading data...</h3> :
                data && data.length > 0 ? (
                  <div>
                    <Table data={data} columns={columns}></Table>
                  </div>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <h3 className="loader">No data found</h3>
                  </div>
                )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

const mapStateToProps = (state) => {
  return {
    loading: state.ocpi.isLoading,
    list: state.ocpi.allpartner,
    activateRes: state.ocpi.activateRoamingPartner,
    deactivateRes: state.ocpi.deactivateRoamingPartner,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    allPartner: () => dispatch(allRoamingPartner()),
    activateRoaming: (id) => dispatch(activateRoamingPartner(id)),
    deactivateRoaming: (id) => dispatch(deactivateRoamingPartner(id)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ViewOCPIPartner);
