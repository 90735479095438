import React, { useMemo } from "react";
import { Row, Col, Card, Container } from "react-bootstrap";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import Table from "../../reacttable/table";
import moment from "moment";

function RfidList(props) {
    const { t } = useTranslation();
    // console.log("props", props);

    const data = props.fetchRfidListRes && props.fetchRfidListRes.data;
    // console.log("data", data);

    const columns = useMemo(
        () => [
            {
                Header: "Rfid",
                accessor: "rfid",
            },
            {
                Header: "User Name",
                id: "useridd",
                accessor: "userid",
            },
            {
                Header: "Created Date",
                id: "registerat",
                accessor: (d) => {
                    return moment(d.registerat).format("DD-MM-YYYY");
                },
            },

        ],
        []
    );

    return (
        <div>
            <div className="customercard mt-2">
                {props.loading ? <h3 style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "250px",
                }}>{t("Loading data...")}</h3> :
                    props.fetchRfidListRes && props.fetchRfidListRes.data && props.fetchRfidListRes.data.length !== null ? (
                        <div>
                            <Table data={data} columns={columns} />
                        </div>
                    ) : (
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                        >
                            <h3>{t("No Data Found")}</h3>
                        </div>
                    )}
            </div>

        </div >
    )
}

const mapStateToProps = (state) => {
    return {
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(RfidList);
