import React, { useEffect } from "react";
import { connect } from "react-redux";
import ApexCharts from "react-apexcharts";
import { fetchMonthlyGraphData } from "../../../../store/actions/loyaltyAction";
import moment from "moment";
import { useTranslation } from "react-i18next";


function CustomerRedemptionGraph(props) {
  const { t } = useTranslation();
  const today = moment();
  const monthlycurrent = today.subtract(6, "month").format("YYYY-MM-DD");
  const monthlylastDay = today.add(6, "month").format("YYYY-MM-DD");
  useEffect(() => {
    props.graphData(monthlycurrent, monthlylastDay);
  }, []);

  // const graphData = props.monthGraphApi && props.monthGraphApi.data;
  // console.log(graphData ,"graphData");
  let monthpush = [];

  const ps_month = props.monthGraphApi?.data && props.monthGraphApi?.data.map((n) => {
    if (n.number === 1) {
      monthpush.push("January");

    } else if (n.number === 2) {
      monthpush.push("February");

    } else if (n.number === 3) {
      monthpush.push("March");

    } else if (n.number === 4) {
      monthpush.push("April");

    } else if (n.number === 5) {
      monthpush.push("May");

    } else if (n.number === 6) {
      monthpush.push("June");

    } else if (n.number === 7) {
      monthpush.push("July");

    } else if (n.number === 8) {
      monthpush.push("August");

    } else if (n.number === 9) {
      monthpush.push("September");

    } else if (n.number === 10) {
      monthpush.push("October");

    } else if (n.number === 11) {
      monthpush.push("November");

    }
    else if (n.number === 12) {
      monthpush.push("December");

    }
  });
  // console.log(monthpush,"month")

  const options = {
    chart: {
      type: "bar",
      height: 350,
      stacked: false,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        barWidth: "50%",
        borderRadius: 8,
        width: "100%",
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: 1,
      colors: ["#fff"],
    },

    xaxis: {
      categories: monthpush,
      labels: {
        style: {
          colors: "#FFFFFF", // Replace with the color you want for Y-axis content
        },
      },    
    },
    yaxis: {
      title: {
        text: t("Number of redemption points"),
        style: {
          color: "#FFFFFF",
          fontSize:"13px",
          fontWeight: 600,
      },
      },
      labels: {
        style: {
          colors: "#FFFFFF", // Replace with the color you want for Y-axis content
        },
      },    
    },
    tooltip: {
      enabled: true,
      enabledOnSeries: undefined,
      followCursor: false,
      fillSeriesColor: false,
      theme: false,
      style: {
        fontSize: '12px',
      },
      y: {
        formatter: function (val) {
          return val.toFixed(2);
        },
      },
    },
    fill: {
      type: "gradient",
      gradient: {
        shade: "light",
        type: "vertical",
        shadeIntensity: 0.25,
        gradientToColors: undefined,
        inverseColors: true,
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 100],
      },
    },
    legend: {
      position: "top",
      horizontalAlign: "left",
      offsetX: 40,
      labels: {
        colors: "#FFFFFF", // Replace with the desired color for legend labels
      },
    },
  };

  return (
    <>

      <div>
        {props.monthGraphApi.data &&
          props.monthGraphApi.data.length > 0 ? (
          <ApexCharts
            options={options}
            series={[
              {
                name: t("Total Customer"),
                data: props.monthGraphApi.data.map(item => item.total_customer),
              },
              {
                name: t("Total Earned Points"),
                data: props.monthGraphApi.data.map(item => item.total_earned_points),
              },

              {
                name: t("Total Redeemed Points"),
                data: props.monthGraphApi.data.map(item => item.total_redeemed_points),
              },
            ]}
            type="bar"
            height={400}
          />
        ) : (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "400px",
            }}
          >
            {props.loading ? <h3>{t("Loading data...")}</h3> : <h3>{t("No data found")}</h3>}
          </div>
        )}
      </div>
    </>
  )
}
const mapStateToProps = (state) => {
  return {
    loading: state.loyalty.isLoading,
    monthGraphApi: state.loyalty.monthlyGraph,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    graphData: (startdate, enddate) => dispatch(fetchMonthlyGraphData(startdate, enddate)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(CustomerRedemptionGraph);