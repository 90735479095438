import React from 'react';
import jsPDF from "jspdf";
import "jspdf-autotable";
import "./pdf.css"
import { useTranslation } from 'react-i18next';

// Create Document Component
const MyDocument = (props) => {
  const { t } = useTranslation();
  const gstNumber = process.env.REACT_APP_CLIENT_GST_NO;

  // console.log("props", props)
  const generatePDF = () => {
    // console.log("generatePDF")
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "landscape"; // portrait or landscape
    const marginLeft = 40;
    const marginTop = 40;
    const doc = new jsPDF(orientation, unit, size);
    doc.setFontSize(12);
    const org_name = props.org === "All" ? "All" : props.org
    const { organisations } = props.orgdata
    const organisation = organisations && organisations.filter(or => or.name === org_name)
    // const {address,city,email,phone}=organisation[0]
    const range = "Date range: " + props.start.toLocaleString() + " to " + props.end.toLocaleString()
    const title = "Organisation: " + org_name;
    // const org_add="Address: "+address;
    // const org_city="City: "+city
    // const org_email="Email: "+email
    // const org_ph="Phone: "+phone.toString()
    const org_unit = "Total kWh: " + props.csvDataRes?.total_energy
    const org_amt = "Total Revenue: " + props.csvDataRes?.total_revenue
    const gst_number = "GST Number: " + gstNumber;

    const headers = [["Date", "Name", "Station Name", "Operator", "City", "Start time", "End time", "Charging time (HH:MM:SS)", "Total kWh", "Idle Time", "Idle Price", "Charging Amount", "Tax Amount", "Total Amount"]];
    const data = props.csvDataRes?.data.map(elt => [elt.end_date, elt.customer_name, elt.stationid, elt.operator, elt.city, elt.start_time, elt.stop_time, elt.consume_time, elt.kwh, elt.idle_consumedTime, elt.idle_calculated_price?.toFixed(2), elt.charging_amount.toFixed(2), elt.tax_amount, elt.total_amount]);
    // console.log("invoice data", data);
    let content = {
      startY: 160,
      head: headers,
      body: data
    };

    doc.text(title, marginLeft, 40);
    // doc.text(org_add, marginLeft, 55);
    // doc.text(org_city, marginLeft, 70);
    // doc.text(org_email, marginLeft, 85);
    // doc.text(org_ph, marginLeft, 100); 
    doc.text(range, marginLeft, 120);
    doc.text(org_unit, marginLeft, 140);
    doc.text(org_amt, marginLeft, 155);
    // doc.text(org_add, marginLeft, 55);
    // doc.text(org_city, marginLeft, 70);
    doc.text(gst_number, marginLeft, 100);
    doc.autoTable(content);
    doc.save(props.org + "_" + props.start.toISOString().substr(0, 10) + "_" + props.end.toISOString().substr(0, 10) + `.pdf`)
  }
  const handleButtonClick = async () => {
    // Generate the PDF
    generatePDF();
    props.onClick();
  };

  return (
    <div>
      <button className='pdfbutton' onClick={handleButtonClick} type="button">{t("PDF(Admin)")}</button>
    </div>
  )
}

export default MyDocument;