import React, { useEffect, useMemo } from "react";
import Table from "../reacttable/table";
import "../reacttable/reactTable.css";
import { FaDownload, FaEdit, FaEye } from "react-icons/fa";
// import { CSVLink } from "react-csv";
import { TiTick } from "react-icons/ti";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Card, Col, Container, Row } from "react-bootstrap";
import PureBreadcrumbs from "../breadcrums";
import { fetchPartnerTariff } from "../../../store/actions/ocpiAction";


const ViewPartnerTariffList = (props) => {
    const { t } = useTranslation();

    useEffect(() => {
        props.allPartnerTariff();
    }, [])

    const data = props.list && props.list.data;
    // console.log(data)

    const activateStation = (id) => {
        // console.log(id);
        props.activateTariffAPI(id);
    };

    // if (props.activate_tariff_api.statuscode === 200) {
    //     window.location.reload();
    // }

    const deactivateStation = (id) => {
        // console.log(id);
        props.deActivateTariff(id);
    };

    // if (props.deactivate_tariff_api.statuscode === 200) {
    //     window.location.reload();
    // }
    const columns = useMemo(
        () => [
            {
                Header: "Tariff",
                accessor: "name",
            },
            {
                Header: "Organisation",
                accessor: "operator",
            },
            {
                Header: "Description",
                accessor: "description",
            },
            {
                Header: "Currency",
                accessor: "currency",
            },
            {
                Header: "Is Peak Hours ?",
                accessor: "ispeak",
            },
            {
                Header: "Status",
                accessor: "status",
                Cell: (status) => {
                    // console.log(publish.value)
                    return (
                        <div>
                            <span>
                                {status.value === "active" ? (
                                    <span className="text-success">{t("Active")}</span>
                                ) : status.value === "inactive" ? (
                                    <span className="text-danger">{t("Inactive")}</span>
                                ) : (
                                    <span className="text-warning">{t("Draft")}</span>
                                )}
                            </span>
                        </div>
                    );
                },
            },
            {
                Header: "Actions",
                accessor: "id",
                Cell: (id) => {
                    return (
                        <div>
                            <span>
                                <a
                                    href={`/dashboard/view_tariff/singletariff/${id.value}`}
                                    rel="tooltip"
                                    title="View"
                                >
                                    <FaEye />
                                </a>
                            </span>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <span>
                                <a
                                    href={`/dashboard/updatetariff/${id.value}`}
                                    rel="tooltip"
                                    title="Edit"
                                >
                                    <FaEdit />
                                </a>
                            </span>
                            &nbsp;&nbsp;  &nbsp;&nbsp;
                            <span style={{ cursor: "pointer" }}>
                                <TiTick
                                    onClick={() => activateStation(id.value)}
                                    title="Activate"
                                />
                            </span>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <span style={{ cursor: "pointer" }}>
                                <AiOutlineCloseCircle
                                    onClick={() => deactivateStation(id.value)}
                                    title="De-Activate"
                                />
                            </span>
                        </div>
                    );
                },
            },
        ],
        []
    );

    // const headers = [
    //     { label: 'ID', key: 'id' },
    //     { label: 'Peak Hour', key: 'ispeak' },
    //     // { label: 'Time From', key: 'timeslots[0].from' },
    //     // { label: 'Time To', key: 'timeslots[0].to' },
    //     { label: 'Amount', key: 'components[0].amount' },
    //     { label: 'Tax', key: 'components[0].tax' },
    //     { label: 'Unit', key: 'components[0].unit' },
    //     { label: 'Currency', key: 'currency' },
    //     { label: 'Description', key: 'description' },
    //     { label: 'Name', key: 'name' },
    //     // { label: 'Day', key: 'day' },
    //     { label: 'Role', key: 'role' },
    //     { label: 'Operator', key: 'operator' },
    //     { label: 'Created At', key: 'createdat' },
    //     { label: 'Updated At', key: 'updatedat' },
    //     { label: 'Status', key: 'status' },
    //     { label: 'Created By', key: 'created_by' },
    // ];

    return (
        <Container fluid >
            <PureBreadcrumbs />
            <br />
            <Row >
                <Col className="mt-4">

                    <Card className="customercard">
                        <div className="table-header"> {t("Partner Tariff List")}</div>
                        {props.loading ? <h3 style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            height: "250px",
                        }}>Loading data...</h3> :
                            data && data.length > 0 ? (
                                <div>
                                    {/* <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <button className="csv-file-btn">
                                <CSVLink
                                    className="fas"
                                    data={data}
                                    headers={headers}
                                    filename="Tariff-List.csv"
                                    style={{ textDecoration: 'none' }}
                                >
                                    <FaDownload className="download-icon" />
                                </CSVLink>
                            </button>
                        </div> */}
                                    <Table data={data} columns={columns}></Table>
                                </div>
                            ) : (
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                    }}
                                > <h4>{t("No Data Found")}</h4>
                                </div>
                            )}
                    </Card>
                </Col>
            </Row>
        </Container>
    );

}
const mapStateToProps = (state) => {
    return {
        loading: state.ocpi.isLoading,
        list: state.ocpi.fetchPartnerTariff,
        // activateRes: state.ocpi.activateRoamingPartner,
        // deactivateRes: state.ocpi.deactivateRoamingPartner,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        allPartnerTariff: () => dispatch(fetchPartnerTariff()),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(ViewPartnerTariffList);

