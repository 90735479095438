import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Bounce, ToastContainer, toast } from 'react-toastify';

const ChangeAvailabilityComponent = (props) => {
    const { t } = useTranslation();
    const evsedata = props.fetchstation && props.fetchstation
    const [isToastVisible, setIsToastVisible] = useState(false);

    // console.log(evsedata)
    const conn = evsedata && evsedata.evses
    const zxc = conn && conn.map(en => en.connectors)
    const data = Object.values(zxc).flatMap(arr => arr)
    // console.log(props.changeavailability.statuscode)

    const handlechangeavailability = (evse_id, connectorId, type) => {
        props.changeAvailability(evse_id, connectorId, type)
    }
    // if (props.changeavailability.statuscode === 200) {
    //     alert("Success!!! ")
    // }

    // console.log(props.changeavailability.statuscode);

    useEffect(() => {
        if (props.changeavailability.statuscode === 200) {
            setIsToastVisible(true);
            toast.success('Success!!!', {
                position: "top-center",
                autoClose: 300,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
                onClose: () => {
                    setIsToastVisible(false);
                    window.location.reload();
                },
            });
        }
    }, [props.changeavailability]);

    return (
        <div className='container'>
            {isToastVisible && <div className="overlay" />}
            <ToastContainer
                position="top-center"
                autoClose={20}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
                style={{ top: "10%", width: "auto", zIndex: "9999" }}
            />
            <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-12 mx-auto">
                    <div className="table-responsive">
                        <table className="table table-sm table-bordered">
                            <thead>
                                <tr>
                                    <th scope="col" className='textblack'>{t("Charge Box ID")}</th>
                                    <th scope="col" className='textblack'>{t("Connector ID ")}</th>
                                    <th scope="col" className='textblack'>{t("Connector Type")}</th>
                                    <th scope="col" className='textblack'>{t("Actions")}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data && data.map((subItems) => {
                                    return (
                                        <tr key={subItems.id}>
                                            <td>{subItems.evse_id} </td>
                                            <td> {subItems.id}</td>
                                            <td> {subItems.standard}</td>
                                            <td>
                                                <button className="btn commandButtons" type="button" onClick={() => { handlechangeavailability(subItems.evse_id, subItems.id, "Operative"); }}>{t("Operative")}</button>&nbsp;&nbsp;&nbsp;
                                                <button className="btn commandButtons" type="button0" onClick={() => { handlechangeavailability(subItems.evse_id, subItems.id, "Inoperative"); }}>{t("Inoperative")}</button>
                                            </td>
                                        </tr>
                                    )
                                })}


                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ChangeAvailabilityComponent;