// TranslationLoader.js

import { useEffect } from 'react';
import i18n from './i18n';
import { connect } from 'react-redux';
import { fetchTranslation } from './store/actions/insightAction';

const TranslationLoader = (props) => {

    useEffect(()=>{
        props.getTranslationData();
    },[])

    const TranslationData = props.fetchTranslationJson && props.fetchTranslationJson.data;
    // console.log("translation data: " ,TranslationData)

    useEffect(() => {
        TranslationData && TranslationData.map(translations => (
            Object.keys(translations).forEach(language => {
                i18n.addResourceBundle(language, 'translation', translations[language].translation, true, true);
            })
        ));
    }, [TranslationData]);

    return null; // Since this component is just for side effect, it doesn't render anything
};
const mapStateToProps = (state) => {
    return {
      loading: state.insight.isLoading,
      fetchTranslationJson: state.insight.getTranslation,
    };
  };
  const mapDispatchToProps = (dispatch) => {
    return {
        getTranslationData: () => dispatch(fetchTranslation()),
    };
  };
  export default connect(mapStateToProps, mapDispatchToProps)(TranslationLoader);

