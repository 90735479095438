import React from "react";
import {
  fetchHrsSessionTypeAction,
  fetchMonthSessionTypeAction,
  fetchWeekSessionTypeAction,
} from "../../../../store/actions/insightAction";
import { useEffect } from "react";
import { connect } from "react-redux";
import Chart from "react-apexcharts";
import { Container, Row, Col, Card } from "react-bootstrap";
import PureBreadcrumbs from "../../breadcrums";
import Moment from "moment";
import EnergyRevenueTable from "../../insights/energyrevenue/energyrevenuelist";
import { useTranslation } from "react-i18next";

function EnergyRevenueReport(props) {
  const today = Moment();
  const weeklycurrent = today.startOf("week").format("YYYY-MM-DD");
  const weeklylastDay = today.endOf("week").format("YYYY-MM-DD");
  const monthlycurrent = today.subtract(4, "month").format("YYYY-MM-DD");
  const monthlylastDay = today.add(4, "month").format("YYYY-MM-DD");

  const { t } = useTranslation();

  useEffect(() => {
    props.getHRS();
    props.getWeekdata(weeklycurrent, weeklylastDay);
    props.getMonthsData(monthlycurrent, monthlylastDay);
  }, []);

  //----------------Daily Graph Start ---------------//
  let timepush = [];
  //daily calculation
  const dailyenergy = props.hrs_total && props.hrs_total.data && props.hrs_total.data.map((en) => en.energy);
  // console.log(props.hrs_total && props.hrs_total.data);
  // console.log(dailyenergy);
  let dailyunit = [];
  for (let i = 0; i < dailyenergy.length; i++) {
    if (dailyenergy.length > 0) {
      dailyunit.push(Math.ceil(dailyenergy[i]));
    }
  }
  const dailyamount = props.hrs_total && props.hrs_total.data.map((amt) => amt.amount);
  let dailyrevenue = [];
  for (let i = 0; i < dailyamount.length; i++) {
    if (dailyamount.length > 0) {
      dailyrevenue.push(Math.ceil(dailyamount[i]));
    }

  }

  //hr lables
  const timelabels =
    props.hrs_total &&
    props.hrs_total.data.map((label) => {
      const { number } = label.ID;
      const hrs = number;
      var hours = hrs,
        am; //new Date().getHours(), am;
      hours = (hours + 24 + 12) % 24;
      am = hours < 12 ? ":00 AM" : ":00 PM";
      var finalhrs = hours.toFixed() + am;
      timepush.push(finalhrs);
    });
  // console.log("timepush", timepush);

  const hrdata = {
    chart: {
      type: "area",
      height: 350,
    },
    grid: {
      show: true,      // you can either change hear to disable all grids
      xaxis: {
        lines: {
          show: false  //or just here to disable only x axis grids
         }
       },  
      yaxis: {
        lines: { 
          show: false  //or just here to disable only y axis
         }
       },   
    },
   
    legend: {
      position: "bottom",
      labels: {
        colors: "#FFFFFF", // Replace with the desired color for legend labels
      },
    },
    xaxis: {
      categories: timepush,
      labels: {
        style: {
          colors: "#FFFFFF", // Replace with the color you want for Y-axis content
        },
      },
    },
    yaxis: [
      {
        title: {
          text: "Daily Energy",
          style: {
            color: "#FFFFFF",
            fontSize:"13px",
            fontWeight: 600,
        },
        },
        labels: {
          style: {
            colors: "#FFFFFF", // Replace with the color you want for Y-axis content
          },
        },
      },
      {
        opposite: true,
        title: {
          text: "Daily Revenue",
          style: {
            color: "#FFFFFF",
            fontSize:"13px",
            fontWeight: 600,
        },
        },
        labels: {
          style: {
            colors: "#FFFFFF", // Replace with the color you want for Y-axis content
          },
        },
      },
      
    ],
    dataLabels: {
      enabled: false,
      
    },
    stroke: {
      curve: "smooth",
    },
    colors: ["#5559B2", "#0BCF6B"],
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.9,
        stops: [0, 100],
      },
    },
    series: [
      {
        name: "Daily Energy",
        data: dailyunit,
      },
      {
        name: "Daily Revenue",
        data: dailyrevenue,
        yaxis: 1,
      },
    ],
    tooltip: {
      y: {
        formatter: function (val, { series, seriesIndex }) {
          if (seriesIndex === 0) {
            return val + " kWh";
          } else {
            return val;
          }
        },
      },
      enabled: true,
      enabledOnSeries: undefined,
      followCursor: false,
      fillSeriesColor: false,
      theme: false,
      style: {
        fontSize: '12px',
    },
    },
     
  };
  //----------------Daily Graph end ---------------//

  //------------------------Weekly Graph Start ------------------------//
  let weekpush = [];

  const weeklyenergy = props.week_total && props.week_total.data.map((en) => en.energy);

  // console.log("Weekly",props.week_total && props.week_total.data);

  let weeklyunit = [];
  for (let i = 0; i < weeklyenergy.length; i++) {
    if (weeklyenergy.length > 0) {
      weeklyunit.push(Math.ceil(weeklyenergy[i]));
    }
  }
  const weeklyamount = props.week_total && props.week_total.data.map((amt) => amt.amount);
  let weeklyrevenue = [];
  for (let i = 0; i < weeklyamount.length; i++) {
    if (weeklyamount.length > 0) {
      weeklyrevenue.push(Math.ceil(weeklyamount[i]));
    }
  }

  //weeklabel
  const weeklylabel = props.week_total && props.week_total.data.map((label) => {
    const { number } = label.ID;
    let week = number;
    if (week === 1) {
      weekpush.push("Sunday");
    } else if (week === 2) {
      weekpush.push("Monday");
    } else if (week === 3) {
      weekpush.push("Tuesday");
    } else if (week === 4) {
      weekpush.push("Wednesday");
    } else if (week === 5) {
      weekpush.push("Thursday");
    } else if (week === 6) {
      weekpush.push("Friday");
    } else if (week === 7) {
      weekpush.push("Saturday");
    }
  });

  const weekdata = {
    chart: {
      type: "area",
      height: 350,
    },
    grid: {
      show: true,      // you can either change hear to disable all grids
      xaxis: {
        lines: {
          show: false  //or just here to disable only x axis grids
         }
       },  
      yaxis: {
        lines: { 
          show: false  //or just here to disable only y axis
         }
       },   
    },
   
    legend: {
      position: "bottom",
      labels: {
        colors: "#FFFFFF", // Replace with the desired color for legend labels
      },
    },
    xaxis: {
      categories: weekpush,
      labels: {
        style: {
          colors: "#FFFFFF", // Replace with the color you want for Y-axis content
        },
      },
    },
    yaxis: [
      {
        title: {
          text: "Weekly Energy",
          style: {
            color: "#FFFFFF",
            fontSize:"13px",
            fontWeight: 600,
        },
        },
        labels: {
          style: {
            colors: "#FFFFFF", // Replace with the color you want for Y-axis content
          },
        },
      },
      {
        opposite: true,
        title: {
          text: "Weekly Revenue",
          style: {
            color: "#FFFFFF",
            fontSize:"13px",
            fontWeight: 600,
        },
        },
        labels: {
          style: {
            colors: "#FFFFFF", // Replace with the color you want for Y-axis content
          },
        },
      },
    ],
    dataLabels: {
      enabled: false,
    },
    colors: ["#5559B2", "#0BCF6B"],
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
        endingShape: "rounded",
      },
    },
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.9,
        stops: [0, 100],
      },
    },
    series: [
      {
        name: "Weekly Energy",
        data: weeklyunit,
      },
      {
        name: "Weekly Revenue",
        data: weeklyrevenue,
        yaxis: 1,
      },
    ],
    tooltip: {
      y: {
        formatter: function (val, { series, seriesIndex }) {
          if (seriesIndex === 0) {
            return val + " kWh";
          } else {
            return val;
          }
        },
      },
      enabled: true,
      enabledOnSeries: undefined,
      followCursor: false,
      fillSeriesColor: false,
      theme: false,
      style: {
        fontSize: '12px',
    },
    },
  };
  //------------------------Weekly Graph end -----------------------//

  //------------------------Monthly Graph Start ------------------------//
  let monthpush = [];
  //monthly calculation
  const monthlyenergy = props.month_total && props.month_total.data.map((en) => en.energy);
  // console.log(props.month_total.data)
  let monthlyunit = [];
  for (let i = 0; i < monthlyenergy.length; i++) {
    if (monthlyenergy.length > 0) {
      monthlyunit.push(Math.ceil(monthlyenergy[i]));
    }
  }
  const monthlyamount = props.month_total && props.month_total.data.map((amt) => amt.amount);
  let monthlyyrevenue = [];
  for (let i = 0; i < monthlyamount.length; i++) {
    if (monthlyamount.length > 0) {
      monthlyyrevenue.push(Math.ceil(monthlyamount[i]));
    }
  }

  //month label
  const monthlylabel = props.month_total && props.month_total.data.map((label) => {
    const { number } = label.ID;
    let month = number;

    if (month === 12) {
      monthpush.push("December");
    } else if (month === 11) {
      monthpush.push("November");
    } else if (month === 10) {
      monthpush.push("October");
    } else if (month === 9) {
      monthpush.push("September");
    } else if (month === 8) {
      monthpush.push("August");
    } else if (month === 7) {
      monthpush.push("July");
    } else if (month === 6) {
      monthpush.push("June");
    } else if (month === 5) {
      monthpush.push("May");
    } else if (month === 4) {
      monthpush.push("April");
    } else if (month === 3) {
      monthpush.push("March");
    } else if (month === 2) {
      monthpush.push("February");
    } else if (month === 1) {
      monthpush.push("January");
    }
  });

  const monthdata = {
    chart: {
      type: "area",
      height: 350,
    },
    grid: {
      show: true,      // you can either change hear to disable all grids
      xaxis: {
        lines: {
          show: false  //or just here to disable only x axis grids
         }
       },  
      yaxis: {
        lines: { 
          show: false  //or just here to disable only y axis
         }
       },   
    },
   
    legend: {
      position: "bottom",
      labels: {
        colors: "#FFFFFF", // Replace with the desired color for legend labels
      },
    },
    xaxis: {
      categories: monthpush,
      labels: {
        style: {
          colors: "#FFFFFF", // Replace with the color you want for Y-axis content
        },
      },
    },
    yaxis: [
      {
        title: {
          text: "Monthly Energy",
          style: {
            color: "#FFFFFF",
            fontSize:"13px",
            fontWeight: 600,
        },
        },
        labels: {
          style: {
            colors: "#FFFFFF", // Replace with the color you want for Y-axis content
          },
        },
      },
      {
        opposite: true,
        title: {
          text: "Monthly Revenue",
          style: {
            color: "#FFFFFF",
            fontSize:"13px",
            fontWeight: 600,
        },
        },
        labels: {
          style: {
            colors: "#FFFFFF", // Replace with the color you want for Y-axis content
          },
        },
      },
    ],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },
    colors: ["#5559B2", "#0BCF6B"],
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.9,
        stops: [0, 100],
      },
    },
    series: [
      {
        name: "Monthly Energy",
        data: monthlyunit,
      },
      {
        name: "Monthly Revenue",
        data: monthlyyrevenue,
        yaxis: 1,
      },
    ],
    tooltip: {
      y: {
        formatter: function (val, { series, seriesIndex }) {
          if (seriesIndex === 0) {
            return val + " kWh";
          } else {
            return val;
          }
        },
      },
      enabled: true,
      enabledOnSeries: undefined,
      followCursor: false,
      fillSeriesColor: false,
      theme: false,
      style: {
        fontSize: '12px',
    },
    },
  };

  //------------------------Monthly Graph end ------------------------//
  //   const options = {
  //     scales: {
  //       x: {
  //         grid: {
  //           display: false,
  //         },
  //       },
  //     },
  //   };

  return (
    <Container fluid>
      <PureBreadcrumbs />
      <br />
      <Row>
        <Col sm={4} className="mb-2">
          <Card className="statuscard">
            <Card.Title className="text-center">
              {t("Daily Energy vs Revenue Graph")}
            </Card.Title>
            <Card.Body>
              {props.hrs_total && props.hrs_total.data.length > 0 ? (<Chart
                options={hrdata}
                series={hrdata.series}
                type="area"
                height={250}
              />) : (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "250px",
                  }}
                >
                  {props.loading ? <h3>Loading data...</h3> : <h3>{t("No data found")}</h3>}
                </div>
              )}
            </Card.Body>
          </Card>
        </Col>

        <Col sm={4} className="mb-2">
          <Card className="statuscard">
            <Card.Title className="text-center">
              {t("Weekly Energy vs Revenue Graph")}
            </Card.Title>
            <Card.Body>
              {props.week_total && props.week_total.data.length > 0 ? (<Chart
                options={weekdata}
                series={weekdata.series}
                type="area"
                height={250}
              />) : (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "250px",
                  }}
                >
                  {props.loading ? <h3>Loading data...</h3> : <h3>{t("No data found")}</h3>}
                </div>
              )}
            </Card.Body>
          </Card>
        </Col>
        <Col sm={4} className="mb-2">
          <Card className="statuscard">
            <Card.Title className="text-center">
              {t("Monthly Energy vs Revenue Graph")}
            </Card.Title>
            <Card.Body>
              {props.month_total && props.month_total.data.length > 0 ? (<Chart
                options={monthdata}
                series={monthdata.series}
                type="area"
                height={250}
              />) : (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "250px",
                  }}
                >
                  {props.loading ? <h3>Loading data...</h3> : <h3>{t("No data found")}</h3>}
                </div>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row className="mt-4">
        <Col>
          <EnergyRevenueTable className="mb-5" />
        </Col>
      </Row>
    </Container>
  );
}

const mapStateToProps = (state) => {
  return {
    loading: state.insight.isLoading,
    hrs_total: state.insight.hrs_session,
    week_total: state.insight.week_session,
    month_total: state.insight.month_session,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getHRS: () => dispatch(fetchHrsSessionTypeAction()),
    getMonthsData: (start, end) => dispatch(fetchMonthSessionTypeAction(start, end)),
    getWeekdata: (start, end) => dispatch(fetchWeekSessionTypeAction(start, end)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EnergyRevenueReport);
