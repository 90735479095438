import React, { useEffect, useMemo } from "react";
import { Row, Col, Card, Container } from "react-bootstrap";
import "react-dates/initialize";
import "react-datepicker/dist/react-datepicker.css";
import Table from "../../reacttable/table";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { FaEdit, FaEye } from "react-icons/fa";
import { TiTick } from "react-icons/ti";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { activateRuleApi, deactivateRuleApi, fetchAllRuleApi } from "../../../../store/actions/loyaltyAction";
import PureBreadcrumbs from "../../breadcrums";

function ViewRuleList(props) {
  const { t } = useTranslation();

  useEffect(() => {
    props.fetch_all_rule();
  }, [])

  const data = props.allRuleRes && props.allRuleRes.data;
  // console.log(data);
  const total = data && data.length;
  const active = data && data.filter((item) => item.status === true).length;
  const inActive = data && data.filter((item) => item.status === false).length;

  const activateRule = (e) => {
    // console.log(e);
    props.activateRule(e);
  };

  if (props.activateRes.statuscode === 200) {
    window.location.reload();
  }

  const deactivateRule = (e) => {
    // console.log(e);
    props.deactivateRule(e);
  };

  if (props.deactivateRes.statuscode === 200) {
    window.location.reload();
  }

  const columns = useMemo(
    () => [
      {
        Header: "Rule Name",
        id: "1",
        accessor: "rule_name",
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: (status) => {
          // console.log(publish.value)
          return (
            <div>
              <span>
                {status.value === true ? (
                  <span className="text-success">{t("Active")}</span>
                ) : (
                  <span className="text-danger">{t("Inactive")}</span>
                )}
              </span>
            </div>
          );
        },
      },
      {
        Header: "Actions",
        accessor: "rule_name",
        Cell: (rule_name) => {
          return (
            <div>
              <span>
                <a
                  href={`/dashboard/view_rule/singleRule/${rule_name.value}`}
                  rel="tooltip"
                  title="View"
                >
                  <FaEye />
                </a>
              </span>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <span>
                <a
                  href={`/dashboard/view_rule/updateRule/${rule_name.value}`}
                  rel="tooltip"
                  title="Edit"
                >
                  <FaEdit />
                </a>
              </span>
              &nbsp;&nbsp;  &nbsp;&nbsp;
              <span style={{ cursor: "pointer" }}>
                <TiTick
                  onClick={() => activateRule(rule_name.value)}
                  title="Activate"
                />
              </span>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <span style={{ cursor: "pointer" }}>
                <AiOutlineCloseCircle
                  onClick={() => deactivateRule(rule_name.value)}
                  title="De-Activate"
                />
              </span>
            </div>
          );
        },
      },

    ],
    []
  );
  return (
    <>
      <Container fluid className="pb-4">
        <PureBreadcrumbs />
        <Row className="mt-2">
          <Col className="col-md-4 col-12">
            <Card className="customercard card-custom total-rules">
              <div className="diagonal-line"></div>
              <div style={{ textAlign: "left" }}>
                <Card.Text style={{ fontSize: "16px" }}>{t("TOTAL RULES")}</Card.Text>
                <Card.Title>
                  <b>{total != undefined ? total : 0}</b>
                </Card.Title>
              </div>
            </Card>
            <br />
          </Col>
          <Col className="col-md-4 col-12">
            <Card className="customercard card-custom total-active-rules">
              <div className="diagonal-line"></div>
              <div style={{ textAlign: "left" }}>
                <Card.Text style={{ fontSize: "16px" }}>{t("TOTAL ACTIVE RULES")}</Card.Text>
                <Card.Title>
                  <b>{active != undefined ? active : 0}</b>
                </Card.Title>
              </div>
            </Card>
            <br />
          </Col>
          <Col className="col-md-4 col-12">
            <Card className="customercard card-custom total-inactive">
              <div className="diagonal-line"></div>
              <div style={{ textAlign: "left" }}>
                <Card.Text style={{ fontSize: "16px" }}>{t("TOTAL INACTIVE RULES")}</Card.Text>
                <Card.Title>
                  <b>{inActive != undefined ? inActive : 0}</b>
                </Card.Title>
              </div>
            </Card>
            <br />
          </Col>
        </Row>


        <Row className="mt-2">
          <Col lg={12}>
            <Card className="mt-2 customercard ">
              <div className="table-header">{t("View Rule")}</div>

              <Card.Body>
                <div className="evse">
                  {props.loading ? <h3 style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "250px",
                  }}>Loading data...</h3> :
                    props.allRuleRes && props.allRuleRes.data != null ? (
                      <div>
                        <Table data={data} columns={columns} ></Table>
                      </div>
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          height: "250px",
                        }}
                      >
                        <h3>{t("No Data Found")}</h3>
                      </div>
                    )}
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  )

}
const mapStateToProps = (state) => {
  return {
    loading: state.loyalty.isLoading,
    allRuleRes: state.loyalty.fetchAllRule,
    activateRes: state.loyalty.activateRule,
    deactivateRes: state.loyalty.deactivateRule,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    fetch_all_rule: () => dispatch(fetchAllRuleApi()),
    activateRule: (rule_name) => dispatch(activateRuleApi(rule_name)),
    deactivateRule: (rule_name) => dispatch(deactivateRuleApi(rule_name)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ViewRuleList);

