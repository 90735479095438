import { OCPI_URL, SAVE_ROAMING_PARTNER, VIEW_ALL_ROAMING_PARTNER, VIEW_SINGLE_ROAMING_PARTNER, UPDATE_ROAMING_PARTNER, FETCH_ALL_OCPI_API, FETCH_SINGLE_OCPI_VIEW_API, ACTIVATE_ROAMING_API, DEACTIVATE_ROAMING_API, FETCH_PARTNER_TARIFF_API } from "../../components/config/config";

export const saveRoamingPartner = (data) => {
    const { partner_name, country_code, address, city, state, country, party_id, url,tokenA } = data

    const { token, role, organisation } = JSON.parse(localStorage.getItem('user'));
    console.log(token)
    const bearer = 'basic ' + token
    return dispatch => {
        dispatch({ type: 'OCPI_LOADING', payload: '' })
        return fetch(OCPI_URL + SAVE_ROAMING_PARTNER, {
            method: "POST",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
            body: JSON.stringify({
                "partner_name": partner_name,
                'country_code': country_code,
                'address': address,
                "city": city,
                "state": state,
                'country': country,
                'url': url,
                "party_id": party_id,
                'role': role,
                "organisation": organisation,
                "tokenA":tokenA
            })
        }).then(resp => resp.json()).then(data => {
            dispatch({ type: 'SAVE_ROAMING_PARTNER', payload: data })
        })
    }
}

export const allRoamingPartner = () => {
    const { token, role, organisation } = JSON.parse(localStorage.getItem('user'));
    const bearer = 'basic ' + token
    return dispatch => {
        dispatch({ type: 'OCPI_LOADING', payload: '' })
        return fetch(OCPI_URL + VIEW_ALL_ROAMING_PARTNER + "?role=" + role + "&organisation=" + organisation, {
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        }).then(resp => resp.json()).then(data => {
            // console.log(data)
            dispatch({ type: 'VIEW_ALL_ROAMING_PARTNER', payload: data })
        })
    }
}

export const singleRoamingPartner = (id) => {
    const { token } = JSON.parse(localStorage.getItem('user'));
    const bearer = 'basic ' + token
    return dispatch => {
        dispatch({ type: 'OCPI_LOADING', payload: '' })
        return fetch(OCPI_URL + VIEW_SINGLE_ROAMING_PARTNER + "?id=" + id, {
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        }).then(resp => resp.json()).then(data => {
            dispatch({ type: 'VIEW_SINGLE_ROAMING_PARTNER', payload: data })
        })
    }
}

export const updateRoamingPartner = (id, data) => {
    const { partner_name, country_code, address, city, state, country, party_id, url,tokenA } = data
    const { token, role, organisation } = JSON.parse(localStorage.getItem('user'));
    const bearer = 'basic ' + token
    return dispatch => {
        dispatch({ type: 'OCPI_LOADING', payload: '' })
        return fetch(OCPI_URL + UPDATE_ROAMING_PARTNER, {
            method: "POST",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
            body: JSON.stringify({
                "id": id,
                "partner_name": partner_name,
                'country_code': country_code,
                'address': address,
                "city": city,
                "state": state,
                'country': country,
                'url': url,
                "party_id": party_id,
                'role': role,
                "organisation": organisation,
                "tokenA":tokenA

            })
        }).then(resp => resp.json()).then(data => {
            // console.log(data)
            dispatch({ type: 'UPDATE_ROAMING_PARTNER', payload: data })
        })
    }
}

export const fetchAllOcpi = (partner_name) => {
    const { token } = JSON.parse(localStorage.getItem('user'));
    const bearer = 'basic ' + token
    return dispatch => {
        dispatch({ type: 'OCPI_LOADING', payload: '' })
        return fetch(OCPI_URL + FETCH_ALL_OCPI_API + "?partner_name=" + partner_name, {
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        }).then(resp => resp.json()).then(data => {
            dispatch({ type: 'VIEW_ALL_OCPI_API', payload: data })
        })
    }
}

export const viewSingleOcpi = (name) => {
    const { token } = JSON.parse(localStorage.getItem('user'));
    const bearer = 'basic ' + token;
    return dispatch => {
        dispatch({ type: 'OCPI_LOADING', payload: '' })
        return fetch(OCPI_URL + FETCH_SINGLE_OCPI_VIEW_API + "?name=" + name, {
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        }).then(resp => resp.json()).then(data => {
            dispatch({ type: 'FETCH_SINGLE_OCPI_API', payload: data })
        })
    }
}

export const activateRoamingPartner = (id) => {
    const { token } = JSON.parse(localStorage.getItem('user'));
    const bearer = 'basic ' + token
    return dispatch => {
        dispatch({ type: 'OCPI_LOADING', payload: '' })
        return fetch("http://192.168.1.17:7041/ocpi/secure/api/v1/activate/roamingpartner?id=6596a1744705d79425d6f19b", {
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        }).then(resp => resp.json()).then(data => {
            dispatch({ type: 'ACTIVATE_ROAMING_PARTNER', payload: data })
        })
    }
}

export const deactivateRoamingPartner = (id) => {
    const { token } = JSON.parse(localStorage.getItem('user'));
    const bearer = 'basic ' + token
    return dispatch => {
        dispatch({ type: 'OCPI_LOADING', payload: '' })
        return fetch("http://192.168.1.17:7041/ocpi/secure/api/v1/deactivate/roamingpartner?id=6596a1744705d79425d6f19b", {
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        }).then(resp => resp.json()).then(data => {
            dispatch({ type: 'DEACTIVATE_ROAMING_PARTNER', payload: data })
        })
    }
}

export const fetchPartnerTariff = () => {
    const { token } = JSON.parse(localStorage.getItem('user'));
    const bearer = 'basic ' + token
    return dispatch => {
        dispatch({ type: 'OCPI_LOADING', payload: '' })
        return fetch(OCPI_URL + FETCH_PARTNER_TARIFF_API, {
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        }).then(resp => resp.json()).then(data => {
            dispatch({ type: 'FETCH_PARTNER_TARIFF', payload: data })
        })
    }
}