import React from "react";
import {
  useTable,
  useGlobalFilter,
  usePagination,
  useSortBy,
} from "react-table";
import "../../reacttable/reactTable.css"
import { FaFilter } from "react-icons/fa";
import GlobalFilter from "../../reacttable/GlobalFilter";
import { useTranslation } from "react-i18next";

export default function EnterpriseTable({ columns, data }) {
  const { t } = useTranslation();
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    gotoPage,
    pageCount,
    setPageSize,
    prepareRow,
    state,
    setGlobalFilter,
    allColumns,
    getToggleHideAllColumnsProps,
    // rows,
  } = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );
  const { globalFilter, pageIndex, PageSize } = state;
  return (
    <>


      <div className="container-fluid ">
        <div className="row mt-2">
          <div className="col-md-12 d-flex justify-content-end mb-2">

            {/* Global filter code logic start here */}
            <div className="row d-flex justify-content-end">
              <div className="col mr-3">
                <GlobalFilter
                  filter={globalFilter}
                  setFilter={setGlobalFilter}
                />
              </div>
              <div className="row mr-4 mt-1">
                <a
                  data-toggle="collapse"
                  href="#collapseExample"
                  role="button"
                  aria-expanded="false"
                  aria-controls="collapseExample"
                  style={{ color: "var(--text-primary)", outline: 'none', textDecoration: 'none' }}
                >
                  <FaFilter style={{ fontSize: '20px' }} />
                </a>
              </div>
            </div>

            {/* Global filter code logic Ends here */}
          </div>
        </div>
        <div className="row ">
          <div className="col-md-12 d-flex justify-content-between" >
            <div className="collapse" id="collapseExample" >
              <div className="d-inline-flex checkbox-container" >
                <span className=" mr-3 filterall">
                  <input
                    type="checkbox"
                    className="custom-checkbox"
                    {...getToggleHideAllColumnsProps()}
                  />
                  <span style={{ fontSize: '12px' }}>Filter All </span>
                </span>
                {allColumns.map((column) => (
                  <span key={column.id} className="pr-3 mt-2 ">
                    <label>
                      <input
                        className="custom-checkbox d-flex mb-2"
                        type="checkbox"
                        {...column.getToggleHiddenProps()}
                      />

                      <span className="column-header mt-2">{column.Header}</span>
                    </label>
                  </span>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="table-responsive">
          <div className="customtable">
            <table {...getTableProps()} id="User_Details">
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}
                      >
                        {t(column.render("Header"))}
                        {column.canGroupBy ? (
                          <span {...column.getGroupByToggleProps()}>
                            {column.isGrouped ? "🛑 " : "👊 "}
                          </span>
                        ) : null}
                        <span>
                          {column.isSorted
                            ? column.isSortedDesc
                              ? " 🔽"
                              : " 🔼"
                            : ""}
                        </span>
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>

              <tbody {...getTableBodyProps()}>
                {page.map((row, i) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => {
                        return (
                          <td {...cell.getCellProps()}>
                            {cell.render("Cell")}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>

            </table>

          </div>
        </div>

        <div className="row mt-4">
          <div className="col-md-4 text-left">
            {/* page number */}
            <span style={{ fontSize: "14px", color: "var(--text-primary)" }}>
              Page{" "}
              <strong>
                {pageIndex + 1} of {pageOptions.length}
              </strong>{" "}
            </span>
          </div>
          <div className="col-md-4 text-center">
            <div className="row">
            <div className="col-md-6 text-right ">
            <span style={{ fontSize: "14px", color: "var(--text-primary)" }}>
                  Page: {""}
                  <input
                    className="inputPage"
                    type="number"
                    defaultValue={pageIndex + 1}
                    onChange={(e) => {
                      const pageNumber = e.target.value
                        ? Number(e.target.value) - 1
                        : 0;
                      gotoPage(pageNumber);
                    }}
                    style={{ width: "30px", height: "25px" }}
                  />
                </span>
              </div>
              <div className="col-md-6 text-left " >
                <select
                  className="inputPage"
                  value={PageSize}
                  onChange={(e) => setPageSize(Number(e.target.value))}
                  style={{ height: "25px" }}
                >
                  {[10, 200, 500, 1000].map((PageSize) => (
                    <option key={PageSize} value={PageSize}>
                      {PageSize} Row
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>

          <div className="col-md-4 text-right">
            <button
              className=" columnsBtn btn-sm mr-2"
              onClick={() => {
                gotoPage(0);
              }}
              disabled={!canPreviousPage}
            >
              {"<<"}
            </button>
            <button
              className=" columnsBtn btn-sm mr-2"
              onClick={() => previousPage()}
              disabled={!canPreviousPage}
            >
              Previous
            </button>
            <button
              className="columnsBtn btn-sm mr-2"
              onClick={() => nextPage()}
              disabled={!canNextPage}
            >
              Next
            </button>
            <button
              className="columnsBtn btn-sm mr-2"
              onClick={() => {
                gotoPage(pageCount - 1);
              }}
              disabled={!canNextPage}
            >
              {">>"}
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
