const initState = {
    isLoading: false,
    fetchcutomers: '',
    fetchsinglecutomers: '',
    fetchAllRFID: '',
    saveRfid: '',
    updateCustomerProfile: '',
    customerPercentageChange: '',
    customerFeedback: '',
    chargingHistory: '',
    authenicationlog: '',
    activateAccount: '',
    deactivateAccount: '',
    updateAdminStatus: '',
    getAdminStatus: '',
    updateRfid: '',
    activateRfid: '',
    deactivateRfid: '',
    saveCustomerGrouping: '',
    fetchAllCustomerGroups: '',
    fetchSingleCustomerGroupData: '',
    updateCustomerGroup: '',
    activateCustomerGroup: '',
    deactivateCustomerGroup: '',
    fetchRfidList: '',
    saveRfidBindings: '',
    saveCustomerAccount: '',
    fetchSingleRfid: '',

}
const customerReducer = (state = initState, action) => {
    switch (action.type) {
        case 'LOAD_CUSTOMER':
            return { ...state, isLoading: true }
        case 'LOAD_CUSTOMER_LIST':
            return { ...state, fetchcutomers: action.payload, isLoading: false };
        case 'LOAD_SINGLE_CUSTOMER':
            return { ...state, fetchsinglecutomers: action.payload, isLoading: false };
        case 'LOAD_RFID_LIST':
            return { ...state, fetchAllRFID: action.payload, isLoading: false };
        case 'SAVE_RFID':
            return { ...state, saveRfid: action.payload, isLoading: false };
        case 'UPDATE_CUSTOMER_PROFILE':
            return { ...state, updateCustomerProfile: action.payload, isLoading: false };
        case 'CUSTOMER_PERCENTAGE_CHANGE_API':
            return { ...state, customerPercentageChange: action.payload, isLoading: false };
        case 'CUSTOMER_FEEDBACK_API':
            return { ...state, customerFeedback: action.payload, isLoading: false };
        case 'FETCH_USER_CHARGING_HISTORY':
            return { ...state, chargingHistory: action.payload, isLoading: false };
        case 'FETCH_USER_AUTHENTICATION_LOG':
            return { ...state, authenicationlog: action.payload, isLoading: false };
        case 'ACTIVATE_USER_ACCOUNT':
            return { ...state, activateAccount: action.payload, isLoading: false };
        case 'DEACTIVATE_USER_ACCOUNT':
            return { ...state, deactivateAccount: action.payload, isLoading: false };
        case 'SAVE_ADMIN_STATUS':
            return { ...state, updateAdminStatus: action.payload, isLoading: false };
        case 'FETCH_ADMIN_STATUS':
            return { ...state, getAdminStatus: action.payload, isLoading: false };
        case 'UPDATE_RFID':
            return { ...state, updateRfid: action.payload, isLoading: false };
        case 'ACTIVATE_RFID':
            return { ...state, activateRfid: action.payload, isLoading: false };
        case 'DEACTIVATE_RFID':
            return { ...state, deactivateRfid: action.payload, isLoading: false };
        case 'SAVE_CUSTOMER_GROUP':
            return { ...state, saveCustomerGrouping: action.payload, isLoading: false };
        case 'FETCH_ALL_CUSTOMER_GROUP':
            return { ...state, fetchAllCustomerGroups: action.payload, isLoading: false };
        case 'FETCH_SINGLE_CUSTOMER_GROUP':
            return { ...state, fetchSingleCustomerGroupData: action.payload, isLoading: false };
        case 'UPDATE_SINGLE_CUSTOMER_GROUP':
            return { ...state, updateCustomerGroup: action.payload, isLoading: false };
        case 'ACTIVATE_CUSTOMER_GROUP':
            return { ...state, activateCustomerGroup: action.payload, isLoading: false };
        case 'DEACTIVATE_CUSTOMER_GROUP':
            return { ...state, deactivateCustomerGroup: action.payload, isLoading: false };
        case 'FETCH_ALL_RFID_API':
            return { ...state, fetchRfidList: action.payload, isLoading: false };
        case 'SAVE_RFID_BINDING_API':
            return { ...state, saveRfidBindings: action.payload, isLoading: false };
        case 'SAVE_CUSTOMER_ACCOUNT':
            return { ...state, saveCustomerAccount: action.payload, isLoading: false };
        case 'FETCH_SINGLE_RFID_API':
            return { ...state, fetchSingleRfid: action.payload, isLoading: false };
        default:
            return state;
    }
}
export default customerReducer;