import React from "react";
import { useEffect } from "react";
import { Card, Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import PureBreadcrumbs from "../../breadcrums";
import "../loyalty.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { fetchSingleRedeemApi } from "../../../../store/actions/loyaltyAction";
import moment from "moment";

const ViewSingleRedeemRule = (props) => {
    const { t } = useTranslation();
    const { redeem_name } = useParams();
    // console.log(redeem_name)

    useEffect(() => {
        props.fetchSingle(redeem_name)
    }, [])

    const data = props.fetchSingleRes && props.fetchSingleRes.data
    // console.log(data)

    return (
        <>
            <div className="container-fluid">
                <PureBreadcrumbs />
                <Row className="d-flex justify-content-center align-items-center pt-5">
                    <Col xs={12} md={12} lg={12}>
                        <form onSubmit={(e) => e.preventDefault()}>
                            <div>
                                <Card className="d-flex justify-content-center align-items-center tariffcard">
                                    <Card.Body>
                                        <h6 className="text-center"> {t("View Single Redeem Rules")}</h6>
                                    </Card.Body>
                                </Card>
                                <Card className="tariffcard ml-0 mr-0">

                                    <Row >
                                        <Col lg={4}>
                                            <div className="">
                                                <div className="form-floating">
                                                    <label>
                                                        {t("Redeem Name")}
                                                        <span className="mandatory-field">*</span>
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className={`form-control gray-border `}
                                                        name={`redeem_name`}
                                                        id="floatingInputplan_name"
                                                        placeholder={t("Redeem Name")}
                                                        disabled
                                                        value={data[0]?.redeem_name || ''}
                                                    />

                                                </div>
                                            </div>
                                        </Col>
                                        <Col lg={4} className=" d-flex justify-content-start align-items-center">
                                            <label className="text-center mt-4 mr-3">
                                                {t("Selected Start Date")} :
                                            </label>
                                            <DatePicker
                                                className='gray-border mt-4'
                                                disabled
                                                value={moment(data[0]?.startdate).format("DD/MM/YYYY") || null}
                                            />
                                        </Col>
                                        <Col lg={4} className="d-flex justify-content-start align-items-center">
                                            <label className="text-center mt-4  mr-1" style={{ width: "18rem" }}>
                                                {t("Selected End Date")} :
                                            </label>
                                            <input
                                                disabled
                                                className='gray-border mt-4'
                                                value={moment(data[0]?.enddate).format("DD/MM/YYYY") || null}
                                            />
                                        </Col>
                                    </Row>


                                </Card>

                                {data && data[0]?.components?.map((item, i) => (
                                    <Card className="tariffcard" key={i + 1}>
                                        {i === 0 ? (
                                            <h6 className="text-start p-3">
                                                <b>{t("View Redeem Rule")}</b>
                                            </h6>
                                        ) : (
                                            ""
                                        )}
                                        <Card.Body>
                                            <div className="row g-2 mt-3 ">
                                                <div className="col-md">
                                                    <div className="form-floating">
                                                        <label>
                                                            {t("Rule Type")}
                                                            <span className="mandatory-field">*</span>
                                                        </label>
                                                        <select
                                                            className={`form-select form-control `}
                                                            name={`redeemGroup[${i}].rule_type`}
                                                            id="floatingSelectGridrule_type"
                                                            disabled
                                                        >
                                                            <option value={item.type}>{item.type}</option>
                                                        </select>
                                                    </div>
                                                </div>


                                                <div className="col-md">
                                                    <div className="form-floating">
                                                        {item?.type && item?.type === '' ? <label>
                                                            {t("Rule Value")}
                                                            <span className="mandatory-field">*</span>
                                                        </label>
                                                            : item?.type && item?.type === 'kwh' ?
                                                                <label>
                                                                    {t("Rule Value ( kWh )")}
                                                                    <span className="mandatory-field">*</span>
                                                                </label>
                                                                : item?.type && item?.type === 'time' ?
                                                                    <label>
                                                                        {t("Rule Value ( Hours )")}
                                                                        <span className="mandatory-field">*</span>
                                                                    </label>
                                                                    : item?.type && item?.type === 'flat' ?
                                                                        <label>
                                                                            {t("Rule Value ( Flat )")}
                                                                            <span className="mandatory-field">*</span>
                                                                        </label> : ''
                                                        }
                                                        <input
                                                            type="text"
                                                            className={`form-control gray-border `}
                                                            placeholder={t("Rule Value")}
                                                            name={`redeemGroup[${i}].rule_value`}
                                                            id="floatingSelectGridperiod_end"
                                                            disabled
                                                            value={item.value || ""}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-sm mt-4" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                    <div className="form-floating" >
                                                        {t("Contains")}
                                                    </div>
                                                </div>
                                                <div className="col-md">
                                                    <div className="form-floating">
                                                        <label>
                                                            {t("Input value points")}
                                                            <span className="mandatory-field">*</span>
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className={`form-control gray-border `}
                                                            placeholder={t("Input Value")}
                                                            name={`redeemGroup[${i}].input_value`}
                                                            id="floatingSelectGridperiod_end"
                                                            disabled
                                                            value={item.points || ""}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                ))}
                            </div>
                        </form>
                    </Col>
                </Row>
            </div>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        loading: state.loyalty.isLoading,
        fetchSingleRes: state.loyalty.fetchSingleRedeem,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        fetchSingle: (redeem_name) => dispatch(fetchSingleRedeemApi(redeem_name)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(ViewSingleRedeemRule);
