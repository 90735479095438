import React, { useEffect, useMemo } from "react";
import PureBreadcrumbs from "../../breadcrums";
import { connect } from "react-redux";
import { Container, Card, Col, Row } from "react-bootstrap";
import SearchGraph from "./searchGraph";
import { fetchCustomerTop5SearchResult } from "../../../../store/actions/insightAction";
import Table from "../../reacttable/table";
import "../../reacttable/reactTable.css";
import { useTranslation } from "react-i18next";

function CustomerPridictiveInsight(props) {
  const { t } = useTranslation();
  useEffect(() => {
    props.getTop5Search();
  }, []);

  const dataSearch = props.top5Search && props.top5Search.data && props.top5Search.data.map((en) => en.Search);
  // console.log(dataSearch);
  const FlatData = dataSearch && dataSearch.flat(2);
  const columns = useMemo(
    () => [
      {
        Header: "Address",
        accessor: "address",
      },
      {
        Header: "State",
        accessor: "state",
      },
      {
        Header: "Country",
        accessor: "country",
      },
      {
        Header: "Latitude",
        accessor: "latitude",
      },
      {
        Header: "Longitude",
        accessor: "longitude",
      },

      {
        Header: "Search count",
        accessor: "search_count",
      },
    ],
    []
  );
  return (
    <Container fluid className="pb-2">
      <div className="row">
        <div className="col-lg-6 col-sm-12">
          <PureBreadcrumbs />
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-lg-6 col-sm-12">
          <h3>
            <b>{t("Customer Behaviour Tracking")}</b>
          </h3>
        </div>
      </div>

      <div className="row">
        <div className="col">
          <div className="row">
            <div className="col-12 col-md-5 col-lg-5">
              <br />
              <br />
              <br />
              <br />
              <Card className="herocard text-center" >
                <h5>
                  <img
                    src={
                      process.env.PUBLIC_URL + "/images/insight/highDemand.png"
                    }
                    alt=""
                    style={{ width: "60px" }}
                  />
                  <br /> <span> {t("There are")} </span>
                  <b>
                    {" "}
                    {FlatData && FlatData[0]?.search_count && FlatData[0]?.search_count !== undefined ? FlatData[0]?.search_count : 0}
                  </b>
                  <span> {t("searches for chargers near location")} </span> <br />
                  <b>
                    {" "}
                    {FlatData && FlatData[0]?.address && FlatData && FlatData[0]?.address !== undefined
                      ? FlatData && FlatData[0]?.address
                      : 0}{" "}
                  </b>{" "}
                  <br />
                  {t("whose coordinates are")} &nbsp;
                  <b>
                    {FlatData && FlatData[0]?.latitude && FlatData && FlatData[0]?.latitude !== undefined
                      ? FlatData && FlatData[0]?.latitude
                      : 0}
                    ,
                    {FlatData && FlatData[0]?.longitude && FlatData && FlatData[0]?.longitude !== undefined
                      ? FlatData && FlatData[0]?.longitude
                      : 0}
                  </b>
                  <br /> <br />
                  {t("Adding a charger nearby can turn out to be a profitable outcome.")}
                </h5>
              </Card>
            </div>
            <div className="col-6 col-md-7 col-lg-7" style={{ "display": "flex", "justifyContent": "end" }}>
              <Card className="customercard customerinsight-graph" >
                <Row>
                  <Col xs={12} md={6}>
                    <div className="table-header">
                      {t("Customer Top Search Analytics")}
                    </div>
                  </Col>
                </Row>
                <br />
                <SearchGraph {...props}></SearchGraph>
              </Card>
            </div>
          </div>
        </div>
      </div>

      <div className="row mt-4 mb-3">
        <div className="col">
          <Card className="customercard">
            <div className="table-header"> {t("User Search Behaviour")}</div>

            <div>
              {props.loading ? <h3 style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "355px",
              }}>Loading data...</h3> :
                FlatData && FlatData.length > 0 ? (
                  <div>
                    <Table data={FlatData} columns={columns}></Table>
                  </div>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <h3 className="loader">No data found</h3>
                  </div>
                )}
            </div>
          </Card>
        </div>
      </div>
    </Container>
  );
}

const mapStateToProps = (state) => {
  return {
    top5Search: state.insight.customer_top5_searches,
    loading: state.insight.isLoading,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getTop5Search: () => dispatch(fetchCustomerTop5SearchResult()),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomerPridictiveInsight);
