import React, { useMemo, useEffect } from "react";
import { connect } from "react-redux";
import "../reacttable/reactTable.css";
// import Table from "../../reacttable/table";
// import { activateRfid, allRFIDAction, deactivateRfid } from "../../../../store/actions/customerActions";
import { FaEdit, FaEye } from "react-icons/fa";
import { TiTick } from "react-icons/ti";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { useTranslation } from "react-i18next";
import PureBreadcrumbs from "../breadcrums";
import { Card, Col, Container, Row, Button } from 'react-bootstrap';
import Table from "../reacttable/table";
import { fetchAllReconcilationApi } from "../../../store/actions/stripeAction";
import moment from "moment";
import MyDocument from "../pdf/invoice";
import ManualReconciliationPdf from "./downloadManualReconciliationPdf";


function ViewManualReconcilation(props) {
    const { t } = useTranslation();
    useEffect(() => {
        props.fetchAll();
    }, []);


    const data = props.list && props.list.data;
    // console.log(data);

    // const activateStation = (id) => {
    //     // console.log(id);
    //     props.activateRFID(id);
    // };

    // if (props.activeRfid.statuscode === 200) {
    //     window.location.reload();
    // }

    // const deactivateStation = (id) => {
    //     // console.log(id);
    //     props.deactivateRFID(id);

    // };

    // if (props.deactivateRfid.statuscode === 200) {
    //     window.location.reload();
    // }


    const columns = useMemo(
        () => [
            {
                Header: "Transfered To",
                accessor: "transfered_to",
            },
            {
                Header: "Amount",
                accessor: "amount",
            },
            {
                Header: "GST",
                accessor: "gst",
            },
            {
                Header: "CGST",
                accessor: "cgst",
            },
            {
                Header: "IGST",
                accessor: "igst",
            },
            {
                Header: "SGST",
                accessor: "sgst",
            },
            {
                Header: "Payment Mode",
                accessor: "payment_mode",
            },
            {
                Header: "UTR",
                accessor: "utr",
            },
            {
                Header: "Transfer Date",
                // accessor: "transfer_date",
                accessor: (d) => {
                    return moment(d.transfer_date, "DD-MM-YYYY").format("DD-MM-YYYY");
                },
            },
            {
                Header: "Client Name",
                accessor: "client_name",
            },
            {
                Header: "Client Account No.",
                accessor: "client_account_number",
            },
            {
                Header: "Actions",
                accessor: "id",
                Cell: ({ row }) => {
                    const { original } = row;
                    const { status, id } = original;
                    // console.log("original", original);

                    return (
                        <div>
                            {<span>
                                <a
                                    href={`/dashboard/viewSingleReconcilation/${id}`}
                                    rel="tooltip"
                                    title="Edit"
                                >
                                    <FaEye />
                                </a>
                            </span>}
                            &nbsp;&nbsp;
                            &nbsp;&nbsp;
                            {<span>
                                <a
                                    href={`/dashboard/updateSingleReconcilation/${id}`}
                                    rel="tooltip"
                                    title="Edit"
                                >
                                    <FaEdit />
                                </a>
                            </span>}
                            &nbsp;&nbsp;
                            &nbsp;&nbsp;
                        </div>
                    );
                },
            },
        ],
        []
    );

    return (
        <Container fluid>
            <PureBreadcrumbs />
            <br />
            <Row className="mt-4">
                <Col>
                    <Card className="customercard">
                        <div className="table-header row">
                            <h4 className="col-md-6">
                                {t("View Manual Reconciliation List")}
                            </h4>
                            <div className="col-md-6 d-flex justify-content-end">
                                <ManualReconciliationPdf
                                    {...props}
                                    data={data}
                                // onClick={handleCSVLinkClick}
                                ></ManualReconciliationPdf>
                            </div>
                        </div>
                        <div>
                            {props.loading ? <h3 style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                height: "350px",
                            }}>Loading data...</h3> :
                                data && data.length > 0 ? (
                                    <div>
                                        <Table data={data} columns={columns}></Table>
                                    </div>
                                ) : (
                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                        }}
                                    >
                                        <h3 className="loader">{("No data found")}</h3>
                                    </div>
                                )}
                        </div>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
}

const mapStateToProps = (state) => {
    return {
        isLoading: state.stripe.isLoading,
        list: state.stripe.fetchAllReconcilation,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        fetchAll: () => dispatch(fetchAllReconcilationApi()),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(ViewManualReconcilation);
