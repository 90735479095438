import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import PureBreadcrumbs from "../../breadcrums";
import { Card, Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { fetchSingleStationGroupWithTariff } from "../../../../store/actions/evseAction";

const ViewSingleStationGroupWithTariff = (props) => {
    const { id } = useParams();
    // console.log(id)
    const { t } = useTranslation();
    // const group_name = id;

    useEffect(() => {
        props.singleData(id)
    }, [])

    const data = props.getSingle && props.getSingle.data && props.getSingle.data[0]
    const station_list = data && data.station_list
    // console.log(data)


    return (
        <Container fluid>
            <PureBreadcrumbs />
            <Row className="mt-2" >
                <Col lg={12} className="">
                    <div className="tariffcard mt-2">
                        <div className="table-header pl-0">{t("View Single Station Group With Tariff Details")}</div>
                        <Row className="mt-3">
                            <Col className="col-lg-6">
                                <div>
                                    <label>{t("Station Group Name")} <span className='mandatory-field'>*</span></label>
                                    <input
                                        disabled
                                        type="text"
                                        className="form-control gray-border"
                                        value={data?.tariff_station_group_name || ''}
                                        placeholder="Enter Station Group Name"
                                    />
                                </div>
                            </Col>
                            <Col className="col-lg-6">
                                <div>
                                    <div className="">
                                        <label>{t("Selected Tariff")} <span className='mandatory-field'>*</span></label>
                                        <select
                                            disabled
                                            className={`form-select form-control`}
                                            name={"tariff"}
                                            id={"floatingSelectCustomerGroup"}
                                        >
                                            <option value={""}>{data?.tariff}</option>
                                        </select>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <fieldset disabled className="mt-3">
                            {station_list && station_list.map((item, i) => (
                                <div key={i}>
                                    <Card className='tariffcard mb-2' key={i}>
                                        <Card.Body>
                                            <div className="row g-2 mt-3 ">
                                                <div className="col-md">
                                                    <div className="form-floating">
                                                        <label>{t("Station Name")} </label>
                                                        <select className={`form-select form-control`} name={`station_list`} id="floatingSelectstation_list">
                                                            <option >{item.name}</option>
                                                        </select>
                                                    </div>

                                                </div>

                                                <div className="col-md">
                                                    <div className="form-floating">
                                                        <label>{t("Addesss")} </label>
                                                        <select className={`form-select form-control`} name={`address`} id="floatingSelectGridaddress">
                                                            <option >{item.address}</option>
                                                        </select>
                                                    </div>

                                                </div>
                                                <div className="col-md">
                                                    <div className="form-floating">
                                                        <label>{t("City")} </label>
                                                        <select className={`form-select form-control`} name={`city`} id="floatingSelectGridcity">
                                                            <option >{item.city}</option>
                                                        </select>
                                                    </div>

                                                </div>
                                            </div>


                                        </Card.Body>
                                    </Card>
                                </div>
                            ))}
                        </fieldset>

                    </div>

                </Col>
            </Row>
        </Container>
    )
}
const mapStateToProps = (state) => {
    return {
        loading: state.evse.isLoading,
        getSingle: state.evse.fetchSingleStationGroupWithTariff,
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        singleData: (id) => dispatch(fetchSingleStationGroupWithTariff(id))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ViewSingleStationGroupWithTariff)