import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { allEvseAction } from '../../../../store/actions/evseAction';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import PureBreadcrumbs from "../../breadcrums";
import StationGroupWithTariffTable from './station_group_with_tariff_table';
import { fetchActiveTariffList } from '../../../../store/actions/tariffAction';

const CreateStationGroupWithTariff = (props) => {
    const { t } = useTranslation();

    useEffect(() => {
        props.all();
        props.getActiveTariffList();
    }, [])

    const [stationGroupName, setStationGroupName] = useState('');
    const [tariffName, setTariffName] = useState('');

    const handleStationGroupName = (event) => {
        setStationGroupName(event.target.value);
    };
    const handleSelectTariff = (event) => {
        setTariffName(event.target.value);
    };
    // console.log(stationGroupName)

    const data = props.list && props.list.data;
    // console.log("data", data);
    const filteredData = data && data.map(item => ({
        name: item.name,
        address: item.address,
        city: item.city,
    }));
    // console.table(filteredData)

    const columns = useMemo(
        () => [
            {
                Header: 'Station Name',
                accessor: 'name'
            },
            {
                Header: 'City',
                accessor: 'city'
            },
            {
                Header: 'Address',
                accessor: 'address'
            },

        ],
        []
    );


    return (

        <div className="container-fluid pt-2">
            <PureBreadcrumbs />
            <Row className="">
                <Col lg={12} className="">
                    <div className="customercard mt-2">
                        <div className=""><h4>{t("Station Grouping With Tariff")}</h4></div>
                        <Row className="mt-3">
                            <Col className="col-lg-6">
                                <div>
                                    <label>{t("Enter Station Group Name")} <span className='mandatory-field'>*</span></label>
                                    <input
                                        autoComplete='off'
                                        type="text"
                                        className="form-control gray-border"
                                        value={stationGroupName}
                                        onChange={handleStationGroupName}
                                        placeholder="Enter Station Group Name"
                                    />
                                </div>
                            </Col>
                            <Col className="col-lg-6">
                                <div>
                                    <div className="">
                                        <label>{t("Select Tariff")} <span className='mandatory-field'>*</span></label>
                                        <select
                                            autoComplete='off'
                                            className={`form-select form-control`}
                                            name={"tariff"}
                                            id={"floatingSelectGridAdminStatus"}
                                            onChange={handleSelectTariff}
                                        >
                                            <option value={""}>Select Tariff</option>
                                            {props.fetchActiveTariffList && props.fetchActiveTariffList.data && props.fetchActiveTariffList.data.map((v, k) =>
                                                <option key={v.id} value={v.name}>{v.name}</option>
                                            )}

                                        </select>
                                    </div>
                                </div>
                            </Col>
                        </Row>

                        {props.loading ? <h3 style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            height: "450px",
                        }}>Loading data...</h3> :
                            filteredData && filteredData.length > 0 ?
                                (<div> <StationGroupWithTariffTable {...props} stationGroupName={stationGroupName} tariffName={tariffName} data={filteredData} columns={columns}></StationGroupWithTariffTable></div>)
                                :
                                (<div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "450px", }}><h3 className="loader">No data found</h3></div>)
                        }
                    </div>

                </Col>
            </Row>
        </div>
    )
}
const mapStateToProps = (state) => {
    return {
        loading: state.evse.isEvseLoading,
        list: state.evse.allevse,
        fetchActiveTariffList: state.tariff.fetchActiveTariffName,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        all: () => dispatch(allEvseAction()),
        getActiveTariffList: () => dispatch(fetchActiveTariffList()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateStationGroupWithTariff);
