import React from 'react'
import { Card, Row, Col, Container } from "react-bootstrap";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useEffect, useState } from "react";
import PureBreadcrumbs from "../../breadcrums";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../loyalty.css";
import { useTranslation } from 'react-i18next';
import { fetchAllRuleApi, saveProgramApi } from '../../../../store/actions/loyaltyAction';
import { Bounce, ToastContainer, toast } from 'react-toastify';


const CreateProgram = (props) => {
    const { t } = useTranslation();
    const [isToastVisible, setIsToastVisible] = useState(false);

    useEffect(() => {
        props.fetch_all_rule();
    }, [])

    const programSchema = Yup.object().shape({
        program_name: Yup.string().required('program name is required'),
        rule_name: Yup.string().required('rule name is required'),
    });

    const [startdate, setstartdate] = useState(new Date(Date.now() + (3600 * 1000 * 1)));
    const [enddate, setenddate] = useState(new Date(Date.now() + (3600 * 1000 * 720)));

    // const [startdate, setStartDate] = useState(null);
    // const [enddate, setEndDate] = useState(null);
    // const [warning, setWarning] = useState('');

    const { register, handleSubmit, reset, formState: { errors } } = useForm({ resolver: yupResolver(programSchema) });

    const onSubmit = async (data) => {
        props.saveProgram(data, startdate, enddate)
        // console.log("data saved successfully", data, startdate, enddate);
    };

    useEffect(() => {
        if (props.saveProgramRes.statuscode === 200) {
            setIsToastVisible(true);
            toast.success('New program has been created successfully', {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
                onClose: () => {
                    setIsToastVisible(false);
                    window.location.reload();
                },
            });
        } else if (props.saveProgramRes.statuscode === 405) {
            setIsToastVisible(true);
            toast.error(`❌ ${props.saveProgramRes.error}`, {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
                onClose: () => {
                    setIsToastVisible(false);
                    window.location.reload();
                },
            });
        }
    }, [props.saveProgramRes]);

    // console.log(props.allRuleRes && props.allRuleRes.data?.filter((status) => status.status !== false))

    // // this function is to check if a date is the 1st day of the month
    // const isFirstDayOfMonth = (date) => {
    //     // console.log("date.getDate() === 1",date);
    //     return date.getDate() === 1;
    // };

    // // this function is to check if a date is the last day of the month
    // const isLastDayOfMonth = (date) => {
    //     const nextDay = new Date(date);
    //     nextDay.setDate(nextDay.getDate() + 1);
    //     return nextDay.getDate() === 1;
    // };

    // // Filter function for start date picker to allow only 1st day of every month
    // const filterStartDate = (date) => {
    //     return isFirstDayOfMonth(date);
    // };

    // // Filter function for end date picker to allow only last day of every month
    // const filterEndDate = (date) => {
    //     return isLastDayOfMonth(date);
    // };

    //  // Handle change of start date
    //  const handleStartDateChange = (date) => {
    //     setStartDate(date);
    //     setWarning('');
    //     if (enddate && date.getMonth() !== enddate.getMonth()) {
    //         setWarning('Please select the start and end date of the same month.');
    //     }
    // };

    // // Handle change of end date
    // const handleEndDateChange = (date) => {
    //     setEndDate(date);
    //     setWarning('');
    //     if (startdate && date.getMonth() !== startdate.getMonth()) {
    //         setWarning('Please select the start and end date of the same month.');
    //     }
    // };

    // console.log(props.allRuleRes && props.allRuleRes.data);

    return (
        <Container fluid className="pb-4">
            {isToastVisible && <div className="overlay" />}
            <ToastContainer
                position="top-center"
                autoClose={20}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
                style={{ top: "10%", width: "auto", zIndex: "9999" }}
            />
            <PureBreadcrumbs />
            <br />
            <Row>
                <Col xs={12} md={1}>
                </Col>
                <Col xs={12} md={10}>
                    <Card className="tariffcard" >
                        <Card.Text style={{ fontSize: "14px", padding: "10px", paddingLeft: "15px" }}><b>{t("Add New Program")}</b></Card.Text>
                        <Card.Body>
                            <form autoComplete="off" onSubmit={e => e.preventDefault()}>
                                <Row>
                                    <Col lg={6}>
                                        <div className="form-floating">
                                            <label>{t("Enter program name")}  <span className='mandatory-field'>*</span></label>
                                            <input type="text" autoFocus className={`form-control gray-border ${errors.program_name ? 'is-invalid' : ''}`} name="program_name" id="floatingInputGridProgramName" placeholder={t("Enter the program name")} {...register("program_name")} />
                                            <div className="invalid-feedback">{errors.program_name?.message}</div>
                                        </div>
                                    </Col>

                                    <Col lg={6}>
                                        <div className="form-floating">
                                            <label>{t("Select Rule Name")}  <span className='mandatory-field'>*</span></label>
                                            <select className={`form-select form-control ${errors.rule_name ? 'is-invalid' : ''}`} name="rule_name" id="floatingSelectGridrule_name"  {...register("rule_name")}>
                                                <option value={""}>{t("Select Rule Name")} </option>
                                                {props.allRuleRes && props.allRuleRes.data?.filter((status) => status.status !== false)?.map((item, index) => (
                                                    <option key={index} value={item.rule_name}>{t(item.rule_name)} </option>
                                                ))}
                                            </select>
                                            <div className="invalid-feedback">{errors.rule_name?.message}</div>
                                        </div>
                                    </Col>
                                </Row>

                                <Row className="mt-3">
                                    <Col className="text-center">
                                        {t("Select Start Date")} :
                                    </Col>
                                    <Col>
                                        <DatePicker
                                            className="gray-border"
                                            selected={startdate}
                                            onChange={(date) => setstartdate(date)}
                                            selectsStart
                                            startDate={startdate}
                                            dateFormat="dd/MM/yyyy"
                                            minDate={new Date()}
                                            placeholderText={t("Select Start Date")}
                                        />
                                        {startdate === null && <div className='invalid-feedback fa-1x'>{t("Start Date is required")}</div>}
                                    </Col>
                                </Row><br />
                                <Row>
                                    <Col className="text-center">
                                        {t("Select End Date")} :
                                    </Col>
                                    <Col>
                                        <DatePicker

                                            className="gray-border"
                                            selected={enddate}
                                            onChange={(date) => setenddate(date)}
                                            selectsEnd
                                            startDate={startdate}
                                            endDate={startdate}
                                            minDate={startdate}
                                            showDisabledMonthNavigation
                                            dateFormat="dd/MM/yyyy"
                                            placeholderText={t("Select End Date")}
                                        />
                                        {enddate === null && <div className='invalid-feedback fa-1x'>{t("End Date is required")}</div>}
                                    </Col>
                                </Row>


                                <Row className="mt-5">
                                    <Col lg={6} md={5} className="d-flex justify-content-center justify-content-md-start mt-2">
                                        <button className="lgn-btn btn" type="button" onClick={() => {
                                            reset({
                                                program_name: '', rule_name: '',
                                                startDate: setstartdate(new Date(Date.now() + (3600 * 1000 * 1))),
                                                endDate: setenddate(new Date(Date.now() + (3600 * 1000 * 720))),
                                            });
                                        }}> {t("Reset")} </button>
                                    </Col>
                                    <Col lg={6} md={7} sm={12} xs={12} className="d-flex justify-content-center justify-content-md-end mt-2">
                                        {props.loading ?
                                            <button className="btn lgn-btn" type="button" disabled>
                                                <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                                                {t("Saving...")}
                                            </button>
                                            :
                                            <button className="lgn-btn btn" type="submit" disabled={startdate === null || enddate === null} onClick={handleSubmit(onSubmit)}>{t("CREATE PROGRAM")}</button>
                                        }
                                    </Col>
                                </Row>
                            </form>
                        </Card.Body>
                        <br /><br />
                    </Card>

                </Col>
                <Col xs={12} md={1}>
                </Col>

            </Row>
        </Container>


    )
}



const mapStateToProps = (state) => {
    return {
        loading: state.loyalty.isLoading,
        allRuleRes: state.loyalty.fetchAllRule,
        saveProgramRes: state.loyalty.saveProgram,
    }
}
const mapDispatchToProps = dispatch => {
    return {
        fetch_all_rule: () => dispatch(fetchAllRuleApi()),
        saveProgram: (data, startdate, enddate) => dispatch(saveProgramApi(data, startdate, enddate))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(CreateProgram)
