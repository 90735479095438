
import { Container, Card, Row, Col } from "react-bootstrap";
import React, { useEffect, useState } from "react"
import PureBreadcrumbs from "../breadcrums";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { createStripeCustomerAction, createStripeCustomerTokenAction, createStripeExtAccountAction, stripeOnboardingEmailAction } from "../../../store/actions/stripeAction";
import { connect } from "react-redux";
import { BASE_URL } from "../../config/config";
import { useTranslation } from "react-i18next";
import { Bounce, ToastContainer, toast } from "react-toastify";

function AddBankDetails(props) {
    const { t } = useTranslation();
    const [data, setData] = useState([])
    const [isToastVisible, setIsToastVisible] = useState(false);

    const bankSchema = Yup.object().shape({
        name: Yup.string().required('Businees Name is required'),
        business_type: Yup.string().required('Business Type is required'),
        country: Yup.string().required('Country is required'),
        email: Yup.string().required('Business Email is required').email('Email is invalid'),
        type: Yup.string().required('Type is required'),
        url: Yup.string().required('URL Type is required'),
        personal_details:
            Yup.object().shape({
                first_name: Yup.string().required('First Name is required'),
                last_name: Yup.string().required('Last Name is required'),
                phone: Yup.string().required('Phone number is a required').typeError('Phone number is a required'),
                email: Yup.string(),
                dob:
                    Yup.object().shape({
                        day: Yup.number().required('Day is required').min(1).max(31),
                        month: Yup.number()
                            .required('Month is required')
                            .min(1, 'Month must be between 1 and 12')
                            .max(12, 'Month must be between 1 and 12'),
                        year: Yup.number()
                            .required('Year is required')
                            .min(1900, 'Year must be greater than or equal to 1900')
                            .max(9999, 'Year must be less than or equal to 9999'),
                    }),
                address:
                    Yup.object().shape({
                        city: Yup.string().required('City is required'),
                        country: Yup.string().required('Country is required'),
                        line1: Yup.string().required('Address line1 is required'),
                        line2: Yup.string().required('Address line2 is required'),
                        postal_code: Yup.string().required('Postal code is required'),
                        state: Yup.string().required('State is required'),
                        town: Yup.string().required('Town is required'),
                    }),

            }),


        account_holder_name: Yup.string().required('Holder Name is required'),
        account_number: Yup.string().required('Account Number is required'),
        account_holder_type: Yup.string().required('Account Holder Type is required'),
        routing_number: Yup.string().required('Routing Number is required'),
        bank_name: Yup.string().required('Bank Name is required'),
        currency: Yup.string().required('Currency is required'),

    })
    const { register, handleSubmit, setValue, formState: { errors, isValid } } = useForm({ resolver: yupResolver(bankSchema), mode: "all" });
    const { organisation } = JSON.parse(localStorage.getItem('user'));
    const onSubmit = async data => {
        setData(data)
        props.createStripeCustomer(data)

    };

    useEffect(() => {
        if (props.createCustomer && props.createCustomer.statuscode === 200) {
            props.createStripeCustomerToken(data.country, data.currency, data.routing_number, data.account_number, data.account_holder_name, data.account_holder_type)
        }
    }, [props.createCustomer])

    useEffect(() => {
        if (props.createCustomerToken && props.createCustomerToken.statuscode === 200) {
            props.createStripeExtAccount(props.createCustomer.data[0].id, props.createCustomerToken.data[0].id)
        }
    }, [props.createCustomerToken])

    useEffect(() => {
        if (props.createCustomerAccount && props.createCustomerAccount.statuscode === 200) {
            console.log(data.personal_details.first_name, data.personal_details.last_name, data.personal_details.email, props.createCustomer.data[0].id)
            props.triggerEmailOnboarding(data.personal_details.first_name, data.personal_details.last_name, data.personal_details.email, props.createCustomer.data[0].id)
        }
    }, [props.createCustomerAccount])

    // if (props.createCustomer.statuscode === 200 && props.createCustomerAccount.statuscode === 200 && props.triggerOnboardingemail.statuscode === 200) {
    //     alert("Connect account has been created successfully")
    //     window.location.reload();
    // }

    // console.log("props.createCustomer", props.createCustomer.statuscode);
    // console.log("props.createCustomerAccount", props.createCustomerAccount.statuscode);
    // console.log("props.triggerOnboardingemail", props.triggerOnboardingemail.statuscode);
    // console.log("props.createCutomerToken", props.createCutomerToken.statuscode);

    useEffect(() => {
        if (props.createCustomer.statuscode === 200 && props.createCustomerAccount.statuscode === 200 && props.triggerOnboardingemail.statuscode === 200) {
            setIsToastVisible(true);
            toast.success('Connect account has been created successfully', {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
                onClose: () => {
                    setIsToastVisible(false);
                    window.location.reload();
                },
            });
        }
    }, [props.createCustomer, props.createCustomerAccount, props.triggerOnboardingemail]);

    useEffect(() => {
        if (props.createCustomer && props.createCustomer.statuscode === 405) {
            setIsToastVisible(true);
            toast.error(`❌ ${props.createCustomer.error}`, {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
                onClose: () => {
                    setIsToastVisible(false);
                    window.location.reload();
                },
            });
        }
        if (props.createCutomerToken && props.createCustomerToken.statuscode === 405) {
            setIsToastVisible(true);
            toast.error(`❌ ${props.createCustomerToken.error}`, {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
                onClose: () => {
                    setIsToastVisible(false);
                    window.location.reload();
                },
            });
        }
        if (props.createCustomerAccount && props.createCustomerAccount.statuscode === 405) {
            setIsToastVisible(true);
            toast.error(`❌ ${props.createCustomerAccount.error}`, {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
                onClose: () => {
                    setIsToastVisible(false);
                    window.location.reload();
                },
            });
        }
    }, [props.createCustomer, props.createCustomerToken, props.createCustomerAccount,]);


    // useEffect(() => {
    //     if (props.createCustomer && props.createCustomer.statuscode === 405) {
    //         alert(props.createCustomer.error)
    //         // console.log("create customer failed")
    //         window.location.reload();
    //     }
    //     if (props.createCutomerToken && props.createCustomerToken.statuscode === 405) {
    //         alert(props.createCustomerToken.error)
    //         window.location.reload();
    //         // console.log("create customer token failed")

    //     }
    //     if (props.createCustomerAccount && props.createCustomerAccount.statuscode === 405) {
    //         alert(props.createCustomerAccount.error)
    //         // console.log("create customer Account failed")
    //         window.location.reload();
    //     }
    // }, [props.createCustomer, props.createCustomerToken, props.createCustomerAccount])

    setValue("name", organisation);
    setValue("url", BASE_URL);


    return (
        <Container fluid>
            {isToastVisible && <div className="overlay" />}
            <ToastContainer
                position="top-center"
                autoClose={20}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
                style={{ top: "10%", width: "auto", zIndex: "9999" }}
            />
            <PureBreadcrumbs />
            <div className="row">
                <div className="col-lg-12 col-sm-12 pad_t1">
                    <span style={{ color: "var(--text-primary)", fontWeight: "bold" }}>{t("Add Stripe Connect Account")} </span>
                </div>
            </div>

            <div className="row pad_t1">
                <div className="col-lg-12 col-sm-12">
                    <Card className="tariffcard">
                        <Card.Body>
                            <form autoComplete="off" onSubmit={e => e.preventDefault()}>

                                <div className="row">
                                    <div className="col">
                                        <span style={{ color: "var(--text-primary)", fontWeight: "bold", fontSize: 22 }}> {t("Personal Details")} </span>
                                    </div>
                                </div>

                                <div className="row mt-3">
                                    <div className="col-lg-4 col-sm-12">
                                        <div className="form-floating">
                                            <label>{t("Email")}  <span className='mandatory-field'>*</span></label>
                                            <input
                                                type="email"
                                                className={`form-control gray-border ${errors?.personal_details?.email ? 'is-invalid' : ''}`}
                                                name={`personal_details.email`}
                                                placeholder={t("Enter Email")}
                                                {...register(`personal_details.email`)}
                                            />
                                            <small className="form-text text-muted">{t("Stripe Verification Email Will Be Sent On This Email")}</small>
                                            {errors?.personal_details?.email && <span className="invalid-feedback">{errors.personal_details.email.message}</span>}
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-sm-12">
                                        <div className="form-floating">
                                            <label>{t("First Name")}  <span className='mandatory-field'>*</span></label>
                                            <input
                                                type="text"
                                                className={`form-control gray-border ${errors?.personal_details?.first_name ? 'is-invalid' : ''}`}
                                                name="personal_details.first_name"
                                                placeholder={t("Enter First Name")}
                                                {...register('personal_details.first_name')}
                                            />
                                            {errors?.personal_details?.first_name && <span className="invalid-feedback">{errors.personal_details.first_name.message}</span>}
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-sm-12">
                                        <div className="form-floating">
                                            <label>{t("Last name")} <span className='mandatory-field'>*</span></label>
                                            <input
                                                type="text"
                                                className={`form-control gray-border ${errors?.personal_details?.last_name ? 'is-invalid' : ''}`}
                                                name="personal_details.last_name"
                                                placeholder={t("Enter Last Name")}
                                                {...register('personal_details.last_name')}
                                            />
                                            {errors?.personal_details?.last_name && <span className="invalid-feedback">{errors.personal_details.last_name.message}</span>}
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-4">
                                    <div className="col-lg-6 col-sm-12">
                                        <div className="form-floating">
                                            <label>{t("Phone")}  <span className='mandatory-field'>*</span></label>
                                            <input
                                                type="text"
                                                className={`form-control gray-border ${errors?.personal_details?.phone ? 'is-invalid' : ''}`}
                                                name="personal_details.phone"
                                                placeholder={t("Enter Phone Number")}
                                                {...register('personal_details.phone')}
                                            />
                                            {errors?.personal_details?.phone && <span className="invalid-feedback">{errors.personal_details.phone.message}</span>}
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-sm-12">
                                        <div className="form-floating">
                                            <label>{t("Date of Birth")} <span className='mandatory-field'>*</span></label>
                                            <div className="flex-parent-element">
                                                <div className="flex-child-element">
                                                    <input
                                                        type="number"
                                                        minLength="1"
                                                        maxLength="2"
                                                        min="1"
                                                        max="31"
                                                        className={`form-control gray-border ${errors.personal_details?.dob?.day ? 'is-invalid' : ''}`}
                                                        name={`personal_details.dob.day`}
                                                        id="floatingInputaccount_id"
                                                        placeholder={t("Day")}
                                                        {...register(`personal_details.dob.day`)}

                                                    />
                                                </div>&nbsp;&nbsp;
                                                <div className="flex-child-element">
                                                    <input
                                                        type="number"
                                                        minLength="1"
                                                        maxLength="2"
                                                        className={`form-control gray-border ${errors.personal_details?.dob?.month ? 'is-invalid' : ''}`}
                                                        name={`personal_details.dob.month`}
                                                        id="floatingInputaccount_id"
                                                        placeholder={t("Month")}
                                                        {...register(`personal_details.dob.month`)}
                                                    />
                                                </div>&nbsp;&nbsp;
                                                <div className="flex-child-element">
                                                    <input
                                                        type="number"
                                                        minLength="4"
                                                        maxLength="4"
                                                        className={`form-control gray-border ${errors.personal_details?.dob?.year ? 'is-invalid' : ''}`}
                                                        name={`personal_details.dob.year`}
                                                        id="floatingInputaccount_id"
                                                        placeholder={t("Year")}
                                                        {...register(`personal_details.dob.year`)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div className="row mt-4">
                                    <div className="col-lg-6 col-sm-12">
                                        <div className="form-floating">
                                            <label>{t("Address Line 1")}  <span className='mandatory-field'>*</span></label>
                                            <input type="text" className={`form-control gray-border ${errors.personal_details?.address?.line1 ? 'is-invalid' : ''}`} name={`personal_details.address.line1`} id="floatingInputline1" placeholder={t("Enter") + " " + t("Address Line 1")}  {...register(`personal_details.address.line1`)} />
                                            <div className="invalid-feedback">{errors.personal_details?.address?.line1?.message}</div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-sm-12">
                                        <div className="form-floating">
                                            <label>{t("Address Line 2")} <span className='mandatory-field'>*</span></label>
                                            <input type="text" className={`form-control gray-border ${errors.personal_details?.address?.line2 ? 'is-invalid' : ''}`} name={`personal_details.address.line2`} id="floatingInputaccount_id" placeholder={t("Enter") + " " + t("Address Line 2")}  {...register(`personal_details.address.line2`)} />
                                            <div className="invalid-feedback">{errors.personal_details?.address?.line2?.message}</div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row mt-4">
                                    <div className="col-lg-4 col-sm-12">
                                        <div className="form-floating">
                                            <label>{t("City")}  <span className='mandatory-field'>*</span></label>
                                            <input type="text" className={`form-control gray-border ${errors.personal_details?.address?.city ? 'is-invalid' : ''}`} name={`personal_details.address.city`} id="floatingInputcity" placeholder={t("Enter") + " " + t("City")}  {...register(`personal_details.address.city`)} />
                                            <div className="invalid-feedback">{errors.personal_details?.address?.city?.message}</div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-sm-12">
                                        <div className="form-floating">
                                            <label>{t("State")} <span className='mandatory-field'>*</span></label>
                                            <input type="text" className={`form-control gray-border ${errors.personal_details?.address?.state ? 'is-invalid' : ''}`} name={`personal_details.address.state`} id="floatingInputaccount_id" placeholder={t("Enter") + " " + t("State")}   {...register(`personal_details.address.state`)} />
                                            <div className="invalid-feedback">{errors.personal_details?.address?.state?.message}</div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-sm-12">
                                        <div className="form-floating">
                                            <label>{t("Town")} <span className='mandatory-field'>*</span></label>
                                            <input type="text" className={`form-control gray-border ${errors.personal_details?.address?.town ? 'is-invalid' : ''}`} name={`personal_details.address.town`} id="floatingInputaccount_id" placeholder={t("Enter") + " " + t("Town")}  {...register(`personal_details.address.town`)} />
                                            <div className="invalid-feedback">{errors.personal_details?.address?.town?.message}</div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row mt-4">
                                    <div className="col-lg-6 col-sm-12">
                                        <div className="form-floating">
                                            <label>{t("Postal code")}  <span className='mandatory-field'>*</span></label>
                                            <input type="text" className={`form-control gray-border ${errors.personal_details?.address?.postal_code ? 'is-invalid' : ''}`} name={`personal_details.address.postal_code`} id="floatingInputpostal_code" placeholder={t("Enter") + " " + t("Postal code")} {...register(`personal_details.address.postal_code`)} />
                                            <div className="invalid-feedback">{errors.personal_details?.address?.postal_code?.message}</div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-sm-12">
                                        <div className="form-floating">
                                            <label>{t("Country")} <span className='mandatory-field'>*</span></label>
                                            <select className={`form-select  form-control ${errors.personal_details?.address?.country ? 'is-invalid' : ''}`} name={`personal_details.address.country`}  {...register(`personal_details.address.country`)} id="floatingSelectGridcountry" aria-label="Floating label select example">
                                                <option value="" >{t("Select Country")} </option>
                                                <option value="BR">{t("BR")}</option>
                                            </select>
                                            <div className="invalid-feedback">{errors.personal_details?.address?.country?.message}</div>
                                        </div>
                                    </div>
                                </div>


                                <div className="row mt-4">
                                    <div className="col">
                                        <span style={{ color: "var(--text-primary)", fontWeight: "bold", fontSize: 22 }}> {t("Business Details")} </span>
                                    </div>
                                </div>
                                <div className="row mt-1">
                                    <div className="col-lg-4 col-sm-12">
                                        <div className="form-floating">
                                            <label>{t("Doing Business As")}  <span className='mandatory-field'>*</span></label>
                                            <input type="text" className={`form-control gray-border ${errors.name ? 'is-invalid' : ''}`} name="name" id="floatingInputname" placeholder={t("Enter") + " " + t("Business Name")}  {...register("name")} disabled />
                                            <div className="invalid-feedback">{errors.name?.message}</div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-sm-12">
                                        <div className="form-floating">
                                            <label>{t("Business Type")} <span className='mandatory-field'>*</span></label>
                                            <select className={`form-select  form-control ${errors.business_type ? 'is-invalid' : ''}`} name="business_type" {...register("business_type")} id="floatingSelectGridbusiness_type" aria-label="Floating label select example">
                                                <option value="" >{t("Select Business Type")} </option>
                                                <option value="individual">{t("Individual")}</option>

                                            </select>
                                            <div className="invalid-feedback">{errors.business_type?.message}</div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-sm-12">
                                        <div className="form-floating">
                                            <label>{t("URL")}  <span className='mandatory-field'>*</span></label>
                                            <input type="text" className={`form-control gray-border ${errors.url ? 'is-invalid' : ''}`} name="url" id="floatingInputname" placeholder={t("Enter") + " " + t("URL")}  {...register("url")} disabled />
                                            <div className="invalid-feedback">{errors.url?.message}</div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col pad_t2">
                                        <span style={{ color: "var(--text-primary)", fontWeight: "bold", fontSize: 22 }}> {t("Bank Details")} </span>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-6 col-sm-12">
                                        <div className="form-floating">
                                            <label>{t("Account Holder Name")}  <span className='mandatory-field'>*</span></label>
                                            <input type="text" className={`form-control gray-border ${errors.account_holder_name ? 'is-invalid' : ''}`} name="account_holder_name" id="floatingInputaccount_holder_name" placeholder={t("Enter") + " " + t("Account Holder Name")}  {...register("account_holder_name")} />
                                            <small className="form-text text-muted">{t("The name of the person or business that owns the bank account.")}</small>
                                            <div className="invalid-feedback">{errors.account_holder_name?.message}</div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-sm-12">
                                        <div className="form-floating">
                                            <label>{t("Account Number")} <span className='mandatory-field'>*</span></label>
                                            <input type="text" className={`form-control gray-border ${errors.account_number ? 'is-invalid' : ''}`} name="account_number" id="floatingInputaccount_id" placeholder={t("Enter") + " " + t("Account Number")}  {...register("account_number")} />
                                            <small className="form-text text-muted">{t("The account number that the bank account is associated with")}</small>
                                            <div className="invalid-feedback">{errors.account_number?.message}</div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row mt-4">
                                    <div className="col-lg-6 col-sm-12">
                                        <div className="form-floating">
                                            <label>{t("Bank Name")}  <span className='mandatory-field'>*</span></label>
                                            <input type="text" className={`form-control gray-border ${errors.bank_name ? 'is-invalid' : ''}`} name="bank_name" id="floatingInputbank_name" placeholder={t("Enter") + " " + t("Bank Name")}  {...register("bank_name")} />
                                            <small className="form-text text-muted">{t("Name of the bank associated with the routing number (e.g., WELLS FARGO)")}</small>
                                            <div className="invalid-feedback">{errors.bank_name?.message}</div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-sm-12">
                                        <div className="form-floating">
                                            <label>{t("Account Holder Type")} <span className='mandatory-field'>*</span></label>
                                            <select className={`form-select  form-control ${errors.account_holder_type ? 'is-invalid' : ''}`} name="account_holder_type" {...register("account_holder_type")} id="floatingSelectGridaccount_holder_type" aria-label="Floating label select example">
                                                <option value="" >{t("Select Holder Type")} </option>
                                                <option value="individual">{t("Individual")}</option>
                                                <option value="company">{t("Company")}</option>
                                            </select>
                                            <div className="invalid-feedback">{errors.account_holder_type?.message}</div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row mt-4">
                                    <div className="col-lg-6 col-sm-12">
                                        <div className="form-floating">
                                            <label>{t("Routing Number")}  <span className='mandatory-field'>*</span></label>
                                            <input type="text" className={`form-control gray-border ${errors.routing_number ? 'is-invalid' : ''}`} name="routing_number" id="floatingInputrouting_number" placeholder={t("Enter") + " " + t("Routing Number")}  {...register("routing_number")} />
                                            <small className="form-text text-muted">{t("The routing transit number for the bank account")}</small>
                                            <div className="invalid-feedback">{errors.routing_number?.message}</div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-sm-12">
                                        <div className="form-floating">
                                            <label>{t("Email")}  <span className='mandatory-field'>*</span></label>
                                            <input type="email" className={`form-control gray-border ${errors.routing_number ? 'is-invalid' : ''}`} name="email" id="floatingInputemail" placeholder={t("Enter") + " " + t("Email")}  {...register("email")} />
                                            <div className="invalid-feedback">{errors.email?.message}</div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row mt-4">
                                    <div className="col-lg-4 col-sm-12">
                                        <div className="form-floating">
                                            <label>{t("Account Type")} <span className='mandatory-field'>*</span></label>
                                            <select className={`form-select  form-control ${errors.type ? 'is-invalid' : ''}`} name="type" {...register("type")} id="floatingSelectGridtype" aria-label="Floating label select example">
                                                <option value="" >{t("Select Type")} </option>
                                                <option value="express">{t("Express")}</option>

                                            </select>
                                            <div className="invalid-feedback">{errors.country?.message}</div>
                                        </div>
                                    </div>

                                    <div className="col-lg-4 col-sm-12">
                                        <div className="form-floating">
                                            <label>{t("Country")} <span className='mandatory-field'>*</span></label>
                                            <select className={`form-select  form-control ${errors.country ? 'is-invalid' : ''}`} name="country" {...register("country")} id="floatingSelectGridcountry" aria-label="Floating label select example">
                                                <option value="" >{t("Select Country")} </option>
                                                <option value="BR">{t("BR")}</option>

                                            </select>
                                            <div className="invalid-feedback">{errors.country?.message}</div>
                                        </div>
                                    </div>

                                    <div className="col-lg-4 col-sm-12">
                                        <div className="form-floating">
                                            <label>{t("Currency")} <span className='mandatory-field'>*</span></label>
                                            <select className={`form-select  form-control ${errors.currency ? 'is-invalid' : ''}`} name="currency" {...register("currency")} id="floatingSelectGridcurrency" aria-label="Floating label select example">
                                                <option value="" >{t("Select Currency")} </option>
                                                <option value="brl">{t("brl")}</option>

                                            </select>
                                            <div className="invalid-feedback">{errors.currency?.message}</div>
                                        </div>
                                    </div>
                                </div>
                                <div>


                                    <Row className='mt-3'>
                                        <Col>
                                            {props.loading ?
                                                (
                                                    <Col className='d-flex justify-content-end'>
                                                        <button className="btn lgn-btn" type="button" disabled>
                                                            <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                                                            {t("Saving...")}
                                                        </button>
                                                    </Col>
                                                )
                                                :
                                                (<Col className='d-flex justify-content-end'>
                                                    <button className="lgn-btn btn" type="submit" disabled={!isValid} onClick={handleSubmit(onSubmit)}>{t("CREATE ACCOUNT")}</button>
                                                </Col>)

                                            }
                                        </Col>
                                    </Row>
                                    <small style={{ color: "red" }}>{t("None of the above user data is being captured or processed by .")} {process.env.REACT_APP_CLIENT_NAME}.</small>
                                </div>
                            </form>
                        </Card.Body>
                        <br /><br /><br />
                    </Card>
                </div>
            </div>
        </Container>

    )
}


const mapStateToProps = (state) => {
    return {
        loading: state.stripe.isLoading,
        createCustomer: state.stripe.createConnectCustomer,
        createCustomerToken: state.stripe.getCustomerToken,
        createCustomerAccount: state.stripe.createExternalAccount,
        triggerOnboardingemail: state.stripe.triggerOnboardingEmail,


    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        createStripeCustomer: (data) => dispatch(createStripeCustomerAction(data)),
        createStripeCustomerToken: (country, currency, routing_number, account_number, account_holder_name, account_holder_type) => dispatch(createStripeCustomerTokenAction(country, currency, routing_number, account_number, account_holder_name, account_holder_type)),
        createStripeExtAccount: (account, id) => dispatch(createStripeExtAccountAction(account, id)),
        triggerEmailOnboarding: (first_name, last_name, email, account) => dispatch(stripeOnboardingEmailAction(first_name, last_name, email, account))

    };
};
export default connect(mapStateToProps, mapDispatchToProps)(AddBankDetails);
