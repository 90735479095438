import React from 'react'
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { Row, Col, Card } from "react-bootstrap";
import DatePicker from "react-datepicker";
import * as Yup from 'yup';
import { connect } from "react-redux";
import PureBreadcrumbs from "../../../breadcrums";
import { Redirect, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { updateEmailCampaignAction, updateNotificationAction, updateSMSCampaignAction, viewSingleEmailAction, viewSingleNotificationAction, viewSingleSmsAction } from '../../../../../store/actions/campaignAction';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Bounce, ToastContainer, toast } from 'react-toastify';


function UpdateEmail(props) {
    const { id } = useParams();
    const { t } = useTranslation();
    const [redirect, setRedirect] = useState(false);
    const [isToastVisible, setIsToastVisible] = useState(false);

    useEffect(() => {
        props.viewEmail(id)
    }, [])

    const smsSchema = Yup.object().shape({
        message: Yup.string().required('Message is required'),
        recipients: Yup.string().required('Recipients is required'),
        subject: Yup.string().required('Title is required'),
    });

    const [startDate, setStartDate] = useState(null);
    const { register, handleSubmit, setValue, reset, formState: { errors } } = useForm({ resolver: yupResolver(smsSchema) });
    const data = props.viewEmailRes && props.viewEmailRes.data;
    const campaign_id = data && data[0]?.campaign_id;
    // console.log(data);
    const previousStartDate = data && data[0]?.date;
    const PrevStartDate = previousStartDate ? new Date(previousStartDate) : null;

    useEffect(() => {
        if (data[0]) {
            setValue("subject", data[0].subject);
            setValue("message", data[0].message);
            setValue("recipients", data[0].recipients);
        }
    }, [data])

    const onSubmit = async data => {
        if (startDate === null && PrevStartDate !== null) {
            // console.log("data saved with  previous startDate successfully", data, PrevStartDate);
            props.updateEmail(data, campaign_id, PrevStartDate)
        } else if (startDate !== null && PrevStartDate !== null) {
            // console.log("data saved with startDate successfully", data, startDate);
            props.updateEmail(data, campaign_id, startDate)
        }
    }

    useEffect(() => {
        if (props.saveEmail.statuscode === 200) {
            setIsToastVisible(true);
            toast.success('Email has been Updated.', {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
                onClose: () => {
                    setIsToastVisible(false);
                    setRedirect(true)
                },
            });
        } else if (props.saveEmail.statuscode === 405) {
            setIsToastVisible(true);
            toast.error(`❌ ${props.saveEmail.error}`, {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
                onClose: () => {
                    setIsToastVisible(false);
                    window.location.reload();
                },
            });
        }
    }, [props.saveEmail]);

    if (redirect) {
        return <Redirect to="/dashboard/view_campaign" />
    }


    return (
        <div className='container-fluid'>
            {isToastVisible && <div className="overlay" />}
            <ToastContainer
                position="top-center"
                autoClose={20}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
                style={{ top: "10%", width: "auto", zIndex: "9999" }}
            />
            <Row>
                <Col lg={12}>
                    <PureBreadcrumbs />
                </Col>
            </Row>
            <Row className='mt-5'>
                <Col lg={12}>
                    <Card className='tariffcard pb-5'>
                        <b>Update Email Details</b>
                        <Card.Body>
                            <form>
                                <div className="form-floating">
                                    <label htmlFor="floatingSelectGridU">Select Recipients</label>
                                    <select autoFocus className={`form-select form-control ${errors.unit ? 'is-invalid' : ''}`} name="recipients" id="floatingSelectGridU" {...register("recipients")}>
                                        <option value="" disabled>Select Recipients</option>
                                        <option value="All">All</option>
                                    </select>
                                    <div className="invalid-feedback">{errors.recipients?.message}</div>
                                </div><br />

                                <div className="form-floating">
                                    <label> Email Subject <span className='mandatory-field'>*</span></label>
                                    <input type="description" className={`form-control gray-border ${errors.subject ? 'is-invalid' : ''}`} name="subject" id="floatingInputGridtitle" placeholder="Enter Email subject" {...register("subject")} />
                                    <div className="invalid-feedback">{errors.subject?.message}</div>
                                </div><br />

                                <div className="form-floating">
                                    <label>Email Message <span className='mandatory-field'>*</span></label>
                                    <input type="description" className={`form-control gray-border ${errors.message ? 'is-invalid' : ''}`} name="message" id="floatingInputGridmessage" placeholder="Message" {...register("message")} />
                                    <div className="invalid-feedback">{errors.message?.message}</div>
                                </div><br />

                                <div className="form-floating">
                                    <Row>
                                        <Col md={4}>
                                            Select Date <span className='mandatory-field'>*</span> :
                                        </Col>
                                        <Col md={4}>
                                            <DatePicker
                                                className='gray-border'
                                                selected={startDate || PrevStartDate}
                                                name="start"
                                                onChange={(date) => setStartDate(date)}
                                                selectsStart
                                                startDate={startDate || PrevStartDate}
                                                dateFormat="dd/MM/yyyy"
                                            />
                                        </Col>
                                    </Row>
                                </div>
                                <Row className="mt-5 d-flex justify-content-end">
                                    {/* <Col className=''>
                                        <button className="lgn-btn btn" type="button" onClick={() => {
                                            reset({
                                                message: '', recipients: '', subject: '',
                                                startDate: setStartDate(new Date(Date.now() + (3600 * 1000 * 1))),
                                            });
                                        }}> Reset </button>
                                    </Col> */}
                                    <Col lg={6} className='d-flex justify-content-end'>
                                        {props.loading ?
                                            <button className="btn lgn-btn" type="button" disabled>
                                                <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                                                &nbsp;
                                                {t("Saving...")}
                                            </button>
                                            :
                                            <button className="lgn-btn btn" type="submit" onClick={handleSubmit(onSubmit)}>UPDATE EMAIL CAMPAIGN</button>
                                        }

                                    </Col>
                                </Row>
                            </form>
                        </Card.Body>
                    </Card>

                </Col>
            </Row>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {

        loading: state.campaign.isLoading,
        viewEmailRes: state.campaign.viewSingleEmail,
        saveEmail: state.campaign.updateEmail,
    }
}
const mapDispatchToProps = dispatch => {
    return {
        viewEmail: (id) => dispatch(viewSingleEmailAction(id)),
        updateEmail: (data, campaign_id, startDate) => dispatch(updateEmailCampaignAction(data, campaign_id, startDate))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(UpdateEmail)
