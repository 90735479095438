// import React, { useMemo } from "react";
// import { Row, Col } from "react-bootstrap";
// import moment from "moment";
// import ReactJson from "react-json-view";
// import "react-datepicker/dist/react-datepicker.css";
// import Table from "../reacttable/table";
// import "../reacttable/reactTable.css";
// import "./logCard.css";
// import CustomReportPaginationTable from "../report/adminReport/customReportPaginationTable";

// const LogListComponent = (props) => {


//   const columns = useMemo(
//     () => [
//       {
//         Header: "Date",
//         id: "date",
//         accessor: (d) => {
//           return moment(d.date).local().format("DD-MM-YYYY hh:mm:ss");
//         },
//       },
//       {
//         Header: "Charge Box Id",
//         accessor: "evse_id",
//       },
//       {
//         Header: "OCPP Command",
//         accessor: "eventtype",
//       },
//       {
//         Header: "Payload",
//         id: "payload",
//         accessor: (d) => {
//           return (
//             <div>
//               <ReactJson src={d.payload} collapsed={true} />
//             </div>
//           );
//         },
//       },
//     ],
//     []
//   );

//   const data = props.fetchOcppLog && props.fetchOcppLog.data;

//   return (
//     <div className="container-fluid">
//       <Row className="mt-4">
//         <Col>
//           <div className="evse">
//             <div>
//               {props.loading ? <h3 style={{
//                 display: "flex",
//                 alignItems: "center",
//                 justifyContent: "center",
//                 height: "250px",
//               }}>Loading data...</h3> :
//                 data && data ? (
//                   <CustomReportPaginationTable
//                     data={data}
//                     allData={csvData}
//                     pageCount={pageCount}
//                     columns={columns}
//                     search={search}
//                     setSearch={setSearch}
//                     currentPage={currentPage}
//                     setCurrentPage={setCurrentPage}
//                     pageSize={pageSize}
//                     setPageSize={setPageSize}
//                     filteredData={filteredData}
//                     setFilteredData={setFilteredData}
//                     loading={props.loading}
//                     t={t}
//                   />
//                 ) : (
//                   <div
//                     style={{
//                       display: "flex",
//                       alignItems: "center",
//                       justifyContent: "center",
//                       height: "250px",
//                     }}
//                   >
//                     <h3>No data found </h3>
//                   </div>
//                 )}
//             </div>
//           </div>
//         </Col>
//       </Row>
//     </div>
//   );
// };

// export default LogListComponent;
