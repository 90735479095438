import React, { useState, useEffect } from 'react';
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { Card, Row, Col, Container, Form, Modal, Button } from "react-bootstrap";
import { fetchSingleRfidApi, updateRfidAction } from "../../../../store/actions/customerActions";
import { connect } from "react-redux";
import PureBreadcrumbs from "../../breadcrums";
import { allCurrencyAction } from "../../../../store/actions/tariffAction";
import { Redirect, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { useTranslation } from 'react-i18next';
import "../evse.css";
import { Bounce, ToastContainer, toast } from 'react-toastify';
import { allEvseAction } from '../../../../store/actions/evseAction';
import { HandleKeyPress } from '../../resuableComponent/handleKeyPress';
import Select from 'react-select';
import moment from 'moment';
import DarkThemeStyles from '../../resuableComponent/reactSelectSingleValueStyle';

const UpdateRFID = (props) => {
    const { id } = useParams();
    const { t } = useTranslation();
    const [isAmountBased, setIsAmountBased] = useState(false);
    const [isFreeCharge, setIsFreeCharge] = useState(false);
    const [iskWhBased, setIskWhBased] = useState(false);
    const [redirect, setRedirect] = useState(false);
    const [isToastVisible, setIsToastVisible] = useState(false);
    const [totalAmount, setTotalAmount] = useState('');
    const [rfidRechargeAmount, setRfidRechargeAmount] = useState('');
    const [creditDebitAmount, setCreditDebitAmount] = useState('');
    const [kWhUsage, setkWhUsage] = useState('');
    const [kWhAmount, setKWhAmount] = useState('');
    const [isTimeBased, setIsTimeBased] = useState(false);
    const [time, setTime] = useState('');
    const [timeRechargeAmount, setTimeRechargeAmount] = useState('');
    const [rfidType, setRfidType] = useState('');
    const [postpaidDuration, setPostpaidDuration] = useState('');
    const [isPaymentCollectedOffline, setIsPaymentCollectedOffline] = useState(false);
    const [totalKwhUsage, setTotalKwhUsage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [totalTimeUsage, setTotalTimeUsage] = useState('');
    const [submitBlockByKwh, setSubmitBlockByKwh] = useState(false);
    const [submitBlockByTime, setSubmitBlockByTime] = useState(false);
    // const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const [pendingChange, setPendingChange] = useState(null);


    useEffect(() => {
        props.getAllCurrency();
        props.allEvse();
        props.getSingleRfidData(id);
    }, [id]);

    const rfidSchema = Yup.object().shape({
        rfid: Yup.string(),
        commission: Yup.string(),
        currency: Yup.string().required('Currency is required'),
        station_name: Yup.array()
            .min(1, 'Please select at least one station name.')  // Require at least one selection
            .of(Yup.string().required('A station name is required.')),
    });
    const { register, handleSubmit, setValue, getValues, reset, control, watch, formState: { errors, isValid } } = useForm({ resolver: yupResolver(rfidSchema), mode: 'all' });

    const data = props.singleRfidRes?.rfids?.[0] || {};
    // console.log("data", data);

    const allFields = watch();
    // console.log("watch", allFields);

    useEffect(() => {
        if (data) {
            setValue('rfid', data.rfid);
            setValue('commission', data.commission);
            setValue('currency', data.currency);
            if (data.station && data.station === 'All') {
                // console.log("All station is here...")
                setValue('station_name', [data.station]);
            } else if (data.station && data.station !== 'All') {
                // console.log("Except All,All stations is here...")
                setValue('station_name', [data.station]);
            } else {
                setValue('station_name', data.rfid_stations);
            }
            setIsAmountBased(data.amount_based);
            setIsFreeCharge(data.free_charging);
            setRfidRechargeAmount(data.amount);
            setIskWhBased(data.kwh_based);
            setkWhUsage(data.kwh?.toString());
            setKWhAmount(data.kwh_amount?.toString());
            setIsTimeBased(data.time_based);
            setTime(data.time?.toString());
            setTimeRechargeAmount(data.time_amount?.toString())
            setRfidType(data.type);
            setPostpaidDuration(data.sub_duration);
            setTotalAmount(data.amount)
            setIsPaymentCollectedOffline((data.offline_payment))
        }
    }, [data, setValue]);
    const stationWatch = watch('station_name');

    // useEffect(() => {
    //     // Check if all options are unselected to disable the button
    //     if (!isFreeCharge && !isAmountBased && !iskWhBased && !isTimeBased) {
    //         setIsButtonDisabled(true); // Disable button
    //         console.log("Please select at least one: free charge, amount, kWh, or time-based charge");
    //     } else {
    //         setIsButtonDisabled(false); // Enable button if at least one is selected
    //     }
    // }, [isFreeCharge, isAmountBased, iskWhBased, isTimeBased]);

    // console.log("isButtonDisabled", isButtonDisabled)
    // console.log("!isFreeCharge || !isAmountBased || !iskWhBased || !isTimeBased", !isFreeCharge, !isAmountBased, !iskWhBased, !isTimeBased)
    // console.log("!isValid", !isValid)
    // console.table("errors", errors)
    // console.log("submitBlockByKwh", submitBlockByKwh)
    // console.log("getValues('station_name')}", !getValues('station_name')?.length > 0)


    const onSubmit = async data => {
        const submissionData = {
            ...data,
            isAmountBased: isAmountBased,
            isRfidFreeCharger: isFreeCharge,
            amount: isAmountBased ? totalAmount : '',
            iskWhBased: iskWhBased,
            kWhUsage: iskWhBased ? (totalKwhUsage || kWhUsage) : '',
            kWhAmount: kWhAmount,
            isTimeBased: isTimeBased,
            time: isTimeBased ? (totalTimeUsage || time) : '',
            timeRechargeAmount: timeRechargeAmount,
            rfidType: rfidType,
            postpaidDuration: postpaidDuration,
            isPaymentCollectedOffline: isFreeCharge === false ? (isPaymentCollectedOffline === true) : false,
        };
        // console.log("onSubmit data", submissionData, id);
        props.update(submissionData, id);
    };

    const handleFreeCharge = () => {
        // setTotalAmount('')
        setKWhAmount('');
        setTotalKwhUsage('');
        setTotalTimeUsage('');
        setShowModal(false);
        setIsFreeCharge(!isFreeCharge);
        if (!isFreeCharge) {
            // setRfidRechargeAmount('0');
            // setCreditDebitAmount('0')
            // setTotalAmount('0')
            setShowModal(true);
        }
    }

    const handleAmountBasedChange = () => {
        setKWhAmount('');
        setPendingChange('amount');
        setShowModal(false);
        // setRfidRechargeAmount('');
        setIsFreeCharge(false)
        setIsPaymentCollectedOffline(false)
        setIsAmountBased(!isAmountBased);
        setTotalKwhUsage('');
        if (!isAmountBased) {
            // console.log("!isAmountBased", !isAmountBased)
            setPendingChange(null);
            setShowModal(true);
            setIskWhBased(false);

        }
    };

    const handleKiloWattBasedChange = () => {
        // setKWhAmount('')
        // setRfidRechargeAmount('');
        setShowModal(false);
        setPendingChange('kWh');
        setIsFreeCharge(false)
        setIsPaymentCollectedOffline(false)
        setIskWhBased(!iskWhBased);
        setTotalKwhUsage('');
        // setkWhUsage('');
        // setKWhAmount('');

        if (!iskWhBased) {
            // console.log("!iskWhBased from onchange", !iskWhBased)

            setTotalKwhUsage('');
            setPendingChange(null);
            setShowModal(true);
            setIsAmountBased(false);
            // setRfidRechargeAmount('');
        }
    };

    const handleTimeBasedChange = () => {
        // setKWhAmount('')
        setRfidRechargeAmount('');
        setShowModal(false);
        setPendingChange('time');
        // setTime('')
        // setTimeRechargeAmount('');
        setIsFreeCharge(false)
        setIsPaymentCollectedOffline(false)
        setIsTimeBased(!isTimeBased);
        if (!isTimeBased) {
            // console.log("!isTimeBased", !isTimeBased)
            // setTime('');
            setPendingChange(null);
            setShowModal(true);
            // setTimeRechargeAmount('');

        }
    };
    // console.log("isAmountBased", isAmountBased)
    // console.log("isKwhBased", iskWhBased)
    // console.log("isTimeBased", isTimeBased)
    // console.log("pending change", pendingChange)

    const HandleAmountBasedKeyPress = (event) => {
        // console.log("event", event);
        const { key, target: { value } } = event;

        if (
            key === 'Backspace' ||
            key === 'Delete' ||
            key === 'ArrowLeft' ||
            key === 'ArrowRight' ||
            key === 'Tab'
        ) {
            return;
        }

        // if (key === '-' && value === '') {
        //     event.preventDefault();
        // }
        if (key === '.' && value === '') {
            event.preventDefault();
            // return;
        }
        if (key === '.' && value.includes('.')) {
            event.preventDefault();
            return;
        }

        // Prevent entering '0' initially or if the value is empty
        if (key === '0' && value === '') {
            event.preventDefault();
        }

        // Allow numbers and decimal point
        if (!/[0-9.-]/.test(key)) {
            event.preventDefault();
        }
    };

    const HandleKwhBasedKeyPress = (event) => {
        const { key, target: { value } } = event;
        // console.log("key,value", key, value)
        if (
            key === 'Backspace' ||
            key === 'Delete' ||
            key === 'ArrowLeft' ||
            key === 'ArrowRight' ||
            key === 'Tab'
        ) {
            return;
        }

        // Allow minus sign only at the start
        if (key === '-' && value === '') {
            return;
        }

        // Prevent multiple decimal points
        if (key === '.' && (value === '' || value.includes('.'))) {
            event.preventDefault();
            return;
        }

        // Prevent entering '0' initially if value is empty
        // if (key === '0' && value === '') {
        //     event.preventDefault();
        //     return;
        // }

        // Allow numbers, decimal point, and minus sign at the start
        if (!/[0-9.-]/.test(key) || (key === '-' && value !== '')) {
            event.preventDefault();
        }
    };

    const handleCreditDebitAmountChange = (e) => {
        setCreditDebitAmount(e.target.value);
        const rechargeAmount = parseFloat(rfidRechargeAmount) || 0;
        const creditDebit = parseFloat(e.target.value) || 0;
        if (creditDebit < 0) {
            // Ensure that creditDebit is not less than kwhUsage in subtraction
            if (Math.abs(creditDebit) > rechargeAmount) {
                setErrorMessage('Debit usage cannot be greater than Initial value.');
                setSubmitBlockByTime(true);
                e.preventDefault(); // Block the entry
                return;
            }
        } else {
            setSubmitBlockByTime(false);
            setErrorMessage('')

        }
        setTotalAmount((rechargeAmount + creditDebit).toFixed(0))
    };
    // console.log(errorMessage)
    const handleCreditDebitKwhChange = (e) => {
        const kwhUsage = parseFloat(kWhUsage) || 0;
        const creditOrDebitUsage = parseFloat(e.target.value) || 0;

        // Check if it's a subtraction scenario
        if (creditOrDebitUsage < 0) {
            // Ensure that creditOrDebitUsage is not less than kwhUsage in subtraction
            if (Math.abs(creditOrDebitUsage) > kwhUsage) {
                setErrorMessage('Debit usage cannot be greater than Initial value.');
                setSubmitBlockByKwh(true);
                e.preventDefault(); // Block the entry
                return;
            }
        } else {
            setSubmitBlockByKwh(false);
            setErrorMessage('')

        }

        // Update total KWh usage
        setTotalKwhUsage((kwhUsage + creditOrDebitUsage).toFixed(0));
    };

    // handle Credit and debit calculation of time
    const handleCreditDebitTimeChange = (e) => {
        const kwhUsage = parseFloat(time) || 0;
        const creditOrDebitUsage = parseFloat(e.target.value) || 0;

        // Check if it's a subtraction scenario
        if (creditOrDebitUsage < 0) {
            // Ensure that creditOrDebitUsage is not less than kwhUsage in subtraction
            if (Math.abs(creditOrDebitUsage) > kwhUsage) {
                setErrorMessage('Debit usage cannot be greater than Initial value.');
                setSubmitBlockByTime(true);
                e.preventDefault(); // Block the entry
                return;
            }
        } else {
            setSubmitBlockByTime(false);
            setErrorMessage('')

        }

        // Update total KWh usage
        setTotalTimeUsage((kwhUsage + creditOrDebitUsage).toFixed(0));
    };

    // console.log("totalKwhUsage", totalKwhUsage)
    // console.log("totalTimeUsage", totalTimeUsage)

    const handleRfidTypeChange = (e) => {
        setPostpaidDuration('');
        const value = e.target.value;
        setRfidType(value);
    };

    const handlePostpaidDuration = (e) => {
        const value = e.target.value;
        setPostpaidDuration(value);
    };

    const handleIsCollectedOffline = (event) => {
        const value = event.target.value === "true";
        setIsPaymentCollectedOffline(value);
        // console.log("Is payment collected offline?", value);
    };
    // console.log("isPayment collected offline", isPaymentCollectedOffline)

    useEffect(() => {
        if (props.updateresponse.statuscode === 200) {
            setIsToastVisible(true);
            toast.success('RFID has been updated successfully.', {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
                onClose: () => {
                    setIsToastVisible(false);
                    setRedirect(true)
                },
            });
        }
    }, [props.updateresponse]);
    // console.log(props.updateresponse)

    if (redirect) {
        return <Redirect to='/dashboard/view_rfid_pin' />;
    }

    const handleCurrency = (e) => {
        const code = e.value;
        // console.log("currency", code);
        setValue("currency", code);
    };

    const currencyOptions = props.allcurrency && props.allcurrency.currency?.map(item => ({
        value: item.currency_code,
        label: item.currency_code
    }));

    const stationNameOptions = [
        { value: 'All', label: 'All' }, // Static option
        ...(props.evseList && props.evseList.data ? props.evseList.data
            .filter((item) => item.status !== 'Discovery' && item.publish !== false)
            .map(item => ({
                value: item.name,
                label: item.name
            }))
            : []) // Dynamic options
    ];

    const stationOptions = stationWatch?.map(station => ({
        value: station,
        label: station
    }));

    const isSubmitDisabled = () => {
        if (iskWhBased && ((!kWhUsage && !kWhUsage) || !kWhAmount)) {
            // console.log("1")
            return true;
        }
        if (isTimeBased && ((!time && !totalTimeUsage) || !timeRechargeAmount)) {
            // console.log("2")
            return true;
        }
        if (rfidType === 'postpaid' && !postpaidDuration) {
            // console.log("3")
            return true;
        }
        // console.log("No if condition is printed")

        return false;
    };


    const handleCancel = () => {
        setShowModal(false);
        setPendingChange(null);
    };

    return (
        <Container fluid>
            {isToastVisible && <div className="overlay" />}
            <ToastContainer
                position="top-center"
                autoClose={20}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
                style={{ top: "10%", width: "auto", zIndex: "9999" }}
            />
            <PureBreadcrumbs />
            <br />
            <Row>
                <Col xs={12} md={1}></Col>
                <Col xs={12} md={10}>
                    <Card className="tariffcard">
                        <Card.Text style={{ fontSize: "14px", padding: "10px", paddingLeft: "15px" }}>
                            <b>{t("UPDATE RFID")}</b>
                        </Card.Text>
                        <Card.Body>
                            <form onSubmit={e => e.preventDefault()}>
                                <div className="form-floating">
                                    <label>{t("RFID")} <span className='mandatory-field'>*</span></label>
                                    <input disabled type="text" className={`form-control gray-border`} name="rfid" id="floatingInputGridrfid" placeholder={t("RFID")} {...register("rfid")} />
                                </div>
                                <br />

                                <div className="form-floating">
                                    <label>{t("Date")}</label>
                                    <input disabled type="text" className={`form-control gray-border`} name="commission" id="floatingInputGridName" placeholder={'Commission Date'} {...register('commission')} />
                                </div>
                                <br />

                                <div className="row my-4">
                                    <div className="col-lg-5 col-md-10">
                                        <p style={{ fontSize: '1.2rem', fontWeight: 'bold', marginBottom: '10px' }}>
                                            Do you want to give this RFID free charge?
                                        </p>
                                    </div>
                                    <div className='col-lg-7 col-md-2 d-flex'>
                                        <input
                                            className="form-check-input w-25"
                                            type="checkbox"
                                            id="freeChargeCheckbox"
                                            checked={isFreeCharge}
                                            onChange={handleFreeCharge}
                                            disabled={isAmountBased || iskWhBased || isTimeBased}
                                        />
                                    </div>
                                </div>

                                <Row className="row d-flex align-items-center mt-4">
                                    <Col lg={4} className='mt-1'>
                                        <div className="">
                                            <input className="form-check-input ml-0" type="checkbox" id="amountBasedCheckbox" checked={isAmountBased} onChange={handleAmountBasedChange} disabled={iskWhBased || isTimeBased || isFreeCharge} />
                                            <label className="form-check-label" htmlFor="amountBasedCheckbox">
                                                {t("Amount Based")}
                                            </label>
                                        </div>
                                    </Col>
                                    <Col lg={4} className='mt-1'>
                                        <div>
                                            <input className="form-check-input ml-0" type="checkbox" id="kiloWattBasedCheckbox" checked={iskWhBased} onChange={handleKiloWattBasedChange} disabled={isAmountBased || isTimeBased || isFreeCharge} />
                                            <label className="form-check-label" htmlFor="kiloWattBasedCheckbox">
                                                {t("kWh Based")}
                                            </label>
                                        </div>
                                    </Col>
                                    <Col lg={4} className='mt-1'>
                                        <div>
                                            <input className="form-check-input ml-0" type="checkbox" id="timeBasedCheckbox" checked={isTimeBased} onChange={handleTimeBasedChange} disabled={isAmountBased || iskWhBased || isFreeCharge} />
                                            <label className="form-check-label" htmlFor="timeBasedCheckbox">
                                                {t("Time Based")}
                                            </label>
                                        </div>
                                    </Col>
                                </Row>
                                <br />

                                {isAmountBased && (
                                    <>
                                        <Row>
                                            <Col md={4}>
                                                <div className="form-floating">
                                                    <label>{t("RFID Recharge Amount")} <span className='mandatory-field'>*</span></label>
                                                    <input type="number" className={`form-control gray-border`} name="rfidRechargeAmount" id="floatingInputRfidRechargeAmount" placeholder={t("RFID Recharge Amount")} value={rfidRechargeAmount} disabled />
                                                </div>
                                                <br />
                                            </Col>
                                            <Col md={4}>
                                                <div className="form-floating">
                                                    <label>{t("Credit Amount")}</label>
                                                    <input type="number"
                                                        className={`form-control gray-border`}
                                                        name="creditDebitAmount"
                                                        id="floatingInputCreditDebitAmount"
                                                        placeholder={t("Credit Amount")}
                                                        disabled={isFreeCharge}
                                                        value={creditDebitAmount}
                                                        onKeyDown={HandleAmountBasedKeyPress}
                                                        onChange={handleCreditDebitAmountChange}
                                                    />
                                                </div>
                                                <br />
                                            </Col>
                                            <Col md={4}>
                                                <div className="form-floating">
                                                    <label>{t("Total Amount")}</label>
                                                    <input type="text" className={`form-control gray-border`} name="totalAmount" id="floatingInputTotalAmount" placeholder={t("Total Amount")} value={totalAmount || rfidRechargeAmount} disabled />
                                                </div>
                                                <br />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className='d-flex justify-content-center'>
                                                {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
                                            </Col>
                                        </Row>
                                        {!isFreeCharge &&
                                            <div className='row'>
                                                <div className='col-md'>
                                                    <div className="form-floating">
                                                        <label>{t("Is payment collected offline ?")} <span className='mandatory-field'>*</span></label>
                                                        <select className={`form-select form-control ${isPaymentCollectedOffline === null ? 'is-invalid' : ''}`} disabled={isFreeCharge} name="isPaymentCollectedOffline" id="floatingSelectGridisPaymentCollectedOffline" onChange={handleIsCollectedOffline} required>
                                                            <option value="" hidden>
                                                                {isPaymentCollectedOffline === true ? "Yes" : isPaymentCollectedOffline === false ? "No" : "Select an option"}
                                                            </option>
                                                            <option value={true}>{t("Yes")}</option>
                                                            <option value={false}>{t("No")}</option>
                                                        </select>
                                                        {isPaymentCollectedOffline === null && (
                                                            <div className="invalid-feedback">{"This field is required"}</div>
                                                        )}
                                                    </div>
                                                    <br />
                                                </div>
                                            </div>
                                        }
                                    </>
                                )}

                                {iskWhBased && (
                                    <>
                                        <div className='row'>
                                            <div className='col-md'>
                                                <div className="form-floating">
                                                    <label>{t("Remaining kWh Usage")} <span className='mandatory-field'>*</span></label>
                                                    <input type="number"
                                                        className={`form-control gray-border`}
                                                        name="kiloWattUsage"
                                                        id="floatingInputKiloWattUsage"
                                                        placeholder={t("kWh Usage")}
                                                        value={kWhUsage}
                                                        disabled
                                                    />
                                                </div>
                                            </div>
                                            <Col md={4}>
                                                <div className="form-floating">
                                                    <label>{t("Credit/Debit usage")}</label>
                                                    <input type="number"
                                                        className={`form-control gray-border`}
                                                        name="creditDebitAmount"
                                                        id="floatingInputCreditDebitUsage"
                                                        placeholder={t("Credit/Debit usage")}
                                                        // disabled={isKwhFreeCharge}
                                                        onKeyDown={HandleKwhBasedKeyPress}
                                                        // defaultValue={totalKwhUsage || 0}
                                                        onChange={handleCreditDebitKwhChange}
                                                    />
                                                </div>
                                                <br />
                                            </Col>
                                            <Col md={4}>
                                                <div className="form-floating">
                                                    <label>{t("Final kWh Usage")}</label>
                                                    <input type="number"
                                                        className={`form-control gray-border`}
                                                        name="creditDebitAmount"
                                                        id="floatingInputCreditDebitAmount"
                                                        placeholder={t("Final kWh Usage")}
                                                        value={totalKwhUsage || kWhUsage}
                                                        disabled
                                                    />
                                                </div>
                                                <br />
                                            </Col>

                                        </div>
                                        <Row>
                                            <Col className='d-flex justify-content-center'>
                                                {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
                                            </Col>
                                        </Row>
                                        <br />
                                        <div className='row'>
                                            <div className='col-md'>
                                                <div className="form-floating">
                                                    <label>{t("Recharge Amount")} <span className='mandatory-field'>*</span></label>
                                                    <input type="number"
                                                        className={`form-control gray-border ${!kWhAmount ? 'is-invalid' : ''}`}
                                                        name="kWhAmount"
                                                        id="floatingInputkWhAmount"
                                                        placeholder={t("Recharge Amount")}
                                                        defaultValue={kWhAmount}
                                                        onChange={(e) => setKWhAmount(e.target.value)}
                                                        onKeyDown={HandleKeyPress}

                                                    />
                                                    {!kWhAmount && (
                                                        <div className="invalid-feedback">{"Amount is required"}</div>
                                                    )}
                                                </div>
                                            </div>

                                            {!isFreeCharge &&
                                                <div className={`col-md`}>
                                                    <div className="form-floating">
                                                        <label>{t("Is payment collected offline ?")} <span className='mandatory-field'>*</span></label>
                                                        <select className={`form-select form-control ${isPaymentCollectedOffline === null ? 'is-invalid' : ''}`} name="isPaymentCollectedOffline" id="floatingSelectGridisPaymentCollectedOffline" onChange={handleIsCollectedOffline} required>
                                                            <option value="" hidden>
                                                                {isPaymentCollectedOffline === true ? "Yes" : isPaymentCollectedOffline === false ? "No" : "Select an option"}
                                                            </option>
                                                            <option value={true}>{t("Yes")}</option>
                                                            <option value={false}>{t("No")}</option>
                                                        </select>
                                                        {isPaymentCollectedOffline === null && (
                                                            <div className="invalid-feedback">{"This field is required"}</div>
                                                        )}
                                                    </div>
                                                    <br />
                                                </div>}
                                        </div>

                                    </>
                                )}

                                {isTimeBased && (
                                    <>
                                        <div className='row my-2'>
                                            <div className='col-md'>
                                                <div className="form-floating">
                                                    <label>{t("Remaining Time Usage")} <span className='mandatory-field'>*</span></label>
                                                    <input type="number" className={`form-control gray-border`}
                                                        name="time" id="floatingInputTime"
                                                        placeholder={t("No. of Hours")}
                                                        value={time}
                                                        disabled={true}

                                                    />
                                                    <small className="form-text text-muted">
                                                        {t("No. Of Hours")}
                                                    </small>
                                                </div>
                                            </div>
                                            <Col md={4}>
                                                <div className="form-floating">
                                                    <label>{t("Credit/Debit usage")}</label>
                                                    <input type="number"
                                                        className={`form-control gray-border`}
                                                        name="creditDebitAmount"
                                                        id="floatingInputCreditDebitUsage"
                                                        placeholder={t("Credit/Debit usage")}
                                                        // disabled={isTimeFreeCharge}
                                                        onKeyDown={HandleKwhBasedKeyPress}
                                                        // defaultValue={totalKwhUsage || 0}
                                                        onChange={handleCreditDebitTimeChange}
                                                    />
                                                </div>
                                                <br />
                                            </Col>
                                            <Col md={4}>
                                                <div className="form-floating">
                                                    <label>{t("Final Time Usage")}</label>
                                                    <input type="number"
                                                        className={`form-control gray-border`}
                                                        name="creditDebitTime"
                                                        id="floatingInputCreditDebitTime"
                                                        placeholder={t("Final Time Usage")}
                                                        value={totalTimeUsage || time}
                                                        disabled
                                                    />
                                                </div>
                                                <br />
                                            </Col>
                                        </div>
                                        <Row>
                                            <Col className='d-flex justify-content-center'>
                                                {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
                                            </Col>
                                        </Row>
                                        <br />
                                        <div className='row my-2'>
                                            <div className='col-md'>
                                                <div className="form-floating">
                                                    <label>{t("Recharge Amount")} <span className='mandatory-field'>*</span></label>
                                                    <input type="number"
                                                        className={`form-control gray-border ${!timeRechargeAmount ? 'is-invalid' : ''}`}
                                                        name="timeRechargeAmount"
                                                        id="floatingInputRechargeAmount"
                                                        placeholder={t("Recharge Amount")}
                                                        value={timeRechargeAmount}
                                                        onChange={(e) => setTimeRechargeAmount(e.target.value)}
                                                        onKeyDown={HandleKeyPress}
                                                    />
                                                    {!timeRechargeAmount && <small className="text-danger">{t("Recharge Amount is required")}</small>}
                                                </div>
                                            </div>

                                            {!isFreeCharge &&
                                                <div className={`${isPaymentCollectedOffline ? 'col-lg-12' : 'col-md'}`}>
                                                    <div className="form-floating">
                                                        <label>{t("Is payment collected offline ?")} <span className='mandatory-field'>*</span></label>
                                                        <select className={`form-select form-control ${isPaymentCollectedOffline === null ? 'is-invalid' : ''}`} name="isPaymentCollectedOffline" id="floatingSelectGridisPaymentCollectedOffline" onChange={handleIsCollectedOffline} required>
                                                            <option value="" hidden>
                                                                {isPaymentCollectedOffline === true ? "Yes" : isPaymentCollectedOffline === false ? "No" : "Select an option"}
                                                            </option>
                                                            <option value={true}>{t("Yes")}</option>
                                                            <option value={false}>{t("No")}</option>
                                                        </select>
                                                        {isPaymentCollectedOffline === null && (
                                                            <div className="invalid-feedback">{"This field is required"}</div>
                                                        )}
                                                    </div>
                                                    <br />
                                                </div>
                                            }
                                        </div>
                                    </>
                                )}

                                <div className='row mt-3'>
                                    <div className='col-md'>
                                        <div className="form-floating">
                                            <label>{t("Tariff Currency")} <span className='mandatory-field'>*</span></label>
                                            <Select
                                                options={currencyOptions ? currencyOptions : []}
                                                className={`${errors.currency ? 'is-invalid' : ''}`}
                                                name="currency"
                                                id="floatingInputGridcurrency"
                                                placeholder={getValues("currency") ? getValues("currency") : "Select Currency"}
                                                styles={DarkThemeStyles}
                                                menuPortalTarget={document.body}
                                                onChange={handleCurrency}
                                            />
                                            <div className="invalid-feedback">{errors.currency?.message}</div>
                                        </div><br />
                                    </div>
                                    <div className='col-md'>
                                        <div className="form-floating">
                                            <label>
                                                {t("Select Station Name")}
                                                <span className="mandatory-field">*</span>
                                            </label>
                                            <Controller
                                                name="station_name"
                                                control={control}
                                                className={`${errors.station_name ? 'is-invalid' : ''}`}
                                                render={({ field: { onChange, value } }) => (
                                                    <Select
                                                        options={stationNameOptions || []}
                                                        isMulti
                                                        placeholder="Select Station Name"
                                                        styles={DarkThemeStyles}
                                                        menuPortalTarget={document.body}
                                                        onChange={(selectedOptions) => {
                                                            onChange(selectedOptions ? selectedOptions.map(option => option?.value) : []);  // Update form value
                                                        }}
                                                        value={stationOptions}
                                                        isOptionDisabled={(option) => {
                                                            const isAnyStationSelected = value && value.length > 0 && !value.includes('All');
                                                            const isAllSelected = value && value.includes('All');

                                                            // Disable "All" if any other station is selected
                                                            if (option.value === 'All') {
                                                                return isAnyStationSelected;
                                                            }

                                                            // Disable all other options if "All" is selected
                                                            return isAllSelected;
                                                        }}
                                                    />
                                                )}
                                            />

                                            {/* Display validation error message */}
                                            {errors.station_name && (
                                                <div className="invalid-feedback">
                                                    {errors.station_name.message}
                                                </div>
                                            )}
                                        </div>
                                    </div>

                                </div>

                                {!isFreeCharge &&
                                    <div className='row'>
                                        <div className='col-md'>
                                            <div className="form-floating">
                                                <label>{t("RFID Type")} <span className='mandatory-field'>*</span></label>
                                                <select className={`form-select form-control`} name="rfidType" id="floatingSelectGridCurrency" defaultValue={rfidType} onChange={(e) => handleRfidTypeChange(e)} >
                                                    <option value="" hidden disabled>{`${rfidType ? rfidType : t("Select RFID Type")}`}</option>
                                                    <option value="prepaid">{t("Prepaid")}</option>
                                                    <option value="postpaid">{t("Postpaid")}</option>
                                                </select>
                                                {rfidType === '' && <div className="invalid-feedback">{"RFID Type is required"}</div>}
                                            </div><br />
                                        </div>
                                        <div className={`${rfidType === 'postpaid' ? "col-md" : "col-12"}`}>
                                            {rfidType === 'postpaid' &&
                                                <div className="form-floating">
                                                    <label>
                                                        {t("Postpaid Duration")}
                                                        <span className="mandatory-field">*</span>
                                                    </label>
                                                    <select
                                                        className={`form-select form-control`}
                                                        name={`postpaidDuration`}
                                                        id="floatingSelectGridsubscriptionDuration"
                                                        onChange={(e) => handlePostpaidDuration(e)}
                                                    // disabled
                                                    >

                                                        <option value="" hidden>
                                                            {`${postpaidDuration ? postpaidDuration.charAt(0).toUpperCase() + postpaidDuration.slice(1) : t("Select Postpaid Duration")}`}
                                                        </option>
                                                        <option value={"weekly"}>{t("Weekly")}</option>
                                                        <option value={"monthly"}>{t("Monthly")}</option>
                                                        <option value={"yearly"}>{t("Yearly")}</option>
                                                    </select>
                                                    {postpaidDuration === '' && <div className="invalid-feedback">{"Postpaid Duration is required"}</div>}

                                                </div>
                                            }
                                        </div>
                                    </div>
                                }

                                {/* {(!isFreeCharge && !isAmountBased && !iskWhBased && !isTimeBased) &&
                                    <Row>
                                        <Col className='d-flex justify-content-center'>
                                            <p className='mandatory-field'>
                                                Please select at least one option: Free charge or Amount/KWh/Time-based charge.
                                            </p>
                                        </Col>
                                    </Row>
                                } */}

                                <Row className='mt-3 d-flex justify-content-end'>
                                    <Col lg={6} sm={12} className='d-flex justify-content-end'>
                                        <button
                                            className="lgn-btn btn ml-4"
                                            type="submit"
                                            onClick={handleSubmit(onSubmit)}
                                            disabled={isSubmitDisabled() || submitBlockByKwh || submitBlockByTime || (!getValues('station_name')?.length > 0) || props.loading || isToastVisible}
                                        >
                                            {t("UPDATE RFID")}
                                        </button>
                                    </Col>
                                </Row>
                                <br />
                            </form>
                        </Card.Body>
                    </Card>
                </Col>
                <Col xs={12} md={1}></Col>
            </Row>
            {/* Modal */}
            <Modal show={showModal} onHide={handleCancel}>
                <Modal.Header closeButton>
                    <Modal.Title>Warning</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Please be careful while changing the conditions. Your previous amount/kWh/time will reset to 0.
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCancel}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={handleCancel}>
                        Confirm
                    </Button>
                </Modal.Footer>
            </Modal>
        </Container>
    );
};

const mapStateToProps = (state) => {
    return {
        loading: state.customer.isLoading,
        updateresponse: state.customer.updateRfid,
        allcurrency: state.tariff.fetchcurrency,
        evseList: state.evse.allevse,
        singleRfidRes: state.customer.fetchSingleRfid,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        update: (data, id) => dispatch(updateRfidAction(data, id)),
        getAllCurrency: () => dispatch(allCurrencyAction()),
        allEvse: () => dispatch(allEvseAction()),
        getSingleRfidData: (id) => dispatch(fetchSingleRfidApi(id)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(UpdateRFID);
