import { FETCH_CHARGER_REPORT_API, CHARGING_SERVICE_URL, PREDICTIVE_URL, FETCH_DAILY_REPORT_API, FETCH_ALL_REPORT_API, RELEASE_TRANSACTION_API, REPORT_PERCENTAGE_CHANGE_API, REVENUE_SHARING_LIST_API, REVENUE_TOP10_TRANSACTION_API, FETCH_PLUG_UNPLUG_REPORT } from "../../components/config/config";


export const fetchChargerReport = () => {
  const { token, role, organisation, region } = JSON.parse(localStorage.getItem('user'));
  const operator = role === 'Admin' ? 'All' : organisation;
  const region1 = role === 'Regional Manager' ? (region[0] || "") : "";
  const region2 = role === 'Regional Manager' ? (region[1] || "") : "";
  const region3 = role === 'Regional Manager' ? (region[2] || "") : "";
  const region4 = role === 'Regional Manager' ? (region[3] || "") : "";

  const bearer = 'basic ' + token
  return dispatch => {
    dispatch({ type: 'REPORT_LOADING', payload: '' })
    return fetch(CHARGING_SERVICE_URL + FETCH_CHARGER_REPORT_API + "?region1=" + region1 + "&region2=" + region2 + "&region3=" + region3 + "&region4=" + region4 + "&operator=" + operator, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    }).then(resp => resp.json()).then(data => {
      // console.log(data);
      dispatch({ type: 'LOAD_CHARGER_REPORT_API', payload: data })
    })
  }
}


export const fetchDailyReport = () => {
  const { token } = JSON.parse(localStorage.getItem('user'));
  const bearer = 'basic ' + token
  return dispatch => {
    dispatch({ type: 'REPORT_LOADING', payload: '' })
    return fetch(PREDICTIVE_URL + FETCH_DAILY_REPORT_API, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    }).then(resp => resp.json()).then(data => {
      dispatch({ type: 'LOAD_DAIL_REPORT', payload: data })
    })
  }
}



export const fetchMyReportReport = (start, end, org, currentPage, pageSize) => {
  const { token, role, region } = JSON.parse(localStorage.getItem('user'));
  const region1 = role === 'Regional Manager' ? (region[0] || "") : "";
  const region2 = role === 'Regional Manager' ? (region[1] || "") : "";
  const region3 = role === 'Regional Manager' ? (region[2] || "") : "";
  const region4 = role === 'Regional Manager' ? (region[3] || "") : "";
  // console.log("currentPage action", currentPage)
  // console.log("pageSize action", pageSize);
  const bearer = 'basic ' + token
  return dispatch => {
    dispatch({ type: 'REPORT_LOADING', payload: '' })
    return fetch(PREDICTIVE_URL + FETCH_ALL_REPORT_API + "?region1=" + region1 + "&region2=" + region2 + "&region3=" + region3 + "&region4=" + region4 + "&start=" + start + "&end=" + end + "&operator=" + org + "&page=" + currentPage + "&limit=" + pageSize, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    }).then(resp => resp.json()).then(data => {
      // console.log(data)
      dispatch({ type: 'LOAD_ALL_REPORT', payload: data })
    })
  }
}
export const fetchReportCsvData = (start, end, org, currentPage, pageSize) => {
  const { token, role, region } = JSON.parse(localStorage.getItem('user'));
  const region1 = role === 'Regional Manager' ? (region[0] || "") : "";
  const region2 = role === 'Regional Manager' ? (region[1] || "") : "";
  const region3 = role === 'Regional Manager' ? (region[2] || "") : "";
  const region4 = role === 'Regional Manager' ? (region[3] || "") : "";
  // console.log("currentPage action", currentPage)
  // console.log("pageSize action", pageSize);
  const bearer = 'basic ' + token
  return dispatch => {
    dispatch({ type: 'REPORT_LOADING', payload: '' })
    return fetch(PREDICTIVE_URL + FETCH_ALL_REPORT_API + "?region1=" + region1 + "&region2=" + region2 + "&region3=" + region3 + "&region4=" + region4 + "&start=" + start + "&end=" + end + "&operator=" + org + "&page=" + currentPage + "&limit=" + pageSize, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    }).then(resp => resp.json()).then(data => {
      // console.log(data)
      dispatch({ type: 'FETCH_REPORT_CSV', payload: data })
    })
  }
}
export const fetchPlugUnplugsReport = (start, end, org) => {
  // console.log("start,end,org",start,end,org)
  const { token } = JSON.parse(localStorage.getItem('user'));
  // const operator = role === 'Admin' ? 'All' : organisation;
  const bearer = 'basic ' + token
  return dispatch => {
    dispatch({ type: 'REPORT_LOADING', payload: '' })
    return fetch(PREDICTIVE_URL + FETCH_PLUG_UNPLUG_REPORT + "?start=" + start + "&end=" + end + "&operator=" + org, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    }).then(resp => resp.json()).then(data => {
      // console.log(data)
      dispatch({ type: 'GET_PLUG_UNPLUG_REPORT', payload: data })
    })
  }
}

export const fetchTop10Transaction = () => {
  const { token, organisation, role, region } = JSON.parse(localStorage.getItem('user'));
  const operator = role === 'Admin' ? 'All' : organisation;
  const region1 = role === 'Regional Manager' ? (region[0] || "") : "";
  const region2 = role === 'Regional Manager' ? (region[1] || "") : "";
  const region3 = role === 'Regional Manager' ? (region[2] || "") : "";
  const region4 = role === 'Regional Manager' ? (region[3] || "") : "";

  // console.log(org);
  const bearer = 'basic ' + token
  return dispatch => {
    dispatch({ type: 'REPORT_LOADING', payload: '' })
    return fetch(PREDICTIVE_URL + REVENUE_TOP10_TRANSACTION_API + "?region1=" + region1 + "&region2=" + region2 + "&region3=" + region3 + "&region4=" + region4 + "&operator=" + operator, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    }).then(resp => resp.json()).then(data => {
      // console.log("data from action",data)
      dispatch({ type: 'TOP10_REVENUE_SHARING_LIST', payload: data })
    })
  }
}

export const releaseTransaction = (charging_id) => {
  // console.log(charging_id)
  // console.log(CHARGING_SERVICE_URL+RELEASE_TRANSACTION_API+"?charging_id="+charging_id)
  const { token } = JSON.parse(localStorage.getItem('user'));
  const bearer = 'basic ' + token
  return dispatch => {
    dispatch({ type: 'REPORT_LOADING', payload: '' })
    return fetch(CHARGING_SERVICE_URL + RELEASE_TRANSACTION_API + "?charging_id=" + charging_id, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    }).then(resp => resp.json()).then(data => {
      console.log("afterhit", data)
      dispatch({ type: 'RELEASE_TRANSACTION', payload: data })
    })
  }
}

export const reportPercentageChange = () => {
  const { token, organisation, role } = JSON.parse(localStorage.getItem('user'));
  const operator = role === 'Admin' ? 'All' : organisation;
  const bearer = 'basic ' + token
  // console.log(token)
  return dispatch => {
    dispatch({ type: 'REPORT_LOADING', payload: '' })
    return fetch(PREDICTIVE_URL + REPORT_PERCENTAGE_CHANGE_API + "?operator=" + operator, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    }).then(resp => resp.json()).then(data => {
       console.log("afterhit", data)
      dispatch({ type: 'REPORT_PERCENTAGE_CHANGE', payload: data })
    })
  }
}


export const fetchRevenueSharingReport = (start, end) => {
  const { token, role, organisation } = JSON.parse(localStorage.getItem('user'));
  const operator = role === 'Admin' ? 'All' : organisation;
  const bearer = 'basic ' + token
  return dispatch => {
    dispatch({ type: 'REPORT_LOADING', payload: '' })
    return fetch(PREDICTIVE_URL + REVENUE_SHARING_LIST_API + "?start=" + start + "&end=" + end + "&operator=" + operator, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    }).then(resp => resp.json()).then(data => {

      dispatch({ type: 'REVENUE_SHARING_LIST', payload: data })
    })
  }
}

