import React, { useEffect, useState } from 'react';
import { FaFilter } from 'react-icons/fa';

function CustomReportPaginationTable({
    data,
    allData,
    pageCount,
    columns,
    search,
    setSearch,
    currentPage,
    setCurrentPage,
    pageSize,
    setPageSize,
    filteredData,
    setFilteredData,
    loading,
    t,
}) {
    // console.log("all data", allData)

    const [showCheckboxes, setShowCheckboxes] = useState(false);

    const toggleCheckboxes = () => {
        setShowCheckboxes((prevState) => !prevState);
    };

    // State for managing which columns are visible
    const [visibleColumns, setVisibleColumns] = useState(
        columns.reduce((acc, col) => {
            // console.log("acc", acc)
            acc[col.accessor] = true; // Initially, all columns are visible and true
            return acc;
        }, {})
    );
    // console.log("visibleColumns", visibleColumns);

    // Debounce the search filter input
    useEffect(() => {
        if (!search) {
            setFilteredData(data);
            return;
        }

        const delayDebounceFn = setTimeout(() => {
            const filtered = allData && allData.filter((item) =>
                Object.values(item).some((value) =>
                    value && value.toString().toLowerCase().includes(search.toLowerCase())
                )
            );
            setFilteredData(filtered);
        }, 30);

        // Cleanup function to clear the timeout
        return () => clearTimeout(delayDebounceFn);
    }, [search, data, allData]);

    // Toggle column visibility based on checkbox changes
    const toggleColumnVisibility = (accessor) => {
        setVisibleColumns((prevState) => {
            // console.log("prevState: ", prevState);
            return {
                ...prevState,
                [accessor]: !prevState[accessor],
            };
        });
    };

    // Filter columns based on visibility state 
    const filteredColumns = columns.filter((col) => visibleColumns[col.accessor]);
    // console.log("filteredColumns", filteredColumns);

    // debouncing the onchange event
    let debounceTimeout;
    const handlePageInputChange = (e) => {
        clearTimeout(debounceTimeout);
        debounceTimeout = setTimeout(() => {
            const newPage = Number(e.target.value);
            if (!isNaN(newPage) && newPage > 0 && newPage <= pageCount) {
                setCurrentPage(newPage);
            }
        }, 1000);
    };

    //clearning up the debounce event
    useEffect(() => {
        return () => {
            clearTimeout(debounceTimeout);
        };
    }, []);

    return (
        <div>
            {/* Search filter for global search */}
            <div className="row pb-2">
                <div className="col-md-12">
                    <div className="col-md-12 pt-2">
                        <span className='float-right ml-3 mt-0'>
                            <FaFilter
                                onClick={toggleCheckboxes}
                                style={{ cursor: 'pointer', marginRight: '10px' }}
                            />
                        </span>
                        <div className="row float-right">
                            <div className="col-md-12">
                                <span>
                                    <input
                                        className="SearchFilter p-3"
                                        placeholder={t("Search")}
                                        value={search}
                                        onChange={(e) => setSearch(e.target.value)}
                                    />
                                    <i className=""
                                        style={{ cursor: "pointer", marginLeft: "-30px" }}>
                                        <img
                                            className="pr-1"
                                            alt="Search icon"
                                            src={process.env.PUBLIC_URL + '/images/admin/inputSearch.svg'}
                                            style={{ height: "15px" }}
                                        />
                                    </i>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Render checkboxes for each column */}
            <div className="column-checkboxes-toggle">
                {showCheckboxes && (
                    <div className="column-checkboxes">
                        {columns.map((col) => (
                            <label key={col.accessor} style={{ fontSize: "15px" }}>
                                <input
                                    className=''
                                    style={{ fontSize: "11px" }}
                                    type="checkbox"
                                    checked={visibleColumns[col.accessor]}
                                    onChange={() => toggleColumnVisibility(col.accessor)}
                                />
                                {t(col.Header)}
                            </label>
                        ))}
                    </div>
                )}
            </div>

            {/* Loading or data display */}
            {loading ? (
                <h3 style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '500px' }}>
                    {t("Loading data...")}
                </h3>
            ) : (
                <div>
                    {filteredData && filteredData.length > 0 ? (
                        <div>
                            <table className="custom-table">
                                <thead>
                                    <tr>
                                        {filteredColumns && filteredColumns.map((col, index) => (
                                            <th key={index}>{t(col.Header)}</th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    {filteredData?.map((item, rowIndex) => (
                                        <tr key={rowIndex}>
                                            {filteredColumns && filteredColumns.map((col, colIndex) => (
                                                <td key={colIndex}>
                                                    {col.Cell
                                                        ? col.Cell({ cell: item[col.accessor], item }) // Passing entire cell data and item
                                                        : item[col.accessor]}
                                                </td>
                                            ))}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>

                            {/* Pagination controls */}
                            {search === '' &&
                                <div className="d-flex justify-content-center pt-2">
                                    <button
                                        className="columnsBtn  mr-1"
                                        onClick={() => setCurrentPage((prevPage) => prevPage - 1)}
                                        disabled={currentPage <= 1}
                                        style={{ margin: "5px" }}
                                    >
                                        Previous
                                    </button>
                                    <span className='m-1'>
                                        Page
                                        <input
                                            type="number"
                                            className='gray-border ml-1'
                                            defaultValue={currentPage}
                                            placeholder='Jump'
                                            onChange={handlePageInputChange}
                                            style={{ width: '80px', margin: '0.5px', }}
                                        />
                                        {pageCount !== null && pageCount !== undefined ? ` of ${pageCount}` : ''}
                                    </span>
                                    <button
                                        className="columnsBtn  mr-1 p-1"
                                        onClick={() => setCurrentPage((prevPage) => prevPage + 1)}
                                        disabled={currentPage >= pageCount}
                                        style={{ margin: "5px" }}
                                    >
                                        Next
                                    </button>
                                </div>}
                        </div>
                    ) : (
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <h3 className="loader">{t("No data found")}</h3>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
}

export default CustomReportPaginationTable;
