import React from 'react';
import jsPDF from "jspdf";
import "jspdf-autotable";
import "./../../pdf/pdf.css"
import { useTranslation } from 'react-i18next';

// Create Document Component
const ChargerPdf = (props) => {
    const { t } = useTranslation();
    // console.log("props data ", props);

    const generatePDF = (props) => {

        const { role, organisation } = JSON.parse(localStorage.getItem('user'));
        const { status, data } = props;
        // console.log("status and data", status, data);
        const unit = "pt";
        const size = "A4"; // Use A1, A2, A3 or A4
        const orientation = "landscape"; // portrait or landscape
        const marginLeft = 40;
        const marginTop = 40;
        const doc = new jsPDF(orientation, unit, size);
        doc.setFontSize(18);

        const title = "Organisation: " + organisation;
        const headers = [["EVSE ID", "Station Name", "EVSE Status", "Address", "City", "State"]];

        const body_data = data?.flatMap(station =>
            station?.evses?.map((evse) => {
                if (status === evse.status) {
                    return [
                        evse.evse_id,
                        station.name,
                        evse.status,
                        station.address,
                        station.city,
                        station.state,
                    ];
                } else if (status === "All") {
                    return [
                        evse.evse_id,
                        station.name,
                        evse.status,
                        station.address,
                        station.city,
                        station.state,
                    ];
                }
                return null;
            })
        ).filter(row => row !== null);

        // console.log("body_data", body_data);

        let content = {
            startY: 100,
            head: headers,
            body: body_data
        };

        doc.text(title, marginLeft, 40);
        doc.autoTable(content);
        doc.save(`Stations_${new Date().toISOString().substr(0, 10)}.pdf`);
    }

    const handleButtonClick = async () => {
        generatePDF(props);
        // props.onClick();
    };

    return (
        <div>
            <button className='pdfbutton' onClick={handleButtonClick} disabled={props.data === null} type="button">{t("Download PDF")}</button>
        </div>
    )
}

export default ChargerPdf;
