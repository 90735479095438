import { FETCH_VEHICLE_DATA, FLEET_URL, FETCH_LOCATION_API, FETCH_VEHICLE_LOCATION_API, FETCH_HEAVY_VEHICLE_API, FETCH_LIGHT_VEHICLE_API, SAVE_FLEET_VEHICLE_API, SAVE_FLEET_OWNER_API, FETCH_FLEET_VEHICLEBYOPERATOR_API, CHARGING_SERVICE_URL, FETCH_FLEET_LIVE_CHARGING, FETCH_ALL_FLEET_NAME, FETCH_FLEET_INSIGHTS, FETCH_FLEET_CHARGING, FETCH_FLEET_REPORT, FETCH_FLEET_CONSUMPTION_GRAPH, FETCH_FLEET_REVENUE_GRAPH, FETCH_FLEET_NAME, FETCH_VIN_NO_API, FETCH_FLEET_DATA, FETCH_VIN_DATA, SAVE_VEHICLE_CHARGING_PRIORITY_API } from "../../components/config/config";

export const allFleetLocationAction = () => {
	const { token } = JSON.parse(localStorage.getItem('user'));
	const bearer = 'basic ' + token
	return dispatch => {
		dispatch({ type: 'LOAD_FLEET', payload: '' })
		return fetch(FLEET_URL + FETCH_LOCATION_API, {
			method: "GET",
			headers: {
				'Authorization': bearer,
				'Content-Type': 'application/json',
				Accept: 'application/json',
			}
		}).then(resp => resp.json()).then(data => {
			dispatch({ type: 'LOAD_FLEET_LOCATION_LIST', payload: data })
		})
	}
}

export const getVehicleLocationAction = (id) => {
	const { token } = JSON.parse(localStorage.getItem('user'));
	const bearer = 'basic ' + token
	return dispatch => {
		dispatch({ type: 'LOAD_FLEET', payload: '' })
		return fetch(FLEET_URL + FETCH_VEHICLE_LOCATION_API + "?lastid=" + id, {
			method: "GET",
			headers: {
				'Authorization': bearer,
				'Content-Type': 'application/json',
				Accept: 'application/json',
			}
		}).then(resp => resp.json()).then(data => {
			dispatch({ type: 'LOAD_VEHICLE_LOCATION_DATA', payload: data })
		})
	}
}

export const getHeavyVehicleAction = (id) => {
	const { token } = JSON.parse(localStorage.getItem('user'));
	const bearer = 'basic ' + token
	return dispatch => {
		dispatch({ type: 'LOAD_FLEET', payload: '' })
		return fetch(FLEET_URL + FETCH_HEAVY_VEHICLE_API, {
			method: "GET",
			headers: {
				'Authorization': bearer,
				'Content-Type': 'application/json',
				Accept: 'application/json',
			}
		}).then(resp => resp.json()).then(data => {
			dispatch({ type: 'LOAD_HEAVY_VEHICLE_DATA', payload: data })
		})
	}
}

export const getLightVehicleAction = (id) => {
	const { token } = JSON.parse(localStorage.getItem('user'));
	const bearer = 'basic ' + token
	return dispatch => {
		dispatch({ type: 'LOAD_FLEET', payload: '' })
		return fetch(FLEET_URL + FETCH_LIGHT_VEHICLE_API, {
			method: "GET",
			headers: {
				'Authorization': bearer,
				'Content-Type': 'application/json',
				Accept: 'application/json',
			}
		}).then(resp => resp.json()).then(data => {
			dispatch({ type: 'LOAD_LIGHT_VEHICLE_DATA', payload: data })
		})
	}
}

export const saveVehicleChargingPriorityApi = (data) => {
	const { vin, charging_priority } = data;
	const { token, organisation, role } = JSON.parse(localStorage.getItem('user'));
	const bearer = 'basic ' + token
	return dispatch => {
		dispatch({ type: 'LOAD_FLEET', payload: '' })
		return fetch(FLEET_URL + SAVE_VEHICLE_CHARGING_PRIORITY_API, {
			method: "POST",
			headers: {
				'Authorization': bearer,
				'Content-Type': 'application/json',
				Accept: 'application/json',
			},
			body: JSON.stringify({
				"vin": vin,
				'charging_priority': Number(charging_priority),
				"organisation": organisation,
				"role": role,
			})
		}).then(resp => resp.json()).then(data => {
			// console.log(data)
			dispatch({ type: 'SAVE_VEHICLE_CHARGING_PRIORITY_API', payload: data })
		})
	}
}

export const createFleetOwnerAction = (data) => {
	const { ownername, organisations } = data;
	// console.log(ownername);
	const { token, role } = JSON.parse(localStorage.getItem('user'));
	// console.log(status);
	const bearer = 'basic ' + token
	return dispatch => {
		dispatch({ type: 'LOAD_FLEET', payload: '' })
		return fetch(FLEET_URL + SAVE_FLEET_OWNER_API, {
			method: "POST",
			headers: {
				'Authorization': bearer,
				'Content-Type': 'application/json',
				Accept: 'application/json',
			},
			body: JSON.stringify({
				"ownername": ownername,
				"role": role,
				"organisation": organisations,
				// "status": status,

			})
		}).then(resp => resp.json()).then(data => {
			// console.log(data)
			dispatch({ type: 'SAVE_FLEET_OWNER', payload: data })
		})
	}
}

export const fetchFleetVehiclebyOperator = () => {
	const { token, organisation, role } = JSON.parse(localStorage.getItem('user'));
	const bearer = 'basic ' + token
	return dispatch => {
		dispatch({ type: 'LOAD_FLEET', payload: '' })
		return fetch(FLEET_URL + FETCH_FLEET_VEHICLEBYOPERATOR_API + "?role=" + role + "&organisation=" + organisation, {
			method: "GET",
			headers: {
				'Authorization': bearer,
				'Content-Type': 'application/json',
				Accept: 'application/json',
			}
		}).then(resp => resp.json()).then(data => {
			// console.log(data)
			dispatch({ type: 'FETCH_VEHICLE_BY_OPERATOR', payload: data })
		})
	}
}

export const fetchFleetLiveCharging = () => {
	const { token, organisation, role } = JSON.parse(localStorage.getItem('user'));
	const operator = role === 'Admin' ? 'All' : organisation;
	const bearer = 'basic ' + token
	return dispatch => {
		dispatch({ type: 'LOAD_FLEET', payload: '' })
		return fetch(CHARGING_SERVICE_URL + FETCH_FLEET_LIVE_CHARGING + "?operator=" + operator, {
			method: "GET",
			headers: {
				'Authorization': bearer,
				'Content-Type': 'application/json',
				Accept: 'application/json',
			}
		}).then(resp => resp.json()).then(data => {
			// console.log("WEFEWFEWF",data)
			dispatch({ type: 'FETCH_FLEET_LIVE_CHARGING', payload: data })
		})
	}
}

export const getAllFleetName = () => {
	const { token, organisation, role } = JSON.parse(localStorage.getItem('user'));
	const operator = role === 'Admin' ? 'All' : organisation;
	const bearer = 'basic ' + token
	// console.log(FLEET_URL + FETCH_ALL_FLEET_NAME + "?role=" + role + "&operator=" + operator)
	return dispatch => {
		dispatch({ type: 'LOAD_FLEET', payload: '' })
		return fetch(FLEET_URL + FETCH_ALL_FLEET_NAME + "?role=" + role + "&organisation=" + operator, {
			method: "GET",
			headers: {
				'Authorization': bearer,
				'Content-Type': 'application/json',
				Accept: 'application/json',
			}
		}).then(resp => resp.json()).then(data => {
			// console.log("WEFEWFEWF",data)
			dispatch({ type: 'FETCH_ALL_FLEET_NAME', payload: data })
		})
	}
}

export const getFleetInsights = (fleet) => {
	const { token, role, organisation } = JSON.parse(localStorage.getItem('user'));
	const bearer = 'basic ' + token
	return dispatch => {
		dispatch({ type: 'LOAD_FLEET', payload: '' })
		return fetch(FLEET_URL + FETCH_FLEET_INSIGHTS + "?fleet=" + fleet + "&role=" + role + "&organisation=" + organisation, {
			method: "GET",
			headers: {
				'Authorization': bearer,
				'Content-Type': 'application/json',
				Accept: 'application/json',
			}
		}).then(resp => resp.json()).then(data => {
			// console.log("data",data)
			dispatch({ type: 'FETCH_FLEET_INSIGHTS', payload: data })
		})
	}
}

export const fleetLiveCharging = (fleet) => {
	const { token, role, organisation } = JSON.parse(localStorage.getItem('user'));
	const operator = role === 'Admin' ? 'All' : organisation;
	const bearer = 'basic ' + token
	// console.log(FLEET_URL + FETCH_FLEET_CHARGING + "?fleet=" + fleet + "&role=" + role + "&organisation=" + organisation)
	return dispatch => {
		dispatch({ type: 'LOAD_FLEET', payload: '' })
		return fetch(FLEET_URL + FETCH_FLEET_CHARGING + "?operator=" + operator, {
			method: "GET",
			headers: {
				'Authorization': bearer,
				'Content-Type': 'application/json',
				Accept: 'application/json',
			}
		}).then(resp => resp.json()).then(data => {
			// console.log("data for live charging", data)
			dispatch({ type: 'FLEET_LIVE_CHARGING', payload: data })
		})
	}

}


export const getFleetReport = (fleet, start, end) => {
	// console.log(fleet)
	const { token, role, organisation } = JSON.parse(localStorage.getItem('user'));
	const operator = role === 'Admin' ? 'All' : organisation;
	const bearer = 'basic ' + token
	// console.log(FLEET_URL + FETCH_FLEET_REPORT + "?fleet=" + fleet + "&role=" + role + "&organisation=" + organisation + "&start=" + start + "&end=" + end)
	return dispatch => {
		dispatch({ type: 'LOAD_FLEET', payload: '' })
		return fetch(FLEET_URL + FETCH_FLEET_REPORT + "?operator=" + operator + "&start=" + start + "&end=" + end + "&fleet=" + fleet, {
			method: "GET",
			headers: {
				'Authorization': bearer,
				'Content-Type': 'application/json',
				Accept: 'application/json',
			}
		}).then(resp => resp.json()).then(data => {
			// console.log("Report data", data)
			dispatch({ type: 'FETCH_FLEET_REPORT', payload: data })
		})
	}
}


export const getMonthlyConsumptionReport = (fleet, start, end) => {
	const { token, role, organisation } = JSON.parse(localStorage.getItem('user'));
	const operator = role === 'Admin' ? 'All' : organisation;
	const bearer = 'basic ' + token
	// console.log(token)
	// console.log(FLEET_URL + FETCH_FLEET_CONSUMPTION_GRAPH +"?operator=" + operator + "&fleet=" + fleet +"&start=" + start + "&end=" + end)
	return dispatch => {
		dispatch({ type: 'LOAD_FLEET', payload: '' })
		return fetch(FLEET_URL + FETCH_FLEET_CONSUMPTION_GRAPH + "?operator=" + operator + "&fleet=" + fleet + "&start=" + start + "&end=" + end, {
			method: "GET",
			headers: {
				'Authorization': bearer,
				'Content-Type': 'application/json',
				Accept: 'application/json',
			}
		}).then(resp => resp.json()).then(data => {
			// console.log("monthly data", data)
			dispatch({ type: 'FETCH_FLEET_CONSUMPTION_GRAPH', payload: data })
		})
	}
}

export const getMonthlyRevenueReport = (fleet, start, end) => {
	const { token, role, organisation } = JSON.parse(localStorage.getItem('user'));
	const operator = role === 'Admin' ? 'All' : organisation;
	const bearer = 'basic ' + token
	// console.log(FLEET_URL + FETCH_FLEET_REVENUE_GRAPH + "?start=" + start + "&end=" + end + "&fleet=" + fleet + "&organisation=" + organisation + "&role=" + role)
	return dispatch => {
		dispatch({ type: 'LOAD_FLEET', payload: '' })
		return fetch(FLEET_URL + FETCH_FLEET_REVENUE_GRAPH + "?operator=" + operator + "&start=" + start + "&end=" + end + "&fleet=" + fleet, {
			method: "GET",
			headers: {
				'Authorization': bearer,
				'Content-Type': 'application/json',
				Accept: 'application/json',
			}
		}).then(resp => resp.json()).then(data => {
			// console.log("Revenue data", data)
			dispatch({ type: 'FETCH_FLEET_REVENUE_GRAPH', payload: data })
		})
	}
}

export const fetchVehicleData = () => {
	const { token, organisation, role } = JSON.parse(localStorage.getItem('user'));
	// console.log("organisation", organisation)
	const operator = role === 'Admin' ? 'All' : organisation;
	const bearer = 'basic ' + token
	return dispatch => {
		dispatch({ type: 'LOAD_FLEET', payload: '' })
		// console.log(FLEET_URL + FETCH_VEHICLE_DATA)
		return fetch(FLEET_URL + FETCH_VEHICLE_DATA + "?organisation=" + operator, {
			method: "GET",
			headers: {
				'Authorization': bearer,
				'Content-Type': 'application/json',
				Accept: 'application/json',
			}
		}).then(resp => resp.json()).then(data => {
			// console.log("fleet vehicle data",data)
			dispatch({ type: 'FETCH_FLEET_VEHICLE_DATA', payload: data })
		})
	}
}

// New Fleet API integration

export const fetchFleetName = () => {
	const { token, organisation, role } = JSON.parse(localStorage.getItem('user'));
	const operator = role === 'Admin' ? 'All' : organisation;
	const bearer = 'basic ' + token
	return dispatch => {
		dispatch({ type: 'LOAD_FLEET', payload: '' })
		// console.log(FLEET_URL + FETCH_VEHICLE_DATA)
		return fetch(FLEET_URL + FETCH_FLEET_NAME + "?organisation=" + operator, {
			method: "GET",
			headers: {
				'Authorization': bearer,
				'Content-Type': 'application/json',
				Accept: 'application/json',
			}
		}).then(resp => resp.json()).then(data => {
			// console.log("FETCH_FLEET_NAME_API data",data)
			dispatch({ type: 'FETCH_FLEET_NAME_API', payload: data })
		})
	}
}
export const fetchVinNo = (fleet_name) => {
	const { token, organisation, role } = JSON.parse(localStorage.getItem('user'));
	const operator = role === 'Admin' ? 'All' : organisation;
	const bearer = 'basic ' + token
	return dispatch => {
		dispatch({ type: 'LOAD_FLEET', payload: '' })
		// console.log(FLEET_URL + FETCH_VEHICLE_DATA)
		return fetch(FLEET_URL + FETCH_VIN_NO_API + "?fleet=" + fleet_name, {
			method: "GET",
			headers: {
				'Authorization': bearer,
				'Content-Type': 'application/json',
				Accept: 'application/json',
			}
		}).then(resp => resp.json()).then(data => {
			// console.log("FETCH_VIN_NUMBER_API data",data)
			dispatch({ type: 'FETCH_VIN_NUMBER_API', payload: data })
		})
	}
}
export const fetchVinDataApi = (vin) => {
	const { token, organisation, role } = JSON.parse(localStorage.getItem('user'));
	const operator = role === 'Admin' ? 'All' : organisation;
	const bearer = 'basic ' + token
	return dispatch => {
		dispatch({ type: 'LOAD_FLEET', payload: '' })
		// console.log(FLEET_URL + FETCH_VEHICLE_DATA)
		return fetch(FLEET_URL + FETCH_VIN_DATA + "?vin=" + vin, {
			method: "GET",
			headers: {
				'Authorization': bearer,
				'Content-Type': 'application/json',
				Accept: 'application/json',
			}
		}).then(resp => resp.json()).then(data => {
			// console.log("FETCH_VIN_DATA_API data",data)
			dispatch({ type: 'FETCH_VIN_DATA_API', payload: data })
		})
	}
}