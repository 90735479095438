import { Container, Col, Card } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { useParams, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PureBreadcrumbs from "../../breadcrums";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from 'yup';
import { getStatus, saveFeedbackStatusUpdate } from "../../../../store/actions/customerActions";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";
import { t } from "i18next";
import { Bounce, ToastContainer, toast } from "react-toastify";


function SingleAdminStatus(props) {
    const { id } = useParams()
    const [redirect, setRedirect] = useState(false);
    const [isToastVisible, setIsToastVisible] = useState(false);

    // console.log(id)

    useEffect(() => {
        props.fetchAdminStatus(id);
    }, []);

    const AdminFeedback = Yup.object().shape({
        adminStatus: Yup.string().required('Status is required'),
        adminFeedback: Yup.string().required('feedback  is required'),
        extraId: Yup.string()
    });

    const { register, handleSubmit, setValue, formState: { errors } } = useForm({ resolver: yupResolver(AdminFeedback), mode: "all" });
    const data = props.prevStatus && props.prevStatus.data
    // console.log("data", data)

    useEffect(() => {
        const { admin_comment, status } = data
        if (data) {
            setTimeout(() => {
                setValue("adminStatus", status, { shouldValidate: true });
                setValue("adminFeedback", admin_comment);
                setValue("extraId", id)
            }, 1500);
        }
    }, [data])

    const onSubmit = async (data) => {
        props.saveStatus(data);
    };

    useEffect(() => {
        if (props.updateStatus.statuscode === 200) {
            setIsToastVisible(true);
            toast.success('Admin Status Updated Successfully', {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
                onClose: () => {
                    setIsToastVisible(false);
                    window.location.reload();
                },
            });
        }
    }, [props.updateStatus]);

    if (redirect) {
        return <Redirect to='/dashboard/accountcard' />;
    }


    return (

        <Container fluid className="pb-5">
            {isToastVisible && <div className="overlay" />}
            <ToastContainer
                position="top-center"
                autoClose={20}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
                style={{ top: "10%", width: "auto", zIndex: "9999" }}
            />
            <PureBreadcrumbs />
            <div className="row">
                <div className="col pad_t2">
                    <span style={{ color: "var(--text-primary)", fontWeight: "bold" }}> {t("Admin Status Approval")} </span>
                </div>
            </div>
            <Card className="tariffcard mt-4">
                <Card.Body>
                    <form autoComplete="off" className="p-3 mt-2" onSubmit={(e) => e.preventDefault()}>
                        <div className="row">
                            <div className="col-lg-6">
                                <label htmlFor="floatingInputGridName">{t("Username")}</label>
                                <input className="form-control gray-border" type="text" name="UserID" id="floatingInputGridName" placeholder={t("Username")} defaultValue={data.userid} disabled />
                            </div>
                            <div className="col-lg-6">
                                <label htmlFor="floatingInputGridRating">{t("Rating in")} (*)</label>
                                <input className="form-control gray-border" type="text" name="Rating" id="floatingInputGridRating" placeholder={t("Rating in")} defaultValue={data.rating} disabled />
                            </div>

                        </div>

                        <div className="row mt-2">

                            <div className="col-lg-3">
                                <label htmlFor="floatingInputGridOrg">{t("Organisation")}</label>
                                <input className="form-control gray-border" type="text" name="Org" id="floatingInputGridOrg" placeholder={t("Organisation")} defaultValue={data.operator} disabled />
                            </div>
                            <div className="col-lg-3">
                                <label htmlFor="floatingInputGridStationName">{t("Station Name")}</label>
                                <input className="form-control gray-border" type="text" name="StationName" id="floatingInputGridStationName" placeholder={t("Station Name")} defaultValue={data.stationid} disabled />
                            </div>
                            <div className="col-lg-3">
                                <label htmlFor="floatingInputGridUserComment">{t("User Comment")}</label>
                                <input className="form-control gray-border" type="text" name="comment" id="floatingInputGridUserComment" placeholder="User Comment" defaultValue={data.comment} disabled />
                            </div>
                            <div className="col-lg-3">
                                <label>{t("Select Status")} <span className='mandatory-field'>*</span></label>
                                <select
                                    className={`form-select form-control ${errors.adminStatus ? 'is-invalid' : ''}`}
                                    name={"adminStatus"}
                                    id={"floatingSelectGridAdminStatus"}
                                    {...register("adminStatus", { required: true })}
                                >
                                    <option value={""}>Select Status</option>
                                    <option value={"Open"}>Open</option>
                                    <option value={"In-review"}>In-Review</option>
                                    <option value={"Closed"}>In-Corporated / Closed</option>
                                </select>
                                <div className="invalid-feedback">{errors.adminStatus?.message}</div>
                            </div>
                        </div>

                        <div className="row mt-2">
                            <div className="col-lg-12 mt-2">

                                <div className="form-floating">
                                    <label htmlFor="floatingInputAdminFeedback">{t("Admin Feedback Comment")} <span className='mandatory-field'>*</span></label>
                                    <textarea
                                        type="text"
                                        autoFocus
                                        className={`form-control gray-border ${errors.adminFeedback ? 'is-invalid' : ''}`}
                                        name="adminFeedback"
                                        id="floatingInputAdminFeedback"
                                        placeholder="Enter Your Comment... "
                                        {...register("adminFeedback", { required: true })}
                                    // style={{height:"50px"}}
                                    />
                                    <div className="invalid-feedback">{errors.adminFeedback?.message}</div>
                                </div>
                            </div>
                        </div>

                        <input
                            type="hidden"
                            name="extraId"
                            {...register("extraId")}
                            value={id}
                        />

                        <div >
                            <Col lg={12} className="d-flex justify-content-end mt-4 mb-4" >
                                {props.loading ?
                                    <button className="btn lgn-btn" type="button" disabled>
                                        <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                                        &nbsp;
                                        {t("Saving...")}
                                    </button>
                                    : <button type="button" className="lgn-btn btn" onClick={handleSubmit(onSubmit)}> {t("Save")}</button>
                                }
                            </Col>
                        </div>
                    </form>
                </Card.Body>
            </Card>
        </Container>
    )
}


const mapStateToProps = (state) => {
    return {
        loading: state.customer.isLoading,
        updateStatus: state.customer.updateAdminStatus,
        prevStatus: state.customer.getAdminStatus,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        saveStatus: (data) => dispatch(saveFeedbackStatusUpdate(data)),
        fetchAdminStatus: (adminID) => dispatch(getStatus(adminID)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SingleAdminStatus));