import { Container, Row, Col, Card } from "react-bootstrap";
import React, { useEffect, useMemo } from "react"
import { useTranslation } from "react-i18next";
import PureBreadcrumbs from "../breadcrums";
import { connect } from "react-redux";
import { deleteSingleConnectAccount, fetchAllConnectAccountList } from "../../../store/actions/stripeAction";
import Table from "../reacttable/table";
import "../reacttable/reactTable.css"
import { TiTick } from "react-icons/ti";
import { ImCross } from "react-icons/im";
import { FaEye } from "react-icons/fa";
import { MdDelete } from "react-icons/md";

function ViewBankList(props) {
    const { t } = useTranslation();
    useEffect(() => {
        props.fetchAllConnectAccount();
    }, []);

    const data = props.getAllConnectAccount && props.getAllConnectAccount.data
    const totalAccount = data && data.length
    const payoutENabled = data && data.filter(active => active.payouts_enabled === true).length
    const payoutDisabled = data && data.filter(active => active.payouts_enabled === false).length

    const handleDelete = (id) => {
        const isConfirm = window.confirm('This will permanently delete the stripe account. Do you wish to continue ?');
        if (isConfirm) {
            props.deleteSingleAccount(id)
        } else {
            return;
        }
    }
    if (props.deleteConnectAccount.statuscode === 200) {
        window.location.reload();
    }

    const columns = useMemo(
        () => [
            {
                Header: 'Account ID',
                id: "ids",
                accessor: 'id'
            },
            {
                Header: 'Operator',
                id: "Operator",
                accessor: d => {
                    return d.business_profile.name
                }
            },
            {
                Header: 'Email',
                accessor: 'email'
            },
            {
                Header: 'Payout Enabled',
                id: "payouts_enabled",
                accessor: d => {
                    return d.payouts_enabled ? <span className="text-success"><TiTick /></span> : <span className="text-danger"><ImCross /></span>
                }
            },
            {
                Header: 'Charges Enabled',
                id: "charges_enabled",
                accessor: d => {
                    return d.charges_enabled ? <span className="text-success"><TiTick /></span> : <span className="text-danger"><ImCross /></span>
                }
            },

            {
                Header: 'Country',
                accessor: 'country'
            },
            {
                Header: 'Account Status',
                id: "status",
                accessor: d => {
                    return d.payouts_enabled && d.charges_enabled ? <span className="text-success">   <button className="btn btn-sm btn btn-success" type="button">
                        {t("Completed")} &nbsp;&nbsp;<TiTick />
                    </button></span> : <span className="text-danger"><button className="btn  btn-sm btn-outline-danger" type="button">
                        {t("Restricted")} &nbsp;&nbsp;<ImCross />
                    </button></span>
                }
            },
            {
                Header: 'Actions',
                accessor: 'id',
                Cell: (id) => {
                    return (
                        <>
                            <div>
                                <span>
                                    <a href={`/dashboard/view_back_details/viewaccountifo/${id.value}`}
                                        rel="tooltip"
                                        title="View"
                                    >
                                        <FaEye />
                                    </a>
                                </span>&nbsp;&nbsp;
                                <span
                                    onClick={() => handleDelete(id.value)}
                                    style={{ color: "red", cursor: "pointer" }}
                                    rel="tooltip"
                                >

                                    <MdDelete style={{ fontSize: "1.5rem" }} />
                                </span>
                            </div>
                        </>
                    );
                },
            }
        ],
        []
    );
    return (
        <Container fluid>
            <PureBreadcrumbs />
            <br />
            <Row>
                <Col xs={12} md={4}>
                    <Card className="customercard">
                        <div style={{ textAlign: "left" }}>
                            <Card.Text style={{ fontSize: "14px" }}>{t("ALL ACCOUNT")}</Card.Text>
                            <Card.Title>
                                <b>{totalAccount != undefined ? totalAccount : 0}</b>
                            </Card.Title>
                            <span
                                style={{
                                    float: "right",
                                    marginTop: "-60px",
                                    marginRight: "-8px",
                                }}
                            >
                                <div >
                                    <img
                                        src={
                                            process.env.PUBLIC_URL +
                                            "/images/finance/allacount.svg"
                                        }
                                        alt="evse"
                                    />
                                </div>
                            </span>
                        </div>
                    </Card>
                    <br />
                </Col>
                <Col xs={12} md={4}>
                    <Card className="customercard">
                        <div style={{ textAlign: "left" }}>
                            <Card.Text style={{ fontSize: "14px" }}>{t("RESTRICTED ACCOUNT")}</Card.Text>
                            <Card.Title>
                                <b>{payoutDisabled != undefined ? payoutDisabled : 0}</b>
                            </Card.Title>
                            <span
                                style={{
                                    float: "right",
                                    marginTop: "-60px",
                                    marginRight: "-8px",
                                }}
                            >
                                <div style={{ width: "20%" }}>
                                    <img
                                        src={
                                            process.env.PUBLIC_URL +
                                            "/images/finance/restriced_account.svg"
                                        }
                                        alt="evse"
                                    />
                                </div>
                            </span>
                        </div>
                    </Card>
                    <br />
                </Col>
                <Col xs={12} md={4}>
                    <Card className="customercard">
                        <div style={{ textAlign: "left" }}>
                            <Card.Text style={{ fontSize: "14px" }}>{t("COMPLETED ACCOUNT")}</Card.Text>
                            <Card.Title>
                                <b>{payoutENabled != undefined ? payoutENabled : 0}</b>

                            </Card.Title>

                            <span
                                style={{
                                    float: "right",
                                    marginTop: "-60px",
                                    marginRight: "-8px",
                                }}
                            >
                                <div style={{ width: "20%" }}>
                                    <img
                                        src={
                                            process.env.PUBLIC_URL +
                                            "/images/finance/completed.svg"
                                        }
                                        alt="evse"
                                    />
                                </div>
                            </span>
                        </div>
                    </Card>
                    <br />
                </Col>
            </Row>
            <Row className="mt-4">
                <Col>
                    <Card className="customercard">
                        <div className="table-header">{t("Bank Details List")}</div>
                        <div>
                            {props.loading ? <h3 style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                height: "550px",
                            }}>Loading data...</h3> :
                                data && data.length > 0 ?
                                    (<div> <Table data={data} columns={columns}></Table></div>)
                                    :
                                    (<div
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            height: "550px",
                                        }}
                                    >
                                        <h3>{t("No data found")}</h3>
                                    </div>)
                            }
                        </div>
                    </Card>
                </Col>
            </Row>
            <br />
        </Container>
    )
}


const mapStateToProps = (state) => {
    return {
        getAllConnectAccount: state.stripe.getAllConnectAcount,
        deleteConnectAccount: state.stripe.deleteConnectAccount

    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        fetchAllConnectAccount: () => dispatch(fetchAllConnectAccountList()),
        deleteSingleAccount: (id) => dispatch(deleteSingleConnectAccount(id))
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(ViewBankList);
