import { Container, Row, Col, Card } from "react-bootstrap";
import React, { useEffect } from "react";
import { useParams, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PureBreadcrumbs from "../../breadcrums";
import { singleRoamingPartner } from "../../../../store/actions/ocpiAction";

function ViewSinglePartner(props) {
    const { id } = useParams()
    useEffect(() => {
        props.getSinglerData(id);
    }, []);
    const data = props.fetchSingleDetails && props.fetchSingleDetails

    return (
        <Container fluid>
            <PureBreadcrumbs />
            <Row>
                <Col className="mt-4">
                    <span style={{ color: "var(--text-primary)", fontWeight: "bold" }}> Partner Details </span>
                </Col>
            </Row>

            <Row className="mt-3">
                <Col>
                    <Card className="tariffcard">
                        <Card.Body>
                            <Row>
                                <Col xs={12} md={6}>
                                    <div>
                                        <label htmlFor="floatingInputGridName">Partner Name</label>
                                        <input className="input gray-border" type="text" name="partner_name" id="floatingInputGridName" placeholder="Firstname" defaultValue={data.partner_name} disabled />
                                    </div>
                                </Col>
                                <Col xs={12} md={6}>
                                    <div>
                                        <label htmlFor="floatingInputGridName">Party ID</label>
                                        <input className="input gray-border" type="text" name="party_id" id="floatingInputGridparty_id" placeholder="Party ID" defaultValue={data.party_id} disabled />
                                    </div>
                                </Col>
                            </Row>
                            <Row className="mt-4">
                                <Col xs={12} md={6}>
                                    <div>
                                        <label htmlFor="floatingInputGridName">Country Code</label>
                                        <input className="input gray-border" type="text" name="country_code" id="floatingInputGridCountry" placeholder="Country Code" defaultValue={data.country_code} disabled />
                                    </div>
                                </Col>
                                <Col xs={12} md={6}>
                                    <div>
                                        <label htmlFor="floatingInputGridName">Country</label>
                                        <input className="input gray-border" type="text" name="country" id="floatingInputGridCountry" placeholder="Country" defaultValue={data.country} disabled />
                                    </div>
                                </Col>
                            </Row>
                            <Row className="mt-4">
                                <Col xs={12} md={6}>
                                    <div>
                                        <label htmlFor="floatingInputGridName">State</label>
                                        <input className="input gray-border" type="text" name="state" id="floatingInputGridState" placeholder="State" defaultValue={data.state} disabled />
                                    </div>
                                </Col>
                                <Col xs={12} md={6}>
                                    <div>
                                        <label htmlFor="floatingInputGridName">City</label>
                                        <input className="input gray-border" type="text" name="city" id="floatingInputGridCity" placeholder="City" defaultValue={data.city} disabled />
                                    </div>
                                </Col>
                            </Row>
                            <Row className="mt-4">
                                <Col xs={12} md={12}>
                                    <div>
                                        <label htmlFor="floatingInputGridName">Address</label>
                                        <input className="input gray-border" type="text" name="address" id="floatingInputGridAddress" placeholder="Address" defaultValue={data.address} disabled />
                                    </div>
                                </Col>
                            </Row>

                            <Row className="mt-4">
                                <Col xs={12} md={12}>
                                    <div>
                                        <label htmlFor="floatingInputGridName">URL</label>
                                        <input className="input gray-border" type="text" name="url" id="floatingInputGridurl" placeholder="URL" defaultValue={data.url} disabled />
                                    </div>
                                </Col>
                            </Row>
                            <Row className="mt-4">
                                <Col xs={12} md={12}>
                                    <div>
                                        <label htmlFor="floatingInputGridName">Token</label>
                                        <input className="input gray-border" type="text" name="tokenA" id="floatingInputGridtoken" placeholder="Token" defaultValue={data.tokenA} disabled />
                                    </div>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>

    )

}


const mapStateToProps = function (state) {
    return {
        fetchSingleDetails: state.ocpi.fetchsinglepartner,
    }
}
const mapDispatchToProps = dispatch => {
    return {
        getSinglerData: (id) => dispatch(singleRoamingPartner(id)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ViewSinglePartner));