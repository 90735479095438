import React, { useState } from "react";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Row, Col, Container, Card } from "react-bootstrap";
import PureBreadcrumbs from "../../breadcrums";
import * as Yup from "yup";
import { connect } from "react-redux";
import { fetchFleetName, fetchVinDataApi, fetchVinNo, saveVehicleChargingPriorityApi } from "../../../../store/actions/fleetAction";
import { useTranslation } from "react-i18next";
import { Bounce, ToastContainer, toast } from "react-toastify";

function SaveFleetVehicle(props) {
  const { t } = useTranslation;
  const [isToastVisible, setIsToastVisible] = useState(false);

  useEffect(() => {
    props.allFleetName();
  }, []);

  // const handleManufactureChange = (e) => {
  //   const value = e.target.value;
  //   // console.log("manufacture value",);
  // };
  const handleFleetChange = (e) => {
    const value = e.target.value;
    // console.log("fleet name", value);
    props.allVin(value)

  };
  const handleVinChange = (e) => {
    const value = e.target.value;
    // console.log("vin no. ", value);
    props.getVinData(value)
  };

  const data = props.fetchVinDataRes && props.fetchVinDataRes.data;
  // console.log(data[0]);
  const vehicleSchema = Yup.object().shape({
    vin: Yup.string().required("VIN number is required"),
    fleet_name: Yup.string().required("Fleet name is required"),
    charging_priority: Yup.number().required("Charging Priority is required").typeError('Charging Priority is required'),
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({ resolver: yupResolver(vehicleSchema) });

  const onSubmit = async (data) => {
    // console.log(data);
    props.savePriority(data);
  };

  // if (props.save.statuscode === 200) {
  //   alert("Charging priority has been updated");
  //   window.location.reload();
  // }

  useEffect(() => {
    if (props.save.statuscode === 200) {
      setIsToastVisible(true);
      toast.success('Charging priority has been updated', {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
        onClose: () => {
          setIsToastVisible(false);
          window.location.reload();
        },
      });
    } else if (props.save.statuscode === 405) {
      setIsToastVisible(true);
      toast.error(`❌ ${props.save.error}`, {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
        onClose: () => {
          setIsToastVisible(false);
          window.location.reload();
        },
      });
    }
  }, [props.save]);

  return (
    <Container fluid className="pb-4">
      {isToastVisible && <div className="overlay" />}
      <ToastContainer
        position="top-center"
        autoClose={20}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        style={{ top: "10%", width: "auto", zIndex: "9999" }}
      />
      <PureBreadcrumbs />
      <br />
      <Row>
        <Col xs={12} md={1}></Col>
        <Col xs={12} md={10}>
          <br />
          <Card className="tariffcard">
            <Card.Text style={{ fontSize: "14px", padding: "10px", paddingLeft: "15px" }}>
              <b style={{ fontSize: "1.5rem" }}>Add Vehicle Charging Priority</b>
            </Card.Text>
            <Card.Body>
              <form autoComplete="off" onSubmit={(e) => e.preventDefault()}>
                <div className="form-floating">
                  <label>Fleet Name  <span className='mandatory-field'>*</span></label>
                  <select
                    autoFocus
                    className={`form-select form-control ${errors.fleet_name ? "is-invalid" : ""}`}
                    name="fleet_name"
                    id="floatingSelectGridfleet_name"
                    {...register("fleet_name")}
                    onChange={(e) => handleFleetChange(e)}
                  >
                    <option value="">Select Fleet Name</option>
                    {props.fleetNameRes &&
                      props.fleetNameRes.data?.map((v, k) => (
                        <option key={k} value={v.fleet}>
                          {v.fleet}
                        </option>
                      ))}
                  </select>
                  <div className="invalid-feedback">
                    {errors.fleet_name?.message}
                  </div>
                </div>
                <br />
                <div className="form-floating">
                  <label>VIN No. <span className='mandatory-field'>*</span></label>
                  <select

                    className={`form-select form-control ${errors.vin ? "is-invalid" : ""}`}
                    name="vin"
                    id="floatingSelectGridvin"
                    {...register("vin")}
                    onChange={(e) => handleVinChange(e)}
                  >
                    <option value="">Select Fleet Name</option>
                    {props.fetchVinRes && props.fetchVinRes.data?.map((v, k) => (
                      <option key={v.vin} value={v.vin}>
                        {v.vin}
                      </option>
                    ))}
                  </select>
                  <div className="invalid-feedback">{errors.vin?.message}</div>
                </div>
                <br />
                <div className="form-floating">
                  <label>Manufacturer Name  <span className='mandatory-field'>*</span></label>
                  <select
                    disabled
                    className={`form-select form-control`}
                    name="manufacturer_name"
                    id="floatingSelectGridmanufacturer_name"
                    // onChange={(e) => handleManufactureChange(e)}
                    // {...register("manufacturer_name")}
                    value={data[0]?.manufacturer_name || "Not available"}
                  >
                    <option value={data[0]?.manufacturer_name}>{data[0]?.manufacturer_name ? data[0]?.manufacturer_name : "Not Available"}</option>
                  </select>
                </div>
                <br />
                <div className="form-floating">
                  <label>Vehicle Model  <span className='mandatory-field'>*</span></label>
                  <select
                    className={`form-select form-control`}
                    name="v_model"
                    id="floatingSelectGridv_model"
                    // {...register("v_model")}
                    disabled
                    value={data[0]?.v_model || "Not available"}
                  >
                    <option value={data[0]?.v_model}>{data[0]?.v_model ? data[0]?.v_model : "No Model is available"}</option>
                  </select>
                </div>
                <br />
                <Row>
                  <Col className="text-center">
                    Vehicle Registration Date :
                  </Col>
                  <Col>
                    <input
                      className="gray-border"
                      value={data[0]?.registration_date || "Not available"}
                      placeholder="Not available"
                      disabled
                    />
                  </Col>
                </Row>
                <br />
                <br />
                <div className="form-floating">
                  <label>Vehicle Type  <span className='mandatory-field'>*</span></label>
                  <input
                    disabled
                    type="text"
                    className={`form-control gray-border `}
                    name="v_type"
                    id="floatingInputGridv_type"
                    placeholder="Enter Vehicle Type"
                    // {...register("v_type")}
                    value={data[0]?.v_type ? data[0].v_type : "Not available"}
                  />
                </div>
                <br />
                <div className="form-floating">
                  <label>Charging Priority <span className='mandatory-field'>*</span></label>
                  <input
                    type="number"
                    className={`form-control gray-border ${errors.charging_priority ? "is-invalid" : ""
                      }`}
                    name="charging_priority"
                    id="floatingInputGridcharging_priority"
                    placeholder="Vehicle Charging Priority"
                    {...register("charging_priority")}
                  />
                  <div className="invalid-feedback">
                    {errors.charging_priority?.message}
                  </div>
                </div>
                <br />
                <br />
                <div>
                  <Row className="d-flex justify-content-between">
                    <Col lg={5} md={3} sm={4} xs={12} className="mt-2">
                      <button
                        className="lgn-btn btn"
                        type="button"
                        onClick={() => reset()}
                      >
                        Reset
                      </button>
                    </Col>
                    <br />
                    <Col lg={5} md={3} sm={4} xs={12} className="d-md-flex justify-content-end mt-2">
                      {props.loading ?
                        <button className="btn lgn-btn" type="button" disabled>
                          <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                          &nbsp;
                          {t("Saving...")}
                        </button>
                        :
                        <button
                          className="lgn-btn btn"
                          type="submit"
                          onClick={handleSubmit(onSubmit)}
                        >
                          SUBMIT
                        </button>
                      }
                    </Col>
                  </Row>
                </div>
                <br />
              </form>
            </Card.Body>
            <br /> <br />
          </Card>
        </Col>
        <Col xs={12} md={1}></Col>
      </Row>
    </Container>
  );
}

const mapStateToProps = (state) => {
  return {
    loading: state.fleet.isLoading,
    fleetNameRes: state.fleet.fetchFleetName,
    fetchVinRes: state.fleet.fetchVinNumber,
    fetchVinDataRes: state.fleet.fetchVinData,
    save: state.fleet.save_vehicle_charging_priority,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    allFleetName: () => dispatch(fetchFleetName()),
    allVin: (fleet_name) => dispatch(fetchVinNo(fleet_name)),
    getVinData: (vin) => dispatch(fetchVinDataApi(vin)),
    savePriority: (data) => dispatch(saveVehicleChargingPriorityApi(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(SaveFleetVehicle);
