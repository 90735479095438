import React from 'react'
import { Card, Row, Col, Container } from "react-bootstrap";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useEffect, useState } from "react";
import { allOrgTariffAction, saveCorporateCode } from "../../../../store/actions/organisationAction";
import PureBreadcrumbs from "../../breadcrums";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../organisation.css"
import { fetchActiveTariffList, fetchAllTimeSlotAction } from "../../../../store/actions/tariffAction";
import { useTranslation } from 'react-i18next';
import { Bounce, ToastContainer, toast } from 'react-toastify';


const CreateCorporateCode = (props) => {
    const { t } = useTranslation();
    const [amount, setAmount] = useState('')
    const [unit, setUnit] = useState("")
    const [isToastVisible, setIsToastVisible] = useState(false);

    useEffect(() => {
        props.getActiveTariffList()
        props.allOrg()
    }, []);

    const data = props.organisationlist && props.organisationlist.organisations;

    const promotionSchema = Yup.object().shape({
        corporate_code: Yup.string().required('Corporate Code is required'),
        code_name: Yup.string().required('Corporate code name is required'),
        tariff: Yup.string().required('Tariff is required'),
        assignedoperator: Yup.string().required('Organisation is required'),

    });
  
    const [startdate, setstartdate] = useState(new Date(Date.now() + (3600 * 1000 * 1)));
    const [enddate, setenddate] = useState(new Date(Date.now() + (3600 * 1000 * 720)));

    const { register, handleSubmit, reset, formState: { errors } } = useForm({ resolver: yupResolver(promotionSchema) });

    // const handleOnchange = () => {
    //     const singleValue = getValues("tariff");
    //     const result = props.fetchTariffList && props.fetchTariffList.data.filter((tr => tr.name == singleValue));
    //     console.log(result);
    //     if (singleValue != "" && result != []) {
    //         setAmount(result[0].amount)
    //         setUnit(result[0].unit)
    //     } else {
    //         setAmount(0)
    //         setUnit("")
    //     }
    // }

    const onSubmit = async (data) => {
        props.saveCorporatecode(data, startdate, enddate, amount, unit)
        // console.log("data saved successfully",data,startdate, enddate);

    };

    useEffect(() => {
        if (props.savecorporate.statuscode === 201) {
            setIsToastVisible(true);
            toast.success('New Corporate Code has been created', {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
                onClose: () => {
                    setIsToastVisible(false);
                    window.location.reload();
                },
            });
        } else if (props.savecorporate.statuscode === 405) {
            setIsToastVisible(true);
            toast.error(`❌ ${props.savecorporate.error}`, {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
                onClose: () => {
                    setIsToastVisible(false);
                    window.location.reload();
                },
            });
        }
    }, [props.savecorporate]);

    // if (props.savecorporate.statuscode === 201) {
    //     alert("New Corporate Code has been created")
    //     window.location.reload();
    // }

    return (
        <Container fluid className="pb-4">
            {isToastVisible && <div className="overlay" />}
            <ToastContainer
                position="top-center"
                autoClose={20}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
                style={{ top: "10%", width: "auto", zIndex: "9999" }}
            />
            <PureBreadcrumbs />
            <br />
            <Row>
                <Col xs={12} md={1}>
                </Col>
                <Col xs={12} md={10}>
                    <Card className="tariffcard">
                        <Card.Text style={{ fontSize: "14px", padding: "10px", paddingLeft: "15px" }}><b>{t("Add New Corporate Code")}</b></Card.Text>
                        <Card.Body>
                            <form autoComplete='off' onSubmit={e => e.preventDefault()}>
                                <Row>
                                    <Col lg={6} >
                                        <div className="form-floating">
                                            <label>{t("Enter the Corporate Code")}  <span className='mandatory-field'>*</span></label>
                                            <input type="text" autoFocus className={`form-control gray-border ${errors.corporate_code ? 'is-invalid' : ''}`} name="corporate_code" id="floatingInputGridcorporate_code" placeholder={t("Enter the Corporate Code")} {...register("corporate_code")} />
                                            <div className="invalid-feedback">{errors.corporate_code?.message}</div>
                                        </div>
                                    </Col>

                                    <Col lg={6}>
                                        <div className="form-floating">
                                            <label>{t("Enter specific corporate code name")}  <span className='mandatory-field'>*</span></label>
                                            <input type="text" className={`form-control gray-border ${errors.code_name ? 'is-invalid' : ''}`} name="code_name" id="floatingInputGridnumber" placeholder={t("Enter specific corporate code name")} {...register("code_name")} />
                                            <div className="invalid-feedback">{errors.code_name?.message}</div>
                                        </div>
                                    </Col>
                                </Row>

                                <Row className="mt-4">
                                    <Col className="text-center">
                                        {t("Select Corporate code Start Date")} :
                                    </Col>
                                    <Col>
                                        <DatePicker
                                        className='gray-border'
                                            selected={startdate}
                                            name="start"
                                            onChange={(date) => setstartdate(date)}
                                            selectsStart
                                            startdate={startdate}
                                            dateFormat="dd/MM/yyyy"
                                        />
                                        {startdate === null && <div className='invalid-feedback fa-1x'>{t("Start Date is required")}</div>}
                                    </Col>
                                </Row><br />
                                <Row>
                                    <Col className="text-center">
                                        {t("Select Corporate code End Date")} :
                                    </Col>
                                    <Col>
                                        <DatePicker
                                        className='gray-border'
                                            selected={enddate}
                                            onChange={(date) => setenddate(date)}
                                            selectsEnd
                                            startdate={startdate}
                                            enddate={enddate}
                                            minDate={startdate}
                                            showDisabledMonthNavigation
                                            dateFormat="dd/MM/yyyy"
                                        />
                                        {enddate === null && <div className='invalid-feedback fa-1x'>{t("End Date is required")}</div>}
                                    </Col>
                                </Row><br />

                                <Row>
                                    <Col>
                                        <div className="row g-2 py-2">
                                            <div className="col-md">
                                                <div className="form-floating">
                                                    <label>{t("Select Tariff")}  <span className='mandatory-field'>*</span></label>
                                                    <select className={`form-select form-control ${errors.tariff ? 'is-invalid' : ''}`} name="tariff" id="floatingSelectGridtariff"  {...register("tariff")}>
                                                        <option value={""}>{t("Select Tariff")} </option>
                                                        {props.fetchActiveTariffList && props.fetchActiveTariffList.data && props.fetchActiveTariffList.data.map((v, k) =>
                                                            <option key={v.id} value={v.name}>{v.name}</option>
                                                        )}
                                                    </select>
                                                    <div className="invalid-feedback">{errors.tariff?.message}</div>
                                                </div>
                                            </div><br /><br />
                                            <div className="col-md">
                                                <div className="form-floating">
                                                    <label>{t("Select Organisation")}  <span className='mandatory-field'>*</span></label>
                                                    <select className={`form-select form-control ${errors.assignedoperator ? 'is-invalid' : ''}`} name="unit" id="floatingSelectGridorganisation"  {...register("assignedoperator")}>
                                                        <option value="">{t("Select Organisation")}</option>
                                                        {data && data.map((v, k) =>
                                                            <option key={v.id} value={v.name}>{v.name}</option>
                                                        )}
                                                    </select>
                                                    <div className="invalid-feedback">{errors.assignedoperator?.message}</div>
                                                </div>
                                            </div>
                                        </div><br />
                                    </Col>
                                </Row>

                                <Row className="mt-2 d-flex justify-content-end">
                                    <Col lg={6} md={4} sm={4} xs={12} className="mt-2">
                                        <button className="lgn-btn btn" type="button" onClick={() => {
                                            reset({
                                                corporate_code: '', code_name: '',tariff:'',assignedoperator:'',
                                                startDate: setstartdate(new Date(Date.now() + (3600 * 1000 * 1))),
                                                endDate: setenddate(new Date(Date.now() + (3600 * 1000 * 720))),
                                                amount: setAmount(''),
                                                unit: setUnit('')
                                                
                                            });
                                        }}> {t("Reset")} </button>
                                    </Col>
                                    <Col lg={6} md={8} sm={8} xs={12} className='d-md-flex justify-content-end mt-2'>
                                        {props.loading ?
                                            <button className="btn lgn-btn" type="button" disabled>
                                                <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                                                &nbsp;
                                                {t("Saving...")}
                                            </button>
                                            :
                                            <button className="lgn-btn btn" type="submit" disabled={startdate === null || enddate === null} onClick={handleSubmit(onSubmit)}>{t("CREATE CORPORATE CODE")}</button>
                                        }
                                    </Col>
                                </Row>
                            </form>
                        </Card.Body>
                        <br /><br />
                    </Card>

                </Col>
                <Col xs={12} md={1}>
                </Col>

            </Row>
        </Container>

    )
}



const mapStateToProps = (state) => {
    return {
        loading: state.operator.isLoading,
        fetchTariffList: state.tariff.timeSlotFetch,
        fetchActiveTariffList: state.tariff.fetchActiveTariffName,
        savecorporate: state.operator.savecorporatecode,
        organisationlist: state.operator.organisationsList,
    }
}
const mapDispatchToProps = dispatch => {
    return {
        getAllTimeofUseList: () => dispatch(fetchAllTimeSlotAction()),
        getActiveTariffList: () => dispatch(fetchActiveTariffList()),
        allOrg: () => dispatch(allOrgTariffAction()),
        saveCorporatecode: (data, startdate, enddate, amount, unit) => dispatch(saveCorporateCode(data, startdate, enddate, amount, unit))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(CreateCorporateCode)
