import React, { useEffect, useRef, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { RxCross1 } from "react-icons/rx";
import { connect } from 'react-redux';
import format from 'date-fns/format';
import { VscCircleFilled } from "react-icons/vsc";
import { fetchMarkasread, postNotificationAction } from '../../store/actions/insightAction';
import { MdOutlineSearch } from "react-icons/md";
import { useTranslation } from 'react-i18next';



const CustomOffCanvas = (props) => {
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [allFilterData, setAllFilterData] = useState([]);
  const [filterAdmin, setFilterAdmin] = useState([]);
  const [filterCpo, setFilterCpo] = useState([]);
  // console.log(props)
  const offcanvasRef = useRef(null);

  useEffect(() => {
    props.getNotificationAPI()
  }, [props.updateNotification])

  useEffect(() => {
    props.getNotificationAPI()
  }, [props.markAllasRead])

  useEffect(() => {

    //  Here we are storing API data to the useState . This will be use in the 1st time when component load.
    setData(props.fetchNotifi && props.fetchNotifi.data && props.fetchNotifi.data)
    setAllFilterData(props.fetchNotifi && props.fetchNotifi.data && props.fetchNotifi.data)
    setFilterAdmin(props.fetchNotifi && props.fetchNotifi.data && props.fetchNotifi.data.filter((item) => item.module === "ADMIN"));
    setFilterCpo((props.fetchNotifi && props.fetchNotifi.data && props.fetchNotifi.data.filter((item) => item.module === "CPO")))
  }, [props.fetchNotifi && props.fetchNotifi.data]);

  // console.log("allFilterData data", allFilterData)
  // console.log("props data", props.fetchNotifi && props.fetchNotifi.data)

  const Filter = (e) => {

    // This is the main logic for the search fiter . This will be used after you search the words.
    setAllFilterData(data && data.filter(item => item.description.toLowerCase().includes(e.target.value.toLowerCase())))
    setFilterAdmin(data && data.filter(item => item.description.toLowerCase().includes(e.target.value.toLowerCase())))
    setFilterCpo(data && data.filter(item => item.description.toLowerCase().includes(e.target.value.toLowerCase())))
  }

  // Here we are filtering admin (User) and session (CPO) moduel and then we use this for mapping list
  const Admin = filterAdmin && filterAdmin.filter((item) => item.module === "ADMIN")
  const Cpo = filterCpo && filterCpo.filter((item) => item.module === "CPO")

  // logic to close the notification when click outside
  const handleOutsideClick = (e) => {
    if (offcanvasRef.current && !offcanvasRef.current.contains(e.target)) {
      // console.log(offcanvasRef.current && !offcanvasRef.current.contains(e.target))
      props.handleClose(false);
    }
  };

  if (offcanvasRef.current && offcanvasRef.current) {
    document.addEventListener('click', handleOutsideClick);
  }

  const handleMarkAsRead = (id, url) => {
    props.saveNotification(id)
  }
  const handleMarkAllAsRead = () => {
    // console.log("markAllAsRead clicked")
    props.markallasread();
  }

  if (props.markAllasRead.statuscode === 200) {
    // window.location.reload();
  }


  return (
    <Card ref={offcanvasRef} className="custom-off-canvas">

      <Row className=''>
        <span className=" offcanvas-close-btn" onClick={props.handleClose}>
          <RxCross1 style={{ fontSize: "27px", fontWeight: "400",color:"#fff" }} />
        </span>
        <span className='header' onClick={props.handleClose}>
          <b className='notify-text'>{t("Notification")}</b>
        </span>
      </Row>

      <div className=" p-2" >
        <Row className="">
          <Col>
            <div className="searchBox" style={{ display: "flex", alignItems: "center" }}>
              <input
                type="text"
                className={`form-control gray-border`}
                name="search"
                id="floatingInputGridSearchFilter"
                placeholder="Search"
                onChange={Filter}
              />
              <MdOutlineSearch className='search-icon' style={{ marginLeft: '-2rem', fontSize: "1.5rem" }} />
            </div>
          </Col>
        </Row>
        <Row className='d-flex justify-content-end mt-2 mb-2 h5'>
          <a href='#3' className='mr-3' style={{ textDecoration: 'none', color: 'white' }} onClick={() => handleMarkAllAsRead()}>
            {t("Mark all as read")}
          </a>
        </Row>
        <div className="nav nav-pills" key={1}>
          <div className="nav-item">
            <input type="radio" name="notif" id="tab_notif1" defaultChecked className="tab-switch" />
            <label htmlFor="tab_notif1" className="tab-label" style={{ paddingLeft: "40px", paddingRight: "40px" }}>{t("All")}</label>
            <div className="tab-content" id='tab_notif1'>
              <article className='mt-1 mb-5 content' style={{ borderRadius: "10px" }}>
                {allFilterData && allFilterData.length === 0 && <b>{t("No Available Notification")}</b>}
                {allFilterData && allFilterData.map((item) => (
                  <Card key={item.id} className='mt-2 notificationcard' onClick={() => handleMarkAsRead(item.id)}>
                    <a href={item.url !== '' ? `/dashboard${item.url}` : '#'} style={{ textDecoration: 'none', outline: 'none', color: 'inherit', cursor: 'pointer' }}>
                      <Card.Body>
                        <Row>
                          <span className='col-1'>

                          </span>
                          <span className='col-8 header-row'>
                            <b style={{ userSelect: "none" }}>
                              {item.title}
                              {/* {item.module === "ADMIN" ? t("New App Signup") : t("Charging completed")} */}
                            </b>
                          </span>
                          <span className='col-3'>
                            <p className='mt-1' style={{ fontSize: "12px", userSelect: "none" }}>
                              {format(new Date(item.createat), 'dd  MMM yyyy')}
                            </p>
                          </span>
                        </Row>
                        <Row>
                          <div className='col-1 mb-4' style={{ display: "flex", alignItems: "center" }}>
                            <p className='' style={{ userSelect: "none" }}>
                              {item.module === "CPO" && <img className='notificationHeader-icon' src={process.env.PUBLIC_URL + '/images/sidebaricons/CPO.svg'} alt="" style={{ width: '30px', height: '30px' }} />}
                              {item.module === "EMSP" && <img className='notificationHeader-icon' src={process.env.PUBLIC_URL + '/images/sidebaricons/emsp.svg'} alt="" style={{ width: '30px', height: '30px' }} />}
                              {item.module === "ENERGY" && <img className='notificationHeader-icon' src={process.env.PUBLIC_URL + '/images/sidebaricons/sidebar-energy.svg'} alt="" style={{ width: '30px', height: '30px' }} />}
                              {item.module === "PARTNER" && <img className='notificationHeader-icon' src={process.env.PUBLIC_URL + '/images/sidebaricons/sidebar-partner.svg'} alt="" style={{ width: '30px', height: '30px' }} />}
                              {item.module === "FLEET" && <img className='notificationHeader-icon' src={process.env.PUBLIC_URL + '/images/sidebaricons/sidebar-fleet.svg'} alt="" style={{ width: '30px', height: '30px' }} />}
                              {item.module === "CAMPAIGN" && <img className='notificationHeader-icon' src={process.env.PUBLIC_URL + '/images/sidebaricons/sidebar-campaign.svg'} alt="" style={{ width: '30px', height: '30px' }} />}
                              {item.module === "ALERT" && <img className='notificationHeader-icon' src={process.env.PUBLIC_URL + '/images/sidebaricons/sidebar-notification.svg'} alt="" style={{ width: '30px', height: '30px' }} />}
                              {item.module === "ADMIN" && <img className='notificationHeader-icon' src={process.env.PUBLIC_URL + '/images/sidebaricons/sidebar-admin.svg'} alt="" style={{ width: '30px', height: '30px' }} />}
                            </p>
                          </div>
                          <div className='col-10' style={{ userSelect: "none" }}>
                            {item.module === "ADMIN" ? (
                              <p style={{ textAlign: "left", paddingLeft: "5px" }}>
                                {item.description}
                              </p>
                            ) : (
                              <p style={{ textAlign: "left", paddingLeft: "5px" }}>
                                {item.description}
                              </p>
                            )}
                          </div>
                          <div className='col-1 d-flex align-items-center'>
                            <span className="notification-mark-asread-dot mr-3" title='Mark as Read' style={{ marginTop: "-2rem" }}>
                              {item.isRead ? <VscCircleFilled className="mark-asread-dot " title='Already Read' /> : <VscCircleFilled className="mark-unread-dot" title=' Mark as Read' onClick={() => handleMarkAsRead(item.id)} />}
                            </span>
                          </div>
                        </Row>
                      </Card.Body>
                    </a>
                  </Card>
                ))}
              </article>
            </div>
          </div>

          <div className="nav-item">
            <input type="radio" name="notif" id="tab_notif2" className="tab-switch" />
            <label htmlFor="tab_notif2" className="tab-label" style={{ paddingLeft: "40px", paddingRight: "40px" }}>{t("Users")}</label>
            <div className="tab-content p-0" >
              <article className='mt-1 mb-5 content' style={{ borderRadius: "10px" }}>
                {Admin && Admin.length === 0 && <b>{t("No Available Notification")}</b>}
                {Admin && Admin.map((item) => (
                  <Card key={item.id} className='mt-2 notificationcard' onClick={() => handleMarkAsRead(item.id)}>
                    <a href={item.url !== '' ? `/dashboard${item.url}` : '#'} style={{ textDecoration: 'none', outline: 'none', color: 'inherit', cursor: 'pointer' }}>
                      <Card.Body>
                        <Row>
                          <span className='col-1'>

                          </span>
                          <span className='col-8 header-row'>
                            <b style={{ userSelect: "none" }}>
                              {t(item.title)}
                            </b>
                          </span>
                          <span className='col-3'>
                            <p className='mt-1' style={{ fontSize: "12px", userSelect: "none" }}>
                              {format(new Date(item.createat), 'dd  MMM yyyy')}
                            </p>
                          </span>
                        </Row>
                        <Row>
                          <div className='col-1 mb-4' style={{ display: "flex", alignItems: "center" }}>
                            <p className='' style={{ userSelect: "none" }}>
                              {item.module === "CPO" && <img className='notificationHeader-icon' src={process.env.PUBLIC_URL + '/images/sidebaricons/CPO.svg'} alt="" style={{ width: '30px', height: '30px' }} />}
                              {item.module === "EMSP" && <img className='notificationHeader-icon' src={process.env.PUBLIC_URL + '/images/sidebaricons/emsp.svg'} alt="" style={{ width: '30px', height: '30px' }} />}
                              {item.module === "ENERGY" && <img className='notificationHeader-icon' src={process.env.PUBLIC_URL + '/images/sidebaricons/sidebar-energy.svg'} alt="" style={{ width: '30px', height: '30px' }} />}
                              {item.module === "PARTNER" && <img className='notificationHeader-icon' src={process.env.PUBLIC_URL + '/images/sidebaricons/sidebar-partner.svg'} alt="" style={{ width: '30px', height: '30px' }} />}
                              {item.module === "FLEET" && <img className='notificationHeader-icon' src={process.env.PUBLIC_URL + '/images/sidebaricons/sidebar-fleet.svg'} alt="" style={{ width: '30px', height: '30px' }} />}
                              {item.module === "CAMPAIGN" && <img className='notificationHeader-icon' src={process.env.PUBLIC_URL + '/images/sidebaricons/sidebar-campaign.svg'} alt="" style={{ width: '30px', height: '30px' }} />}
                              {item.module === "ALERT" && <img className='notificationHeader-icon' src={process.env.PUBLIC_URL + '/images/sidebaricons/sidebar-notification.svg'} alt="" style={{ width: '30px', height: '30px' }} />}
                              {item.module === "ADMIN" && <img className='notificationHeader-icon' src={process.env.PUBLIC_URL + '/images/sidebaricons/sidebar-admin.svg'} alt="" style={{ width: '30px', height: '30px' }} />}
                            </p>
                          </div>
                          <div className='col-10' style={{ userSelect: "none" }}>
                            <p style={{ textAlign: "left", paddingLeft: "5px" }}>
                              {/* {t('signupMessage', {
                                admin: item.module, // Replace adminName with the actual admin's name
                                name: item.name,
                                source: item.source
                              })} */}
                              {item.description}
                            </p>
                          </div>
                          <div className='col-1 d-flex align-items-center'>
                            <span className="notification-mark-asread-dot mr-3" title='Mark as Read' style={{ marginTop: "-2rem" }}>
                              {item.isRead ? <VscCircleFilled className="mark-asread-dot " title='Already Read' /> : <VscCircleFilled className="mark-unread-dot" title=' Mark as Read' onClick={() => handleMarkAsRead(item.id)} />}
                            </span>
                          </div>
                        </Row>
                      </Card.Body>
                    </a>
                  </Card>
                ))}
              </article>
            </div>
          </div>

          <div className="nav-item">
            <input type="radio" name="notif" id="tab_notif3" className="tab-switch" />
            <label htmlFor="tab_notif3" className="tab-label" style={{ paddingLeft: "40px", paddingRight: "40px" }}>{t("Sessions")}</label>
            <div className="tab-content">
              <article className='mt-1 mb-5 content' style={{ borderRadius: "10px" }}>
                {Cpo && Cpo.length === 0 && <b className=''>{t("No Available Notification")}</b>}
                {Cpo && Cpo.map((item) => (
                  <Card key={item.id} className='mt-2 notificationcard' onClick={() => handleMarkAsRead(item.id)}>
                    <a href={item.url !== '' ? `/dashboard${item.url}` : '#'} style={{ textDecoration: 'none', outline: 'none', color: 'inherit', cursor: 'pointer' }}>
                      <Card.Body>
                        <Row>
                          <span className='col-1'>

                          </span>
                          <span className='col-8 header-row'>
                            <b style={{ userSelect: "none" }}>
                              {t(item.title)}
                            </b>
                          </span>
                          <span className='col-3'>
                            <p className='mt-1' style={{ fontSize: "12px", userSelect: "none" }}>
                              {format(new Date(item.createat), 'dd  MMM yyyy')}
                            </p>
                          </span>
                        </Row>
                        <Row>
                          <div className='col-1 mb-4' style={{ display: "flex", alignItems: "center" }}>
                            <p className='' style={{ userSelect: "none" }}>
                              {item.module === "CPO" && <img className='notificationHeader-icon' src={process.env.PUBLIC_URL + '/images/sidebaricons/CPO.svg'} alt="" style={{ width: '30px', height: '30px' }} />}
                              {item.module === "EMSP" && <img className='notificationHeader-icon' src={process.env.PUBLIC_URL + '/images/sidebaricons/emsp.svg'} alt="" style={{ width: '30px', height: '30px' }} />}
                              {item.module === "ENERGY" && <img className='notificationHeader-icon' src={process.env.PUBLIC_URL + '/images/sidebaricons/sidebar-energy.svg'} alt="" style={{ width: '30px', height: '30px' }} />}
                              {item.module === "PARTNER" && <img className='notificationHeader-icon' src={process.env.PUBLIC_URL + '/images/sidebaricons/sidebar-partner.svg'} alt="" style={{ width: '30px', height: '30px' }} />}
                              {item.module === "FLEET" && <img className='notificationHeader-icon' src={process.env.PUBLIC_URL + '/images/sidebaricons/sidebar-fleet.svg'} alt="" style={{ width: '30px', height: '30px' }} />}
                              {item.module === "CAMPAIGN" && <img className='notificationHeader-icon' src={process.env.PUBLIC_URL + '/images/sidebaricons/sidebar-campaign.svg'} alt="" style={{ width: '30px', height: '30px' }} />}
                              {item.module === "ALERT" && <img className='notificationHeader-icon' src={process.env.PUBLIC_URL + '/images/sidebaricons/sidebar-notification.svg'} alt="" style={{ width: '30px', height: '30px' }} />}
                              {item.module === "ADMIN" && <img className='notificationHeader-icon' src={process.env.PUBLIC_URL + '/images/sidebaricons/sidebar-admin.svg'} alt="" style={{ width: '30px', height: '30px' }} />}
                            </p>
                          </div>
                          <div className='col-10' style={{ userSelect: "none" }}>
                            <p style={{ textAlign: "left", paddingLeft: "5px" }}>
                              {/* {t('chargeMessage', {
                                name: item.name,
                                station: item.stationid,
                                amount: item.amount,
                                energy: item.energy
                              })} */}
                              {item.description}
                            </p>

                          </div>
                          <div className='col-1 d-flex align-items-center'>
                            <span className="notification-mark-asread-dot mr-3" title='Mark as Read' style={{ marginTop: "-2rem" }}>
                              {item.isRead ? <VscCircleFilled className="mark-asread-dot " title='Already Read' /> : <VscCircleFilled className="mark-unread-dot" title=' Mark as Read' onClick={() => handleMarkAsRead(item.id)} />}
                            </span>
                          </div>
                        </Row>
                      </Card.Body>
                    </a>
                  </Card>
                ))}
              </article>
            </div>
          </div>

        </div>

      </div>
    </Card>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.insight.isLoading,
    updateNotification: state.insight.post_notifications,
    markAllasRead: state.insight.saveMarkasRead,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    saveNotification: (id) => dispatch(postNotificationAction(id)),
    markallasread: () => dispatch(fetchMarkasread()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(CustomOffCanvas);
