import { Container, Row, Col, Card, Button, Spinner, Form } from "react-bootstrap";
import PureBreadcrumbs from "../../breadcrums";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { allCutomersAction, createRfidbindingApi, fetchRfidList } from "../../../../store/actions/customerActions";
import RfidList from "./rfidList";
import Popup from "reactjs-popup";
import { ImCross } from "react-icons/im";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from 'yup';
import { allCurrencyAction } from "../../../../store/actions/tariffAction";


function RfidCard(props) {
  const { t } = useTranslation();
  useEffect(() => {
    props.getRfid();
    props.getCustomers();
    props.getAllCurrency();
  }, []);

  const data = props.list && props.list.customers
  // console.log("data", data);

  const rfidSchema = Yup.object().shape({
    username: Yup.string().required('UserName is required'),
    rfidNo: Yup.string().required('Rfid number is required'),
  });

  const { register, handleSubmit, setValue, formState: { errors } } = useForm({ resolver: yupResolver(rfidSchema), mode: "all" });


  const onSubmit = async data => {
    console.log(data)
    props.saveRfid(data)
  };

  if (props.saveRfidRes.statuscode === 200) {
    alert("Rfid binding with customer is saved successfully")
    window.location.reload();
  }
  if (props.saveRfidRes.statuscode === 406) {
    alert(props.saveRfidRes.result)
    window.location.reload();
  }
  if (props.saveRfidRes.statuscode === 404) {
    alert(props.saveRfidRes.error)
    window.location.reload();
  }
  if (props.saveRfidRes.statuscode === 405) {
    alert(props.saveRfidRes.error)
    window.location.reload();
  }

  return (
    <Container fluid>
      <PureBreadcrumbs />
      <br />
      {/* <div className="row">
        <div className="col-lg-3 col-sm-12">
          <Card className="customercard">
            <div style={{ textAlign: "left" }}>
              <Card.Text style={{ fontSize: "14px" }}>{t("TOTAL ORDER")}</Card.Text>
              <Card.Title>
                <b>0</b>
              </Card.Title>
              <span style={{ fontSize: "11px" }}>
                0.0% {t(" change in last 30 days")}
              </span>
              <span
                style={{
                  float: "right",
                  marginTop: "-60px",
                  marginRight: "-8px",
                }}
              >
                <div style={{ width: "20%" }}>
                  <img
                    src={
                      process.env.PUBLIC_URL + "/images/customer/totalorder.svg"
                    }
                    alt="customer"
                  />
                </div>
              </span>
            </div>
          </Card>
          <br />
        </div>
        <div className="col-lg-3 col-sm-12">
          <Card className="customercard">
            <div style={{ textAlign: "left" }}>
              <Card.Text style={{ fontSize: "14px" }}>{t("PENDING")}</Card.Text>
              <Card.Title>
                <b>0</b>
              </Card.Title>
              <span style={{ fontSize: "11px" }}>
                0.0% {t(" change in last 30 days")}
              </span>
              <span
                style={{
                  float: "right",
                  marginTop: "-60px",
                  marginRight: "-8px",
                }}
              >
                <div style={{ width: "20%" }}>
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/images/customer/pendingrfid.svg"
                    }
                    alt="customer"
                  />
                </div>
              </span>
            </div>
          </Card>
          <br />
        </div>
        <div className="col-lg-3 col-sm-12">
          <Card className="customercard">
            <div style={{ textAlign: "left" }}>
              <Card.Text style={{ fontSize: "14px" }}>
                {t("READY TO DISPATCH")}
              </Card.Text>
              <Card.Title>
                <b>0</b>
              </Card.Title>
              <span style={{ fontSize: "11px" }}>
                0.0% {t(" change in last 30 days")}
              </span>
              <span
                style={{
                  float: "right",
                  marginTop: "-60px",
                  marginRight: "-8px",
                }}
              >
                <div style={{ width: "20%" }}>
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/images/customer/readydispatchrfid.svg"
                    }
                    alt="customer"
                  />
                </div>
              </span>
            </div>
          </Card>
          <br />
        </div>
        <div className="col-lg-3 col-sm-12">
          <Card className="customercard">
            <div style={{ textAlign: "left" }}>
              <Card.Text style={{ fontSize: "14px" }}>{t("DISPATCHED")}</Card.Text>
              <Card.Title>
                <b>0</b>
              </Card.Title>
              <span style={{ fontSize: "11px" }}>
                0.0% {t(" change in last 30 days")}
              </span>
              <span
                style={{
                  float: "right",
                  marginTop: "-60px",
                  marginRight: "-8px",
                }}
              >
                <div style={{ width: "20%" }}>
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/images/customer/dispatchedrfid.svg"
                    }
                    alt="customer"
                  />
                </div>
              </span>
            </div>
          </Card>
        </div>
      </div> */}

      <Row className="mt-4">
        <Col>
          <Card className="customercard">
            <Card.Header>
              <Row className="align-items-center">
                <Col xs={6} md={6} className="rfid-test">
                  {t("RFID List")}
                </Col>

                <Col xs={6} md={6} className="text-right">
                  <Popup
                    trigger={
                      <button
                        className="lgn-btn btn"
                        type="button"
                        style={{ position: "relative", width: "auto", height: "auto" }}
                      >
                        {t("Bind Rfid With Customer")}
                      </button>
                    }
                    modal
                    closeOnDocumentClick={false}
                    contentStyle={{
                      borderRadius: "10px",
                      padding: "20px",
                      width: window.innerWidth > 600 ? "50%" : "80%", // Adjust width based on screen size
                      margin: "auto",
                      maxHeight: "80vh",
                      overflow: "auto"
                    }}
                    className="popup-content"
                  >
                    {(close) => (
                      <>
                        <div style={{ textAlign: "right" }}>
                          <button className="close-btn" onClick={() => window.location.reload()}>
                            <span>
                              <ImCross />
                            </span>
                          </button>
                        </div>
                        <div className="customercard">
                          <h2 style={{ textAlign: "center" }}>{t("Bind RFID With Customer")}</h2>
                          <Row className="add-roaming-station">
                            <Form className="p-3 mt-4" onSubmit={handleSubmit(onSubmit)}>
                              <Row>
                                <Col md={6} className="mb-3">
                                  <Form.Group controlId="floatingSelectGridusername">
                                    <Form.Label>{t("User Name")}</Form.Label>
                                    <Form.Control
                                      as="select"
                                      className={errors.username ? 'is-invalid' : ''}
                                      {...register("username")}
                                    >
                                      <option value={''}>{t("Select Username")}</option>
                                      {data && data.map((item, index) => (
                                        <option key={item.id} value={item.userid}>{item.userid}</option>
                                      ))}
                                    </Form.Control>
                                    {errors.username && (
                                      <div className="invalid-feedback">{errors.username.message}</div>
                                    )}
                                  </Form.Group>
                                </Col>

                                <Col md={6} className="mb-3">
                                  <Form.Group controlId="floatingInputGridrfidNo">
                                    <Form.Label>{t("Rfid No.")}</Form.Label>
                                    <Form.Control
                                      type="text"
                                      placeholder={t("Rfid No.")}
                                      className={errors.rfidNo ? 'is-invalid' : ''}
                                      {...register("rfidNo")}
                                    />
                                    {errors.rfidNo && (
                                      <div className="invalid-feedback">{errors.rfidNo.message}</div>
                                    )}
                                  </Form.Group>
                                </Col>
                              </Row>

                              <Row className="justify-content-center mt-2 mb-4">
                                <Col lg={6} className="d-flex justify-content-center">
                                  <button
                                    className="lgn-btn btn"
                                    type="submit"
                                    disabled={props.loading}
                                  >
                                    {props.loading ? (
                                      <>
                                        <Spinner
                                          as="span"
                                          animation="grow"
                                          size="sm"
                                          role="status"
                                          aria-hidden="true"
                                        />
                                        &nbsp;
                                        {t("Saving...")}
                                      </>
                                    ) : (
                                      <p>
                                        {t("Save")}
                                      </p>
                                    )}
                                  </button>
                                </Col>
                              </Row>
                            </Form>
                          </Row>
                        </div>
                      </>
                    )}
                  </Popup>
                </Col>
              </Row>
            </Card.Header>
            <RfidList {...props} />
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

const mapStateToProps = (state) => {
  return {
    loading: state.customer.isLoading,
    fetchRfidListRes: state.customer.fetchRfidList,
    list: state.customer.fetchcutomers,
    saveRfidRes: state.customer.saveRfidBindings,
    allcurrency: state.tariff.fetchcurrency,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getRfid: () => dispatch(fetchRfidList()),
    saveRfid: (data) => dispatch(createRfidbindingApi(data)),
    getCustomers: () => dispatch(allCutomersAction()),
    getAllCurrency: () => dispatch(allCurrencyAction()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(RfidCard);
