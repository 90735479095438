import { Container, Row, Col, Card } from "react-bootstrap";
import PureBreadcrumbs from "../../breadcrums";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { allCustomerWalletAction } from "../../../../store/actions/walletActions";
import WalletList from "./walletList";
import { t } from "i18next";
import { useTranslation } from "react-i18next";

function WalletCard(props) {
  const { t } = useTranslation();
  useEffect(() => {
    // props.getWallet();
  }, []);
  return (
    <Container fluid className="pb-4">
      <PureBreadcrumbs />
      <br />
      <div className="row">
        <div className="col-lg-3 col-sm-12">
          <Card className="customercard">
            <div style={{ textAlign: "left" }}>
              <Card.Text style={{ fontSize: "14px" }}>{t("CUSTOMERSCARD")}</Card.Text>
              <Card.Title>
                <b>{props.wallets.total_customer}</b>
              </Card.Title>
              <span style={{ fontSize: "11px" }}>
                0.0% {t(" change in last 30 days")}
              </span>
              <span
                style={{
                  float: "right",
                  marginTop: "-60px",
                  marginRight: "-8px",
                }}
              >
                <div style={{ width: "20%" }}>
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/images/customer/totalcustomer.svg"
                    }
                    alt="customer"
                  />
                </div>
              </span>
            </div>
          </Card>
          <br />
        </div>
        <div className="col-lg-3 col-sm-12">
          <Card className="customercard">
            <div style={{ textAlign: "left" }}>
              <Card.Text style={{ fontSize: "14px" }}>{t("WALLET CREDIT")}</Card.Text>
              <Card.Title>
                <b>{props.wallets.total_credit}</b>
              </Card.Title>
              <span style={{ fontSize: "11px" }}>
                0.0% {t(" change in last 30 days")}
              </span>
              <span
                style={{
                  float: "right",
                  marginTop: "-60px",
                  marginRight: "-8px",
                }}
              >
                <div style={{ width: "20%" }}>
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/images/customer/walletcredit.svg"
                    }
                    alt="customer"
                  />
                </div>
              </span>
            </div>
          </Card>
          <br />
        </div>
        <div className="col-lg-3 col-sm-12">
          <Card className="customercard">
            <div style={{ textAlign: "left" }}>
              <Card.Text style={{ fontSize: "14px" }}>{t("DEBIT")}</Card.Text>
              <Card.Title>
                <b>{props.wallets.total_debit}</b>
              </Card.Title>
              <span style={{ fontSize: "11px" }}>
                0.0% {t(" change in last 30 days")}
              </span>
              <span
                style={{
                  float: "right",
                  marginTop: "-60px",
                  marginRight: "-8px",
                }}
              >
                <div style={{ width: "20%" }}>
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/images/customer/walletdebit.svg"
                    }
                    alt="customer"
                  />
                </div>
              </span>
            </div>
          </Card>
          <br />
        </div>
        <div className="col-lg-3 col-sm-12">
          <Card className="customercard">
            <div style={{ textAlign: "left" }}>
              <Card.Text style={{ fontSize: "14px" }}>{t("AVAILABLE")}</Card.Text>
              <Card.Title>
                <b>{props.wallets.total_available}</b>
              </Card.Title>
              <span style={{ fontSize: "11px" }}>
                0.0% {t(" change in last 30 days")}
              </span>
              <span
                style={{
                  float: "right",
                  marginTop: "-60px",
                  marginRight: "-8px",
                }}
              >
                <div style={{ width: "50%" }}>
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/images/customer/walletavailable.svg"
                    }
                    alt="customer"
                  />
                </div>
              </span>
            </div>
          </Card>
        </div>
      </div>

      <div className="row pad_t1">
        <div className="col-lg-12 col-sm-12">
          <Card className="customercard">
            <WalletList />
          </Card>
        </div>
      </div>
    </Container>
  );
}

const mapStateToProps = (state) => {
  return {
    wallets: state.wallet.fetchAll,
    loading: state.wallet.isLoading,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getWallet: () => dispatch(allCustomerWalletAction()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(WalletCard);
