import { ACTIVATE_EMAIL,DEACTIVATE_EMAIL,CAMPAIGN_URL, CREATE_EMAIL_CAMPAIGN_API, CREATE_PUSH_NOTIFICATION_CAMPAIGN_API, CREATE_SMS_CAMPAIGN, FETCH_EMAIL_CAMPAIGN_API, FETCH_SMS_CAMPAIGN_API, FETCH_PUSH_CAMPAIGN_API, GET_MONTHLY_EMAIL_API, GET_MONTHLY_SMS_API, GET_MONTHLY_PUSH_API, CAMPAIGN_PERCENTAGE_CHANGE, ACTIVATE_SMS_API, DEACTIVATE_SMS_API, ACTIVATE_NOTIFICATION_API, DEACTIVATE_NOTIFICATION_API, VIEW_SINGLE_SMS_API, UPDATE_SMS_CAMPAIGN, VIEW_SINGLE_NOTIFICATION_API, UPDATE_PUSH_NOTIFICATION_CAMPAIGN_API, VIEW_SINGLE_EMAIL_API, UPDATE_EMAIL_CAMPAIGN_API } from "../../components/config/config";

export const createEmailCampaignAction = (data, date) => {
    // console.log(date)
    const { recipients, subject,message } = data;
    const { token, organisation,role } = JSON.parse(localStorage.getItem('user'));
    const bearer = 'basic ' + token
    return dispatch => {
        dispatch({ type: 'LOAD_CAMPAIGN', payload: '' })
        return fetch(CAMPAIGN_URL + CREATE_EMAIL_CAMPAIGN_API, {
            method: "POST",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
            body: JSON.stringify({
                "recipients": recipients,
                'subject': subject,
                'message': message,
                'date': date,
                "operator": organisation,
                "role": role,
            })
        }).then(resp => resp.json()).then(data => {
            // console.log(data)
            dispatch({ type: 'CREATE_EMAIL_CAMPAIGN', payload: data })
        })
    }
}

export const updateEmailCampaignAction = (data,campaign_id, date) => {
    // console.log(date)
    const { recipients, subject,message } = data;
    const { token, organisation,role } = JSON.parse(localStorage.getItem('user'));
    const bearer = 'basic ' + token
    return dispatch => {
        dispatch({ type: 'LOAD_CAMPAIGN', payload: '' })
        return fetch(CAMPAIGN_URL + UPDATE_EMAIL_CAMPAIGN_API, {
            method: "POST",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
            body: JSON.stringify({
                "campaign_id":campaign_id,
                "recipients": recipients,
                'subject': subject,
                'message': message,
                'date': date,
                "operator": organisation,
                "role": role,
            })
        }).then(resp => resp.json()).then(data => {
            // console.log(data)
            dispatch({ type: 'UPDATE_EMAIL_API', payload: data })
        })
    }
}

export const createPushNotifyCampaignAction = (data, date) => {
    const { recipients, message, title } = data;
    const { token, organisation,role } = JSON.parse(localStorage.getItem('user'));
    const bearer = 'basic ' + token
    return dispatch => {
        dispatch({ type: 'LOAD_CAMPAIGN', payload: '' })
        return fetch(CAMPAIGN_URL + CREATE_PUSH_NOTIFICATION_CAMPAIGN_API, {
            method: "POST",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
            body: JSON.stringify({
                "recipients": recipients,
                'message': message,
                'title': title,
                'date': date,
                "role": role,
                "operator": organisation,
            })
        }).then(resp => resp.json()).then(data => {
            dispatch({ type: 'CREATE_PUSH_NOTIFY_CAMPAIGN', payload: data })
        })
    }
}
export const updateNotificationAction = (data,push_campaign_id, date) => {
    const { recipients, message, title } = data;
    const { token, organisation,role } = JSON.parse(localStorage.getItem('user'));
    const bearer = 'basic ' + token
    return dispatch => {
        dispatch({ type: 'LOAD_CAMPAIGN', payload: '' })
        return fetch(CAMPAIGN_URL + UPDATE_PUSH_NOTIFICATION_CAMPAIGN_API, {
            method: "POST",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
            body: JSON.stringify({
                "push_campaign_id": push_campaign_id,
                "recipients": recipients,
                'message': message,
                'title': title,
                'date': date,
                "role": role,
                "operator": organisation,
            })
        }).then(resp => resp.json()).then(data => {
            dispatch({ type: 'UPDATE_NOTIFICATION_API', payload: data })
        })
    }
}

export const updateSMSCampaignAction = (data,campaignId, date) => {
    // console.log(data,date)
    const { recipients, message, title } = data;
    const { token, organisation ,role} = JSON.parse(localStorage.getItem('user'));
    const bearer = 'basic ' + token
    return dispatch => {
        dispatch({ type: 'LOAD_CAMPAIGN', payload: '' })
        return fetch(CAMPAIGN_URL + UPDATE_SMS_CAMPAIGN, {
            method: "POST",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
            body: JSON.stringify({
                "sms_campaign_id": campaignId,
                "recipients": recipients,
                'message': message,
                'title': title,
                'date': date,
                "role": role,
                "operator": organisation,
            })
        }).then(resp => resp.json()).then(data => {
            // console.log("update data",data)
            dispatch({ type: 'UPDATE_SMS_API', payload: data })
        })
    }
}

export const createSMSCampaignAction = (data, date) => {
    const { recipients, message, title } = data;
    const { token, organisation ,role} = JSON.parse(localStorage.getItem('user'));
    const bearer = 'basic ' + token
    return dispatch => {
        dispatch({ type: 'LOAD_CAMPAIGN', payload: '' })
        return fetch(CAMPAIGN_URL + CREATE_SMS_CAMPAIGN, {
            method: "POST",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
            body: JSON.stringify({
                "recipients": recipients,
                'message': message,
                'title': title,
                'date': date,
                "role": role,
                "operator": organisation,
            })
        }).then(resp => resp.json()).then(data => {
            dispatch({ type: 'CREATE_SMS_CAMPAIGN', payload: data })
        })
    }
}


export const allEmailCampaignAction = () => {
    const { token,role,organisation } = JSON.parse(localStorage.getItem('user'));
    const bearer = 'basic ' + token
    // console.log(CAMPAIGN_URL + FETCH_EMAIL_CAMPAIGN_API+ "?role=" + role + "&operator=" + organisation)
    return dispatch => {
        dispatch({ type: 'LOAD_CAMPAIGN', payload: '' })
        return fetch(CAMPAIGN_URL + FETCH_EMAIL_CAMPAIGN_API+ "?role=" + role + "&operator=" + organisation, {
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        }).then(resp => resp.json()).then(data => {
            // console.log("ALL_EMAIL_CAMPAIGN",data)
            dispatch({ type: 'ALL_EMAIL_CAMPAIGN', payload: data })
        })
    }
}

export const allSMSCampaignAction = () => {
    const { token,role,organisation } = JSON.parse(localStorage.getItem('user'));
    const bearer = 'basic ' + token
    return dispatch => {
        dispatch({ type: 'LOAD_CAMPAIGN', payload: '' })
        return fetch(CAMPAIGN_URL + FETCH_SMS_CAMPAIGN_API+ "?role=" + role + "&operator=" + organisation, {
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        }).then(resp => resp.json()).then(data => {
            dispatch({ type: 'ALL_SMS_CAMPAIGN', payload: data })
        })
    }
}

export const allPushNotifyCampaignAction = () => {
    const { token ,role,organisation} = JSON.parse(localStorage.getItem('user'));
    const bearer = 'basic ' + token
    return dispatch => {
        dispatch({ type: 'LOAD_CAMPAIGN', payload: '' })
        return fetch(CAMPAIGN_URL + FETCH_PUSH_CAMPAIGN_API+ "?role=" + role + "&operator=" + organisation, {
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        }).then(resp => resp.json()).then(data => {
            dispatch({ type: 'ALL_PUSH_NOTIFY_CAMPAIGN', payload: data })
        })
    }
}



export const getMonthlyEmailCount = (start_date, end_date) => {
    const { token } = JSON.parse(localStorage.getItem('user'));
    // console.log(CAMPAIGN_URL + GET_MONTHLY_EMAIL_API + "?start_date=" + start_date + "&end_date=" + end_date)
    const bearer = 'basic ' + token
    return dispatch => {
        dispatch({ type: 'LOAD_CAMPAIGN', payload: '' })
        return fetch(CAMPAIGN_URL + GET_MONTHLY_EMAIL_API + "?start_date=" + start_date + "&end_date=" + end_date, {
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        }).then(resp => resp.json()).then(data => {
            // console.log(data)
            dispatch({ type: 'GET_MONTHLY_EMAIL_API', payload: data })
        })
    }
}

export const getMonthlySMSCount = (start_date, end_date) => {
    const { token } = JSON.parse(localStorage.getItem('user'));
    const bearer = 'basic ' + token
    return dispatch => {
        dispatch({ type: 'LOAD_CAMPAIGN', payload: '' })
        return fetch(CAMPAIGN_URL + GET_MONTHLY_SMS_API + "?start_date=" + start_date + "&end_date=" + end_date, {
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        }).then(resp => resp.json()).then(data => {
            dispatch({ type: 'GET_MONTHLY_SMS_API', payload: data })
        })
    }
}

export const getMonthlyPushCount = (start_date, end_date) => {
    const { token } = JSON.parse(localStorage.getItem('user'));
    // console.log(CAMPAIGN_URL + GET_MONTHLY_PUSH_API + "?start_date=" + start_date + "&end_date=" + end_date)
    const bearer = 'basic ' + token
    return dispatch => {
        dispatch({ type: 'LOAD_CAMPAIGN', payload: '' })
        return fetch(CAMPAIGN_URL + GET_MONTHLY_PUSH_API + "?start_date=" + start_date + "&end_date=" + end_date, {
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        }).then(resp => resp.json()).then(data => {
            // console.log(data)
            dispatch({ type: 'GET_MONTHLY_PUSH_API', payload: data })
        })
    }
}


export const getCampaignPercentageChange = () => {
    const { token ,role,organisation} = JSON.parse(localStorage.getItem('user'));
    const bearer = 'basic ' + token
    return dispatch => {
        dispatch({ type: 'LOAD_CAMPAIGN', payload: '' })
        return fetch(CAMPAIGN_URL + CAMPAIGN_PERCENTAGE_CHANGE+ "?role=" + role + "&operator=" + organisation, {
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        }).then(resp => resp.json()).then(data => {
            // console.log("EFEWF",data)
            dispatch({ type: 'CAMPAIGN_PERCENTAGE_CHANGE', payload: data })
        })
    }
}
export const activateSms = (id) => {
    console.log("activate id",id)
    const { token,role,organisation } = JSON.parse(localStorage.getItem('user'));
    const bearer = 'basic ' + token
    return dispatch => {
        // console.log(CAMPAIGN_URL + ACTIVATE_SMS_API+ "?sms_campaign_id=" + id)
        dispatch({ type: 'LOAD_CAMPAIGN', payload: '' })
        return fetch(CAMPAIGN_URL + ACTIVATE_SMS_API+ "?sms_campaign_id=" + id , {
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        }).then(resp => resp.json()).then(data => {
            // console.log(data)
            dispatch({ type: 'ACTIVATE_SMS', payload: data })
        })
    }
}
export const deactivateSms = (id) => {
     console.log("deactivate id",id)
    const { token,role,organisation } = JSON.parse(localStorage.getItem('user'));
    const bearer = 'basic ' + token
    return dispatch => {
        dispatch({ type: 'LOAD_CAMPAIGN', payload: '' })
        return fetch(CAMPAIGN_URL + DEACTIVATE_SMS_API+ "?sms_campaign_id=" + id , {
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        }).then(resp => resp.json()).then(data => {
            // console.log("deactivate data", data);
            dispatch({ type: 'DEACTIVATE_SMS', payload: data })
        })
    }
}
export const activateNotification = (id) => {
     console.log("deactivate id",id)
    const { token,role,organisation } = JSON.parse(localStorage.getItem('user'));
    const bearer = 'basic ' + token
    return dispatch => {
        dispatch({ type: 'LOAD_CAMPAIGN', payload: '' })
        return fetch(CAMPAIGN_URL + ACTIVATE_NOTIFICATION_API+ "?push_campaign_id=" + id , {
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        }).then(resp => resp.json()).then(data => {
            // console.log(data)
            dispatch({ type: 'ACTIVATE_NOTIFICATION_API', payload: data })
        })
    }
}
export const deactivateNotification = (id) => {
     console.log("deactivate id",id)
    const { token,role,organisation } = JSON.parse(localStorage.getItem('user'));
    const bearer = 'basic ' + token
    return dispatch => {
        dispatch({ type: 'LOAD_CAMPAIGN', payload: '' })
        return fetch(CAMPAIGN_URL + DEACTIVATE_NOTIFICATION_API+ "?push_campaign_id=" + id , {
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        }).then(resp => resp.json()).then(data => {
            // console.log(data)
            dispatch({ type: 'DEACTIVATE_NOTIFICATION_API', payload: data })
        })
    }
}
export const viewSingleSmsAction = (id) => {
    //  console.log("id",id)
    const { token,role,organisation } = JSON.parse(localStorage.getItem('user'));
    const bearer = 'basic ' + token
    return dispatch => {
        dispatch({ type: 'LOAD_CAMPAIGN', payload: '' })
        return fetch(CAMPAIGN_URL + VIEW_SINGLE_SMS_API+ "?id=" + id , {
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        }).then(resp => resp.json()).then(data => {
            // console.log(data)
            dispatch({ type: 'VIEW_SINGLE_SMS_API', payload: data })
        })
    }
}
export const viewSingleNotificationAction = (id) => {
    //  console.log("id",id)
    const { token,role,organisation } = JSON.parse(localStorage.getItem('user'));
    const bearer = 'basic ' + token
    return dispatch => {
        dispatch({ type: 'LOAD_CAMPAIGN', payload: '' })
        return fetch(CAMPAIGN_URL + VIEW_SINGLE_NOTIFICATION_API+ "?id=" + id , {
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        }).then(resp => resp.json()).then(data => {
            // console.log(data)
            dispatch({ type: 'VIEW_SINGLE_NOTIFICATION_API', payload: data })
        })
    }
}
export const viewSingleEmailAction = (id) => {
    //  console.log("id",id)
    const { token,role,organisation } = JSON.parse(localStorage.getItem('user'));
    const bearer = 'basic ' + token
    return dispatch => {
        dispatch({ type: 'LOAD_CAMPAIGN', payload: '' })
        return fetch(CAMPAIGN_URL + VIEW_SINGLE_EMAIL_API+ "?id=" + id , {
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        }).then(resp => resp.json()).then(data => {
            // console.log(data)
            dispatch({ type: 'VIEW_SINGLE_EMAIL_API', payload: data })
        })
    }
}
export const activateEmailAction = (campaign_id) => {
    //  console.log("id",id)
    const { token,role,organisation } = JSON.parse(localStorage.getItem('user'));
    const bearer = 'basic ' + token
    return dispatch => {
        dispatch({ type: 'LOAD_CAMPAIGN', payload: '' })
        return fetch(CAMPAIGN_URL + ACTIVATE_EMAIL+ "?campaign_id=" + campaign_id , {
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        }).then(resp => resp.json()).then(data => {
            // console.log(data)
            dispatch({ type: 'ACTIVATION_EMAIL', payload: data })
        })
    }
}
export const deactivateEmailAction = (campaign_id) => {
    //  console.log("id",id)
    const { token,role,organisation } = JSON.parse(localStorage.getItem('user'));
    const bearer = 'basic ' + token
    return dispatch => {
        dispatch({ type: 'LOAD_CAMPAIGN', payload: '' })
        return fetch(CAMPAIGN_URL + DEACTIVATE_EMAIL+ "?campaign_id=" + campaign_id , {
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        }).then(resp => resp.json()).then(data => {
            // console.log(data)
            dispatch({ type: 'DEACTIVATION_EMAIL', payload: data })
        })
    }
}