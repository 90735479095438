import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { fetchChargingStatusAction, fetchEnergyAndRevenueGraph, fetchMonthSessionTypeAction } from "../../../../store/actions/insightAction";
import { Container, Row, Card } from "react-bootstrap";
import PureBreadcrumbs from "../../breadcrums";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CustomMonthlyEnergy from "./monthEnergyGraph";
import CustomStationEnergy from "./stationEnergyGraph";
import EnergyHeatMap from "./energyHeatmap";
import { useTranslation } from "react-i18next";


function EnergyInsightCard(props) {
    const { t } = useTranslation();
    const [startDate, setStartDate] = useState(
        new Date(Date.now() - 3600 * 1000 * 3600)
    );
    const [endDate, setEndDate] = useState(
        new Date(Date.now() + 3600 * 1000 * 24)
    );
    const loadNewData = () => {
        props.getMonthsData(startDate.toISOString().substr(0, 10), endDate.toISOString().substr(0, 10));
        props.getDateRangeEnergy(startDate.toISOString().substr(0, 10), endDate.toISOString().substr(0, 10));
    };

    useEffect(() => {
        props.getMonthsData(startDate.toISOString().substr(0, 10), endDate.toISOString().substr(0, 10));
        props.getDateRangeEnergy(startDate.toISOString().substr(0, 10), endDate.toISOString().substr(0, 10));
        props.getTotalCharging();
    }, []);

    const cardData = props.total_sesion && props.total_sesion;

    return (
        <Container fluid className="pb-4">
            <PureBreadcrumbs />
            <br />
            <Row >
                <div className="col-lg-4 col-sm-12">
                    <Card className="customercard">
                        <div style={{ textAlign: "center" }}>
                            <Card.Text style={{ fontSize: "30px", fontWeight: 900 }}>
                                {cardData.total_energy} <span> kWh</span>
                            </Card.Text>
                            <Card.Title style={{ fontSize: "13px" }}>
                                {t("LIFETIME    TOTAL CONSUMPTION")}
                            </Card.Title>
                        </div>
                    </Card>
                    <br />
                </div>
                <div className="col-lg-4 col-sm-12">
                    <Card className="customercard">
                        <div style={{ textAlign: "center" }}>
                            <Card.Text style={{ fontSize: "30px", fontWeight: 900 }}>
                                {cardData.average_energy} <span> kWh</span>
                            </Card.Text>
                            <Card.Title style={{ fontSize: "13px" }}>
                                {t("LIFETIME    AVERAGE CONSUMPTION")}
                            </Card.Title>
                        </div>
                    </Card>
                    <br />
                </div>
                <div className="col-lg-4 col-sm-12">
                    <Card className="customercard">
                        <div style={{ textAlign: "center" }}>
                            <Card.Text style={{ fontSize: "30px", fontWeight: 900 }}>
                                {cardData.today_energy} <span> kWh</span>
                            </Card.Text>
                            <Card.Title style={{ fontSize: "13px" }}>
                                {t("TODAY'S CONSUMPTION")}
                            </Card.Title>
                        </div>
                    </Card>
                    <br />
                </div>
            </Row>

            <Row >
                <div className="col-lg-12 col-sm-12">
                    <Card className="customercard">
                        <div style={{ textAlign: "right" }}>
                            <div className="row justify-content-end">
                                <div className="col-auto claender">
                                    <DatePicker
                                        className="gray-border"
                                        selected={startDate}
                                        onChange={(date) => setStartDate(date)}
                                        selectsStart
                                        startDate={startDate}
                                        dateFormat="dd/MM/yyyy"
                                        maxDate={new Date()}
                                    />
                                </div>
                                <span style={{ paddingTop: "7px" }}> To </span>
                                <div className="col-auto mx-2">
                                    <DatePicker
                                        className="gray-border"
                                        selected={endDate}
                                        onChange={(date) => setEndDate(date)}
                                        selectsEnd
                                        startDate={startDate}
                                        endDate={endDate}
                                        minDate={startDate}
                                        maxDate={new Date()}
                                        showDisabledMonthNavigation
                                        dateFormat="dd/MM/yyyy"
                                    />
                                </div>
                                <div className="csvbutton">
                                    <button className="btn btn-sm" onClick={loadNewData}>
                                        <span
                                            style={{
                                                fontSize: "16px",
                                                color: "#676767",
                                                fontWeight: "600",
                                            }}
                                        >
                                            GO
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <Row className=" mt-3">
                            <div className="col-lg-6 col-sm-12">
                                <CustomMonthlyEnergy {...props} />
                            </div>
                            <div className="col-lg-6 col-sm-12">
                                <CustomStationEnergy {...props} />
                            </div>
                        </Row>
                    </Card>
                </div>
            </Row>

            <div className="statuscard mt-4">
                <Card.Header>{t("Energy Heat Map")} </Card.Header>
                <EnergyHeatMap />
            </div>
        </Container>
    )

}

const mapStateToProps = (state) => {
    return {
        month_total: state.insight.month_session,
        loading: state.insight.isLoading,
        date_range_energy: state.insight.energy_revenue_insight,
        total_sesion: state.insight.charging_total_status,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        getMonthsData: (start, end) => dispatch(fetchMonthSessionTypeAction(start, end)),
        getDateRangeEnergy: (start, end) => dispatch(fetchEnergyAndRevenueGraph(start, end)),
        getTotalCharging: () => dispatch(fetchChargingStatusAction()),

    };
};

export default connect(mapStateToProps, mapDispatchToProps)(EnergyInsightCard);