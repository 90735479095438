import React, { useState, useEffect } from 'react'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useForm } from "react-hook-form"
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { Bounce, ToastContainer, toast } from 'react-toastify';

const GetChargerDiagnosticComponent = (props) => {

    const { t } = useTranslation();
    const [isToastVisible, setIsToastVisible] = useState(false);

    const diagnosticSchema = Yup.object().shape({
        evse_id: Yup.string().required('EVSE ID is required'),
        location: Yup.string().required('URL is required'),
        retries: Yup.number().required('Retries number is required').typeError('Retries can only be a number'),
        retryInterval: Yup.number().required('retryInterval number is required').typeError('retryInterval can only be a number'),

    });

    const evsedata = props.fetchstation && props.fetchstation

    const [startdate, setstartdate] = useState(new Date(Date.now() + (3600 * 1000 * 1)));
    const [enddate, setenddate] = useState(new Date(Date.now() + (3600 * 1000 * 720)));

    const { register, handleSubmit, reset, formState: { errors } } = useForm({ resolver: yupResolver(diagnosticSchema) });
    const onSubmit = async data => {
        // console.log(data)
        props.triggerGetDiagnostic(data, startdate, enddate)
    };

    // if (props.getDiagnostic.statuscode === 200) {
    //     alert("Success!!! ")
    // }


    useEffect(() => {
        if (props.getDiagnostic.statuscode === 200) {
            setIsToastVisible(true);
            toast.success('Success!!!', {
                position: "top-center",
                autoClose: 300,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
                onClose: () => {
                    setIsToastVisible(false);
                    window.location.reload();
                },
            });
        }
    }, [props.getDiagnostic]);



    return (

        <div className='container'>
            {isToastVisible && <div className="overlay" />}
            <ToastContainer
                position="top-center"
                autoClose={20}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
                style={{ top: "10%", width: "auto", zIndex: "9999" }}
            />
            <form onSubmit={e => e.preventDefault()}>
                <div className="form-floating">
                    <select className={`form-select form-control ${errors.evse_id ? 'is-invalid' : ''}`} name="evse_id" id="floatingSelectGridevse_id" {...register("evse_id")}>
                        <option value={""} >{t("Select EVSE ID")} (*)</option>
                        {evsedata && evsedata.evses.map((own) => (
                            <option key={own.evse_id} value={own.evse_id}>{own.evse_id}</option>
                        ))}
                    </select>
                    <div className="invalid-feedback">{errors.evse_id?.message}</div>
                </div><br />
                <div className="form-floating">
                    <input type="text" className={`form-control gray-border ${errors.location ? 'is-invalid' : ''}`} name="location" id="floatingInputAmt" placeholder={t("Place for keeping diagnostics file (URL) (*)")} {...register("location")} />
                    <div className="invalid-feedback">{errors.location?.message}</div>
                </div><br />
                <div className="form-floating">
                    <select className={`form-select form-control ${errors.retries ? 'is-invalid' : ''}`} name="retries" id="floatingSelectGridretries" {...register("retries")}>
                        <option value="">{t("Number of Attempts")} (*)</option>
                        <option value={1}>1</option>
                        <option value={2}>2</option>
                        <option value={3}>3</option>
                        <option value={4}>4</option>
                        <option value={5}>5</option>

                    </select>
                </div><br />
                <div className="form-floating">
                    <select className={`form-select form-control ${errors.retryInterval ? 'is-invalid' : ''}`} name="retryInterval" id="floatingSelectGridretryInterval" {...register("retryInterval")}>
                        <option value="">{t("Retry Interval")} (*)</option>
                        <option value={10}>10 minutes</option>
                        <option value={20}>20 minutes</option>
                        <option value={30}>30 minutes</option>
                        <option value={60}>60 minutes</option>
                        <option value={120}>120 minutes</option>

                    </select>
                </div><br />

                <div className="col-md-6 offset-md-2 text-center mt-3">
                    <div className='row'>
                        <div className='col-6 text-white'>
                            {t("Start Date")}:
                        </div>
                        <div className='col-6 '>
                            <DatePicker className="gray-border" selected={startdate} name={`startdate`} {...register(`startdate`)} onChange={(date) => setstartdate(date)} selectsStart startDate={startdate} dateFormat="dd/MM/yyyy" />
                            {/* <div className="invalid-feedback">{errors?.startdate?.message}</div> */}
                        </div>
                    </div>
                </div>

                <div className="col-md-6 offset-md-2 text-center mt-3">
                    <div className='row'>
                        <div className='col-6 text-white' >
                            {t("End Date")}:
                        </div>
                        <div className='col-6'>
                            <DatePicker className="gray-border" selected={enddate} name={`.enddate`} {...register(`.enddate`)} onChange={(date) => setenddate(date)} selectsStart startDate={enddate} dateFormat="dd/MM/yyyy" />
                            {/* <div className="invalid-feedback">{errors?.startdate?.message}</div> */}

                        </div>
                    </div>
                </div>
                <div className="col-xl-6 col-sm-6 col-12 float-right p-4">
                    <button className="lgn-btn btn" type="submit" onClick={handleSubmit(onSubmit)}>Get Charger Diagnostics</button>
                </div>
            </form>
        </div>
    )
}

export default GetChargerDiagnosticComponent;