import React from 'react'
import { useEffect, useMemo } from "react";
import { connect } from "react-redux";
import { Container, Card } from "react-bootstrap";
import Table from "../reacttable/table";
import ProgressBar from 'react-bootstrap/ProgressBar';
import { releaseTransaction } from '../../../store/actions/reportActions';
import { fetchFleetLiveCharging } from "../../../store/actions/fleetAction";

function FleetLiveCharging(props) {
    useEffect(() => {
        props.all();
        const interval = setInterval(() => {
            props.all();
        }, 300000)
        return () => clearInterval(interval)

    }, [])
    const data = props.list && props.list.data

    const handleReleaseClick = (charging_id) => {
        props.releaseTransaction(charging_id);
        window.location.reload();
    };

    const columns = useMemo(
        () => [
            {
                Header: 'EVSE ID', accessor: 'evse_id'
            },
            {
                Header: 'Port No', accessor: 'portno'
            },
            {
                Header: 'Customer Name', accessor: 'customer_name'
            },
            {
                Header: 'Customer Number', accessor: 'mobile'
            },
            {
                Header: 'Units consumed', accessor: 'kwh'
            },
            {
                Header: 'Total Amount', accessor: 'total_amount'
            },
            {
                Header: 'State of Charge', accessor: 'soc',
                Cell: (soc) => {
                    return (
                        <div>
                            {soc.value < 25 ? <ProgressBar variant="danger" animated now={soc.value} label={`${soc.value}%`} /> : <ProgressBar variant="success" animated now={soc.value} label={`${soc.value}%`} />}

                        </div>
                    )
                }
            },
            {
                Header: 'Charged Time', accessor: 'consume_time'
            },
            {
                Header: 'Stop Charging',
                accessor: 'charging_id',
                Cell: (charging_id) => {
                    return (
                        <div>
                            <button className="btn-danger" onClick={() => { handleReleaseClick(charging_id.value) }}> Stop Charging </button>
                        </div>
                    );

                },

            }
        ],
        []
    );

    return (
        <Container fluid>
            <div class="row">
                <div class="col-lg-12 col-sm-12 pad_t2">
                    <Card className="customercard ">
                        <Card.Header>Fleet Live Charging</Card.Header>
                        <div>
                            {data && data.length > 0 ?
                                (<div className="App"> <Table data={data} columns={columns}></Table></div>)
                                :
                                (<div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}><h3 className="loader">No data found</h3></div>)
                            }
                        </div>
                    </Card>
                </div>
            </div>
        </Container>
    )

}


const mapStateToProps = (state) => {
    return {
        list: state.fleet.fleet_live_charging,
        release: state.report.releasetransaction,
    }
}
const mapDispatchToProps = dispatch => {
    return {
        all: () => dispatch(fetchFleetLiveCharging()),
        releaseTransaction: (charging_id) => dispatch(releaseTransaction(charging_id))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(FleetLiveCharging)