import React from "react";
import { connect } from "react-redux";
import { useEffect } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { BsThreeDotsVertical } from "react-icons/bs";
import ApexCharts from "react-apexcharts";
import { fetchLifetimeEnergyRevenue } from "../../../../store/actions/insightAction";
import { t } from "i18next";
import { useTranslation } from "react-i18next";

function EnergyVSRevenueGraph(props) {
  const currency = process.env.REACT_APP_CURRENCY;
  const { t } = useTranslation();
  useEffect(() => {

    setTimeout(() => {
      props.getTotal();
    }, 1500);
  }, []);

  // console.log("Graphloading",props.RevenueGraphLoading)
  // console.log("EnergyVSRevenueGraph", props.total);
  const options = {
    options: {
      chart: {
        id: "energy-vs-revenue-chart",
        toolbar: {
          show: true,
        },
      },
      grid: {
        show: true,      // you can either change hear to disable all grids
        xaxis: {
          lines: {
            show: false  //or just here to disable only x axis grids
           }
         },  
        yaxis: {
          lines: { 
            show: false  //or just here to disable only y axis
           }
         },   
      },
      tooltip: {
        enabled: true,
        enabledOnSeries: undefined,
        followCursor: false,
        fillSeriesColor: false,
        theme: false,
        style: {
          fontSize: '12px',
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "60%",
          borderRadius: 5,
        },
      },
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        categories: ["Total"],
        labels: {
          style: {
            colors: "#FFFFFF", // Replace with the color you want for Y-axis content
          },
        },
      },
      yaxis: [
        {
          title: {
            text: "Total Life-time Energy",
            style: {
              color: "#FFFFFF",
              fontSize:"13px",
              fontWeight: 600,
          },
          },
          
          labels: {
            formatter: function (value) {
              return `${value.toFixed(2)}`;
            },
            style: {
              colors: "#FFFFFF", // Replace with the color you want for Y-axis content
            },
          },
        },
        {
          opposite: true,
          title: {
            text: "Total Life-time Revenue",
            style: {
              color: "#FFFFFF",
              fontSize:"13px",
              fontWeight: 600,
          },
          },

          labels: {
            formatter: function (value) {
              return `${currency} ${value.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
            },
            style: {
              colors: "#FFFFFF", // Replace with the color you want for Y-axis content
            },
            
          },
        },
      ],
      fill: {
        type: "gradient",
        gradient: {
          type: "vertical",
          shadeIntensity: 0.5,
          opacityFrom: 1,
          opacityTo: 0.8,
          stops: [0, 100],
        },
      },
      legend: {
        position: "top",
        labels: {
          colors: "#FFFFFF", // Replace with the desired color for legend labels
        },
      },
    },
    series: [
      {
        name: "Total Life-Time Consumption",
        data: [props.total && props.total.totalenergy],
      },
      {
        name: "Total Life-Time Revenue ",
        data: [props.total && props.total.totalrevenue],
      },
    ],

  };
  return (
    <div>
      <Card className="statuscard">
        <Card.Header>
          <Row>
            <Col xs={12} md={8}>
              <div className="text-left">{t('Life Time Energy VS Revenue Graph')}</div>
            </Col>
            <Col>
              <Col className="text-right">
                <div className="btn-group dropleft">
                  <button
                    className="btn btn-md"
                    type="button"
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <i className="text-white" title="Comparision Graph">
                      <BsThreeDotsVertical />
                    </i>
                  </button>
                  <div
                    className="dropdown-menu energy-revenue-graph-toolbar"
                    aria-labelledby="dropdownMenuButton"
                  >
                    <a className="dropdown-item" href="./energyrevenue">
                      {t("Report")}
                    </a>
                  </div>
                </div>
              </Col>
            </Col>
          </Row>
        </Card.Header>
        <div>
          {props.RevenueGraphLoading ? <h3 style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "340px",
          }}> Loading data...</h3> :
            props.total.totalenergy > 0 || props.total.totalrevenue > 0 ? (

              <ApexCharts
                options={options.options}
                series={options.series}
                type="bar"
                height={340}
              />) : (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "340px",
                }}
              >
                <b>No data found </b>
              </div>
            )}
        </div>
      </Card>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    RevenueGraphLoading: state.insight.isRevenueGraphLoading,
    total: state.insight.lifetime_energy_revenue,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getTotal: () => dispatch(fetchLifetimeEnergyRevenue()),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EnergyVSRevenueGraph);
