import React from "react";
import { useForm, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useState, useEffect } from "react";
import { Card, Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import PureBreadcrumbs from "../../breadcrums";
import "../loyalty.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { fetchRedeemRuleTypeDropdownApi, saveRedeemApi } from "../../../../store/actions/loyaltyAction";
import { ToastContainer, toast, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { HandleKeyPress } from "../../resuableComponent/handleKeyPress";

const CreateRedeem = (props) => {
    const { t } = useTranslation();
    const [isMatching, setIsMatching] = useState(false);
    const [startdate, setstartdate] = useState(new Date(Date.now() + (3600 * 1000 * 1)));
    const [enddate, setenddate] = useState(new Date(Date.now() + (3600 * 1000 * 720)));
    const [isToastVisible, setIsToastVisible] = useState(false);

    useEffect(() => {
        props.fetchDropdown();
    }, []);

    const redeemSchema = Yup.object().shape({
        redeem_name: Yup.string().required("Rule Name is required"),
        redeemGroup: Yup.array().of(
            Yup.object().shape({
                type: Yup.string().required("Rule type is required"),
                value: Yup.number().required("Rule value is required").typeError("Rule value is required"),
                points: Yup.number().required("Input value is required").typeError("Input value is required"),
            })
        ),
    });

    const {
        register,
        control,
        getValues,
        setValue,
        watch,
        handleSubmit,
        reset,
        formState: { errors, isValid },
    } = useForm({ resolver: yupResolver(redeemSchema), mode: "all" });
    const {
        fields: fieldsList1,
        remove: removeList1,
        append: appendList1,
    } = useFieldArray({ control, name: "redeemGroup" });
    const elementsTime = watch("redeemGroup");
    // console.log(elementsTime);
    const extractedRuleType = elementsTime && elementsTime.map(item => item.type);
    // console.log(extractedRuleType)

    const handleRuleType = (e, index) => {
        const selectedRuleType = e.target.value;
        // console.log("selectedRuleType: ", selectedRuleType);

        if (extractedRuleType.includes(selectedRuleType)) {
            setIsMatching(true);
        } else {
            setIsMatching(false);
        }
    };



    const onSubmit = async (data) => {
        props.saveRedeem(data, startdate, enddate);
        // console.log(data, startdate, enddate)
    };
    useEffect(() => {
        appendList1({ type: "", value: "", points: "" });
    }, []);
    useEffect(() => {
        // Update the watch function whenever the form state changes.
        // This ensures that the watch function reflects the latest state of the form.
        // This is for dynamic rule value lables.
        watch();
    }, [fieldsList1]);

    useEffect(() => {
        if (props.saveRedeemRes.statuscode === 200) {
            setIsToastVisible(true);
            toast.success('Redeem rule has been updated successfully!', {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
                onClose: () => {
                    setIsToastVisible(false);
                    window.location.reload();
                },
            });
        } else if (props.saveRedeemRes.statuscode === 405) {
            setIsToastVisible(true);
            toast.error(`❌ ${props.saveRedeemRes.error}`, {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
                onClose: () => {
                    setIsToastVisible(false);
                    window.location.reload();
                },
            });
        }
    }, [props.saveRedeemRes]);

    // console.log("elementsTime", elementsTime)
    return (
        <>
            {isToastVisible && <div className="overlay" />}
            <ToastContainer
                position="top-center"
                autoClose={20}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
                style={{ top: "10%", width: "auto", zIndex: "9999" }}
            />
            <div className="container-fluid">
                <PureBreadcrumbs />
                <Row className="d-flex justify-content-center align-items-center pt-5">
                    <Col xs={12} md={12} lg={12}>
                        <form autoComplete="off" onSubmit={(e) => e.preventDefault()}>
                            <div>
                                <Card className="d-flex justify-content-center align-items-center tariffcard">
                                    <Card.Body>
                                        <h6 className="text-center"> {t("Add New Redeem Rules")}</h6>

                                    </Card.Body>
                                </Card>
                                <Card className="tariffcard ml-0 mr-0">
                                    <Row >
                                        <Col lg={4}>
                                            <div className="col-lg">
                                                <div className="form-floating">
                                                    <label>
                                                        {t("Redeem Name")}
                                                        <span className="mandatory-field">*</span>
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className={`form-control gray-border ${errors.redeem_name ? "is-invalid" : ""
                                                            }`}
                                                        name={`redeem_name`}
                                                        id="floatingInputplan_name"
                                                        placeholder={t("Redeem Name")}
                                                        {...register(`redeem_name`)}
                                                    />
                                                    <div className="invalid-feedback">
                                                        {errors.redeem_name?.message}
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col lg={4} md={12} sm={12} className=" d-flex justify-content-start align-items-center">
                                            <div className="d-block text-center mt-4 ml-3 mr-4">
                                                {t("Select Start Date")} :
                                            </div>

                                            <DatePicker
                                                className='gray-border mt-4'
                                                selected={startdate}
                                                name="start"
                                                onChange={(date) => setstartdate(date)}
                                                selectsStart
                                                startdate={startdate}
                                                dateFormat="dd/MM/yyyy"
                                            />
                                        </Col>
                                        <Col lg={4} md={12} sm={12} className="d-flex justify-content-end  justify-content-sm-start justify-content-md-start align-items-center">
                                            <label className="text-center mt-4 ml-3 mr-4">
                                                {t("Select End Date")} :
                                            </label>
                                            <DatePicker
                                                className='gray-border mt-4'
                                                selected={enddate}
                                                onChange={(date) => setenddate(date)}
                                                selectsEnd
                                                startdate={startdate}
                                                enddate={enddate}
                                                minDate={startdate}
                                                showDisabledMonthNavigation
                                                dateFormat="dd/MM/yyyy"
                                            />
                                        </Col>
                                    </Row>
                                </Card>

                                {fieldsList1.slice(0).map((item, i) => (
                                    <Card className="tariffcard" key={i + 1}>
                                        {i === 0 ? (
                                            <h6 className="text-start p-3">
                                                <b>{t("Add New Redeem Rule")}</b>
                                            </h6>
                                        ) : (
                                            ""
                                        )}
                                        <Card.Body>
                                            <div className="row g-2 mt-3 ">
                                                <div className="col-md">
                                                    <div className="form-floating">
                                                        <label>
                                                            {t("Rule Type")}
                                                            <span className="mandatory-field">*</span>
                                                        </label>
                                                        <select
                                                            className={`form-select form-control ${errors.redeemGroup?.[i]?.type ? "is-invalid" : ""
                                                                }`}
                                                            name={`redeemGroup[${i}].type`}
                                                            id="floatingSelectGridtype"
                                                            {...register(`redeemGroup[${i}].type`)}
                                                        // onChange={(e) => handleRuleType(e, i)}
                                                        >
                                                            <option value={""}>{t("Select Rule Type")}</option>
                                                            {props.fetchRuleTypeDropdownRes && props.fetchRuleTypeDropdownRes.redeemtype?.map((v, k) => {
                                                                const unitSelected = fieldsList1.slice(0, i).some(field => field.type === v.value);
                                                                return !unitSelected && <option key={v.key} value={v.value}>{t(v.key)}</option>;
                                                            })}

                                                        </select>
                                                    </div>
                                                    <div className="invalid-feedback">
                                                        {errors.redeemGroup?.[i]?.type?.message}
                                                    </div>
                                                </div>


                                                <div className="col-md">
                                                    <div className="form-floating">
                                                        {watch(`redeemGroup.${i}.type`) === "" && <label>{t("Rule Value")}<span className='mandatory-field'>*</span></label>}
                                                        {watch(`redeemGroup.${i}.type`) === "kwh" && <label>{t("Rule Value ( kWh )")}<span className='mandatory-field'>*</span></label>}
                                                        {watch(`redeemGroup.${i}.type`) === "flat" && <label>{t("Rule Value ( Flat )")}<span className='mandatory-field'>*</span></label>}

                                                        <input
                                                            type="number"
                                                            className={`form-control gray-border ${errors.value ? "is-invalid" : ""
                                                                }`}
                                                            placeholder={t("Rule Value")}
                                                            name={`redeemGroup[${i}].value`}
                                                            id="floatingSelectGridperiod_end"
                                                            {...register(`redeemGroup[${i}].value`)}
                                                            min="1"
                                                            onKeyPress={HandleKeyPress}
                                                        />
                                                    </div>
                                                    <div className="invalid-feedback">
                                                        {errors.redeemGroup?.[i]?.value?.message}
                                                    </div>
                                                </div>
                                                <div className="col-sm mt-4" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                    <div className="form-floating" >
                                                        {t("Contains")}:
                                                    </div>

                                                </div>
                                                <div className="col-md">
                                                    <div className="form-floating">
                                                        <label>
                                                            {t("Input value points")}
                                                            <span className="mandatory-field">*</span>
                                                        </label>
                                                        <input
                                                            type="number"
                                                            className={`form-control gray-border ${errors.redeemGroup?.[i]?.points ? 'is-invalid' : ''}`}
                                                            placeholder={t('Input Value')}
                                                            name={`redeemGroup[${i}].points`}
                                                            id="floatingSelectGridperiod_end"
                                                            {...register(`redeemGroup[${i}].points`)}
                                                            min="1"
                                                            onKeyPress={HandleKeyPress}
                                                        />
                                                    </div>
                                                    <div className="invalid-feedback">
                                                        {errors.redeemGroup?.[i]?.points?.message}
                                                    </div>
                                                </div>
                                            </div>
                                        </Card.Body>
                                        <div className={`d-grid gap-2 p-1 ${isMatching ? "ml-3 d-flex justify-content-between" : "d-flex justify-content-end"}`}>
                                            {isMatching && (
                                                <p className="text-danger">{t("Rule type is already define.Please choose another rule type.")}</p>
                                            )}
                                            <button
                                                className="btn  btn-lg"
                                                type="button"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    removeList1(i);
                                                    setIsMatching(false);
                                                }}
                                            >
                                                {" "}
                                                <i
                                                    className="far fa-trash-alt"
                                                    style={{ color: "red" }}
                                                ></i>
                                            </button>
                                        </div>
                                    </Card>
                                ))}

                                <Card.Footer className={`row ml-1 mr-1 d-flex ${elementsTime && elementsTime.length >= 2 ? "justify-content-end" : "justify-content-between"}`}>
                                    {/* Add Another Redemption Rule Button */}
                                    {elementsTime && elementsTime.length <= 1 && (
                                        <Col lg={6} md={6} sm={12} xs={12} className="d-flex justify-content-center justify-content-md-start mt-2">
                                            <button
                                                className="btn btn-dark"
                                                type="button"
                                                disabled={isMatching}
                                                onClick={() => appendList1({ type: "", value: "", points: "" })}
                                            >
                                                (+) {t("Add Another Redemption Rule")}
                                            </button>
                                        </Col>
                                    )}

                                    {/* Create Redeem Button */}
                                    {props.loading ?
                                        (
                                            <Col lg={6} md={6} sm={12} xs={12} className="d-flex justify-content-center justify-content-md-end mt-2">
                                                <button className="lgn-btn btn" type="button" disabled>
                                                    <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                                                    {t("Saving...")}
                                                </button>
                                            </Col>
                                        )
                                        :
                                        (
                                            <Col lg={6} md={6} sm={12} xs={12} className="d-flex justify-content-center justify-content-md-end mt-2">
                                                <button
                                                    className="lgn-btn btn"
                                                    type="submit"
                                                    disabled={elementsTime && elementsTime.length === 0}
                                                    onClick={handleSubmit(onSubmit)}
                                                >
                                                    {t("CREATE REDEEM")}
                                                </button>
                                            </Col>
                                        )}
                                </Card.Footer>

                            </div>
                        </form>
                    </Col>
                </Row>
            </div >
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        loading: state.loyalty.isLoading,
        saveRedeemRes: state.loyalty.saveRedeem,
        fetchRuleTypeDropdownRes: state.loyalty.fetchRedeemRuleTypeDropdown,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        fetchDropdown: () => dispatch(fetchRedeemRuleTypeDropdownApi()),
        saveRedeem: (records, startdate, enddate) => dispatch(saveRedeemApi(records, startdate, enddate)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(CreateRedeem);
