import React, { useMemo, useEffect } from "react";
import { connect } from "react-redux";
import "../../reacttable/reactTable.css";
import Table from "../../reacttable/table";
import { Card } from "react-bootstrap";
import { fetchLifeTimeDailyList, fetchLifeTimeMonthlyList, fetchLifeTimeWeeklyList } from "../../../../store/actions/insightAction";
import { useTranslation } from "react-i18next";

function EnergyRevenueTable(props) {
  const { t } = useTranslation();
  useEffect(() => {
    props.getDailyTable();
    props.getWeeklyTable();
    props.getMonthlyTable();
  }, []);


  const monthlydata = props.getMonthlyList && props.getMonthlyList.data;
  // console.log("monthlyTable", monthlydata);

  const weeklydata = props.getWeeklyList && props.getWeeklyList.data;
  // console.log("weeklyTable", weeklydata);

  const dailydata = props.getDailyList && props.getDailyList.data;
  // console.log("DailyTable", dailydata);
  const columns = useMemo(
    () => [
      { Header: 'Name', accessor: 'customer_name' },
      { Header: 'Location', accessor: 'location' },
      { Header: 'City', accessor: 'city' },
      { Header: 'Charging time (HH:MM:SS)', accessor: 'consume_time' },
      { Header: 'Total kWh', accessor: 'kwh' },
      { Header: 'Charging Amount', accessor: 'charging_amount' },
      {
        Header: 'Tax Amount',
        id: "tax_amount1",
        accessor: (d) => {
          // console.log(d)
          return d.tax_amount.toFixed(2);
        },
      },
      { Header: 'Total Amount', accessor: 'total_amount' },
    ],
    []
  );



  return (
    <>
      <Card className="customercard mb-4">
        <div className="table-header">{t("Daily Data")}</div>

        {dailydata && dailydata.length > 0 ? (
          <div>
            <Table data={dailydata} columns={columns}></Table>
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {props.loading ? <h3>Loading data...</h3> : <h3>{t("No data found")} </h3>}
          </div>
        )}
      </Card>

      <Card className="customercard mb-4">
        <div className="table-header">{t("Weekly Data")}</div>

        {weeklydata && weeklydata.length > 0 ? (
          <div>
            <Table data={weeklydata} columns={columns}></Table>
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {props.loading ? <h3>Loading data...</h3> : <h3>{t("No data found")}</h3>}
          </div>
        )}
      </Card>

      <Card className="customercard mb-4">
        <div className="table-header">{t("Monthly Data")}</div>

        {monthlydata && monthlydata.length > 0 ? (
          <div>
            <Table data={monthlydata} columns={columns}></Table>
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {props.loading ? <h3>Loading data...</h3> : <h3>{t("No data found")} </h3>}
          </div>
        )}
      </Card>

    </>
  )

}






const mapStateToProps = (state) => {
  return {
    loading: state.insight.isLoading,
    getDailyList: state.insight.lifetime_daily_list,
    getWeeklyList: state.insight.lifetime_Weekly_list,
    getMonthlyList: state.insight.lifetime_monthly_list,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getDailyTable: () => dispatch(fetchLifeTimeDailyList()),
    getWeeklyTable: () => dispatch(fetchLifeTimeWeeklyList()),
    getMonthlyTable: () => dispatch(fetchLifeTimeMonthlyList()),

  };
};
export default connect(mapStateToProps, mapDispatchToProps)(EnergyRevenueTable);