import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Form, Button, Col, Row, Container, Card, Spinner } from 'react-bootstrap';
import { createGuestUserApi } from '../../../../store/actions/customerActions';
import { useTranslation } from 'react-i18next';
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai';
import { Bounce, ToastContainer, toast } from 'react-toastify';
import PureBreadcrumbs from "../../breadcrums";

export const GuestSignUp = (props) => {
    const { t } = useTranslation();
    const [passwordType, setPasswordType] = useState("password");
    const [confirmPasswordType, setconfirmPasswordType] = useState("password");
    const [isToastVisible, setIsToastVisible] = useState(false);

    // Define the regular expressions for validation
    const containsDigit = /.*\d.*/; // Contains at least one digit
    const containsUppercase = /.*[A-Z].*/; // Contains at least one uppercase letter
    const containsSpecialChar = /.*[@#$%^&+=!].*/; // Contains at least one special character


    const signupSchema = yup.object().shape({
        firstName: yup.string().required('First Name is required'),
        lastName: yup.string().required('Last Name is required'),
        mobile: yup.number().required('Mobile number is required').typeError('Mobile number is a required field.'),
        email: yup.string().email('Invalid email').required('Email is required'),
        password: yup
            .string()
            .required('Password is required')
            .matches(containsDigit, 'Password must contain at least one digit')
            .matches(containsUppercase, 'Password must contain at least one uppercase letter')
            .matches(containsSpecialChar, 'Password must contain at least one special character'),
        confirmPassword: yup
            .string()
            .required('Please confirm your password')
            .oneOf([yup.ref('password'), null], 'Confirm password must match')
            .matches(containsDigit, 'Confirm password must contain at least one digit')
            .matches(containsUppercase, 'Confirm password must contain at least one uppercase letter')
            .matches(containsSpecialChar, 'Confirm password must contain at least one special character'),
    });

    const { register, handleSubmit, formState: { errors }, } = useForm({ resolver: yupResolver(signupSchema), mode: "all" });

    const onSubmit = (data) => {
        // console.log(data);
        props.createCustomer(data);
    };

    const togglePassword = () => {
        if (passwordType === "password") {
            setPasswordType("text")
            return;
        }
        setPasswordType("password")
    }
    const toggleConfirmPassword = () => {
        if (confirmPasswordType === "password") {
            setconfirmPasswordType("text")
            return;
        }
        setconfirmPasswordType("password")
    }


    useEffect(() => {
        if (props.saveCustomerRes.statuscode === 200) {
            setIsToastVisible(true);
            toast.success('Customer Account created successfully', {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
                onClose: () => {
                    setIsToastVisible(false);
                    window.location.reload();
                },
            });
        } else if (props.saveCustomerRes.statuscode === 405) {
            setIsToastVisible(true);
            toast.error(`❌ ${props.saveCustomerRes.error}`, {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
                onClose: () => {
                    setIsToastVisible(false);
                    window.location.reload();
                },
            });
        }
    }, [props.saveCustomerRes]);


    return (
        <Container fluid>
            {isToastVisible && <div className="overlay" />}
            <ToastContainer
                position="top-center"
                autoClose={20}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
                style={{ top: "10%", width: "auto", zIndex: "9999" }}
            />
            <PureBreadcrumbs />

            <div className=''>
                <Row className='pt-5 d-flex justify-content-center align-items-center'>
                    <Col lg={8}>
                        <Card className='customercard' style={{ width: "auto", height: "auto" }}>
                            <Card.Body>
                                <Card.Header className='text-center'>{t("Customer Signup")}</Card.Header>
                                <Form autoComplete='off' onSubmit={handleSubmit(onSubmit)} className="p-4">
                                    <Row>
                                        <Col md={6}>
                                            <Form.Group controlId="firstName">
                                                <Form.Label>{t("First Name")}</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder={t("Enter first name")}
                                                    {...register('firstName')}
                                                    className={errors.firstName ? 'is-invalid' : ''}
                                                />
                                                {errors.firstName && <div className="invalid-feedback">{t(errors.firstName.message)}</div>}
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group controlId="lastName">
                                                <Form.Label>{t("Last Name")}</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder={t("Enter last name")}
                                                    {...register('lastName')}
                                                    className={errors.lastName ? 'is-invalid' : ''}
                                                />
                                                {errors.lastName && <div className="invalid-feedback">{t(errors.lastName.message)}</div>}
                                            </Form.Group>
                                        </Col>

                                        <Col md={6}>
                                            <Form.Group controlId="email">
                                                <Form.Label>Email</Form.Label>
                                                <Form.Control
                                                    type={t("email")}
                                                    placeholder={t("Enter your email")}
                                                    {...register('email')}
                                                    className={errors.email ? 'is-invalid' : ''}
                                                />
                                                {errors.email && <div className="invalid-feedback">{t(errors.email.message)}</div>}
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group controlId="mobile">
                                                <Form.Label>{t("Mobile Number")}</Form.Label>
                                                <Form.Control
                                                    type="number"
                                                    placeholder={t("Enter your mobile number")}
                                                    {...register('mobile')}
                                                    className={errors.mobile ? 'is-invalid' : ''}
                                                />
                                                {errors.mobile && <div className="invalid-feedback">{t(errors.mobile.message)}</div>}
                                            </Form.Group>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col md={6}>
                                            <Form.Group controlId="password" className="position-relative">
                                                <Form.Label>{t("Password")}</Form.Label>
                                                <Form.Control
                                                    type={passwordType}
                                                    placeholder={t("Enter your password")}
                                                    {...register('password')}
                                                    className={`${errors.password ? 'is-invalid' : ''}`}
                                                />
                                                <i className="btn guest_password_viewer" onClick={togglePassword}>
                                                    {passwordType === "password" ? <AiFillEyeInvisible /> : <AiFillEye />}
                                                </i>
                                                {errors.password && <div className="invalid-feedback">{t(errors.password.message)}</div>}
                                            </Form.Group>
                                        </Col>

                                        <Col md={6}>
                                            <Form.Group controlId="confirmPassword" className="position-relative">
                                                <Form.Label>{t("Confirm Password")}</Form.Label>
                                                <Form.Control
                                                    type={confirmPasswordType}
                                                    placeholder={t("Confirm your password")}
                                                    {...register('confirmPassword')}
                                                    className={errors.confirmPassword ? 'is-invalid' : ''}
                                                />
                                                <i className="btn guest_confirm_password_viewer" onClick={toggleConfirmPassword}>
                                                    {confirmPasswordType === "password" ? <AiFillEyeInvisible /> : <AiFillEye />}
                                                </i>
                                                {errors.confirmPassword && <div className="invalid-feedback">{t(errors.confirmPassword.message)}</div>}
                                            </Form.Group>
                                        </Col>
                                    </Row>


                                    <div className='d-flex justify-content-center'>
                                        <button disabled={props.loading ? true : false} className="lgn-btn btn mt-3" type="submit" onSubmit={handleSubmit(onSubmit)} >
                                            {props.loading ? (
                                                <>
                                                    <Spinner
                                                        as="span"
                                                        animation="grow"
                                                        size="sm"
                                                        role="status"
                                                        aria-hidden="true"
                                                    />
                                                    &nbsp;
                                                    {t("Saving...")}
                                                </>
                                            ) : (
                                                <p className=''>
                                                    {t("Sign Up")}
                                                </p>
                                            )}
                                        </button>
                                    </div>
                                </Form>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>

            </div>
        </Container>
    );
};

const mapStateToProps = function (state) {
    return {
        loading: state.customer.isLoading,
        saveCustomerRes: state.customer.saveCustomerAccount,
    }
}
const mapDispatchToProps = dispatch => {
    return {
        createCustomer: (data) => dispatch(createGuestUserApi(data)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(GuestSignUp);
