import React, { useState } from "react"
import { connect } from "react-redux";
import { Row, Col, Card } from "react-bootstrap";
import GoogleMapReact from 'google-map-react';
import GoogleMapStyles from "./GoogleMapStyles"
import "./ocpi.css"
import { fetchAllOcpi } from "../../../store/actions/ocpiAction";

const Marker = (props) => {
    const { name, address, id } = props;
    return (
        <div>
            <div className="pin bounce" style={{ cursor: 'pointer' }} title={"Name : " + name + '\n' + "Address : " + address} />
            <div className="pulse" />
        </div>
    );
};

function OPCIMap(props) {
    // console.log("map props:", props);
    const [center, setCenter] = useState({ lat: 20.5937, lng: 78.9629 });
    const [zoom, setZoom] = useState(4.8);
    return (
        <Row className="mt-3 mb-3">
            <Col>
                <Card className="customercard">
                    <div style={{ height: '81vh', width: '100%' }}>
                        <GoogleMapReact
                            options={{ styles: GoogleMapStyles }}
                            bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY }}
                            defaultCenter={center}
                            defaultZoom={zoom}
                            yesIWantToUseGoogleMapApiInternals
                        >
                            {props.fetchAllRes && props.fetchAllRes.data?.map((location, index) => {
                                const evsecordinates = location && location.coordinates
                                return (
                                    <Marker
                                        key={index}
                                        lat={evsecordinates.latitude}
                                        lng={evsecordinates.longitude}
                                        name={location.name}
                                        address={location.address}
                                        color="blue"
                                    />
                                )
                            })}

                        </GoogleMapReact>
                    </div>
                </Card>
            </Col>

        </Row>
    )
}

const mapStateToProps = (state) => {
    return {
        loading: state.evse.isEvseLoading,
        fetchAllRes: state.ocpi.fetchAll,
    }
}
const mapDispatchToProps = dispatch => {
    return {
        allOcpiList: (partner_name) => dispatch(fetchAllOcpi(partner_name)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(OPCIMap)