import React from 'react'
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { Row, Col, Card } from "react-bootstrap";
import DatePicker from "react-datepicker";
import * as Yup from 'yup';
import { connect } from "react-redux";
import { createEmailCampaignAction } from "../../../../store/actions/campaignAction";
import { useTranslation } from 'react-i18next';
import { Bounce, ToastContainer, toast } from 'react-toastify';

function CreateEmailCampaign(props) {
    const { t } = useTranslation();
    const [isToastVisible, setIsToastVisible] = useState(false);

    const emailSchema = Yup.object().shape({
        subject: Yup.string().required('Subject is required'),
        recipients: Yup.string().required('Recipients is required'),
        message: Yup.string().required('Message Body is required'),
    });
    const [startDate, setStartDate] = useState(new Date(Date.now()));
    const { register, handleSubmit, reset, formState: { errors } } = useForm({ resolver: yupResolver(emailSchema) });

    const onSubmit = async data => {
        props.emailcreate(data, startDate)
    }
    // if (props.saveemail.statuscode === 200) {
    //     alert("Email campaign has been created.")
    //     window.location.reload();
    // }


    useEffect(() => {
        if (props.saveemail.statuscode === 200) {
            setIsToastVisible(true);
            toast.success('Email campaign has been created.', {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
                onClose: () => {
                    setIsToastVisible(false);
                    window.location.reload();
                },
            });
        } else if (props.saveemail.statuscode === 405) {
            setIsToastVisible(true);
            toast.error(`❌ ${props.saveemail.error}`, {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
                onClose: () => {
                    setIsToastVisible(false);
                    window.location.reload();
                },
            });
        }
    }, [props.saveemail]);

    return (
        <div className='container-fluid'>
            {isToastVisible && <div className="overlay" />}
            <ToastContainer
                position="top-center"
                autoClose={20}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
                style={{ top: "10%", width: "auto", zIndex: "9999" }}
            />
            {/* <Card className=''> */}
            {/* <Card.Body> */}
            <form onSubmit={e => e.preventDefault()}>
                <div className="form-floating">
                    <select autoFocus className={`form-select form-control ${errors.unit ? 'is-invalid' : ''}`} name="recipients" id="floatingSelectGridU" {...register("recipients")}>
                        <option value="" disabled>Select Recipients</option>
                        <option value="All">All</option>
                    </select>
                    {/* <label for="floatingSelectGridU">Charging Unit</label> */}
                    <div className="invalid-feedback">{errors.recipients?.message}</div>
                </div><br />

                <div className="form-floating">
                    <label> Email Subject <span className='mandatory-field'>*</span> </label>
                    <input type="text" className={`form-control gray-border ${errors.subject ? 'is-invalid' : ''}`} name="subject" id="floatingInputGridsubject" placeholder="Subject" {...register("subject")} />
                    <div className="invalid-feedback">{errors.subject?.message}</div>
                </div><br />
                <div className="form-floating">
                    <label>Email Message <span className='mandatory-field'>*</span></label>
                    <input type="text" className={`form-control gray-border ${errors.message ? 'is-invalid' : ''}`} name="message" id="floatingInputGridmessage" placeholder="Email message" {...register("message")} />
                    <div className="invalid-feedback">{errors.message?.message}</div>
                </div><br />

                <div className="form-floating">
                    <Row>
                        <Col>
                            Select Date  <span className='mandatory-field'>*</span> :
                            <DatePicker
                                className='gray-border ml-3 w-75'
                                selected={startDate}
                                name="start"
                                onChange={(date) => setStartDate(date)}
                                selectsStart
                                startDate={startDate}
                                dateFormat="dd/MM/yyyy"
                            />
                            {startDate === null && <div className='invalid-feedback fa-1x'>{t("Date is required")}</div>}

                        </Col>

                    </Row><br />
                    <div>
                        <Row>
                            <Col lg={6} md={3} sm={12} xs={12} className="d-flex justify-content-center justify-content-md-start mt-2">
                                <button className="lgn-btn btn" type="button" onClick={() => {
                                    reset({
                                        recipients: "",
                                        subject: "",
                                        startDate: setStartDate(new Date(Date.now() + (3600 * 1000 * 1))),
                                    });
                                }}> {t("Reset")} </button>
                            </Col><br />
                            <Col lg={6} md={9} sm={12} xs={12} className='d-flex justify-content-center justify-content-md-end mt-2'>
                                {props.loading ?
                                    <button className="btn lgn-btn" type="button" disabled>
                                        <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                                        &nbsp;
                                        {t("Saving...")}
                                    </button>
                                    :
                                    <button className="lgn-btn btn" type="submit" disabled={startDate === null} onClick={handleSubmit(onSubmit)}>CREATE NEW EMAIL CAMPAIGN</button>
                                }
                            </Col>
                        </Row>

                    </div>
                </div><br />
                <br />

            </form>
            {/* </Card.Body> */}
            {/* </Card> */}
        </div>
    )
}


const mapStateToProps = (state) => {
    return {

        loading: state.campaign.isLoading,
        saveemail: state.campaign.emailcreate
    }
}
const mapDispatchToProps = dispatch => {
    return {

        emailcreate: (data, date) => dispatch(createEmailCampaignAction(data, date))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(CreateEmailCampaign)
