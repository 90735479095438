import React, { useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { Card, Row, Col, Container } from "react-bootstrap";
import { saveRfidAction } from "../../../../store/actions/customerActions";
import { connect } from "react-redux";
import PureBreadcrumbs from "../../breadcrums";
import { allCurrencyAction } from "../../../../store/actions/tariffAction";
import { useTranslation } from 'react-i18next';
import "../evse.css";
import { Bounce, ToastContainer, toast } from 'react-toastify';
import { allEvseAction } from '../../../../store/actions/evseAction';
import { HandleKeyPress } from '../../resuableComponent/handleKeyPress';

const CreateRFID = (props) => {
    const { t } = useTranslation();
    const [isAmountBased, setIsAmountBased] = useState(false);
    const [isRechargeAmountFree, setIsRechargeAmountFree] = useState(false);
    const [iskWhBased, setIskWhBased] = useState(false);
    const [isTimeBased, setIsTimeBased] = useState(false);
    const [rfidRechargeAmount, setRfidRechargeAmount] = useState('');
    const [kWhUsage, setkWhUsage] = useState('');
    const [kWhAmount, setKWhAmount] = useState('');
    const [isToastVisible, setIsToastVisible] = useState(false);
    const [time, setTime] = useState('');
    const [rfidType, setRfidType] = useState('');
    const [postpaidDuration, setPostpaidDuration] = useState('');
    const [timeRechargeAmount, setTimeRechargeAmount] = useState('');
    const [isPaymentCollectedOffline, setIsPaymentCollectedOffline] = useState(false);

    useEffect(() => {
        props.getAllCurrency();
        props.all();
    }, []);

    const rfidSchema = Yup.object().shape({
        rfid: Yup.string().required('RFID serial number is required'),
        commission: Yup.string().required('Commission date is required').matches(/^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/, 'Commission date must be a valid date in the format DD-MM-YYYY'),
        currency: Yup.string().required('Currency is required'),
        station_name: Yup.string().required("Station Name is required"),
    });

    const { register, handleSubmit, reset, formState: { errors } } = useForm({ resolver: yupResolver(rfidSchema), mode: "all" });

    const onSubmit = async data => {

        const submissionData = {
            ...data,
            isAmountBased: isAmountBased,
            isRfidFreeCharger: isRechargeAmountFree,
            rfidRechargeAmount: isAmountBased ? rfidRechargeAmount : '',
            iskWhBased: iskWhBased,
            kWhUsage: iskWhBased ? kWhUsage : '',
            kWhAmount: kWhAmount,
            isTimeBased: isTimeBased,
            time: isTimeBased ? time : '',
            timeRechargeAmount: timeRechargeAmount,
            rfidType: rfidType,
            postpaidDuration: postpaidDuration,
            isPaymentCollectedOffline: isPaymentCollectedOffline === "true" ? true : false,
        };
        // console.log("submissionData", submissionData);
        props.save(submissionData);
    };

    const handleFreeCharge = () => {
        setRfidRechargeAmount('');
        setIsRechargeAmountFree(!isRechargeAmountFree);
        if (!isRechargeAmountFree) {
            setRfidRechargeAmount('0');
            // setIsPaymentCollectedOffline(false)
        }
    }

    const handleAmountBasedChange = () => {
        // setRfidType('prepaid')
        setKWhAmount('');
        setRfidRechargeAmount('');
        setIsRechargeAmountFree(false)
        setIsPaymentCollectedOffline(false)
        setIsAmountBased(!isAmountBased);
        if (!isAmountBased) {
            setIskWhBased(false);
            setkWhUsage('');
            // setRfidType('')
        }
    };

    const handleKiloWattBasedChange = () => {
        // setRfidType('prepaid')
        setKWhAmount('')
        setRfidRechargeAmount('');
        setIsRechargeAmountFree(false)
        setIsPaymentCollectedOffline(false)
        setIskWhBased(!iskWhBased);
        if (!iskWhBased) {
            setIsAmountBased(false);
            setRfidRechargeAmount('');
            // setRfidType('')
        }
    };

    const handleTimeBasedChange = () => {
        // setRfidType('prepaid')
        setKWhAmount('')
        setRfidRechargeAmount('');
        setTimeRechargeAmount('');
        setIsRechargeAmountFree(false)
        setIsPaymentCollectedOffline(false)
        setIsTimeBased(!isTimeBased);
        if (!isTimeBased) {
            setTime('');
            // setRfidType('')
        }
    };

    const handleRfidTypeChange = (e) => {
        // setPostpaidDuration('');
        const value = e.target.value;
        setRfidType(value);
        // console.log("billing type", e.target.value);
    };

    const handleSubDuration = (e) => {
        const value = e.target.value;
        setPostpaidDuration(value);
    };

    const handleIsCollectedOffline = (e) => {
        const value = e.target.value;
        // console.log(value)
        setIsPaymentCollectedOffline(value);
    };


    // console.log("rfidType", rfidType);
    // console.log("postpaid type", postpaidDuration);
    // console.log("isPaymentCollectedOffline", isPaymentCollectedOffline);

    const isSubmitDisabled = () => {
        if (isAmountBased && (!rfidRechargeAmount)) {
            // console.log("1")
            return true;
        }
        if (iskWhBased && (!kWhUsage || !kWhAmount)) {
            // console.log("2")
            return true;
        }
        if (isTimeBased && (!time || !timeRechargeAmount)) {
            // console.log("3")
            return true;
        }
        if (!isRechargeAmountFree && !rfidType) {
            // console.log("4")
            return true;
        }
        if (rfidType === 'postpaid' && !postpaidDuration) {
            // console.log("5")
            return true;
        }
        if (isAmountBased && !isPaymentCollectedOffline) {
            // console.log("6")
            return false;
        }
        if ((iskWhBased || isTimeBased) && !isPaymentCollectedOffline) {
            // console.log("7")
            return true;
        }
        if (!isRechargeAmountFree) {
            // console.log("8")
            return false;
        }
        // console.log("No if condition is printed")

        return false;
    };


    useEffect(() => {
        if (props.response.statuscode === 200) {
            setIsToastVisible(true);
            toast.success('RFID has been saved successfully.', {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
                onClose: () => {
                    setIsToastVisible(false);
                    window.location.reload();
                },
            });
        } else if (props.response.statuscode === 405) {
            setIsToastVisible(true);
            toast.error(`❌ RFID Number has already been registered. Please choose a different number`, {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
                onClose: () => {
                    setIsToastVisible(false);
                    window.location.reload();
                },
            });
        }
    }, [props.response]);

    return (
        <Container fluid>
            {isToastVisible && <div className="overlay" />}
            <ToastContainer
                position="top-center"
                autoClose={20}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
                style={{ top: "10%", width: "auto", zIndex: "9999" }}
            />
            <PureBreadcrumbs />
            <br />
            <Row>
                <Col xs={12} md={1}></Col>
                <Col xs={12} md={10}>
                    <Card className="tariffcard">
                        <Card.Text style={{ fontSize: "14px", padding: "10px", paddingLeft: "15px" }}><b>{t("Commission RFID")}</b></Card.Text>
                        <Card.Body>
                            <form onSubmit={e => e.preventDefault()}>
                                <div className="form-floating">
                                    <label>{t("RFID")} <span className='mandatory-field'>*</span></label>
                                    <input type="text" autoFocus className={`form-control gray-border ${errors.rfid ? 'is-invalid' : ''}`} name="rfid" id="floatingInputGridrfid" placeholder={t("RFID")} {...register("rfid")} />
                                    <div className="invalid-feedback">{errors.rfid?.message}</div>
                                </div>

                                <div className="form-floating mt-3">
                                    <label>{t("Date")} <span className='mandatory-field'>*</span></label>
                                    <input type="date" className={`form-control gray-border ${errors.commission ? 'is-invalid' : ''}`} name="commission" id="floatingInputGridName" placeholder={t("Date")} {...register("commission")} />
                                    <div className="invalid-feedback">{errors.commission?.message}</div>
                                </div>

                                <Row className="row d-flex align-items-center mt-4">
                                    <Col lg={4} className='mt-1'>
                                        <div className="">
                                            <input className="form-check-input ml-0" type="checkbox" id="amountBasedCheckbox" checked={isAmountBased} onChange={handleAmountBasedChange} disabled={iskWhBased || isTimeBased} />
                                            <label className="form-check-label" htmlFor="amountBasedCheckbox">
                                                {t("Amount Based")}
                                            </label>
                                        </div>
                                    </Col>
                                    <Col lg={4} className='mt-1'>
                                        <div>
                                            <input className="form-check-input ml-0" type="checkbox" id="kiloWattBasedCheckbox" checked={iskWhBased} onChange={handleKiloWattBasedChange} disabled={isAmountBased || isTimeBased} />
                                            <label className="form-check-label" htmlFor="kiloWattBasedCheckbox">
                                                {t("kWh Based")}
                                            </label>
                                        </div>
                                    </Col>
                                    <Col lg={4} className='mt-1'>
                                        <div>
                                            <input className="form-check-input ml-0" type="checkbox" id="timeBasedCheckbox" checked={isTimeBased} onChange={handleTimeBasedChange} disabled={isAmountBased || iskWhBased} />
                                            <label className="form-check-label" htmlFor="timeBasedCheckbox">
                                                {t("Time Based")}
                                            </label>
                                        </div>
                                    </Col>
                                </Row><br />

                                {isAmountBased && (
                                    <>
                                        <div className='row'>
                                            <div className='col-lg-6 col-md-12 d-flex align-items-center'>
                                                {!isPaymentCollectedOffline &&
                                                    <div className="mt-3">
                                                        <input className="form-check-input" type="checkbox" id="amountBasedCheckbox" checked={isRechargeAmountFree} onChange={handleFreeCharge} />
                                                        <label className="form-check-label" htmlFor="amountBasedCheckbox">
                                                            {t("IsFreeCharge?")}
                                                        </label>
                                                    </div>}
                                            </div>
                                            <br />
                                            <div className={` ${isPaymentCollectedOffline ? "col-lg-12" : "col-lg-6"} col-md-12 mt-2`}>
                                                <div className="form-floating">
                                                    <label>{t("RFID Recharge Amount")} <span className='mandatory-field'>*</span></label>
                                                    <input type="number" className={`form-control gray-border ${!rfidRechargeAmount && 'is-invalid'}`}
                                                        name="rfidRechargeAmount"
                                                        id="floatingInputRfidRechargeAmount"
                                                        placeholder={t("RFID Recharge Amount")}
                                                        disabled={isRechargeAmountFree}
                                                        value={rfidRechargeAmount}
                                                        onKeyPress={HandleKeyPress}
                                                        onChange={(e) => setRfidRechargeAmount(e.target.value)}
                                                    />
                                                    {!rfidRechargeAmount && <small className="text-danger">{t("RFID Recharge Amount is required")}</small>}
                                                </div>
                                            </div>
                                            <br />
                                        </div>
                                        {!isRechargeAmountFree &&

                                            <div className='row'>
                                                <div className='col-md'>
                                                    <div className="form-floating">
                                                        <label>{t("Is payment collected offline ?")} <span className='mandatory-field'>*</span></label>
                                                        <select className={`form-select form-control ${!isPaymentCollectedOffline ? 'is-invalid' : ''}`} disabled={isRechargeAmountFree} name="isPaymentCollectedOffline" id="floatingSelectGridisPaymentCollectedOffline" onChange={handleIsCollectedOffline} required>
                                                            <option value="">{t("Select Yes/No")}</option>
                                                            <option value={true}>{t("Yes")}</option>
                                                            <option value={false}>{t("No")}</option>
                                                        </select>
                                                        {!isPaymentCollectedOffline && <div className="invalid-feedback">{"This field is required"}</div>}
                                                    </div>
                                                </div>
                                            </div>}
                                    </>
                                )}

                                {iskWhBased && (
                                    <>
                                        <div className='row'>
                                            <div className='col-md'>
                                                <div className="form-floating">
                                                    <label>{t("kWh Usage")} <span className='mandatory-field'>*</span></label>
                                                    <input type="number" className={`form-control gray-border ${!kWhUsage && 'is-invalid'}`}
                                                        name="kiloWattUsage"
                                                        id="floatingInputKiloWattUsage"
                                                        placeholder={t("kWh Usage")}
                                                        value={kWhUsage}
                                                        onChange={(e) => setkWhUsage(e.target.value)}
                                                        onKeyPress={HandleKeyPress}

                                                    />
                                                    {!kWhUsage && <small className="text-danger">{t("kWh Usage is required")}</small>}
                                                </div>
                                            </div>
                                            <div className='col-md'>
                                                <div className='col-md'>
                                                    <div className="form-floating">
                                                        <label>{t("Recharge Amount")} <span className='mandatory-field'>*</span></label>
                                                        <input type="number"
                                                            className={`form-control gray-border ${!kWhAmount && 'is-invalid'}`}
                                                            name="kWhAmount"
                                                            id="floatingInputkWhAmount"
                                                            placeholder={t("Recharge Amount")}
                                                            value={kWhAmount}
                                                            onChange={(e) => setKWhAmount(e.target.value)}
                                                            onKeyPress={HandleKeyPress}

                                                        />
                                                        {!kWhAmount && <small className="text-danger">{t("Recharge Amount is required")}</small>}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <br />
                                        <div className='row'>
                                            <div className='col-md'>
                                                <div className="form-floating">
                                                    <label>{t("Is payment collected offline ?")} <span className='mandatory-field'>*</span></label>
                                                    <select className={`form-select form-control ${!isPaymentCollectedOffline ? 'is-invalid' : ''}`} name="isPaymentCollectedOffline" id="floatingSelectGridisPaymentCollectedOffline" onChange={handleIsCollectedOffline} required>
                                                        <option value="">{t("Select Yes/No")}</option>
                                                        <option value={true}>{t("Yes")}</option>
                                                        <option value={false}>{t("No")}</option>
                                                    </select>
                                                    {!isPaymentCollectedOffline && <div className="invalid-feedback">{"This field is required"}</div>}
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )}

                                {isTimeBased && (
                                    <>
                                        <div className='row'>
                                            <div className='col-md'>
                                                <div className="form-floating">
                                                    <label>{t("Time")} <span className='mandatory-field'>*</span></label>
                                                    <input type="number" className={`form-control gray-border ${!time && 'is-invalid'}`}
                                                        name="time" id="floatingInputTime"
                                                        placeholder={t("Free No. of Hours")}
                                                        value={time}
                                                        onChange={(e) => setTime(e.target.value)}
                                                        onKeyPress={HandleKeyPress}

                                                    />
                                                    <small className="form-text text-muted">
                                                        {t("Free No. Of Hours")}
                                                    </small>
                                                    {!time && <small className="text-danger">{t("Time is required")}</small>}
                                                </div>
                                            </div>
                                            <div className='col-md'>
                                                <div className="form-floating">
                                                    <label>{t("Recharge Amount")} <span className='mandatory-field'>*</span></label>
                                                    <input type="number"
                                                        className={`form-control gray-border ${!timeRechargeAmount && 'is-invalid'}`}
                                                        name="RechargeAmount"
                                                        id="floatingInputRechargeAmount"
                                                        placeholder={t("Recharge Amount")}
                                                        value={timeRechargeAmount}
                                                        onChange={(e) => setTimeRechargeAmount(e.target.value)}
                                                        onKeyPress={HandleKeyPress}

                                                    />
                                                    {!timeRechargeAmount && <small className="text-danger">{t("Recharge Amount is required")}</small>}
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row mt-3'>
                                            <div className='col-md'>
                                                <div className="form-floating">
                                                    <label>{t("Is payment collected offline ?")} <span className='mandatory-field'>*</span></label>
                                                    <select className={`form-select form-control ${!isPaymentCollectedOffline ? 'is-invalid' : ''}`} name="isPaymentCollectedOffline" id="floatingSelectGridisPaymentCollectedOffline" onChange={handleIsCollectedOffline} required>
                                                        <option value="">{t("Select Yes/No")}</option>
                                                        <option value={true}>{t("Yes")}</option>
                                                        <option value={false}>{t("No")}</option>
                                                    </select>
                                                    {!isPaymentCollectedOffline && <div className="invalid-feedback">{"This field is required"}</div>}
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )}
                                <div className='row mt-3'>
                                    <div className='col-md'>
                                        <div className="form-floating">
                                            <label>{t("Tariff Currency")} <span className='mandatory-field'>*</span></label>
                                            <select className={`form-select form-control ${errors.currency ? 'is-invalid' : ''}`} name="currency" id="floatingSelectGridCurrency" {...register("currency")}>
                                                <option value="">{t("Select Currency")}</option>
                                                {props.allcurrency && props.allcurrency.currency.map((v, k) =>
                                                    <option key={v.country} value={v.currency_code}>{v.currency_code}</option>
                                                )}
                                            </select>
                                            <div className="invalid-feedback">{errors.currency?.message}</div>
                                        </div>
                                    </div>
                                    <div className='col-md'>
                                        <div className="form-floating">
                                            <label>
                                                {t("Select Station Name")}
                                                <span className="mandatory-field">*</span>
                                            </label>
                                            <select
                                                className={`form-select form-control ${errors.station_name ? "is-invalid" : ""
                                                    }`}
                                                name={`station_name`}
                                                id="floatingSelectGridstation_name"
                                                {...register(`station_name`)}
                                            // onBlur={handleBlur}
                                            >
                                                <option value={""}>{t("Select Station Name")}</option>
                                                <option value={"All"}>{t("All")}</option>
                                                {props.list &&
                                                    props.list.data?.filter((item) => item.status !== "Discovery")?.map((v, k) => (
                                                        <option key={v.stationid} value={v.name}>
                                                            {v.name}
                                                        </option>
                                                    ))}
                                            </select>
                                            <div className="invalid-feedback">
                                                {errors.station_name?.message}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {!isRechargeAmountFree &&
                                    <div className='row mt-3'>
                                        <div className='col-md'>
                                            <div className="form-floating">
                                                <label>{t("RFID Type")} <span className='mandatory-field'>*</span></label>
                                                <select className={`form-select form-control`} name="billingType" id="floatingSelectGridCurrency" onChange={(e) => handleRfidTypeChange(e)} >
                                                    <option value="">{t("Select RFID Type")}</option>
                                                    <option value="prepaid">{t("Prepaid")}</option>
                                                    {(isAmountBased || iskWhBased || isTimeBased) && (
                                                        <option value="postpaid">{t("Postpaid")}</option>
                                                    )}
                                                </select>
                                                {rfidType === '' && <div className="invalid-feedback">{"RFID Type is required"}</div>}
                                            </div><br />
                                        </div>
                                        <div className={`${rfidType === 'postpaid' ? "col-md" : "col-12"}`}>
                                            {rfidType === 'postpaid' &&
                                                <div className="form-floating">
                                                    <label>
                                                        {t("Postpaid Duration")}
                                                        <span className="mandatory-field">*</span>
                                                    </label>
                                                    <select
                                                        className={`form-select form-control ${postpaidDuration === '' ? "is-invalid" : ""
                                                            }`}
                                                        name={`subscriptionDuration`}
                                                        id="floatingSelectGridsubscriptionDuration"
                                                        onChange={(e) => handleSubDuration(e)}
                                                    >
                                                        <option value={""}>
                                                            {t("Select Postpaid Duration")}
                                                        </option>
                                                        <option value={"weekly"}>{t("Weekly")}</option>
                                                        <option value={"monthly"}>{t("Monthly")}</option>
                                                        <option value={"yearly"}>{t("Yearly")}</option>
                                                    </select>
                                                    {postpaidDuration === '' && <div className="invalid-feedback">{"Postpaid Duration is required"}</div>}
                                                </div>
                                            }
                                        </div>
                                    </div>}

                                <Row className='mt-3'>
                                    <Col className='col-md mt-2'>
                                        <button className="lgn-btn btn" type="button" onClick={() => reset()}> {t("Reset")} </button>
                                    </Col>
                                    <Col className='col-md mt-2 d-md-flex justify-content-end'>
                                        {props.loading ?
                                            <button className="btn lgn-btn" type="button" disabled>
                                                <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                                                &nbsp;
                                                {t("Saving...")}
                                            </button>
                                            :
                                            <button className="lgn-btn btn" type="submit" onClick={handleSubmit(onSubmit)} disabled={isSubmitDisabled()}>{t("SAVE RFID")}</button>
                                        }
                                    </Col>
                                </Row>
                                <br />
                            </form>
                        </Card.Body>
                    </Card>
                </Col>
                <Col xs={12} md={1}></Col>
            </Row>
        </Container >
    );
};

const mapStateToProps = (state) => {
    return {
        loading: state.customer.isLoading,
        response: state.customer.saveRfid,
        allcurrency: state.tariff.fetchcurrency,
        list: state.evse.allevse,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        save: (data) => dispatch(saveRfidAction(data)),
        getAllCurrency: () => dispatch(allCurrencyAction()),
        all: () => dispatch(allEvseAction()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateRFID);
