import React, { useEffect, useState } from "react";
import PureBreadcrumbs from "../breadcrums";
import { Col, Container, Row } from "react-bootstrap";
import OCPIList from "./ocpiList";
import OcpiMap from "./ocpiMap";
import { connect } from "react-redux";
import { allRoamingPartner, fetchAllOcpi } from "../../../store/actions/ocpiAction";
import { useTranslation } from "react-i18next";

function OCPICard(props) {
  const { t } = useTranslation();
  const [partnerName, setPartnerName] = useState("All");
  useEffect(() => {
    props.allPartner();
    props.allOcpiList(partnerName);
  }, [partnerName])

  const partnerNameList = props.list && props.list.data;

  const data = props.fetchAllRes && props.fetchAllRes.data;

  const handleChange = (e) => {
    let partnerid = e.target.value
    setPartnerName(partnerid)
  };

  return (
    <Container fluid className="pb-4">
      <PureBreadcrumbs />
      <br />
      <Row>
        <label className="ml-3 d-flex align-items-center" htmlFor="partnerNameDropdown">{t("Partner name")} :</label>
        <Col lg={4}>
          <select className='form-control form-select' id="partnerNameDropdown" onChange={handleChange}>
            <option value={"All"}>{t("All")}</option>
            {props.list && props.list.data && props.list.data.map((item) =>
              <option key={item.id} value={item.partner_name}>{item.partner_name}</option>
            )}
          </select>
        </Col>
      </Row>
      <OcpiMap {...props} />
      <OCPIList {...props} data={data} />
    </Container>
  );
}
const mapStateToProps = (state) => {
  return {
    loading: state.ocpi.isLoading,
    list: state.ocpi.allpartner,
    fetchAllRes: state.ocpi.fetchAll,
  }

}

const mapDispatchToProps = (dispatch) => {
  return {
    allPartner: () => dispatch(allRoamingPartner()),
    allOcpiList: (partner_name) => dispatch(fetchAllOcpi(partner_name)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OCPICard);
