import React from "react";
import "react-dates/initialize";
import { useMemo } from "react";
import "react-datepicker/dist/react-datepicker.css";
import Table from "../../reacttable/table";
import { useTranslation } from "react-i18next";

function ChargerReportTable(props) {
  const { t } = useTranslation();
  const data = props.charger_report && props.charger_report.data;
  // console.log(data);
  const columns = useMemo(
    () => [
      {
        Header: "Evse ID",
        accessor: "evse_id",
      },
      {
        Header: "Station Name",
        accessor: "stationid",
      },
      {
        Header: "Location",
        accessor: "location",
      },
      {
        Header: "City",
        accessor: "city",
      },
      {
        Header: "State",
        accessor: "state",
      },
      {
        Header: "Total Amount",
        accessor: "total",
      },
      {
        Header: "Total Energy",
        accessor: "energy",
      },
    ],
    []
  );
  return (
    <>
      {props.loading ? <h3 style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "250px",
      }}>Loading data...</h3> :
        props.charger_report && props.charger_report.data && props.charger_report.data.length !== null ? (
          <div>
            <Table data={data} columns={columns} ></Table>
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <h3>{t("No Data Found")}</h3>
          </div>
        )}
    </>
  )

}
export default ChargerReportTable;