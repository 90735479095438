import React from "react";
import { Row, Col, Card, Container } from "react-bootstrap";
import { useState, useEffect } from "react";
import { connect } from "react-redux";
import ApexCharts from "react-apexcharts";
import PureBreadcrumbs from "../../breadcrums";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  fetchSessionStatusByDate,
} from "../../../../store/actions/insightAction";
import { useTranslation } from "react-i18next";

function SessionStatusReport(props) {
  // Chart.register(...registerables);
  const { t } = useTranslation();
  const [startDate, setStartDate] = useState(
    new Date(Date.now() - 3600 * 1000 * 720)
  );
  const [endDate, setEndDate] = useState(
    new Date()
  );
  useEffect(() => {
    props.getSSByDate(startDate.toISOString().substr(0, 10), endDate.toISOString().substr(0, 10))
  }, [])

  const onSubmit = () => {
    props.getSSByDate(startDate.toISOString().substr(0, 10), endDate.toISOString().substr(0, 10));
  };

  let psmonthpush = [];
  let psStatusList = [];


  // const dataAvailable = props.total_s_s && props.total_s_s.List;
  // console.log(dataAvailable)
  const ps_month = props.total_s_s.List && props.total_s_s.List.map((m) => {
    if (m.number === 1) {
      psmonthpush.push("Jan");
      psStatusList.push(m.status);
    } else if (m.number === 2) {
      psmonthpush.push("Feb");
      psStatusList.push(m.status);
    } else if (m.number === 3) {
      psmonthpush.push("March");
      psStatusList.push(m.status);
    } else if (m.number === 4) {
      psmonthpush.push("April");
      psStatusList.push(m.status);
    } else if (m.number === 5) {
      psmonthpush.push("May");
      psStatusList.push(m.status);
    } else if (m.number === 6) {
      psmonthpush.push("Jun");
      psStatusList.push(m.status);
    } else if (m.number === 7) {
      psmonthpush.push("July");
      psStatusList.push(m.status);
    } else if (m.number === 8) {
      psmonthpush.push("Aug");
      psStatusList.push(m.status);
    } else if (m.number === 9) {
      psmonthpush.push("Sep");
      psStatusList.push(m.status);
    } else if (m.number === 10) {
      psmonthpush.push("Oct");
      psStatusList.push(m.status);
    } else if (m.number === 11) {
      psmonthpush.push("Nov");
      psStatusList.push(m.status);
    } else if (m.number === 12) {
      psmonthpush.push("Dec");
      psStatusList.push(m.status);
    }
  });
  const uniqueMonthSet = new Set(psmonthpush);
  const uniqueMonthArray = Array.from(uniqueMonthSet);
  // console.log("uniqueMonthArray",uniqueMonthArray);
  const session = {
    labels: uniqueMonthArray,
    datasets: [
      {
        name: "Completed",
        data: props.total_s_s && props.total_s_s.List.filter((m) => m.status === "Completed").map((item) => item.total)
      },
      {
        name: "Failed",
        data: props.total_s_s && props.total_s_s.List.filter((m) => m.status === "Failed").map((item) => item.total),
      },
      {
        name: "Faulted",
        data: props.total_s_s && props.total_s_s.List.filter((m) => m.status === "Activated").map((item) => item.total),
      },
      {
        name: "Rejected",
        data: props.total_s_s && props.total_s_s.List.filter((m) => m.status === "Rejected").map((item) => item.total),
      },
      {
        name: "SuspendedEVSE",
        data: props.total_s_s && props.total_s_s.List.filter((m) => m.status === "SuspendedEVSE").map((item) => item.total),
      },
      {
        name: "Unavailable",
        data: props.total_s_s && props.total_s_s.List.filter((m) => m.status === "Unavailable").map((item) => item.total),
      },
    ],
  };

  const options = {
    chart: {
      type: "bar",
      height: 350,
      stacked: false,
      toolbar: {
        show: false,
      },
    },
    grid: {
      show: true,      // you can either change hear to disable all grids
      xaxis: {
        lines: {
          show: false  //or just here to disable only x axis grids
         }
       },  
      yaxis: {
        lines: { 
          show: false  //or just here to disable only y axis
         }
       },   
    },
    tooltip: {
      enabled: true,
      enabledOnSeries: undefined,
      followCursor: false,
      fillSeriesColor: false,
      theme: false,
      style: {
        fontSize: '12px',
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        endingShape: "rounded",
        borderRadius: 5,
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: uniqueMonthArray,
      labels: {
        style: {
          colors: "#FFFFFF", // Replace with the color you want for Y-axis content
        },
      },
    },
    fill: {
      opacity: 1,
      gradient: {
        enabled: true,
        shadeIntensity: 1,
        inverseColors: false,
        opacityFrom: 0.5,
        opacityTo: 0,
        stops: [0, 90, 100],
      },
    },
    legend: {
      position: "top",
      labels: {
        colors: "#FFFFFF", // Replace with the desired color for legend labels
      },
    },
    yaxis: {
      title: {
        text: undefined,
      },
      labels: {
        style: {
          colors: "#FFFFFF", // Replace with the color you want for Y-axis content
        },
      },
    },

    colors: ["#007bff", "#00e396", "#feb019", "#ff4560", "#7655e4", "#002C4B"],
  };
  // console.log(props.loading);
  return (
    <Container fluid className="pb-2">
      <PureBreadcrumbs />
      <br />
      <Row className="mt-4 mb-4">
        <Col>
          <Card className="customercard">
            <Card.Header>
              <Row>
                <Col xs={12} md={4}>
                  <p>{t("Session Status Report")} </p>
                </Col>

                <Col xs={12} md={8}>
                  <div className="row justify-content-between float-right">
                    <div className="row justify-content-center">
                      <div className="col-auto">
                        <DatePicker
                          className="gray-border"
                          selected={startDate}
                          onChange={(date) => setStartDate(date)}
                          selectsStart
                          startDate={startDate}
                          maxDate={endDate}
                          dateFormat="dd/MM/yyyy"
                        />
                      </div>
                      <span style={{ paddingTop: "7px" }}> To </span>
                      <div className="col-auto mx-2">
                        <DatePicker
                          className="gray-border"
                          selected={endDate}
                          onChange={(date) => setEndDate(date)}
                          selectsEnd
                          startDate={startDate}
                          endDate={endDate}
                          minDate={startDate}
                          maxDate={new Date()}
                          showDisabledMonthNavigation
                          dateFormat="dd/MM/yyyy"
                          todayButton="Today"
                        />
                      </div>
                    </div>

                    <div className="csvbutton ml-3 mt-0">
                      <button className="btn btn-sm" onClick={onSubmit}>
                        <span
                          style={{
                            fontSize: "16px",
                            color: "#676767",
                            fontWeight: "600",
                          }}
                        >
                          GO
                        </span>
                      </button>
                    </div>
                  </div>
                </Col>
              </Row>
            </Card.Header>
            <Card.Text>
              {props.loading ? <h3 style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "600px",
              }}>Loading data...</h3> :
                props.total_s_s && props.total_s_s.List && props.total_s_s.List.length > 0 ? (

                  <ApexCharts
                    options={options}
                    series={session.datasets}
                    type="bar"
                    height={600}
                  />) : (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      height: "400px",
                    }}
                  >
                    <h3>{t("No data found")}</h3>
                  </div>
                )}
            </Card.Text>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

const mapStateToProps = (state) => {
  return {
    total_s_s: state.insight.daterange_session_status,
    loading: state.insight.isLoading,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getSSByDate: (start, end) => dispatch(fetchSessionStatusByDate(start, end)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(SessionStatusReport);
