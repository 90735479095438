import { Container, Row, Col, Card } from "react-bootstrap";
import PureBreadcrumbs from "../../breadcrums";
import React from "react";
import RFIDList from "./rfidList";
import { useTranslation } from "react-i18next";

function ViewRFID(props) {
  const {t} =useTranslation();
  return (
    <Container fluid>
      <PureBreadcrumbs />
      <br />
      <Row className="mt-4">
        <Col>
          <Card className="customercard">
            <div className="table-header">{t("RFID List")}</div>
            <RFIDList></RFIDList>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default ViewRFID;
