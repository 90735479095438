import { t } from "i18next";
import React from "react";
import ApexCharts from "react-apexcharts";
import { Card } from "react-bootstrap";

function CustomMonthlyRevenue(props) {
  const currency = process.env.REACT_APP_CURRENCY;

    //------------------------Monthly Graph Start ------------------------//
  let monthpush = [];
  //monthly calculation
 
  const monthlyamount = props.month_total && props.month_total.data.map((amt) => amt.amount);
  // console.log(monthlyamount)
  let monthlyyrevenue = [];
  for (let i = 0; i < monthlyamount.length; i++) {
    monthlyyrevenue.push(Math.ceil(monthlyamount[i]));
  }
  // console.log(monthlyyrevenue)
  //month label
  const monthlylabel =
    props.month_total &&
    props.month_total.data.map((label) => {
      const { number } = label.ID;
      let month = number;

      if (month === 12) {
        monthpush.push("December");
      } else if (month === 11) {
        monthpush.push("November");
      } else if (month === 10) {
        monthpush.push("October");
      } else if (month === 9) {
        monthpush.push("September");
      } else if (month === 8) {
        monthpush.push("August");
      } else if (month === 7) {
        monthpush.push("July");
      } else if (month === 6) {
        monthpush.push("June");
      } else if (month === 5) {
        monthpush.push("May");
      } else if (month === 4) {
        monthpush.push("April");
      } else if (month === 3) {
        monthpush.push("March");
      } else if (month === 2) {
        monthpush.push("February");
      } else if (month === 1) {
        monthpush.push("January");
      }
    });
    // console.log(monthpush.sort())

  const monthdata = {
    chart: {
      type: "area",
      height: 350,
    },
    grid: {
      show: true,
      xaxis: {
        lines: {
          show: false
         }
       },  
      yaxis: {
        lines: { 
          show: false
         }
       },   
    },
    tooltip: {
      enabled: true,
      enabledOnSeries: undefined,
      followCursor: false,
      fillSeriesColor: false,
      theme: false,
      style: {
        fontSize: '12px',
      },
    },
    xaxis: {
      categories: monthpush,
      labels: {
        style: {
          colors: "#FFFFFF", // Replace with the color you want for Y-axis content
        },
      },     
    },
    yaxis: [
      {
        title: {
          text: "Monthly Revenue",
          style: {
            color: "#FFFFFF",
            fontSize:"13px",
            fontWeight: 600,
        },
        },
        labels: {
          formatter: function (val) {
            return `${currency} ${val.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
          },
         
        },
        labels: {
          style: {
            colors: "#FFFFFF", // Replace with the color you want for Y-axis content
          },
        },     
      },
    ],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },
    colors: ["#5559B2"],
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.9,
        stops: [0, 100],
      },
    },
    series: [
      {
        name: "Monthly Revenue",
        data: monthlyyrevenue,
        yaxis: 1,
      },
    ],
    tooltip: {
      enabled: true,
      enabledOnSeries: undefined,
      followCursor: false,
      fillSeriesColor: false,
      theme: false,
      style: {
        fontSize: '12px',
      },
      x: {
        formatter: function (val) {
          return val;
        },
        
      },
    },
  };

  //------------------------Monthly Graph end ------------------------//  
    return (
        <Card className="statuscard">
            <Card.Title className="text-center">
             {t("Monthly Revenue Graph")}
            </Card.Title>
            <Card.Body>
            {props.loading  ? <h3  style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "250px",
                }}>Loading data...</h3> :  
                props.month_total && props.month_total.data.length > 0   ? (
 
                <ApexCharts
                          options={monthdata}
                          series={monthdata.series}
                          type="area"
                          height={250}
                />) : (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          height: "250px",
                        }}
                      >
                      <h3>{t("No data found")} </h3>
                      </div>
                )}
            </Card.Body>
          </Card>
    )
}


export default CustomMonthlyRevenue;
