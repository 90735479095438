import React from 'react'
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { fetchEvseStationAction } from "../../../../store/actions/evseAction";
import { triggerUpdateFirmware } from "../../../../store/actions/commandAction";
import { useTranslation } from 'react-i18next';
import { Bounce, ToastContainer, toast } from 'react-toastify';


const UpdateFirmwareModal = (props) => {
    const { t } = useTranslation();
    const { stationid } = useParams()
    const [isToastVisible, setIsToastVisible] = useState(false);

    useEffect(() => {
        props.fetch(stationid);
    }, []);

    const evsedata = props.fetchstation && props.fetchstation

    //logic for update firmware start ->>> //
    const [firmware, setFirmware] = useState();
    const disablePastDate = () => {
        const today = new Date();
        const dd = String(today.getDate() + 1).padStart(2, "0");
        const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
        const yyyy = today.getFullYear();
        return yyyy + "-" + mm + "-" + dd;
    };
    const handleChange = (e) => {
        setFirmware({
            ...firmware,
            [e.target.name]: e.target.value.trim(),
        });
    }
    const handleFirmwareSubmit = (e) => {
        e.preventDefault();
        props.triggerupdateFirmware(firmware);
    }
    //logic for update firmware  end//

    // if (props.updateFirmware.statuscode === 200) {
    //     alert("Success!!! ")
    //     // window.location.reload();
    // }


    useEffect(() => {
        if (props.updateFirmware.statuscode === 200) {
            setIsToastVisible(true);
            toast.success('Success!!!', {
                position: "top-center",
                autoClose: 300,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
                onClose: () => {
                    setIsToastVisible(false);
                    window.location.reload();
                },
            });
        }
    }, [props.updateFirmware]);

    return (
        <div className='container'>
            {isToastVisible && <div className="overlay" />}
            <ToastContainer
                position="top-center"
                autoClose={20}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
                style={{ top: "10%", width: "auto", zIndex: "9999" }}
            />
            <form onSubmit={handleFirmwareSubmit}>

                <div className="form-floating">
                    <input type="text" id="location" name="location" className="form-control gray-border control-label" placeholder={t("Path (*)")} onChange={handleChange} required />
                </div><br />


                <div className="form-label-group required">
                    <select
                        className="form-control  border-radius"
                        name="evse_id"
                        onChange={handleChange}
                        required
                    >
                        <option value="">{t("Select Charger")} (*)</option>
                        {evsedata && evsedata.evses.map((own) => (
                            <option key={own.evse_id} value={own.evse_id}>
                                {own.evse_id}
                            </option>
                        ))}
                    </select>
                </div><br />

                <div className="form-label-group">
                    <input className="form-control gray-border" type="date" id="retrieveDate" name="retrieveDate" min={disablePastDate()} onChange={handleChange} required />
                </div><br />

                <div className="form-label-group">
                    <select className="form-control border-radius" id="retries" name="retries" onChange={handleChange} >
                        <option value="">{t("Retries (Optional)")}</option>
                        <option>1</option>
                        <option>2</option>
                        <option>3</option>
                        <option>4</option>
                        <option>5</option>
                    </select>
                </div><br />

                <div className="form-label-group">
                    <input type="number" id="retryInterval" name="retryInterval" className="form-control gray-border control-label" placeholder={t("Retry Interval")} onChange={handleChange} />
                </div><br />

                <div className="col-xl-6 col-sm-6 col-12 float-right">
                    {/* <button className="lgn-btn second-button">
                        {!props.loading ? "Upload Firmware" : "Please wait..."}
                    </button> */}
                    <button className="lgn-btn btn">
                        {t("Upload Firmware")}
                    </button>
                </div>

            </form>
        </div>

    )

}

const mapStateToProps = (state) => {
    return {
        loadingevse: state.evse.isEvseLoading,
        fetchstation: state.evse.viewstation,
        updateFirmware: state.command.updateFirmware,
    }
}
const mapDispatchToProps = dispatch => {
    return {
        fetch: (stationid) => dispatch(fetchEvseStationAction(stationid)),
        triggerupdateFirmware: (firmware) => dispatch(triggerUpdateFirmware(firmware)),

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UpdateFirmwareModal)