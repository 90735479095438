import React, { useMemo, useState } from "react";
import { useEffect } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import PureBreadcrumbs from "../breadcrums";
import LogListComponent from "./loglist";
import { connect } from "react-redux";
import { fetOCPPLogsData, fetchAllOCPPLogsData } from "../../../store/actions/commandAction";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import ReactJson from "react-json-view";
import moment from "moment";
import CustomReportPaginationTable from "../report/adminReport/customReportPaginationTable";

const OCPPLogComponent = (props) => {
  const { t } = useTranslation();
  const [startDate, setStartDate] = useState(new Date(Date.now() - 3600 * 1000 * 24));
  const [endDate, setEndDate] = useState(new Date(Date.now() + 3600 * 1000 * 24));
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [search, setSearch] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const [org, setOrg] = useState("All");


  const data = props.fetchOcppLog && props.fetchOcppLog.data;
  const allData = props.fetchAllOcppLogRes && props.fetchAllOcppLogRes.data
  const reportCount = props.fetchOcppLog && props.fetchOcppLog.document_count;
  const defaultPageSize = 10;
  const pageCount = Math.ceil(reportCount / defaultPageSize)
  // console.log("data", data)
  // console.log("allData", allData)

  useEffect(() => {
    props.all(org, startDate.toISOString().substr(0, 10), endDate.toISOString().substr(0, 10), currentPage, pageSize);
    props.allLogs(org, startDate.toISOString().substr(0, 10), endDate.toISOString().substr(0, 10), currentPage, reportCount);
  }, [org, currentPage, pageSize, reportCount]);

  const loadNewData = () => {
    props.all(org, startDate.toISOString().substr(0, 10), endDate.toISOString().substr(0, 10), currentPage, pageSize);
  };


  const columns = useMemo(
    () => [
      {
        Header: "Date",
        id: "createdat",
        Cell: (cell) => {
          const item = cell
          const date = item?.item?.createdat;
          // console.log("item:", item);

          return (
            moment(date).local().format("DD-MM-YYYY hh:mm:ss")
          )
        },
      },
      {
        Header: "Charge Box Id",
        accessor: "evse_id",
      },
      {
        Header: "OCPP Command",
        accessor: "eventtype",
      },
      {
        Header: "Payload",
        id: "payload",
        Cell: (cell) => {
          const item = cell
          const payload = item?.item?.payload;
          // console.log("item:", item);

          return (
            <div>
              <ReactJson src={payload} collapsed={true} theme="monokai" />
            </div>
          );
        },
      },
    ],
    []
  );

  return (
    <Container fluid>
      <PureBreadcrumbs />
      <Row className="mt-4">
        <Col>
          <Card className="customercard">
            <Card.Header>
              <Row>
                <Col md={6} sm={12}>
                  <div className="table-header">{t("All Stations Logs")}</div>
                </Col>
                <Col md={6} sm={12}>
                  <div className="row justify-content-between float-right">
                    <div className="row justify-content-center">
                      <div className="col-auto claender">
                        <DatePicker
                          className="gray-border"
                          selected={startDate}
                          onChange={(date) => setStartDate(date)}
                          selectsStart
                          startDate={startDate}
                          dateFormat="dd/MM/yyyy"
                          maxDate={new Date()}
                        />
                      </div>
                      <span style={{ paddingTop: "7px" }}> To </span>
                      <div className="col-auto">
                        <DatePicker
                          className="gray-border"
                          selected={endDate}
                          onChange={(date) => setEndDate(date)}
                          selectsEnd
                          startDate={startDate}
                          endDate={endDate}
                          minDate={startDate}
                          showDisabledMonthNavigation
                          dateFormat="dd/MM/yyyy"
                          maxDate={new Date()}
                        />
                      </div>
                      <div className="col-auto"></div>
                    </div>
                    <div className="csvbutton ">
                      <button className="btn btn-sm mb-0" onClick={loadNewData}>
                        <span
                          style={{
                            fontSize: "16px",
                            color: "#676767",
                            fontWeight: "600",
                          }}
                        >
                          GO
                        </span>
                      </button>
                    </div>
                  </div>
                </Col>
              </Row>
            </Card.Header>
            {props.loadingevse ? <h3 style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "450px",
            }}>Loading data...</h3> :
              props.fetchOcppLog && props.fetchOcppLog.data ? (
                <CustomReportPaginationTable
                  data={data}
                  allData={allData}
                  pageCount={pageCount}
                  columns={columns}
                  search={search}
                  setSearch={setSearch}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  pageSize={pageSize}
                  setPageSize={setPageSize}
                  filteredData={filteredData}
                  setFilteredData={setFilteredData}
                  loading={props.loading}
                  t={t}
                />
              ) : (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "450px",
                  }}
                >
                  <h3>No data found</h3>
                </div>
              )}
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    loadingevse: state.command.isLoading,
    fetchOcppLog: state.command.ocppLogList,
    fetchAllOcppLogRes: state.command.fetchAllOCPPLogsData,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    all: (evseId, startDate, endDate, page, limit) => dispatch(fetOCPPLogsData(evseId, startDate, endDate, page, limit)),
    allLogs: (evseId, startDate, endDate, page, reportCount) => dispatch(fetchAllOCPPLogsData(evseId, startDate, endDate, page, reportCount)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(OCPPLogComponent);
