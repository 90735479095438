const initState = {
    isLoading: false,
    savepartner: '',
    fetchsinglepartner: '',
    allpartner: '',
    updatepartner: '',
    fetchAll: '',
    fetchSingleOcpi: '',
    activateRoamingPartner: '',
    deactivateRoamingPartner: '',
    fetchPartnerTariff: '',
}

const ocpiReducer = (state = initState, action) => {
    switch (action.type) {
        case 'OCPI_LOADING':
            return { ...state, isLoading: true }
        case 'SAVE_ROAMING_PARTNER':
            return { ...state, savepartner: action.payload, isLoading: false };
        case 'VIEW_ALL_ROAMING_PARTNER':
            return { ...state, allpartner: action.payload, isLoading: false };
        case 'VIEW_SINGLE_ROAMING_PARTNER':
            return { ...state, fetchsinglepartner: action.payload, isLoading: false };
        case 'UPDATE_ROAMING_PARTNER':
            return { ...state, updatepartner: action.payload, isLoading: false };
        case 'VIEW_ALL_OCPI_API':
            return { ...state, fetchAll: action.payload, isLoading: false };
        case 'FETCH_SINGLE_OCPI_API':
            return { ...state, fetchSingleOcpi: action.payload, isLoading: false };
        case 'ACTIVATE_ROAMING_PARTNER':
            return { ...state, activateRoamingPartner: action.payload, isLoading: false };
        case 'DEACTIVATE_ROAMING_PARTNER':
            return { ...state, deactivateRoamingPartner: action.payload, isLoading: false };
        case 'FETCH_PARTNER_TARIFF':
            return { ...state, fetchPartnerTariff: action.payload, isLoading: false };
        default:
            return state;
    }
}
export default ocpiReducer;