import PureBreadcrumbs from "../../breadcrums";
import React, { useEffect, useMemo } from "react";
import { connect } from "react-redux";
import { activateAdvertise, allAdvertise, deactivateAdvertise, fetchGraphApi } from "../../../../store/actions/advertisementAction";
import Table from "../../reacttable/table";
import moment from "moment";
import AddGraph from "./addGraph";
import { Row, Col, Card, Container } from "react-bootstrap";
import { FaEdit, FaEye } from "react-icons/fa";
import { TiTick } from "react-icons/ti";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { useTranslation } from "react-i18next";

function FetchAdvertise(props) {
  const { t } = useTranslation();
  useEffect(() => {
    props.allAdvertisement();

  }, []);

  const data = props.all && props.all.data;
  const total = props.all && props.all.data && props.all.data.length;
  const upcoming = props.all && props.all.data && props.all.data.filter((item) => item.status === "Active").length;
  const lapsed = props.all && props.all.data && props.all.data.filter((item) => item.status === "Inactive").length;
  const draft = props.all && props.all.data && props.all.data.filter((item) => item.status === "Draft").length;
  // console.log("draft", draft);
  // console.log("data", data);

  const activate = (advertiseid) => {
    // console.log(advertiseid);
    props.activateApi(advertiseid);
  };

  if (props.activateAd.statuscode === 200) {
    window.location.reload();
  }

  const deactivate = (advertiseid) => {
    // console.log(advertiseid);
    props.deactivateApi(advertiseid);
  };

  if (props.deactivateAd.statuscode === 200) {
    window.location.reload();
  }

  const columns = useMemo(
    () => [
      { Header: "Advertisement Name", accessor: "name" },
      { Header: "Description", accessor: "description" },
      { Header: "Evse ID", accessor: "evse_id" },
      {
        Header: "Advertisement Start Date",
        id: "start",
        accessor: (d) => {
          return moment(d.startdate).local().format("DD-MM-YYYY");
        },
      },
      {
        Header: "Advertisement End Date",
        id: "end",
        accessor: (d) => {
          return moment(d.enddate).local().format("DD-MM-YYYY");
        },
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: (status) => {
          // console.log(publish.value)
          return (
            <div>
              <span>
                {status.value === "Active" ? (
                  <span className="text-success">{t("Active")}</span>
                ) : status.value === "Inactive" ? (
                  <span className="text-danger">{t("Inactive")}</span>
                ) : status.value === "In Progress" ? (
                  <span className="text-info">{t("In Progress")}</span>
                ) : (
                  <span className="text-warning">{t("Draft")}</span>
                )}
              </span>
            </div>
          );
        },
      },
      {
        Header: "Actions",
        accessor: "id",
        Cell: ({ row }) => {
          const { original } = row;
          const id = original.id;
          const advertiseid = original.advertiseid;
          // console.log("id",id)
          // console.log("advertiseid",advertiseid)

          return (
            <div>
              <span>
                <a
                  href={`/dashboard/viewSingleAdvertise/${id}`}
                  rel="tooltip"
                  title="View"
                >
                  <FaEye />
                </a>
              </span>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <span>
                <a
                  href={`/dashboard/updateAdvertise/${id}`}
                  rel="tooltip"
                  title="Edit"
                >
                  <FaEdit />
                </a>
              </span>
              &nbsp;&nbsp;  &nbsp;&nbsp;
              <span style={{ cursor: "pointer" }}>
                <TiTick
                  onClick={() => activate(advertiseid)}
                  title="Activate"
                />
              </span>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <span style={{ cursor: "pointer" }}>
                <AiOutlineCloseCircle
                  onClick={() => deactivate(advertiseid)}
                  title="De-Activate"
                />
              </span>
            </div>
          );
        },
      },
    ],
    []
  );

  return (
    <Container fluid className="pb-2">
      <PureBreadcrumbs />
      <br />
      <Row className="mt-2">
        <Col className="col-md-3 col-12">
          <Card className="customercard card-custom total-rules">
            <div className="diagonal-line"></div>
            <div style={{ textAlign: "left" }}>
              <Card.Text style={{ fontSize: "14px" }}>{t("TOTAL ADS")}</Card.Text>
              <Card.Title>
                <b>{total !== undefined ? total : 0}</b>
              </Card.Title>
            </div>
          </Card>
          <br />
        </Col>
        <Col className="col-md-3 col-12">
          <Card className="customercard card-custom total-active-rules">
            <div className="diagonal-line"></div>
            <div style={{ textAlign: "left" }}>
              <Card.Text style={{ fontSize: "14px" }}>{t("TOTAL UPCOMING ADS")}</Card.Text>
              <Card.Title>
                <b>{upcoming !== undefined ? upcoming : 0}</b>
              </Card.Title>
            </div>
          </Card>
          <br />
        </Col>
        <Col className="col-md-3 col-12">
          <Card className="customercard card-custom total-inactive">
            <div className="diagonal-line"></div>
            <div style={{ textAlign: "left" }}>
              <Card.Text style={{ fontSize: "14px" }}>{t("TOTAL LAPSED ADS")}</Card.Text>
              <Card.Title>
                <b>{lapsed !== undefined ? lapsed : 0}</b>
              </Card.Title>
            </div>
          </Card>
          <br />
        </Col>
        <Col className="col-md-3 col-12">
          <Card className="customercard card-custom total-draft">
            <div className="diagonal-line"></div>
            <div style={{ textAlign: "left" }}>
              <Card.Text style={{ fontSize: "14px" }}>{t("TOTAL DRAFTS ADS")}</Card.Text>
              <Card.Title>
                <b>{draft !== undefined ? draft : 0}</b>
              </Card.Title>
            </div>
          </Card>
          <br />
        </Col>
      </Row>
      <div className="statuscard mb-4 ">
        <Card.Header>{t("Advertisement Statistics")}</Card.Header>
        <AddGraph />
      </div>
      <Card className="mb-4">
        <Row>
          <Col>
            <Card className="customercard">
              <div className="table-header">{t("Advertisement List")}</div>
              <Card.Body>
                {props.loading ? <h3 style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "450px",
                }}>Loading data...</h3> :
                  data && data.length > 0 ? (
                    <div>
                      {" "}
                      <Table data={data} columns={columns}></Table>
                    </div>
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <h3 className="loader">{t("No data found")}</h3>
                    </div>
                  )}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Card>
    </Container>
  );
}

const mapStateToProps = (state) => {
  return {
    loading: state.advertise.isLoading,
    all: state.advertise.fetchall,
    sixMonthGraphApi: state.advertise.fetch_six_month_graph_api,
    activateAd: state.advertise.activate,
    deactivateAd: state.advertise.deactivate,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    allAdvertisement: () => dispatch(allAdvertise()),
    activateApi: (advertiseid) => dispatch(activateAdvertise(advertiseid)),
    deactivateApi: (advertiseid) => dispatch(deactivateAdvertise(advertiseid)),
    graphData: () => dispatch(fetchGraphApi()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(FetchAdvertise);
