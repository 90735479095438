import React, { useMemo, useEffect } from "react";
import { connect } from "react-redux";
import { Card } from "react-bootstrap";
import Table from "../../reacttable/table";
import "../../reacttable/reactTable.css";
import {
  fetchCustomerAllSearchResult,
} from "../../../../store/actions/insightAction";

function CustomerPredictiveList(props) {
  useEffect(() => {
    props.getTopSearch();
  }, []);

  const dataSearch = props.topSearch && props.topSearch.data && props.topSearch.data.map((en) => en.Search);
  const FlatData = dataSearch && dataSearch.flat();
  // console.log(FlatData);
  // console.log(props.loading)

  const columns = useMemo(
    () => [
      {
        Header: "Address",
        accessor: "address",
      },
      {
        Header: "State",
        accessor: "state",
      },
      {
        Header: "Country",
        accessor: "country",
      },
      {
        Header: "Latitude",
        accessor: "latitude",
      },
      {
        Header: "Longitude",
        accessor: "longitude",
      },

      {
        Header: "Search count",
        accessor: "search_count",
      },
    ],
    []
  );

  return (
    <div class="row">
      <div class="col-lg-12 col-sm-12 pad_t2">
        <Card className="customercard">
          <div className="table-header"> User Search Behaviour</div>
          <div>

            {FlatData && FlatData.length > 0 ? (
              <div>
                <Table data={FlatData} columns={columns}></Table>
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "355px",
                }}
              >
                <h3>No data found </h3>
              </div>
            )}
            {/* {FlatData && FlatData.length > 0 ? (
              <div>
                <Table data={FlatData} columns={columns}></Table>
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <h3 className="loader">No data found</h3>
              </div>
            )} */}
          </div>
        </Card>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    topSearch: state.insight.customer_all_searches,
    // loading: state.insight.isLoading,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getTopSearch: () => dispatch(fetchCustomerAllSearchResult()),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomerPredictiveList);
