import { Card, Row, Col } from "react-bootstrap";
import React from "react";
import Table from "../reacttable/table";
import { useTranslation } from "react-i18next";
import { FaEye } from "react-icons/fa";

function OCPIList(props) {
  const { data } = props;
  const { t } = useTranslation();
  console.log(data);

  const columns = React.useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
        id: "1",
      },
      {
        Header: "Address",
        accessor: "address",
      },
      {
        Header: "City",
        accessor: "city",
      },
      {
        Header: "State",
        accessor: "state",
      },
      {
        Header: "Operator",
        accessor: "operator.name",
      },
      {
        Header: "Actions",
        accessor: "name",
        Cell: (name) => {
          return (
            <div>
              <span>
                <a
                  href={`/dashboard/viewSingleOcpi/${name.value}`}
                  rel="tooltip"
                  title="View"
                >
                  <FaEye />
                </a>
              </span>
              &nbsp;&nbsp;
            </div>
          );
        },
      },
    ],
    []
  );
  const loadNewData = () => {
    window.location.reload();
  };
  return (
    <Row>
      <Col>
        <Card className="customercard">
          <Card.Header>
            <Row>
              <div className="table-header">{t("View OCPI")}</div>
              <Col md={10} sm={12}>
                <div className="row justify-content-between float-right">
                  <div>
                    <button className="btn btn-sm" onClick={loadNewData}>
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/images/ocpi/ocpirefresh.svg"
                        }
                        alt="refresh"
                        style={{ height: "25px" }}
                      />
                    </button>
                  </div>
                </div>
              </Col>
            </Row>
          </Card.Header>

          {data && data.length > 0 ? (
            <div>
              <Table data={data} columns={columns}></Table>
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <h3 className="loader">{t("No data found")}</h3>
            </div>
          )}
        </Card>
      </Col>
    </Row>
  );
}

export default OCPIList;
