import React from 'react'
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { Container, Row, Col, Card } from "react-bootstrap";
import { fetchEvseStationAction } from "../../../../store/actions/evseAction";
import { fetchConfigData, saveConfig } from "../../../../store/actions/commandAction";
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';



const GetConfiguration = (props) => {
    const { t } = useTranslation();
    const { stationid } = useParams()
    const [validConfigKey, setValidConfigKey] = useState()
    const [validEvseID, setValidEvseID] = useState('');
    const configSchema = Yup.object().shape({
        configuration_Key: Yup.array().of(
            Yup.object().shape({
                key: Yup.string(),
                readonly: Yup.boolean(),
                value: Yup.string(),
            })),
    });
    // Call api to get EVSE ids
    useEffect(() => {
        if (stationid) {
            props.fetch(stationid);
        }
    }, []);

    // Logic for getting all evse ids
    const evsedata = props.fetchstation && props.fetchstation;
    const evseIDArray = evsedata && evsedata.evses.map((item) => item.evse_id);
    const firstEvseId = evseIDArray && evseIDArray[0]; // Get first evse id 
    // console.log("firstEvseIdfirstEvseId",firstEvseId)
    useEffect(() => {
        props.newConfig(firstEvseId)
        setValidEvseID(firstEvseId)
    }, [firstEvseId]);

    const handleGetConfig = (e) => {
        const evse_id = e.target.value
        props.newConfig(evse_id)
        setValidEvseID(evse_id)
    }

    const newConfigData = props.fetchNewConfig && props.fetchNewConfig.data && props.fetchNewConfig.data?.[0]

    const { register, control, setValue, reset, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(configSchema),
        mode: "all"
    });
    const { fields: fieldsList1 } = useFieldArray({
        control,
        name: "configuration_Key", // Same as the field name in defaultValues
    });

    useEffect(() => {
        if (newConfigData) {
            const { configurationKey } = newConfigData;
            reset({ "configuration_Key": configurationKey });
            setValidConfigKey(configurationKey)
            setValue("configuration_Key", configurationKey);
        } if (!newConfigData) {
            reset({ "configuration_Key": newConfigData });
        }

    }, [reset, setValue, newConfigData]);

    const onSubmit = async data => {
        console.log(data)
        props.saveNewConfig(data, validEvseID)
    };


    if (props.updateConfig.statuscode === 200) {
        alert("Configuration Updated successfully")
        window.location.reload();
    }

    return (

        <Container fluid>
            <Row className='mt-2'>
                <Col md={12} sm={12} lg={12}>
                    <div className="form-label-group required mr-2">
                        <label><b>{t("View and Change charger configuration")}</b></label>
                        <select
                            className="form-control border-radius"
                            name="evse_id"
                            required
                            onChange={handleGetConfig}
                        >
                            {evsedata && evsedata.evses.map((own, idx) => (
                                <option key={own.evse_id} value={own.evse_id}>
                                    {own.evse_id}
                                </option>
                            ))}
                        </select>
                    </div>
                </Col>
            </Row>
            {newConfigData && fieldsList1 && fieldsList1.map((item, i) => (
                <Row key={i} className='mt-2 mb-2 command_card_css'>
                    <Card className=' col-12'>
                        <Card.Body>
                            <Row>
                                <div className="col-4">
                                    <div className="form-floating">
                                        <label>{t("Key Name")}</label>
                                        <input disabled type="text" className={`form-control gray-border`} id="floatingInputkey" placeholder="key Name" {...register(`configuration_Key.${i}.key`)} />
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="form-floating">
                                        <label>{t("Read Only")}?</label>
                                        <input disabled type="text" className={`form-control gray-border`} id="floatingInputkey" placeholder="read only" {...register(`configuration_Key[${i}].readonly`)} />
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="form-floating">
                                        <label>{t("value Name")}</label>
                                        <input type="text" className={`form-control gray-border`} id="floatingInputkey" placeholder="value" {...register(`configuration_Key[${i}].value`)} />
                                    </div>
                                </div>
                            </Row>
                        </Card.Body>
                    </Card>
                </Row>
            ))}
            {newConfigData && validConfigKey && validConfigKey.length > 0 ? (
                <Row className='d-flex justify-content-end '>
                    <span className='col-3'>
                        {props.loading ?
                            <button className="btn lgn-btn" type="button" disabled>
                                <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                                &nbsp;
                                {t("Saving...")}
                            </button>
                            :
                            <button
                                className="lgn-btn btn ml-5 "
                                type="submit"
                                onClick={handleSubmit(onSubmit)}
                            >
                                {t("Submit")}
                            </button>}
                    </span>
                </Row>
            ) : <p className='mt-3'>{t("No Configuration Available")}</p>}
        </Container>

    )

}

const mapStateToProps = (state) => {
    return {
        loading: state.evse.isEvseLoading,
        fetchstation: state.evse.viewstation,// fetch evse list
        fetchNewConfig: state.command.fetchNewConfigData, // get new configurations
        updateConfig: state.command.saveConfig, // save new evse config
    }
}
const mapDispatchToProps = dispatch => {
    return {
        fetch: (stationid) => dispatch(fetchEvseStationAction(stationid)), // fetch evse list
        newConfig: (evse_id) => dispatch(fetchConfigData(evse_id)), // get new configurations
        saveNewConfig: (data, validEvseId) => dispatch(saveConfig(data, validEvseId)), // save new evse config
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(GetConfiguration)