import React from "react";
import { GoogleMap, Marker, MarkerClusterer } from "@react-google-maps/api";
// import discovery_icon from '../../../assets/images/discovery_icon.png'
import available_icon from '../../../assets/images/available_icon.png'
import in_use_icon from '../../../assets/images/in_use.svg'
import cs_icon from '../../../assets/images/commingsoon.svg'
import maintenance_icon from '../../../assets/images/maintenance.svg'

function CustomMarker({ location, clusterer }) {
    const { status, stationid, name } = location;
    const { latitude, longitude } = location.coordinates;

    // Define icon URLs based on the status of the station
    // The `icons` object should contain the URLs for the different icons
    // for each station status.

    const iconUrls = {
        "Available": available_icon,
        "In use": in_use_icon,
        "Coming soon": cs_icon,
        "Maintenance": maintenance_icon,
        "Inoperative": maintenance_icon,
        // "Discovery": discovery_icon
    };

    // Select the appropriate icon URL based on the status of the station
    // Use the `status` to determine which icon URL to use from `iconUrls`
    const iconUrl = iconUrls[status];
    if (status !== "Discovery") {
        return (
            <Marker
                key={stationid}
                position={{ lat: latitude, lng: longitude }}
                icon={{
                    url: iconUrl,
                    scaledSize: new window.google.maps.Size(30, 35),
                }}
                title={name} // Show station name as a tooltip
                onClick={() => {
                    // Redirect to the view single EVSE page when the marker is clicked
                    window.location.href = `/dashboard/viewsingleevse/${stationid}`;
                }}
                clusterer={clusterer}
            />
        );
    }

    return null;

}

function EVSEMap({ stations, center, zoom }) {
    const mapContainerStyle = {
        height: "81vh",
        width: "100%",
    };

    return (
        <GoogleMap
            mapContainerStyle={mapContainerStyle}
            center={center}
            zoom={zoom}
        >
            <MarkerClusterer
                options={{ imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m' }}
            >
                {(clusterer) => (
                    <>
                        {stations && stations.map((location) => (
                            <CustomMarker
                                key={location.stationid}
                                location={location}
                                clusterer={clusterer} // Pass the clusterer prop to CustomMarker
                            />
                        ))}
                    </>
                )}
            </MarkerClusterer>
        </GoogleMap>
    );
}

export default EVSEMap;

