import PureBreadcrumbs from "../../breadcrums";
import React, { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import ApexCharts from "react-apexcharts";
import { Row, Col, Card, Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { advertiseClickCountApi, allAdvertise, fetchAllReport, getTotalOveriewDataAPI } from "../../../../store/actions/advertisementAction";
import moment from "moment";
import DatePicker from "react-datepicker";
import AdvertisementTable from "./advertisementTable";
import { allEvseAction } from "../../../../store/actions/evseAction";


function AdvertisementReport(props) {
    const { t } = useTranslation();
    const [startDate, setStartDate] = useState(new Date(Date.now() - 3600 * 1000 * 720));
    const [endDate, setEndDate] = useState(new Date(Date.now() + 3600 * 1000 * 24));
    const [name, setName] = useState('All')
    const { role } = JSON.parse(localStorage.getItem('user'));


    useEffect(() => {
        props.all();
        props.allAdvertisement();
    }, []);

    const data = props.advertise_report && props.advertise_report.data;
    const totalCardData = props.fetchOverViewData && props.fetchOverViewData
    const total = props.allAdvertiseRes && props.allAdvertiseRes.data && props.allAdvertiseRes.data.length;
    const firstIndex = props.list && props.list.data && props.list.data.length > 0 ? props.list.data[0]?.name : null;


    const handleChange = (e) => {
        const value = e.target.value
        setName(value)
    }

    useEffect(() => {
        if (role === "Admin") {
            props.advertiseReportApi(name, startDate.toISOString().substr(0, 10), endDate.toISOString().substr(0, 10))
            props.getClickCountDataGraph(name, startDate.toISOString().substr(0, 10), endDate.toISOString().substr(0, 10))
            props.allOverViewApi(name, startDate.toISOString().substr(0, 10), endDate.toISOString().substr(0, 10));
        } else if ((role === "Network Operator" || role === "Enterprise") && props.list?.data?.length > 0) {
            props.advertiseReportApi(firstIndex, startDate.toISOString().substr(0, 10), endDate.toISOString().substr(0, 10));
            props.getClickCountDataGraph(firstIndex, startDate.toISOString().substr(0, 10), endDate.toISOString().substr(0, 10));
            props.allOverViewApi(firstIndex, startDate.toISOString().substr(0, 10), endDate.toISOString().substr(0, 10));
        }
    }, [role, startDate, endDate, firstIndex])


    const loadNewData = () => {
        props.allOverViewApi(name, startDate.toISOString().substr(0, 10), endDate.toISOString().substr(0, 10));
        props.getClickCountDataGraph(name, startDate.toISOString().substr(0, 10), endDate.toISOString().substr(0, 10));
        props.advertiseReportApi(name, startDate.toISOString().substr(0, 10), endDate.toISOString().substr(0, 10))
    }

    const columns = useMemo(
        () => [
            { Header: "Station Name", accessor: "station_name" },
            { Header: "Click Count", accessor: "click_count" },
            { Header: "Impression Count", accessor: "impression_count" },
            { Header: "Click-Through-Rate", accessor: "ctr" },
        ],
        []
    );

    const chartData = {
        options: {
            labels: ['CLICKS', 'IMPRESSIONS', 'CTR'],
            options: {
                chart: {
                    type: 'donut',
                    borderRadius: 80,
                },
            },
            fill: {
                type: "gradient",
                gradient: {
                    shade: "light",
                    type: "horizontal",
                    shadeIntensity: 0.5,
                    gradientToColors: ["#ff3912", "#07f2c7", "#07f24e"],
                    inverseColors: true,
                    opacityFrom: 1,
                    opacityTo: 1,
                    stops: [0, 100],
                },
            },
            tooltip: {
                y: {
                    formatter: function (value) {
                        return value.toFixed(0);
                    },
                },
            },
            dataLabels: {
                enabled: true, // Set this to false to hide data labels
            },
            legend: {
                position: "top",
            },
        },
        series: [totalCardData?.total_clicks, totalCardData?.total_impression, totalCardData?.total_ctr],
    };

    const clickCountData = props.fetchClickCount && props.fetchClickCount.data;
    // console.log("clickCountData", clickCountData);
    const labels = clickCountData && clickCountData.map(item => item.advertisement_name);
    const values = clickCountData && clickCountData.map(item => item.click_count);
    const dates = clickCountData && clickCountData.map(item => item.click_date);
    const evses = clickCountData && clickCountData.map(item => item.evse_id);
    const formattedDates = dates && dates.map(date => moment(date).format('DD MMM, YYYY'));
    const barChartDummyData = {
        series: [{
            name: 'Click Count', // Changed series name here
            data: values
        }],
        options: {
            chart: {
                type: 'bar',
                height: 350,
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    barWidth: "80%",
                    // columnWidth: "90%",
                    distributed: false,
                    borderRadius: 5,
                },
            },
            dataLabels: {
                enabled: true,
                formatter: function (values) {
                    return `${values} `;
                }
            },
            xaxis: {
                categories: formattedDates,
                labels: {
                    formatter: function (val) {
                        return val;
                    }
                }
            },
            yaxis: {
                title: {
                    text: 'Click Counts / Day'
                }
            },
            fill: {
                type: "gradient",
                gradient: {
                    type: "vertical",
                    shadeIntensity: 0.5,
                    opacityFrom: 1,
                    opacityTo: 0.8,
                    stops: [0, 100],
                },
            },
            tooltip: {
                y: {
                    formatter: function (val) {
                        return val;
                    }
                },
                // Custom tooltip formatter to display advertisement name instead of date
                custom: function ({ series, seriesIndex, dataPointIndex, w }) {
                    const name = labels[dataPointIndex];
                    const evse_id = evses[dataPointIndex];
                    const count = w.globals.series[seriesIndex][dataPointIndex];
                    // console.log(w.globals.series)
                    return `<div class="tooltip-container">
                                <span class="tooltip-label" id="top_label">Advertisement Name: <span class="tooltip-value">${name}</span></span>
                                <span class="tooltip-label mt-0" id="top_label">Evse I'd: <span class="tooltip-value">${evse_id}</span></span>
                                <span class="tooltip-label" id="bottom_label">Click Count: <span class="tooltip-value">${count}</span></span>
                            </div>`;
                }
            }
        },
    };
    // console.log(props.fetchAdvertiseName);
    return (
        <Container fluid className="pb-4">
            <PureBreadcrumbs />
            <Card className="mt-2">
                <div className="table-header pt-2">{t("Advertisement Report")}</div>
                <Card.Body>
                    <Row className="mt-2">
                        <Col lg={3} xs={12} md={3} style={{ display: 'flex', flexDirection: 'column-reverse' }}>
                            <Card className="totaladscard" >
                                <div style={{ textAlign: "left" }}>
                                    <Card.Text style={{ fontSize: "14px" }}>
                                        {t("TOTAL ADVERTISEMENT")}
                                    </Card.Text>
                                    <Card.Title className="text-center" style={{ marginTop: "2rem" }}>
                                        <b className="">{total ? total : 0}</b>
                                    </Card.Title>
                                </div>
                            </Card>
                        </Col>
                        <Col lg={9} xs={12} md={12} className="d-flex justify-content-end align-items-center">
                            <div className="row d-flex justify-content-center">
                                <div className="" style={{ marginTop: "1.9rem" }}>
                                    {/* <label className="mr-4">Select Advertisement Name :</label> */}
                                    <div className="">
                                        <select className={`form-select`} style={{ borderRadius: "5px", borderColor: "#acaaaa" }} name="advertismentName" id="floatingSelectGridAdvertismentFor" onChange={handleChange}>
                                            {role === 'Admin' && (
                                                <option value="All">{t("All")}</option>
                                            )}
                                            {props.list && props.list.data?.map((v) => (
                                                <option key={v.stationid} value={v.name}>
                                                    {v.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className="col-auto claender" style={{ marginTop: "1.9rem" }}>
                                    <DatePicker

                                        className="gray-border mb-2"
                                        selected={startDate}
                                        onChange={(date) => setStartDate(date)}
                                        selectsStart
                                        startDate={startDate}
                                        dateFormat="dd/MM/yyyy"
                                        maxDate={new Date()}
                                    />
                                </div>
                                <span style={{ paddingTop: "3px", marginTop: "1.9rem" }} > To </span>
                                <div className="col-auto" style={{ marginTop: "1.9rem" }}>
                                    <DatePicker
                                        className="gray-border"
                                        selected={endDate}
                                        onChange={(date) => setEndDate(date)}
                                        selectsEnd
                                        startDate={startDate}
                                        endDate={endDate}
                                        minDate={startDate}
                                        showDisabledMonthNavigation
                                        dateFormat="dd/MM/yyyy"
                                        maxDate={new Date()}
                                    />
                                </div>
                            </div>
                            <div className="csvbutton ml-3" style={{ marginTop: "1.5rem" }}>
                                <button className="btn btn-sm" style={{ borderRadius: "12px" }} onClick={loadNewData}>
                                    <span
                                        style={{
                                            fontSize: "16px",
                                            color: "#676767",
                                            fontWeight: "600",
                                        }}
                                    >
                                        GO
                                    </span>
                                </button>
                            </div>
                        </Col>
                    </Row>

                    <Row className="mb-3 mt-3">
                        <Col lg={4} xs={12} md={3} style={{ display: 'flex', flexDirection: 'column-reverse' }}>

                            <Card className="adscustomercard advertise_card" >
                                <div style={{ textAlign: "left" }}>
                                    <Card.Text style={{ fontSize: "14px" }}>
                                        {t("CLICKS")}
                                    </Card.Text>
                                    <Card.Title className="text-center">
                                        <b>{totalCardData?.total_clicks}</b>
                                    </Card.Title>
                                </div>
                            </Card>
                            <br />
                        </Col>
                        <Col lg={4} xs={12} md={3} style={{ display: 'flex', flexDirection: 'column-reverse' }}>
                            <Card className="adscustomercard advertise_card">
                                <div style={{ textAlign: "left" }}>
                                    <Card.Text style={{ fontSize: "14px" }}>
                                        {t("IMPRESSIONS")}
                                    </Card.Text>
                                    <Card.Title className="text-center">
                                        <b>{totalCardData?.total_impression}</b>
                                    </Card.Title>
                                </div>
                            </Card>
                            <br />
                        </Col>
                        <Col lg={4} xs={12} md={3} style={{ display: 'flex', flexDirection: 'column-reverse' }}>
                            <Card className="adscustomercard advertise_card">
                                <div style={{ textAlign: "left" }}>
                                    <Card.Text style={{ fontSize: "14px" }}>
                                        {t("CTR (CLICK-THROUGH-RATE)")}
                                    </Card.Text>
                                    <Card.Title className="text-center">
                                        <b>{totalCardData?.total_ctr} %</b>
                                    </Card.Title>

                                </div>
                            </Card>
                            <br />
                        </Col>
                    </Row>
                    <Row className="mt-2 mb-3">
                        <Col lg={4} className="mb-3">
                            <Card>
                                <div className="table-header pt-3">{t("Total Overview")}</div>
                                {totalCardData?.total_clicks > 0 || totalCardData?.total_impression > 0 ?
                                    <div>
                                        <ApexCharts
                                            className="pt-4"
                                            options={chartData.options}
                                            series={chartData.series}
                                            type="donut"
                                            height={340}
                                        />
                                    </div>
                                    :
                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            height: "369px",
                                        }}
                                    >
                                        <h3>{t("No Data Found")}</h3>
                                    </div>
                                }
                            </Card>
                        </Col>
                        <Col lg={8}>
                            <Card className="adscustomercard">
                                <div className="table-header">{t("Click Engaged")}</div>
                                {props.loading ? <h3 style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    height: "250px",
                                }}>Loading data...</h3> :
                                    props.advertise_report && props.advertise_report.data && props.advertise_report.data.length !== null ? (
                                        <div>
                                            <AdvertisementTable data={data} columns={columns}></AdvertisementTable>
                                        </div>
                                    ) : (
                                        <div
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                height: "310px",
                                            }}
                                        >
                                            <h3>{t("No Data Found")}</h3>
                                        </div>
                                    )}

                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12}>
                            <Card className="tariffcard">
                                <div className="table-header">{t("Advertisement Click Counts")}</div>
                                {clickCountData && clickCountData.length > 0 ?
                                    <ApexCharts
                                        className="pt-2"
                                        options={barChartDummyData.options}
                                        series={barChartDummyData.series}
                                        type="bar"
                                        height={340}
                                    />
                                    :
                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            height: "310px",
                                        }}
                                    >
                                        <h3>{t("No Data Found")}</h3>
                                    </div>
                                }

                            </Card>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>

        </Container>
    );
}

const mapStateToProps = (state) => {
    return {
        loading: state.advertise.isLoading,
        fetchOverViewData: state.advertise.fetch_overview_data,
        // fetchAdvertiseName: state.advertise.fetch_advertisement_dropdown_api,
        fetchClickCount: state.advertise.fetch_advertise_click_count_api,
        advertise_report: state.advertise.fetch_all_advertise_report,
        list: state.evse.allevse,
        allAdvertiseRes: state.advertise.fetchall,

    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        allOverViewApi: (name, startDate, endDate) => dispatch(getTotalOveriewDataAPI(name, startDate, endDate)),
        getClickCountDataGraph: (name, startDate, endDate) => dispatch(advertiseClickCountApi(name, startDate, endDate)),
        // allAdvertiseNameDropDownApi: () => dispatch(advertisementNameDropdownApi()),
        advertiseReportApi: (name, startDate, endDate) => dispatch(fetchAllReport(name, startDate, endDate)),
        all: () => dispatch(allEvseAction()),
        allAdvertisement: () => dispatch(allAdvertise()),

    };
};
export default connect(mapStateToProps, mapDispatchToProps)(AdvertisementReport);
