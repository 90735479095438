import React from 'react'
import { useEffect } from "react";
import { Row, Col, Card } from "react-bootstrap";
import { connect } from "react-redux";
import PureBreadcrumbs from "../../../breadcrums";
import { viewSingleNotificationAction } from '../../../../../store/actions/campaignAction';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import moment from 'moment';

function ViewSingleNotification(props) {
    const { id } = useParams()
    // console.log(id);
    useEffect(() => {
        props.viewNotifications(id)
    }, [])

    const data = props.viewNotificationsCampaign && props.viewNotificationsCampaign.data[0];
    // console.log(data)

    const date = moment.utc(data?.date).format('DD-MM-YYYY');
    // console.log(date); 

    return (
        <div className='container-fluid'>
            <PureBreadcrumbs />
            <Row className='mt-5'>
                <Col lg={12}>
                    <Card className='tariffcard'>
                        <b>View single notification details</b>
                        <Card.Body >
                            <form>
                                <div className="form-floating">
                                    <label htmlFor="floatingSelectGridU">Selected Recipients</label>
                                    <input disabled value={data?.recipients || ''} type="recipients" className={`form-control gray-border `} name="recipients" id="floatingInputGridrecipients" placeholder="Enter SMS recipients" />
                                </div><br />

                                <div className="form-floating">
                                    <label> Notification Title <span className='mandatory-field'>*</span></label>
                                    <input disabled value={data?.title || ''} type="description" className={`form-control gray-border `} name="title" id="floatingInputGridtitle" placeholder="Enter SMS Notification Title" />
                                </div><br />

                                <div className="form-floating">
                                    <label>Notification Message <span className='mandatory-field'>*</span></label>
                                    <input disabled value={data?.message || ''} type="description" className={`form-control gray-border `} name="message" id="floatingInputGridmessage" placeholder="Message" />
                                </div><br />

                                <div className="form-floating">
                                    <Row>
                                        <Col md={4}>
                                            Selected Date <span className='mandatory-field'>*</span> :
                                        </Col>
                                        <Col md={4}>
                                            <input disabled value={date || ''} type="text" className={`form-control gray-border `} name="date" id="floatingInputGriddate" placeholder="date" />

                                        </Col>
                                    </Row>
                                </div>
                            </form>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {

        loading: state.campaign.isLoading,
        viewNotificationsCampaign: state.campaign.viewNotifications
    }
}
const mapDispatchToProps = dispatch => {
    return {

        viewNotifications: (id) => dispatch(viewSingleNotificationAction(id))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ViewSingleNotification)
